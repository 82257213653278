import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import Form from './Form'
import Select from './Select'

import { closePopup } from '../actions/popup'

import { oauth, formatDate, parseMysqlDate } from '../utils'
import { getStatusOptions } from '../selectors/dropdowns'

import { BASE_ZINDEX } from '../popup-factory'

class CheckOrderStatusPopup extends Component {
  constructor (props) {
    super(props)

    let timeOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }

    this.state = {
      timeOptions: timeOptions,
      section: null,
      statuses: null,
      suggested_promo_status: null,
      new_po_status: null,
      new_status_id: null,
      ship_notifications: null,
      shipment_date: null,
      tenant_details: null,
      credential_status: '',
      error: ''
    }

    _.bindAll(this, ['onFormRef', 'handleFieldChange', 'requestCredential', 'updateShipmentDate', 'handleChangeStatus'])
  }

  returnArray (test) {
    if (test.constructor !== Array) {
      return [test]
    } else {
      return test
    }
  }

  handleChangeStatus (e) {
    this.props.onUpdate(this.props.purchase_order_id, 'status_id', this.state.new_status_id)(e)
    this.setState({ new_status_id: e })
  }

  UNSAFE_componentWillMount () {
    this.checkOrderStatus()
  }

  checkOrderStatus (send_credential_request=false) {
    const data = {
      'purchase_order_id': this.props.purchase_order_id,
      'division_id': this.props.division_id,
      'reference_number': this.props.form_number,
      'query_type': 1,
      'action': 'order_status'
    }

    if (send_credential_request) {
      data.send_credential_request = true
    }

    oauth('POST', 'promo-standards', data).then((response) => {
      if (response.json) {
        let json = response.json

        this.setState({
          section: json.section,
          request_msg: json.request_msg ? json.request_msg : '',
          hide_request_btn: json.hide_request_btn ? json.hide_request_btn : false,
          sent_credential_request: json.sent_credential_request ? json.sent_credential_request : false,
          statuses: json.status && json.status.order_status ? this.returnArray(json.status.order_status.OrderStatusDetailArray.OrderStatusDetail) : null,
          suggested_promo_status: json.status ? json.status.suggested_promo_status : null,
          new_po_status: json.status ? json.status.new_po_status : null,
          new_status_id: json.status ? json.status.new_status_id : null,
          ship_notifications: json.ship_notification ? this.returnArray(json.ship_notification.SalesOrderArray.SalesOrder) : null,
          shipment_date: json.shipment_date,
          tenant_details: json.tenant_details ? json.tenant_details : null,
          error: json.error ? `Error: ${json.error}` : ''
        })
      }
    })
  }

  renderLoading () {
    return (
      <div className='row popup-content column'>
        <div className='small-12 columns'>
          <div className='small-12 text-center'><br /><img src='/images/gears.gif' /><br /></div>
        </div>
      </div>
    )
  }

  onFormRef (form) {
    this._form = form
  }

  handleFieldChange (value, field) {
    this.setState({ tenant_details: Object.assign({}, this.state.tenant_details, {[field]: value}) })
  }

  requestCredential (values, errors) {
    if (!_.every(errors, (error) => error === null)) {
      return
    }

    values.division_id = this.props.division_id
    values.action = 'get_supplier_account_credential'

    oauth('POST', 'promo-standards', values).then((response) => {
      if (response.json) {
        let json = response.json

        if (json.section === 'credential') {
          this.setState({ section: json.section, credential_status: json.credential_status })
        } else {
          this.checkOrderStatus()
        }
      }
    })
  }

  updateShipmentDate () {
    const { onUpdate, purchase_order_id, date_shipped, onClosePopup } = this.props

    onUpdate(purchase_order_id, 'date_shipped', date_shipped)(this.state.shipment_date)
    onClosePopup()
  }

  renderRequestSection () {
    const { tenant_details, request_msg, hide_request_btn } = this.state

    /* const country_options = [
      {key: '', value: ''},
      {key: 'US', value: 'United States'},
      {key: 'CA', value: 'Canada'}
    ] */

    return (
      /* <Form className='row popup-content form'
        ref={this.onFormRef}
        onFieldChange={this.handleFieldChange}
        onSubmit={this.requestCredential}
      >
        <Form.TextInput label='Account Number' field='account_id' value={tenant_details.account_id} required />
        <Form.TextInput label='Company Name' field='company_name' value={tenant_details.company_name} required />
        <Form.TextInput label='Company Phone' field='company_phone' value={tenant_details.company_phone} required />
        <Form.TextInput label='Address 1' field='address_line_1' value={tenant_details.address_line_1} required />
        <Form.TextInput label='Address 2' field='address_line_2' value={tenant_details.address_line_2} required />
        <Form.TextInput label='Address 3' field='address_line_3' value={tenant_details.address_line_3} />
        <Form.TextInput label='Address 4' field='address_line_4' value={tenant_details.address_line_4} />
        <Form.TextInput label='City' field='address_city' value={tenant_details.address_city} required />
        <Form.TextInput label='State/Province' field='address_region' value={tenant_details.address_region} required />
        <Form.TextInput label='Postal/Zip Code' field='address_postal' value={tenant_details.address_postal} required />
        <Form.Select label='Country' field='address_country' options={country_options} value={tenant_details.address_country} required />
        <Form.TextInput label='Contact Email' field='contact_email' value={tenant_details.contact_email} required />
        <Form.TextInput label='Contact First Name' field='contact_first_name' value={tenant_details.contact_first_name} />
        <Form.TextInput label='Contact Last Name' field='contact_last_name' value={tenant_details.contact_last_name} />
        <Form.TextInput label='Contact Phone Number' field='contact_phone' value={tenant_details.contact_phone} required />
      </Form> */
      <div>
        <div dangerouslySetInnerHTML={{ __html: request_msg }}></div>
        <button className="button" onClick={e => {e.preventDefault(); this.checkOrderStatus(true); this.props.onClosePopup(); }} style={hide_request_btn ? {display: 'none'} : null}>Request Credentials</button>
      </div>
    )
  }

  renderCredentialSection () {
    return (
      <div>
        <h4 style={{ fontWeight: 'bold' }}>The status of your request for credential to the supplier is <span>{this.state.credential_status}</span>.</h4>
        <p>If it is Pending, there is currently no extra action required on your end and when we receive your credential from the supplier, this popup will show order status and shipment notification.</p>
        <p>If it is Failed, the supplier's endpoint might be experiencing issues. Please try again later.</p>
        <p>If it is Rejected, you have to contact the Supplier to resolve the issue.</p>
      </div>
    )
  }

  renderStatusSection () {
    const { hasCapability, statuses, onUpdate, purchase_order_id, date_shipped } = this.props

    return (
      <div>
        <p>Promostandards order statuses have different names and values than commonsku does.</p>
        <p title='(worst aggregated status among all factory orders from supplier)'>Promostandard status : {this.state.suggested_promo_status}</p>
        <p>Suggested commonsku status : <span style={{fontWeight: 'bold'}}>{this.state.new_po_status}</span></p>
        <p>commonsku status: </p>
        <Select disabled={!hasCapability('MODIFY-PURCHASE-ORDER')} options={statuses} value={this.state.new_status_id} change={e => this.handleChangeStatus(e)} />
        <br />
        <table style={{border: '1px solid #d7d7d7'}}>
          <thead>
            <tr style={{background: '#eee'}}>
              <td>Factory Order Number</td>
              <td>Status Name</td>
              <td>Expected Ship Date</td>
              <td>Expected Delivery Date</td>
              <td>Respond To</td>
              <td>Additional Explanation</td>
              <td>Response Required</td>
              <td>Updated At</td>
            </tr>
          </thead>
          <tbody>
            {this.state.statuses ? this.state.statuses.map((s, i) =>
              this.renderStatus(s, i)
            ) : null}
          </tbody>
        </table>
        <br />
        <p>PO Shipping Date: <span>{date_shipped && date_shipped.toString().match(/^\d+$/) ? formatDate(date_shipped, true) : date_shipped && date_shipped.toString().match(/^\d+-\d+-\d+ \d+:\d+:\d+$/) ? parseMysqlDate(date_shipped) : null}</span></p>
        <p>Suggested Shipping Date: <span style={{fontWeight: 'bold'}}>{this.state.shipment_date != 0 ? parseMysqlDate(this.state.shipment_date) : null}</span>&nbsp;&nbsp;
          {this.state.shipment_date ? <button className='button' style={{ marginTop: '10px' }} onClick={e => { e.preventDefault(); this.updateShipmentDate() }}>Update</button> : null}
        </p>
        <br />
        <table style={{ border: '1px solid #d7d7d7' }}>
          <tbody>
            {this.state.ship_notifications ? this.state.ship_notifications.map((s, i) =>
              this.renderShipNotification(s, i)
            ) : null}
          </tbody>
        </table>
      </div>
    )
  }

  renderStatus (value, i) {
    let { timeOptions } = this.state

    let expectedShipDate = value.expectedShipDate ? new Date(value.expectedShipDate).toLocaleTimeString('en-us', timeOptions) : 'N/a'
    let expectedDeliveryDate = value.expectedDeliveryDate ? new Date(value.expectedDeliveryDate).toLocaleTimeString('en-us', timeOptions) : 'N/a'
    let updatedAt = value.validTimestamp ? new Date(value.validTimestamp).toLocaleDateString('en-us', timeOptions) : 'N/a'
    let responders = value.ResponseToArray ? this.returnArray(value.ResponseToArray.RespondTo) : null
    let responder_str = ''
    if (responders) {
      _.each(responders, function (responder) {
        responder_str += responder.name + '<br />'
        if (responder.emailAddress) {
          responder_str += responder.emailAddress + '<br />'
        }
        if (responder.phoneNumber) {
          responder_str += responder.phoneNumber + '<br />'
        }
        responder_str += '<br />'
      })
    } else {
      responder_str = 'N/a'
    }

    return (
      <tr key={i} style={{ borderBottom: '1px solid #d7d7d7' }}>
        <td>{value.factoryOrderNumber}</td>
        <td>{value.statusName}</td>
        <td>{expectedShipDate}</td>
        <td>{expectedDeliveryDate}</td>
        <td>{responder_str}</td>
        <td>{value.additionalExplanation ? value.additionalExplanation : 'N/a'}</td>
        <td>{value.responseRequired}</td>
        <td>{updatedAt}</td>
      </tr>
    )
  }

  renderShipNotification (value, i) {
    let locations = this.returnArray(value.ShipmentLocationArray.ShipmentLocation)

    return (
      <tr key={i}>
        <tr style={{ background: '#ccc' }}>
          <td>Factory Order Number</td>
          <td>Complete</td>
          <td>Shipping From</td>
          <td>Shipping To</td>
        </tr>
        {locations ? locations.map((l, i) =>
          this.renderShipLocation(value.salesOrderNumber, l, i)
        ) : null}
      </tr>
    )
  }

  renderShipLocation (salesOrderNumber, location, i) {
    let ship_from = location.ShipFromAddress.address1 + '<br />' +
          (location.ShipFromAddress.address2 ? location.ShipFromAddress.address2 + '<br />' : '') +
          (location.ShipFromAddress.address3 ? location.ShipFromAddress.address3 + '<br />' : '') +
          (location.ShipFromAddress.address4 ? location.ShipFromAddress.address4 + '<br />' : '') +
          location.ShipFromAddress.city + ' ' + location.ShipFromAddress.region + ' ' +
          location.ShipFromAddress.postalCode + ' ' + location.ShipFromAddress.country
    let ship_to = location.ShipToAddress.address1 + '<br />' +
          (location.ShipToAddress.address2 ? location.ShipToAddress.address2 + '<br />' : '') +
          (location.ShipToAddress.address3 ? location.ShipToAddress.address3 + '<br />' : '') +
          (location.ShipToAddress.address4 ? location.ShipToAddress.address4 + '<br />' : '') +
          location.ShipToAddress.city + ' ' + location.ShipToAddress.region + ' ' +
          location.ShipToAddress.postalCode + ' ' + location.ShipToAddress.country

    let boxes = this.returnArray(location.PackageArray.Package)

    return (
      <tr key={i}>
        <tr style={{ borderBottom: '1px solid #d7d7d7' }}>
          <td>{salesOrderNumber}</td>
          <td>{location.complete.toString()}</td>
          <td dangerouslySetInnerHTML={{ __html: ship_from }} />
          <td dangerouslySetInnerHTML={{ __html: ship_to }} />
        </tr>
        <tr style={{ background: '#eee' }}><td>Tracking Number</td><td colSpan='2'>Shipment Date</td><td>Shipment Method</td></tr>
        {boxes ? boxes.map((b, i) =>
          this.renderBox(b, i)
        ) : null}
      </tr>
    )
  }

  renderBox (box, i) {
    let { timeOptions } = this.state
    let ship_date = box.shipmentDate ? new Date(box.shipmentDate).toLocaleTimeString('en-us', timeOptions) : 'N/a'

    return (
      <tr key={i} style={{ borderBottom: '1px solid #d7d7d7' }}>
        <td>{box.trackingNumber}</td>
        <td colSpan='2'>{ship_date}</td>
        <td>{box.shipmentMethod}</td>
      </tr>
    )
  }

  renderError () {
    return (
      <p style={{ color: 'red', fontWeight: 'bold'}}>{this.state.error}</p>
    )
  }

  render () {
    const { purchase_order_id, division_id, form_number, date_shipped, statuses, hasCapability, onUpdate, onClosePopup, index } = this.props
    const leftButtonStyle = {
      position: 'fixed',
      right: '5rem',
      top: '1rem'
    }
    const rightButtonStyle = {
      position: 'fixed',
      right: '1rem',
      top: '1rem'
    }

    return (
      <div id='check-order-status-modal' className='reveal large' style={{ display: 'block', zIndex: BASE_ZINDEX + index }} aria-labelledby='modalTitle' aria-hidden='true' role='dialog'>
        <div className='row'>
          <div className='small-12 columns'>
            <h3 id='modalTitle'>Order Status & Shipment Notification ({form_number})</h3>
            <a className='alert button' style={rightButtonStyle} onClick={e => onClosePopup()}>Cancel</a>
            {/* this.state.section === 'request' 
              ? <a className='button' style={{ position: 'fixed', right: '1rem', top: '1rem' }} onClick={(e) => { e.preventDefault(); this._form && this._form.submit() }}>Save</a>
            : null */}
          </div>
        </div>
        <div className='row popup-content'>
          {this.state.error !== '' ? this.renderError() : null}
          {this.state.section === 'request'
            ? this.renderRequestSection()
            : this.state.section === 'credential'
              ? this.renderCredentialSection()
              : this.state.section === 'status'
                ? this.renderStatusSection()
                : this.renderLoading()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    statuses: getStatusOptions(state, { order_type: 'PURCHASE ORDER' }),
    hasCapability: capability => state.identity.capabilities.includes(capability)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClosePopup: () => {
      dispatch(closePopup())
    }
  }
}

const ConnectedCheckOrderStatusPopup = connect(mapStateToProps, mapDispatchToProps)(CheckOrderStatusPopup)
export default ConnectedCheckOrderStatusPopup
