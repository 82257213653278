import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import {
    createCopyItem
} from '../actions'
import { closePopup } from '../actions/popup'

import { toTitleCase } from '../utils'

import { BASE_ZINDEX } from '../popup-factory'

import { OptionItemOptionRow, OptionItemCostRow } from './OptionItemDetailedSummary'

class CopyItemPopup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            quantity: 0
        }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e) {
        this.setState({ quantity: e.target.value })
    }

    renderBody() {
        const { item } = this.props;

        const visible_options = item.options.filter(o => o.hidden == 0);
        const options = visible_options.length ?
            visible_options.map((o, i) =>
                <OptionItemOptionRow key={o.item_id} index={i + 1} base_item={item} option_item={o} convert={true} handleChangeQuantity={this.handleChange} />
            ) : [<OptionItemOptionRow key={item.item_id} index={1} base_item={item} convert={true} handleChangeQuantity={this.handleChange} />];
        return <tbody style={{ borderTop: '1px solid #ddd' }}>
            {options.concat(item.item_costs.map(ic =>
                <OptionItemCostRow key={ic.item_cost_id} convert={true} base_item={item} item_cost={ic} />
            ))}</tbody>;
    }

    render() {
        const { item, add_to_order, job_number, onClosePopup, index } = this.props;

        return (
            <div id="copy-modal" className="reveal large" style={{ display: 'block', zIndex: BASE_ZINDEX + index }} data-reveal aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
                <div className="row">
                    <div className="small-12 columns">
                        <h3 id="modalTitle">Copy Presentation Item</h3>
                        <a className="button" style={{ position: 'fixed', right: '1rem', top: '1rem' }} 
                            onClick={e => { e.preventDefault(); this.props.onCopyItem(item.item_id, null, add_to_order, this.state.quantity, job_number) }}>
                             Copy To {toTitleCase(add_to_order.order_type)}
                        </a>
                        <a className="alert button" style={{ position: 'fixed', right: '11rem', top: '1rem' }} onClick={e => onClosePopup()}>Cancel</a>
                    </div>
                </div>
                <div className="row popup-content">
                    <div className="small-12 columns popup-size-limit">
                        <table className="convert-products-table">
                            <thead>
                                <tr>
                                    <th width="12%">Quantity</th>
                                    <th colSpan="2">Products</th>
                                    <th>Units</th>
                                    <th>Margin</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            {this.renderBody()}
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        job_number: Object.values(state.entities.projects)[0].job_number
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onClosePopup: () => {
            dispatch(closePopup());
        },
        onCopyItem: (item_id, index, target_order, quantity, job_number) => {
            dispatch(createCopyItem(item_id, index, target_order.order_id, quantity, job_number));
            if (target_order.order_type === 'SALES ORDER') {
                dispatch(push('/project/' + job_number + '/sales-order/' + target_order.form_number));
            } else {
                dispatch(push('/project/' + job_number + '/' + target_order.order_type.toLowerCase() + '/' + target_order.form_number));
            }
            dispatch(closePopup());
        },
    }
}

const ConnectedCopyItemPopup = connect(mapStateToProps, mapDispatchToProps)(CopyItemPopup);
export default ConnectedCopyItemPopup;
