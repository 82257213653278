import React, { Component } from 'react'
import { connect } from 'react-redux'

import { closePopup } from '../actions/popup'

import { oauth } from '../utils'

import { BASE_ZINDEX } from '../popup-factory'

class NewFeaturePopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            max_width: window.innerWidth > 1024 ? 1024 : window.innerWidth / 1.25 - 50,
            max_height: window.innerHeight > 720 ? 720 : window.innerHeight - 100
        }

        this.handleClosePopup = this.handleClosePopup.bind(this)
    }

    handleClosePopup() {
        const { user_id, onClosePopup } = this.props

        oauth('PUT', `user/${user_id}`, { hide_popup: true })
        onClosePopup()
    }

    render() {
        const { company_type, onClosePopup, index } = this.props;
        const { show_popup, max_width, max_height } = this.state
        const popup_width =  window.innerWidth > 1024 ? 1024 : window.innerWidth / 1.25
        const popup_height = window.innerHeight > 720 ? 720 : window.innerHeight - 50

        return (
            <div id='new-feature-modal' className='reveal small' 
                style={{ display: 'block', zIndex: BASE_ZINDEX + index, width: popup_width, height: popup_height, maxHeight: popup_height }} 
                aria-labelledby='modalTitle' aria-hidden='true' role='dialog'>
                <div className='row'>
                    <div className='small-12 columns'>
                        <a className='alert button' style={{ position: 'fixed', right: '1rem', top: '1rem' }} onClick={e => this.handleClosePopup()}>Close</a>
                    </div>
                </div>
                <div className='row' style={{ overflow: 'hidden' }}>
                    <iframe src={`https://${window.location.hostname}/${company_type.toLowerCase()}_user_popup.php`} frameborder="0" style={{ width: max_width, height: max_height, border: 'none' }}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user_id: state.identity.user_id,
        company_type: state.identity.company_type
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onClosePopup: () => {
            dispatch(closePopup())
        },
    }
}


const ConnectedNewFeaturePopup = connect(mapStateToProps, mapDispatchToProps)(NewFeaturePopup)
export default ConnectedNewFeaturePopup