import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import LabelledInput from './LabelledInput'
import LabelledTextArea from './LabelledTextArea'

import { closePopup } from '../actions/popup'
import { createAddTemp, createDeleteTemp, createClearTemp } from '../actions/temp'
import { sendColleaguesInvites } from '../actions'

import { BASE_ZINDEX } from '../popup-factory'

class InviteColleaguesPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      contact_email: '',
      contact_first_name: '',
      contact_last_name: '',
      custom_text: "I’d love to connect with you on commonsku, the business social network designed exclusively for promotional products professionals.",
      invites: props.invites
    };

    _.bindAll(this, ['onChange', 'onAdd', 'handleSendInvites']);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ invites: nextProps.invites });
  }

  onChange(name) {
    return value => {
      this.setState({ [name]: value });
    };
  }

  onAdd() {
    let email = this.state.contact_email + this.props.email_domain;
    let new_state = Object.assign({} , this.state);
    new_state.contact_email = email;
    delete new_state.invites;
    this.props.onCreateAddTemp(email, new_state);
    this.setState({
      contact_email: '',
      contact_first_name: '',
      contact_last_name: '',
      custom_text: "I’d love to connect with you on commonsku, the business social network designed exclusively for promotional products professionals.",
    });
  }

  handleSendInvites() {
    let invites = Object.values(this.state.invites).length > 1 ? { invites: Object.values(this.state.invites) } : Object.values(this.state.invites)[0];
    this.props.onSendColleaguesInvites(invites);   
    this.props.onClosePopup(); 
    this.props.onCreateClearTemp();
  }

  render() {
    const { company_name, email_domain, invites, onClosePopup, onCreateAddTemp, onCreateDeleteTemp, onCreateClearTemp, onSendColleaguesInvites, index } = this.props;
    const disabled_add = this.state.contact_email == '' || this.state.contact_first_name == '' || this.state.contact_last_name == '' || this.state.custom_text == '' ? true : false; 
    const disabled_send = this.state.invites ? false : true;

    return (
      <div className="reveal large" style={{display: 'block', zIndex: BASE_ZINDEX + index}} aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <h3 id="modalTitle" className="tour-color-red">Invite {company_name} colleagues</h3>  
        <div className="row popup-content">
          <p>If you want to invite others from your team to the trial, you can just complete their names and email address below and we will send them an activation link:</p>
          <div className="row">
            <LabelledInput className="small-6 columns" placeholder="First Name" value={this.state.contact_first_name} onChange={this.onChange('contact_first_name')} />
            <LabelledInput className="small-6 columns" placeholder="Last Name" value={this.state.contact_last_name} onChange={this.onChange('contact_last_name')} />
          </div>
          <div className="row">
            <LabelledInput className="small-6 columns" placeholder="Email Address" value={this.state.contact_email} onChange={this.onChange('contact_email')} />
            <p className="small-3 columns" style={{marginTop: '5px'}}>{email_domain}</p>
            <button className="button small-2 columns float-left" onClick={e => {e.preventDefault(); this.onAdd()}} disabled={disabled_add}>+ Add Colleague</button>
          </div>
          <LabelledTextArea className="small-12 columns" placeholder="Personal Message" value={this.state.custom_text} onChange={this.onChange('custom_text')} />
          <div className="row columns">
            {this.state.invites ? invites.map(i => 
              <div key={i.contact_email} className="react-tagsinput-tag tour-tag">{i.contact_email} <a href="javascript:void(0);" onClick={e => {e.preventDefault(); onCreateDeleteTemp(i.contact_email)}}></a></div>
            ) : null}
          </div>
          <button className="button alt float-right" onClick={e => {e.preventDefault(); this.handleSendInvites()}} disabled={disabled_send} style={{marginRight: '10px'}}>Send Invites</button>
        </div>
        <button className="close-button" aria-label="Close modal" type="button" onClick={e => {e.preventDefault(); onClosePopup();}}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let company_name = state.identity.company_name;
  let email_domain = state.identity.login_email.substring(state.identity.login_email.indexOf("@"));
  let invites = state.temp ? Object.values(state.temp) : [];

  return {
    company_name,
    email_domain,
    invites
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClosePopup: () => {
      dispatch(closePopup());
    },
    onCreateAddTemp: (key, value) => {
      dispatch(createAddTemp(key, value));
    },
    onCreateDeleteTemp: (key) => {
      dispatch(createDeleteTemp(key));
    },
    onSendColleaguesInvites: (invites) => {
      dispatch(sendColleaguesInvites(invites));
    },
    onCreateClearTemp: () => {
      dispatch(createClearTemp());
    }
  }
}

const ConnectedInviteColleaguesPopup = connect(mapStateToProps, mapDispatchToProps)(InviteColleaguesPopup);
export default ConnectedInviteColleaguesPopup;

