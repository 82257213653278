import React, { Component } from 'react';
import { connect } from 'react-redux'

import { closePopup } from '../actions/popup'
import { BASE_ZINDEX } from '../popup-factory'
import {
    createLoadDecoratorList,
    createLoadDecorationsByData,
    createLoadUnitsByData,
    createLoadDecorationChargesByData,
    createUpdateDecorationChargeItemData,
    createUpdateDecorationChargeItemListCharges,
} from '../actions/admin_decoration_charges';

import Select from './Select';
import { ListItemPricingTable, PricingTable } from './admin-decoration-matrix/helpers';
import TextInput from './TextInput';

const createDropDownArray = (data, k, v) => {
    let new_data = data ? Object.values(data) : [];
    new_data = new_data.map(val => ({key: val[k], value: val[v]}));
    return new_data;
}

function getPerAdditionalUnitCharge({matrix, charges, chargeKey, charge, unit}) {
    const max_unit = matrix.data[matrix.data.length-2];
    let x = parseInt(max_unit['-']);
    let xV = parseFloat(max_unit[chargeKey]);
    let y = parseInt(charges.data[0]['-']);
    let yV = charge;
    let z = parseInt(unit);

    let d = 0;
    if (z > x) {
        d = Math.ceil((z-x)/y);
        charge = xV + (d*yV);
    }

    return charge;
}


class ApplyMatrixToItemPopup extends Component {
    constructor(props) {
        super(props);
        this.descRef = React.createRef();
  
        this.state = {
            error: '',
            decoration: '',
            decorator_type: 'division',
            ...this.initializeStateVals(),
        };

        [
            'onSelectDecorator',
            'onSelectDecoration',
            'onSelectUnit',
            'updateCharge',
            'getChargeToAdd',
            'applyCharges',
            'onChangeUnit',
            'onChangeQty',
        ].forEach(f => this[f] = this[f].bind(this));
    }

    componentDidMount() {
        this.props.loadDecorators(this.state.decorator_type);

        if (this.state.decorator_id)
        {
            this.onSelectDecorator({
                value: this.state.decorator_id,
            });

            if (this.state.decoration_id && this.state.unit 
                && this.props._charge_type == this.props.charge_type)
            {
                this.onSelectDecoration({value: this.state.decoration_id});
                this.onChangeUnit({value: this.state.unit});
            }
        }
    }

    initializeStateVals() {
        const initialState =  {
            isPresentation: this.props.isPresentation,
            qty: this.props.qty,
            charge_type: this.props.charge_type,
            unit_cost_field: this.props.unit_cost_field,

            decorator_id: this.props.decorator_id ? this.props.decorator_id : '',

            decoration_id: (this.props._charge_type == this.props.charge_type) ? 
                (this.props.decoration_id ? this.props.decoration_id : '') : '',

            unit: (this.props._charge_type == this.props.charge_type) ? 
                (this.props.unit ? this.props.unit : '') : '',
            
            d_desc: (this.props._charge_type == this.props.charge_type) ? 
                (this.props.d_desc ? this.props.d_desc : '') : '',
        };

        return initialState;
    }

    updateCharge(charge) {
        this.setState({charge: charge ? charge : this.getChargeToAdd()});
    }

    getDecoration(decoration_id) {
        const decoration = this.props.original_decorations.filter(
            v => v.decoration_id == (decoration_id ? decoration_id : this.state.decoration_id)
        );
        return decoration && decoration.length>0 ? decoration[0] : {};
    }

    getDecoratorData(decorator_id) {
        const d = this.props.original_decorators.filter(v => v.parent_id == decorator_id);
        return d && d.length>0 ? d[0] : {};
    }

    getDecorationUnit(decoration_id) {
        const decoration = this.getDecoration(decoration_id);
        return decoration ? decoration['units'] : 'Unit';
    }

    getDecorationQty(decoration_id) {
        const decoration = this.getDecoration(decoration_id);
        return decoration ? decoration['qty'] : 1;
    }

    isListCharge(decoration_id) {
        return this.getDecoration(decoration_id).matrix 
            && this.getDecoration(decoration_id).matrix.type == 'list';
    }

    // Step 1
    onSelectDecorator(e) {
        const dr = this.props.original_decorators.filter(v => v.decorator_id == e.value)[0];

        this.props.updateChargeItemData({
            isPresentation: this.props.isPresentation, 
            charge_type: this.props.charge_type,
            unit_cost_field: this.props.unit_cost_field,
            qty: this.props.qty,

            decorator_id: e.value, 
            unit: '', 
            decoration_id: '', 
            d_desc: '',
            decoration: {},
            charge_to_add: null,
            charge_to_add_col: null,
        });

        this.setState({ 
            decorator_id: e.value, 
            unit: '', 
            decoration_id: '', 
            d_desc: '', 
            decorator: dr,
            dr_desc: dr ? dr.description : '',
        }, () => {
            let with_matrix = true;
            if (this.state.charge_type == 'fixed') {
                with_matrix = true;
            }
            this.props.loadDecorations({
                decorator_id: e.value,
                qty: this.state.qty,
                charge_type: this.state.charge_type,
                with_matrix,
            });
        });
    }

    // Step 2
    onSelectDecoration(e) {
        const d = this.props.original_decorations.filter(v => v.decoration_id == e.value)[0];
        d.qty = d.qty ? d.qty : 1;
        const is_list_charge = d.matrix.type == 'list';
        const common_data = {
            decoration_id: e.value, 
            unit: '', 
            d_desc: d.charge_desc, 
            qty: is_list_charge ? d.qty : this.props.qty,
        };

        this.props.updateChargeItemData({
            decoration_id: common_data.decoration_id, 
            unit: common_data.unit, 
            d_desc: common_data.d_desc, 
            d_unit: d.units, 
            d_qty: d.qty, 
            c_type: d.charge_type, 
            c_name: d.charge_name,
            decoration: this.getDecoration(e.value), 
            a_qty: common_data.qty,
            charge_to_add: null,
            charge_to_add_col: null,
        });

        this.setState(common_data, () => {
            if (!this.isListCharge()) {
                this.props.loadDecorationUnits({
                    decoration_id: common_data.decoration_id,
                    qty: common_data.qty,
                    charge_type: this.state.charge_type,
                });
            } else {
                this.props.updateChargeItemData({ list_charges: d.matrix.data });
            }
        });
    }

    // Step 3
    onSelectUnit(e) {
        this.props.updateChargeItemData({
            unit: e.value,
            charge_to_add: null,
            charge_to_add_col: null,
        });
        this.setState({ unit: e.value }, () => {
            if (!this.isListCharge()) {
                this.props.loadDecorationCharges({
                    parent_id: this.state.decoration_id,
                    unit: e.value,
                });
            }
        });
    }

    onChangeUnit(value, update=false) {
        const val = parseInt(value);
        if (isNaN(value) || (!isNaN(value) && val <= 0)) {
            return;
        }
        if (update) {
            this.props.updateChargeItemData({
                unit: val,
                charge_to_add: null,
                charge_to_add_col: null,
            });
        }
        this.setState({unit: value=='' ? '' : val, }, () => {
            if (!this.isListCharge() && update) {
                this.props.loadDecorationCharges({
                    parent_id: this.state.decoration_id,
                    unit: value=='' ? '' : val,
                });
            }
        });
    }

    onChangeQty(value, update=false, action='') {
        let val = parseInt(value);
        if (isNaN(value) || (!isNaN(value) && val <= 0)) { return; }

        const d_qty = isNaN(this.props.charge_data.d_qty) ? 1 : parseInt(this.props.charge_data.d_qty);
        if (action!=='onchange') {
            if (val < d_qty) { val = d_qty; }
        }

        if (update) {
            this.props.updateChargeItemData({
                a_qty: val,
                charge_to_add: null,
                charge_to_add_col: null,
            });
        }

        this.setState({qty: value=='' ? '' : val, }, () => {
            if (!this.isListCharge() && update) {
                this.props.loadDecorationCharges({
                    parent_id: this.state.decoration_id,
                    a_qty: value=='' ? '' : val,
                });
            }
        });
    }

    disableAddBtn() {
        const { decoration_id, decorator_id, isPresentation, charge_type, }= this.state;
        const { charge_data: { unit, a_qty, list_charges } }= this.props;
        const selected_charges = list_charges && list_charges.filter(v => v.checked===true);
        const is_list_charge = this.isListCharge(decoration_id);

        const condition = (!is_list_charge && unit) ||
                    (is_list_charge && (a_qty || isPresentation || charge_type=='run') && selected_charges && selected_charges.length) && 
                    decoration_id && decorator_id;
        return !condition;
    }

    getChargeToAdd(charges, qty, returnColData=false) {
        const { decorator_id, decoration_id } = this.state;
        const { charge_data: { decoration: { matrix } } }= this.props;
        charges = charges ? charges : this.props.charges;

        if (!decorator_id || !decoration_id || !charges || !charges.data) { return; }
        qty = qty ? qty : this.state.qty;

        let colData = charges.cols;

        if (qty || this.state.charge_type == 'run') {
            colData = charges.cols.filter(v => {
                let until = v.until && v.until != '0' ? parseInt(v.until) : '+';
                return (v.key != '-') && (
                    (parseInt(v.label) == qty) || 
                    (until != '+' && qty >= parseInt(v.label) && qty <= until) ||
                    (until == '+' && qty >= parseInt(v.label))
                );
            });
        }

        if (colData && colData.length > 0) {
            colData = colData[0];

            let chargeKey = colData['key'];
            if (isNaN(chargeKey) && this.state.isPresentation) {
                chargeKey = Object.keys(charges.data[0]).filter(v => !isNaN(v))[0];
            }

            let charge = parseFloat(charges.data[0][chargeKey]);
            if (charges.data[0]['price_by_type'] == 'per_additional_unit') {
                charge = getPerAdditionalUnitCharge({matrix, charges, chargeKey, charge, unit: this.props.unit});
            }

            if (returnColData) {
                return {
                    colData, 
                    charge,
                    chargeKey,
                };
            }
            return charge;
        }
        return false;
    }

    applyCharges() {
        const {
            onAddItemCost, onClosePopup, unit, 
            updateChargeItemData, presentation_item_data, 
            qty_field,
        } = this.props;
        const { decorator_id, decoration_id, isPresentation, unit_cost_field, charge_type } = this.state;

        updateChargeItemData({decorator_id, decoration_id, unit, charge_type,});

        if (this.isListCharge(decoration_id)) {
            const ch_s = this.props.charge_data.list_charges.filter(v => v.checked===true);
            const c_qty = charge_type == 'run' ? 1 : parseInt(this.props.charge_data.d_qty) ? parseInt(this.props.charge_data.d_qty) : 1;
            // let ch_s_label = "";
            let sum = 0;
            if (ch_s.length > 0) {
                for (let i = 1; i <= ch_s.length; i++) {
                    if (!isNaN(ch_s[i-1].cell_value)) {
                        sum += parseFloat(ch_s[i-1].cell_value);
                        // ch_s_label = ch_s_label + (ch_s_label ? ", " : "") + ch_s[i-1].label;
                    }
                }
            }

            if (!isPresentation) {
                if (charge_type == 'fixed' && this.props.charge_data.a_qty) {
                    onAddItemCost(qty_field, Math.ceil(this.props.charge_data.a_qty/c_qty));
                }

                onAddItemCost(unit_cost_field, sum);
            }
            else if (isPresentation && presentation_item_data) { // run charge
                const {item_options, item_costs, item_cost_key} = presentation_item_data;
                item_options.forEach((v, i) => {
                    if (!(sum != false && sum != null && sum != undefined && sum != '' && !isNaN(sum))) {
                        sum = 0;
                    }
                    onAddItemCost(sum, item_costs[i][item_cost_key] + '.unit_cost');
                });
            }
            else if (isPresentation && charge_type) { // fixed charge
                sum = sum * Math.ceil(this.props.charge_data.a_qty/c_qty);
                if (!(sum != false && sum != null && sum != undefined && sum != '')) {
                    sum = 0;
                }
                onAddItemCost(sum, unit_cost_field);
            }

        } else {
            if (isPresentation && presentation_item_data) {
                const {item_options, item_option_qty_key, item_costs, item_cost_key} = presentation_item_data;
                item_options.forEach((v, i) => {
                    const qty = v[item_option_qty_key];
                    let charge = this.getChargeToAdd(null, qty);
                    if (!(charge != false && charge != null && charge != undefined)) {
                        charge = 0;
                    }
                    onAddItemCost(parseFloat(charge), item_costs[i][item_cost_key] + '.unit_cost');
                });
            } else if (isPresentation && charge_type) {
                let charge = this.getChargeToAdd();
                if (!(charge != false && charge != null && charge != undefined)) {
                    charge = 0;
                }
                onAddItemCost(parseFloat(charge), unit_cost_field ? unit_cost_field : 'unit_cost');
            } else if (onAddItemCost) {
                let charge = this.getChargeToAdd();
                if (charge_type == 'run') {
                    charge = this.props.charge_data.charge_to_add || charge;
                }

                if (charge != false && charge != null && charge != undefined) {
                    onAddItemCost(unit_cost_field ? unit_cost_field : 'unit_cost', parseFloat(charge));
                    if (charge_type != 'run' && qty_field) {
                        onAddItemCost(qty_field, this.state.unit);
                    }
                }
            }
        }

        if (this.props.label_field && this.props.charge_data.c_name) {
            if (isPresentation) {
                try {
                    onAddItemCost(this.props.charge_data.c_name ? this.props.charge_data.c_name : '', this.props.label_field);
                } catch (error) {
                    // console.log();
                }
            } else {
                onAddItemCost(this.props.label_field, this.props.charge_data.c_name ? this.props.charge_data.c_name : '');
            }
        }

        this.props.updateChargeItemData({list_charges: []});
        onClosePopup();
    }

    renderNoChargesFound() {
        return (
            <div className="small-12 medium-12 columns">
                <p style={{fontWeight: 600, fontSize: '1.3em', color: '#3C505A'}}>No Charges found.</p>
            </div>
        );
    }
    
    renderBody() {
        const { decorators, decorations, charges } = this.props;
        const { error, decorator_id, decoration_id, charge_type, d_desc } = this.state;

        const decoration = decoration_id ? this.getDecoration(decoration_id) : {};
        return (
            <div className="row popup-content">
                <div className="medium-12 small-12 columns">
                    {error && 
                        <div><p style={{color: 'red', fontWeight: 'bold'}}>{error}</p></div>}

                    <div className="small-12 medium-4 columns">
                        <label style={{textAlign: 'left'}}>Decorator</label>
                        <Select
                            id="select_decorator"
                            label="Decorator"
                            value={decorator_id}
                            onChange={this.onSelectDecorator}
                            options={decorators && decorators.length ? decorators : []}
                        />
                    </div>

                    {decorator_id ? <div className="small-12 medium-4 columns">
                        <label style={{textAlign: 'left'}}>Charge</label>
                        <Select
                            id="select_decoration"
                            label="Decoration"
                            value={decoration_id}
                            onChange={this.onSelectDecoration}
                            options={decorations && decorations.length ? decorations : []}
                        />
                    </div> : null}

                    {decoration_id && !this.isListCharge(decoration_id) ? 
                        <div className="small-12 medium-4 columns">
                            <label style={{textAlign: 'left'}}># of {this.getDecorationUnit(decoration_id)}</label>
                            <TextInput
                                id="select_unit"
                                label={this.props.charge_data.d_unit}
                                value={this.state.unit}
                                onChange={(e) => {
                                    this.onChangeUnit(e.target.value, true)
                                }}
                                onBlur={(e) => this.onChangeUnit(e.target.value, true)}
                                onFocus={(e) => e.target.select()}
                            />
                        </div>
                    : decoration_id && this.isListCharge(decoration_id) && charge_type!=='run' ? 
                        <div className="small-12 medium-4 columns">
                            <label style={{textAlign: 'left'}}># of {this.getDecorationUnit(decoration_id)}</label>
                            <TextInput
                                id="select_qty"
                                value={this.state.qty}
                                placeholder={'Min ' + this.props.charge_data.d_qty}
                                onChange={(e) => {
                                    this.onChangeQty(e.target.value, true, 'onchange')
                                }}
                                onBlur={(e) => this.onChangeQty(e.target.value, true)}
                                onFocus={(e) => e.target.select()}
                            />
                        </div> : null}

                    <br />
                    {!(this.props.unit && decoration_id) ? null : 
                        this.props.unit ? <div className="small-12 medium-12 columns">
                            {charges && charges.data && charges.data.length > 0 && charges.cols && charges.cols.length > 0 ?
                                <React.Fragment>
                                    <PricingTable
                                        matrix={this.props.charge_data.decoration.matrix}
                                        data={charges.data}
                                        cols={charges.cols}
                                        charge_type={charge_type}
                                        charge_name={this.props.c_name}
                                        charge_data={this.props.charge_data}
                                        onUpdateCharge={this.updateCharge}
                                        updateChargesData={this.props.updateChargeItemData}
                                        getChargeToAdd={this.getChargeToAdd}
                                        isPresentation={this.state.isPresentation}
                                        getPerAdditionalUnitCharge={({charge, chargeKey,}) => getPerAdditionalUnitCharge({
                                            matrix: this.props.charge_data.decoration.matrix,
                                            unit: this.props.unit,
                                            charges: charges,
                                            charge, chargeKey
                                        })}
                                    />
                                    <hr style={{maxWidth: '100%', }} />
                                    {charges.data[0]['price_by_type'] != 'per_additional_unit' && this.props.unit > parseInt(this.props.charges.max_unit) ?
                                        <div style={{marginTop: 10, marginBottom: 10, textAlign: 'center', fontWeight: 'bold', }}>
                                            The maximum # of {this.getDecorationUnit(decoration_id)} is {this.props.charges.max_unit}
                                            <hr style={{maxWidth: '100%', }} />
                                        </div>
                                    : null}
                                </React.Fragment>
                            : this.renderNoChargesFound()}
                    </div> : null}

                    {!(decoration_id) ? null : 
                        <div className="small-12 medium-12 columns">
                            {decoration && decoration.matrix && 
                                decoration.matrix.data && decoration.matrix.data.length > 0 ?
                                    this.isListCharge(decoration_id) ?
                                        <React.Fragment>
                                            <ListItemPricingTable
                                                decoration_id={decoration_id}
                                                charge_name={this.props.c_name}
                                                charge_type={charge_type}
                                                charge_data={this.props.charge_data}
                                                charges={decoration.matrix.data}
                                                updateListCharges={this.props.updateListCharges}
                                                updateChargesData={this.props.updateChargeItemData}
                                                isPresentation={this.state.isPresentation}
                                            />
                                            <hr style={{maxWidth: '100%', }} />
                                        </React.Fragment>
                                    : null
                            : this.renderNoChargesFound()}
                    </div>}

                    {(decorator_id && this.state.decorator) || (decoration_id && d_desc) ?
                        <div className="small-12 medium-12 columns" ref={this.descRef}>
                            {d_desc ? (<div>
                                <p><strong>Decoration Notes</strong></p>
                                {d_desc.split("\n").map((v,key) => (
                                    <p key={decoration_id+'_'+key}>{v}</p>
                                ))}
                                <hr style={{maxWidth: '100%', }} />
                            </div>) : null}
                            {this.state.decorator.description ? (<div>
                                <p><strong>Decorator Notes</strong></p>
                                {this.state.decorator.description.split("\n").map((v,key) => (
                                    <p key={decorator_id+'_'+key}>{v}</p>
                                ))}
                            </div>) : null}
                        </div>
                    : null}
                </div>
            </div>
        );
    }

    renderHelpText() {
        return (
            <div style={{textAlign: 'center', fontSize: '1.2em', marginBottom: 10, }}>
                <p>No Decorations found.
                    <br />Click <a target="_blank" rel="noopener noreferrer" href="https://commonsku.drift.help/article/decoration-matrix---overview/?v=1581041078026">here</a> to learn more about the Decorator Matrix.
                </p>
                <p>To get started, please visit the admin panel and select the <strong>&apos;Decorator Matrix&apos;</strong> tab.
                    <br /><i>Note: not all users have access to the admin tab. It is permission based.</i>
                </p>
            </div>
        );
    }

    render() {
        const { index, decorators, onClosePopup, unit, a_qty } = this.props;
        const { error, charge_type, qty, decoration_id, decorator_id } = this.state;

        return (
            <div id="apply-matrix-to-order-modal" className="reveal large" 
                style={{display: 'block', overflow: 'auto', zIndex: BASE_ZINDEX + (index ? index : 0), maxHeight: 'initial' }} 
                aria-labelledby="modalTitle" role="dialog">
                { error ? 
                    <div className="row full-width" style={{color: '#BF3F69', textAlign: 'center'}}>
                        {error}
                    </div>
                : null }

                <div className="row">
                    <div className="small-12 columns">
                        <h3 id="modalTitle">
                            <strong>Add Charge</strong>
                            <i style={{fontSize: '0.5em', }}>{' ' + charge_type.charAt(0).toUpperCase() + charge_type.slice(1) + ' Charge'}</i>
                            {qty && charge_type=='run' ? <i style={{fontSize: '0.5em', }}> ({this.props.qty}) </i> : null}
                        </h3>
                        <a className="button" style={{ position: 'fixed', right: '1rem', top: '1rem' }} 
                            disabled={this.disableAddBtn()}
                            onClick={e => { e.preventDefault(); this.applyCharges(); }}>Add</a>
                        <a className="alert button" style={{ position: 'fixed', right: '5rem', top: '1rem' }} onClick={e => onClosePopup()}>Cancel</a>
                    </div>
                </div>

                {decorators.length > 0 ? this.renderBody() : this.renderHelpText()}
            </div>
        );
    }
}



const mapStateToProps = (state, ownProps) => {
    const charge_data = state.entities.decoration_charge_data ? state.entities.decoration_charge_data : '';
    const item_costs = state.entities.item_costs ? state.entities.item_costs : [];

    return {
        decorators: createDropDownArray(state.dropdowns.decorators, 'decorator_id', 'decorator_name'),
        decorations: createDropDownArray(state.dropdowns.decorations, 'decoration_id', 'charge_name'),
        decoration_units: createDropDownArray(state.dropdowns.decoration_units, 'unit', 'unit'),
        original_decorations: state.dropdowns.decorations,
        original_decorators: state.dropdowns.decorators,

        charges: state.entities.decoration_charges,
        charge_data: charge_data,

        _charge_type: charge_data ? charge_data.charge_type : '',
        decorator_id: charge_data ? charge_data.decorator_id : '',
        decoration_id: charge_data ? charge_data.decoration_id : '',
        unit: charge_data ? charge_data.unit : '',
        list_charges: charge_data ? charge_data.list_charges : [],
        item_costs: item_costs,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => ({
    loadDecorators: (type) => dispatch(createLoadDecoratorList(type)),
    onClosePopup: () => {
        dispatch(closePopup());
    },
    loadDecorations: (data) => dispatch(createLoadDecorationsByData(data)),
    loadDecorationUnits: (data) => dispatch(createLoadUnitsByData(data)),
    loadDecorationCharges: (data) => dispatch(createLoadDecorationChargesByData(data)),
    updateChargeItemData: (data) => dispatch(createUpdateDecorationChargeItemData(data)),
    updateListCharges: (c_id, data) => dispatch(createUpdateDecorationChargeItemListCharges(c_id, data)),
});

const ConnectedApplyMatrixToItemPopup = connect(mapStateToProps, mapDispatchToProps)(ApplyMatrixToItemPopup);
export default ConnectedApplyMatrixToItemPopup;
