import { oauth } from '../utils'

export const ADD_ARTWORK_REQUEST = 'ADD_ARTWORK_REQUEST';
export const ADD_ARTWORK_SUCCESS = 'ADD_ARTWORK_SUCCESS';
export const ADD_ARTWORK_FAILURE = 'ADD_ARTWORK_FAILURE';
export const UPDATE_ARTWORK_REQUEST = 'UPDATE_ARTWORK_REQUEST';
export const UPDATE_ARTWORK_SUCCESS = 'UPDATE_ARTWORK_SUCCESS';
export const UPDATE_ARTWORK_FAILURE = 'UPDATE_ARTWORK_FAILURE';
export const DELETE_ARTWORK_REQUEST = 'DELETE_ARTWORK_REQUEST';
export const DELETE_ARTWORK_SUCCESS = 'DELETE_ARTWORK_SUCCESS';
export const DELETE_ARTWORK_FAILURE = 'DELETE_ARTWORK_FAILURE';

const createUpdateArtworkRequest = artwork => ({
  type: UPDATE_ARTWORK_REQUEST,
  payload: {
    id: artwork.artwork_id,
    data: artwork
  }
});

export const createFinalizeUpdateArtwork = (artwork, imprint, item_costs) => ({
  type: UPDATE_ARTWORK_SUCCESS,
  payload: {
    id: artwork.artwork_id,
    data: {
      artwork,
      imprint,
      item_costs
    }
  }
});

const createFailedUpdateArtwork = artwork_id => ({
  type: UPDATE_ARTWORK_FAILURE,
  payload: {
    id: artwork_id
  }
});

export const createUpdateArtwork = artwork => dispatch => {
  dispatch(createUpdateArtworkRequest(artwork));

  oauth('PUT', `artwork/${artwork.artwork_id}`, artwork).then(
    ({ json }) => dispatch(createFinalizeUpdateArtwork(json.artwork, json.imprint, json.item_costs)),
    ({ json }) => dispatch(createFailedUpdateArtwork(artwork.artwork_id))
  );
};

const createAddArtworkRequest = artwork => ({
  type: ADD_ARTWORK_REQUEST,
  payload: {
    data: artwork
  }
});

const createFinalizeAddArtwork = (artwork, imprint, item_costs, item_decorations) => ({
  type: ADD_ARTWORK_SUCCESS,
  payload: {
    id: artwork.artwork_id,
    data: {
      artwork,
      imprint,
      item_costs,
      item_decorations
    }
  }
});

const createFailedAddArtwork = artwork => ({
  type: ADD_ARTWORK_FAILURE,
  payload: {
    data: artwork
  }
});

export const createAddArtwork = artwork => dispatch => {
  dispatch(createAddArtworkRequest(artwork));

  oauth('POST', 'artwork', artwork).then(
    ({ json }) => dispatch(createFinalizeAddArtwork(json.artwork, json.imprint, json.item_costs, json.item_decorations)),
    ({ json }) => dispatch(createFailedAddArtwork(artwork))
  );
};

const createDeleteArtworkRequest = artwork_id => ({
  type: DELETE_ARTWORK_REQUEST,
  payload: {
    id: artwork_id
  }
});

const createFinalizeDeleteArtwork = artwork_id => ({
  type: DELETE_ARTWORK_SUCCESS,
  payload: {
    id: artwork_id
  }
});

const createFailedDeleteArtwork = artwork_id => ({
  type: DELETE_ARTWORK_FAILURE,
  payload: {
    id: artwork_id
  }
});

export const createDeleteArtwork = artwork_id => dispatch => {
  dispatch(createDeleteArtworkRequest(artwork_id));

  oauth('DELETE', `artwork/${artwork_id}`, {}).then(
    ({ json }) => dispatch(createFinalizeDeleteArtwork(artwork_id)),
    ({ json }) => dispatch(createFailedDeleteArtwork(artwork_id))
  );
};
