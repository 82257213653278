import { oauth } from '../utils'

export const ADD_COMMISSION_REQUEST = 'ADD_COMMISSION_REQUEST';
export const ADD_COMMISSION_SUCCESS = 'ADD_COMMISSION_SUCCESS';
export const ADD_COMMISSION_FAILURE = 'ADD_COMMISSION_FAILURE';

export const UPDATE_COMMISSION_REQUEST = 'UPDATE_COMMISSION_REQUEST';
export const UPDATE_COMMISSION_SUCCESS = 'UPDATE_COMMISSION_SUCCESS';
export const UPDATE_COMMISSION_FAILURE = 'UPDATE_COMMISSION_FAILURE';

export const DELETE_COMMISSION_REQUEST = 'DELETE_COMMISSION_REQUEST';
export const DELETE_COMMISSION_SUCCESS = 'DELETE_COMMISSION_SUCCESS';
export const DELETE_COMMISSION_FAILURE = 'DELETE_COMMISSION_FAILURE';

export const LOAD_COMMISSION_REQUEST = 'LOAD_COMMISSION_REQUEST';
export const LOAD_COMMISSION_SUCCESS = 'LOAD_COMMISSION_SUCCESS';
export const LOAD_COMMISSION_FAILURE = 'LOAD_COMMISSION_FAILURE';

export const REMOVE_ESTIMATED_COMMISSION_SUCCESS = 'REMOVE_ESTIMATED_COMMISSION_SUCCESS';

const createUpdateCommissionRequest = commission => ({
  type: UPDATE_COMMISSION_REQUEST,
  payload: {
    id: commission.commission_id,
    data: commission
  }
});

const createFinalizeUpdateCommission = commission => ({
  type: UPDATE_COMMISSION_SUCCESS,
  payload: {
    id: commission.commission_id,
    data: commission
  }
});

const createFailedUpdateCommission = commission_id => ({
  type: UPDATE_COMMISSION_FAILURE,
  payload: {
    id: commission_id
  }
});

export const createUpdateCommission = commission => dispatch => {
  dispatch(createUpdateCommissionRequest(commission));

  oauth('PUT', `commission/${commission.commission_id}`, commission).then(
    ({ json }) => dispatch(createFinalizeUpdateCommission(json.commission)),
    ({ json }) => dispatch(createFailedUpdateCommission(commission.commission_id))
  );
};

const createAddCommissionRequest = commission => ({
  type: ADD_COMMISSION_REQUEST,
  payload: {
    data: commission
  }
});

const createFinalizeAddCommission = commission => ({
  type: ADD_COMMISSION_SUCCESS,
  payload: {
    id: commission.commission_id,
    data: commission
  }
});

const createFailedAddCommission = commission => ({
  type: ADD_COMMISSION_FAILURE,
  payload: {
    data: commission
  }
});

export const createAddCommission = commission => dispatch => {
  dispatch(createAddCommissionRequest(commission));

  oauth('POST', 'commission', commission).then(
    ({ json }) => dispatch(createFinalizeAddCommission(json.commission)),
    ({ json }) => dispatch(createFailedAddCommission(commission))
  );
};

const createDeleteCommissionRequest = commission_id => ({
  type: DELETE_COMMISSION_REQUEST,
  payload: {
    id: commission_id
  }
});

const createFinalizeDeleteCommission = commission_id => ({
  type: DELETE_COMMISSION_SUCCESS,
  payload: {
    id: commission_id
  }
});

const createFailedDeleteCommission = commission_id => ({
  type: DELETE_COMMISSION_FAILURE,
  payload: {
    id: commission_id
  }
});

export const createDeleteCommission = commission_id => dispatch => {
  dispatch(createDeleteCommissionRequest(commission_id));

  oauth('DELETE', `commission/${commission_id}`, {}).then(
    ({ json }) => dispatch(createFinalizeDeleteCommission(commission_id)),
    ({ json }) => dispatch(createFailedDeleteCommission(commission_id))
  );
};

const createLoadCommissionsRequest = job_id => ({
  type: LOAD_COMMISSION_REQUEST,
  payload: {
    job_id
  }
});

const createFinalizeLoadCommissions = commissions => ({
  type: LOAD_COMMISSION_SUCCESS,
  payload: {
    commissions
  }
});

const createFailedLoadCommissions = job_id => ({
  type: LOAD_COMMISSION_FAILURE,
  payload: {
    job_id
  }
});

export const createLoadCommissions = job_id => dispatch => {
  dispatch(createLoadCommissionsRequest(job_id));

  return oauth('GET', 'commission', { job_id }).then(
    ({ json }) => dispatch(createFinalizeLoadCommissions(json.commissions)),
    ({ json }) => dispatch(createFailedLoadCommissions(job_id))
  );
};

export const createRemoveEstimatedCommissions = () => ({
  type: REMOVE_ESTIMATED_COMMISSION_SUCCESS,
  payload: { }
});
