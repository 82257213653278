import React, { Component } from 'react'
import { connect } from 'react-redux'

import { BASE_ZINDEX } from '../popup-factory'
import { closePopup } from '../actions/popup'


class NoticePopup extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { index, message = 'Please hold...', onClosePopup } = this.props;
        return (
            <div className="reveal large" style={{ display: 'block', height: 'initial', zIndex: BASE_ZINDEX + index }}>
                <div className="row" style={{ display: 'none' }}>
                    <a onClick={onClosePopup} style={{ float: 'right', fontSize: '26px' }}>×</a>
                </div>
                <div className="row" style={{ marginBottom: '20px', textAlign: 'center', fontSize: '26px', padding: '100px' }}>
                    <div className="small-12 columns">
                        {message}
                    </div>
                    <div className='small-12 columns'>
                        <div className='small-12 text-center'><br /><img src='/images/gears.gif' /><br /></div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onClosePopup: () => {
            dispatch(closePopup());
        }
    }
}


const ConnectedNoticePopup = connect(null, mapDispatchToProps)(NoticePopup);
export default ConnectedNoticePopup;
