import React, { Component } from 'react'
import { connect } from 'react-redux'

import Form from './Form'

import { createUpdateReminder, createAddMessage } from '../actions/message'
import { closePopup } from '../actions/popup'

import { getUserOptions } from '../selectors/dropdowns'

import { BASE_ZINDEX } from '../popup-factory'

import { mentionLinkToStr } from '../utils'

class EditMessagePopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            message_text: props.message ? props.message.message_text.replace(/<br(( )?\/)?>/g, '') : '',
            reminder_user: props.message ? props.message.reminder_user : props.identity.user_id,
            date_reminder: props.message ? props.message.date_reminder : '',
            reminder_complete: props.message ? props.message.reminder_complete : 0
        }

        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleEditMessage = this.handleEditMessage.bind(this);
        this.onFormRef = this.onFormRef.bind(this);
    }

    UNSAFE_componentWillMount() {
        if (this.props.message) {
            mentionLinkToStr(this.props.message.message_text.replace(/<br(( )?\/)?>/g, '')).then(result => {
                this.setState({ message_text: result })
            })
        }
    }

    onFormRef(form) {
        this._form = form;
    }

    handleFieldChange(value, field) {
        if(this._form) {
            this.setState({ [field]: value })
        }
    }

    handleEditMessage(values, errors) {
        if (!_.every(errors, (error) => error === null)) {
            return
        }

        if(this.props.message) {
            this.props.onEditMessage(this.props.message.message_id, this.props.message.note_id, values.message_text, values.reminder_user, values.date_reminder, values.reminder_complete);
        }else{
            this.props.onCreateAddMessage(this.props.job_id, values.message_text, values.reminder_user, values.date_reminder, [], '');
        }
        this.props.onClosePopup();
    }

    render() {
        const { 
            message,
            message_type, //for now there is only type 'task', later on can use this to control what text to display based on custom defined types,
            job_id,
            users,
            mention_users,
            onClosePopup,
            index } = this.props;

        return (
            <div id='edit-message-modal' className='reveal small' style={{ display: 'block', zIndex: BASE_ZINDEX + index }} aria-labelledby='modalTitle' aria-hidden='true' role='dialog'>
                <div className='row'>
                    <div className='small-12 columns'>
                        <h3 id='modalTitle'>{message ? 'Edit' : 'Add'} Task</h3>
                        <a className='alert button' style={{ position: 'fixed', right: '5rem', top: '1rem' }} onClick={e => onClosePopup()}>Cancel</a>
                        <a className='button' style={{ position: 'fixed', right: '1rem', top: '1rem' }} onClick={(e) => { e.preventDefault(); this._form && this._form.submit() }}>Save</a>
                    </div>
                </div>
                <div className='row popup-content'>
                    <Form className='row popup-content form'
                        ref={this.onFormRef}
                        onFieldChange={this.handleFieldChange}
                        onSubmit={this.handleEditMessage}
                    >
                        <Form.DateInput label='Task Date' field='date_reminder' value={this.state.date_reminder} required />
                        <Form.MentionTextarea label='Task' field='message_text' value={this.state.message_text} mention_users={mention_users} required />
                        <Form.Select label='User' field='reminder_user' options={users} value={this.state.reminder_user} required />
                        {message ? 
                            <Form.Checkbox label='Completed' field='reminder_complete' value={this.state.reminder_complete} />
                        : null}
                    </Form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        users: getUserOptions(state),
        mention_users: Object.values(state.entities.users).filter(u => u.mask != false),
        identity: state.identity
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onClosePopup: () => {
            dispatch(closePopup())
        },
        onEditMessage: (message_id, note_id, message_text, reminder_user, date_reminder, reminder_complete) => {
            dispatch(createUpdateReminder(message_id, note_id, message_text, reminder_user, date_reminder, reminder_complete))
        },
        onCreateAddMessage: (job_id, message_text, reminder_user_id, reminder_date, files, tagged_users, details_parent_id, details_parent_type, product_ids) => {
            dispatch(createAddMessage(job_id, message_text, reminder_user_id, reminder_date, files, tagged_users, details_parent_id, details_parent_type, product_ids));
        }
    }
}


const ConnectedEditMessagePopup = connect(mapStateToProps, mapDispatchToProps)(EditMessagePopup);
export default ConnectedEditMessagePopup;