import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import DropdownMenu, { MenuTrigger } from './DropdownMenu'

import { toTitleCase } from '../utils'

class BaseItemSummary extends Component {

  constructor(props) {
    super(props);

    this.state = {};

    this.handleClickAdd = this.handleClickAdd.bind(this);
    this.handleClickDelete = this.handleClickDelete.bind(this);
    this.handleClickCopy = this.handleClickCopy.bind(this);
    this.handleClickCopyToOrder = this.handleClickCopyToOrder.bind(this);
    this.handleClickToggle = this.handleClickToggle.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.getTooltip = this.getTooltip.bind(this);
  }

  handleMouseOver(tooltip) {
    return e => {
      this.setState({ [tooltip]: !this.props.isDragging, tooltip_position: { top: e.clientY, left: e.clientX } });
    }
  }

  handleMouseOut(tooltip) {
    return e => {
      this.setState({ [tooltip]: false });
    }
  }

  getTooltip(tooltip) {
    return null;
  }

  handleClickAdd(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.locked) {
      return;
    }
    this.props.onAddItem(this.props.index);
  }

  handleClickDelete(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.locked) {
      return;
    }
    this.props.onDeleteItem(this.props.item.item_id);
  }

  handleClickCopy(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.locked) {
      return;
    }
    this.props.onCopyItem(this.props.item.item_id, this.props.index + 1);
  }

  handleClickCopyToOrder = e => {
    e.preventDefault();
    e.stopPropagation();
    const { item, add_to_order, job_number } = this.props;
    if(item.parent_type === 'OPTION') {
      this.props.onCopyOptionItem(item, add_to_order);
    }else{
      this.props.onCopyItem(item.item_id, null, add_to_order, null, job_number);
    }
  };

  handleClickToggle(field) {
    return e => {
      e.preventDefault();
      e.stopPropagation();
      if (this.props.locked) {
        return;
      }
      this.props.onUpdateItem(this.props.item.item_id, field, this.props.item[field])(1 == this.props.item[field] ? 0 : 1);
    }
  }

  renderToggleAction(field, on_label, off_label) {
    return !this.props.locked ? <a className="tiny button" onClick={this.handleClickToggle(field)}>{1 == this.props.item[field] ? off_label : on_label}</a> : null;
  }

  renderAddAction() {
    const {
      locked,
      dropdownOptions,
    } = this.props;
    return <DropdownMenu options={dropdownOptions}>
      {!locked ?
        <MenuTrigger>
          <a className="button alt tiny">
            <i className="fi-plus" />&nbsp;&nbsp;Add
          </a>
        </MenuTrigger> : null}
    </DropdownMenu>
  }

  renderEditAction() {
    return <a className="tiny button">{this.props.locked ? 'view' : 'edit'}</a>;
  }

  renderCopyAction() {
    return !this.props.locked ? <a className="tiny button" onClick={this.handleClickCopy}>copy</a> : null;
  }
  
  renderCopyToOrderAction() {
    const { item, add_to_order } = this.props
    if(add_to_order) {
    	return item.order_id !== add_to_order.order_id ? <a className="tiny button" onClick={this.handleClickCopyToOrder}>copy to {toTitleCase(add_to_order.order_type)}</a> : null;
    }else{
	return null;
    }
  }

  renderDeleteAction() {
    return !this.props.locked ? <a className="tiny button alert" onClick={this.handleClickDelete}>delete</a> : null;
  }

  renderActions() {
    return (
      <span className="actions">
      </span>
    );
  }

  renderItem() {
    return null;
  }

  render() {
    const {
      className = '', 
      item, detailed, display, locked, 
      onEditItem, handleContextMenu, 
      connectDragSource, connectDropTarget,
    } = this.props;

    const handleClick = onEditItem ? (e => {
      e.preventDefault();
      e.stopPropagation();
      onEditItem(item.item_id, item.parent_id);
    }) : null;
    const style = 1 == item.hidden ? { opacity : 0.5, borderTop : '1px solid #ddd' } : { borderTop : '1px solid #ddd' };

    const result = <tbody style={style} 
      className={className + ' item-summary' + (detailed ? ' detailed' : '')}
      onClick={handleClick} onContextMenu={!locked ? handleContextMenu : null}>
        {this.renderItem()}
    </tbody>;
    if (locked || display === 'artwork' || display === 'comment') {
      return result;
    } else {
      return connectDragSource(connectDropTarget(result));
    }
  }
}

export default BaseItemSummary;
