import {
  oauth
} from '../utils';

import { checkMatrixCell } from '../admin-dec-charges-utils';

export const SET_FIXED_CHARGE_TYPE = 'SET_FIXED_CHARGE_TYPE';

export const LOAD_ALL_DECORATORS_REQUEST = 'LOAD_ALL_DECORATORS_REQUEST';
export const LOAD_ALL_DECORATORS_SUCCESS = 'LOAD_ALL_DECORATORS_SUCCESS';
export const LOAD_ALL_DECORATORS_FAILURE = 'LOAD_ALL_DECORATORS_FAILURE';

export const LOAD_DECORATORS_REQUEST = 'LOAD_DECORATORS_REQUEST';
export const LOAD_DECORATORS_SUCCESS = 'LOAD_DECORATORS_SUCCESS';
export const LOAD_DECORATORS_FAILURE = 'LOAD_DECORATORS_FAILURE';

export const UPDATE_DECORATOR_REQUEST = 'UPDATE_DECORATOR_REQUEST';
export const UPDATE_DECORATOR_SUCCESS = 'UPDATE_DECORATOR_SUCCESS';
export const UPDATE_DECORATOR_FAILURE = 'UPDATE_DECORATOR_FAILURE';
export const UPDATE_DECORATOR_STATE_SUCCESS = 'UPDATE_DECORATOR_STATE_SUCCESS';

export const LOAD_DECORATIONS_BY_DATA_REQUEST = 'LOAD_DECORATIONS_BY_DATA_REQUEST';
export const LOAD_DECORATIONS_BY_DATA_SUCCESS = 'LOAD_DECORATIONS_BY_DATA_SUCCESS';
export const LOAD_DECORATIONS_BY_DATA_FAILURE = 'LOAD_DECORATIONS_BY_DATA_FAILURE';

export const LOAD_UNITS_BY_DATA_REQUEST = 'LOAD_UNITS_BY_DATA_REQUEST';
export const LOAD_UNITS_BY_DATA_SUCCESS = 'LOAD_UNITS_BY_DATA_SUCCESS';
export const LOAD_UNITS_BY_DATA_FAILURE = 'LOAD_UNITS_BY_DATA_FAILURE';

export const LOAD_DECORATION_CHARGES_BY_DATA_REQUEST = 'LOAD_DECORATION_CHARGES_BY_DATA_REQUEST';
export const LOAD_DECORATION_CHARGES_BY_DATA_SUCCESS = 'LOAD_DECORATION_CHARGES_BY_DATA_SUCCESS';
export const LOAD_DECORATION_CHARGES_BY_DATA_FAILURE = 'LOAD_DECORATION_CHARGES_BY_DATA_FAILURE';

export const UPDATE_DECORATION_CHARGE_ITEM_DATA_REQUEST = 'UPDATE_DECORATION_CHARGE_ITEM_DATA_REQUEST';
export const UPDATE_DECORATION_CHARGE_ITEM_DATA_SUCCESS = 'UPDATE_DECORATION_CHARGE_ITEM_DATA_SUCCESS';
export const UPDATE_DECORATION_CHARGE_ITEM_DATA_FAILURE = 'UPDATE_DECORATION_CHARGE_ITEM_DATA_FAILURE';
export const UPDATE_DECORATION_CHARGE_ITEM_LIST_CHARGES_SUCCESS = 'UPDATE_DECORATION_CHARGE_ITEM_LIST_CHARGES_SUCCESS';

export const SET_DECORATOR_ID_SUCCESS = 'SET_DECORATOR_ID_SUCCESS';
export const SET_DECORATOR_ID_FAILURE = 'SET_DECORATOR_ID_FAILURE';

export const ADD_DECORATOR_STATE_SUCCESS = 'ADD_DECORATOR_STATE_SUCCESS';
export const ADD_DECORATOR_STATE_FAILURE = 'ADD_DECORATOR_STATE_FAILURE';

export const DELETE_DECORATOR_STATE_SUCCESS = 'DELETE_DECORATOR_STATE_SUCCESS';
export const DELETE_DECORATOR_STATE_FAILURE = 'DELETE_DECORATOR_STATE_FAILURE';

export const ADD_DECORATOR_REQUEST = 'ADD_DECORATOR_REQUEST';
export const ADD_DECORATOR_SUCCESS = 'ADD_DECORATOR_SUCCESS';
export const ADD_DECORATOR_FAILURE = 'ADD_DECORATOR_FAILURE';

export const LOAD_DECORATIONS_REQUEST = 'LOAD_DECORATIONS_REQUEST';
export const LOAD_DECORATIONS_SUCCESS = 'LOAD_DECORATIONS_SUCCESS';
export const LOAD_DECORATIONS_FAILURE = 'LOAD_DECORATIONS_FAILURE';

// Create empty decoration and add to state
export const CREATE_DECORATION_SUCCESS = 'CREATE_DECORATION_SUCCESS';

export const ADD_DECORATION_REQUEST = 'ADD_DECORATION_REQUEST';
export const ADD_DECORATION_SUCCESS = 'ADD_DECORATION_SUCCESS';
export const ADD_DECORATION_FAILURE = 'ADD_DECORATION_FAILURE';
export const UPDATE_DECORATION_REQUEST = 'UPDATE_DECORATION_REQUEST';
export const UPDATE_DECORATION_SUCCESS = 'UPDATE_DECORATION_SUCCESS';
export const UPDATE_DECORATION_FAILURE = 'UPDATE_DECORATION_FAILURE';
export const DELETE_DECORATION_REQUEST = 'DELETE_DECORATION_REQUEST';
export const DELETE_DECORATION_SUCCESS = 'DELETE_DECORATION_SUCCESS';
export const DELETE_DECORATION_FAILURE = 'DELETE_DECORATION_FAILURE';

export const COPY_DECORATION_REQUEST = 'COPY_DECORATION_REQUEST';
export const COPY_DECORATION_SUCCESS = 'COPY_DECORATION_SUCCESS';
export const COPY_DECORATION_FAILURE = 'COPY_DECORATION_FAILURE';

export const LOAD_DECORATION_CHARGE_REQUEST = 'LOAD_DECORATION_CHARGE_REQUEST';
export const LOAD_DECORATION_CHARGE_SUCCESS = 'LOAD_DECORATION_CHARGE_SUCCESS';
export const LOAD_DECORATION_CHARGE_FAILURE = 'LOAD_DECORATION_CHARGE_FAILURE';

export const ADD_LIST_CHARGE_STATE = 'ADD_LIST_CHARGE_STATE';
export const ADD_LIST_CHARGE_REQUEST = 'ADD_LIST_CHARGE_REQUEST';
export const ADD_LIST_CHARGE_SUCCESS = 'ADD_LIST_CHARGE_SUCCESS';
export const ADD_LIST_CHARGE_FAILURE = 'ADD_LIST_CHARGE_FAILURE';

export const UPDATE_LIST_CHARGES_STATE = 'UPDATE_LIST_CHARGES_STATE';

export const UPDATE_LIST_CHARGE_STATE = 'UPDATE_LIST_CHARGE_STATE';
export const UPDATE_LIST_CHARGE_REQUEST = 'UPDATE_LIST_CHARGE_REQUEST';
export const UPDATE_LIST_CHARGE_SUCCESS = 'UPDATE_LIST_CHARGE_SUCCESS';
export const UPDATE_LIST_CHARGE_FAILURE = 'UPDATE_LIST_CHARGE_FAILURE';

export const UPDATE_MATRIX_REQUEST = 'UPDATE_MATRIX_REQUEST';
export const UPDATE_MATRIX_SUCCESS = 'UPDATE_MATRIX_SUCCESS';
export const UPDATE_MATRIX_FAILURE = 'UPDATE_MATRIX_FAILURE';

export const UPDATE_MATRIX_LIST = 'UPDATE_MATRIX_LIST';

export const UPDATE_MATRIX_CELL_STATE = 'UPDATE_MATRIX_CELL_STATE';
export const UPDATE_MATRIX_CELL_REQUEST = 'UPDATE_MATRIX_CELL_REQUEST';
export const UPDATE_MATRIX_CELL_SUCCESS = 'UPDATE_MATRIX_CELL_SUCCESS';
export const UPDATE_MATRIX_CELL_FAILURE = 'UPDATE_MATRIX_CELL_FAILURE';

export const ADD_MATRIX_ROW_REQUEST = 'ADD_MATRIX_ROW_REQUEST';
export const ADD_MATRIX_ROW_SUCCESS = 'ADD_MATRIX_ROW_SUCCESS';
export const ADD_MATRIX_ROW_FAILURE = 'ADD_MATRIX_ROW_FAILURE';
export const UPDATE_MATRIX_ROW_REQUEST = 'UPDATE_MATRIX_ROW_REQUEST';
export const UPDATE_MATRIX_ROW_SUCCESS = 'UPDATE_MATRIX_ROW_SUCCESS';
export const UPDATE_MATRIX_ROW_FAILURE = 'UPDATE_MATRIX_ROW_FAILURE';
export const REMOVE_MATRIX_ROW_REQUEST = 'REMOVE_MATRIX_ROW_REQUEST';
export const REMOVE_MATRIX_ROW_SUCCESS = 'REMOVE_MATRIX_ROW_SUCCESS';
export const REMOVE_MATRIX_ROW_FAILURE = 'REMOVE_MATRIX_ROW_FAILURE';

export const REMOVE_FIX_MATRIX_ROW_REQUEST = 'REMOVE_FIX_MATRIX_ROW_REQUEST';
export const REMOVE_FIX_MATRIX_ROW_SUCCESS = 'REMOVE_FIX_MATRIX_ROW_SUCCESS';
export const REMOVE_FIX_MATRIX_ROW_FAILURE = 'REMOVE_FIX_MATRIX_ROW_FAILURE';

export const ADD_MATRIX_COL_REQUEST = 'ADD_MATRIX_COL_REQUEST';
export const ADD_MATRIX_COL_SUCCESS = 'ADD_MATRIX_COL_SUCCESS';
export const ADD_MATRIX_COL_FAILURE = 'ADD_MATRIX_COL_FAILURE';
export const UPDATE_MATRIX_COL_REQUEST = 'UPDATE_MATRIX_COL_REQUEST';
export const UPDATE_MATRIX_COL_SUCCESS = 'UPDATE_MATRIX_COL_SUCCESS';
export const UPDATE_MATRIX_COL_FAILURE = 'UPDATE_MATRIX_COL_FAILURE';
export const REMOVE_MATRIX_COL_REQUEST = 'REMOVE_MATRIX_COL_REQUEST';
export const REMOVE_MATRIX_COL_SUCCESS = 'REMOVE_MATRIX_COL_SUCCESS';
export const REMOVE_MATRIX_COL_FAILURE = 'REMOVE_MATRIX_COL_FAILURE';

export const UPDATE_MATRIX_COLS_DEF_REQUEST = 'UPDATE_MATRIX_COLS_DEF_REQUEST';
export const UPDATE_MATRIX_COLS_DEF_SUCCESS = 'UPDATE_MATRIX_COLS_DEF_SUCCESS';
export const UPDATE_MATRIX_COLS_DEF_FAILURE = 'UPDATE_MATRIX_COLS_DEF_FAILURE';

export const UPDATE_MATRIX_ROWS_DEF_REQUEST = 'UPDATE_MATRIX_ROWS_DEF_REQUEST';
export const UPDATE_MATRIX_ROWS_DEF_SUCCESS = 'UPDATE_MATRIX_ROWS_DEF_SUCCESS';
export const UPDATE_MATRIX_ROWS_DEF_FAILURE = 'UPDATE_MATRIX_ROWS_DEF_FAILURE';

export const REMOVE_DECORATION_CHARGE_ITEM_REQUEST = 'REMOVE_DECORATION_CHARGE_ITEM_REQUEST';
export const REMOVE_DECORATION_CHARGE_ITEM_SUCCESS = 'REMOVE_DECORATION_CHARGE_ITEM_SUCCESS';
export const REMOVE_DECORATION_CHARGE_ITEM_FAILURE = 'REMOVE_DECORATION_CHARGE_ITEM_FAILURE';

export const DELETE_DECORATOR_SUCCESS = 'DELETE_DECORATOR_SUCCESS';
export const DELETE_DECORATOR_FAILURE = 'DELETE_DECORATOR_FAILURE';

export const REMOVE_DECORATIONS_REQUEST = 'REMOVE_DECORATIONS_REQUEST';
export const REMOVE_DECORATIONS_SUCCESS = 'REMOVE_DECORATIONS_SUCCESS';
export const REMOVE_DECORATIONS_FAILURE = 'REMOVE_DECORATIONS_FAILURE';


// Set fixed charge type
const createFinalizedSetFixedChargeType = (decoration_id, value) => ({
  type: SET_FIXED_CHARGE_TYPE,
  payload: {
    decoration_id,
    value
  }
});

export const createSetFixedChargeType = (decoration_id, fixed_type) => dispatch => {
  dispatch(createFinalizedSetFixedChargeType(decoration_id, fixed_type));
};



// Set decorator id
const createErrorSetDecoratorId = error => ({
  type: SET_DECORATOR_ID_FAILURE,
  payload: {
    error
  }
});


const createFinalizeSetDecoratorId = (decorator_id) => dispatch => {
  dispatch({
    type: SET_DECORATOR_ID_SUCCESS,
    payload: {
      decorator_id
    }
  });
  dispatch(createLoadDecorationsList(decorator_id, true));
};

export const createSetDecoratorId = (decorator_id) => dispatch => {
  if (decorator_id) {
    return dispatch(createFinalizeSetDecoratorId(decorator_id));
  }
  return dispatch(createErrorSetDecoratorId("Cannot update Decorator"));
};


// Update Decorator
const createErrorUpdateDecorator = (decorator_id, error) => ({
  type: UPDATE_DECORATOR_FAILURE,
  payload: {
    decorator_id,
    error
  }
});

const createRequestUpdateDecorator = (decorator_id, value) => dispatch => ({
  type: UPDATE_DECORATOR_REQUEST,
  payload: {
    decorator_id,
    value,
  }
});

const createFinalizeUpdateDecoratorState = (decorator_id, value) => dispatch => ({
  type: UPDATE_DECORATOR_STATE_SUCCESS,
  payload: {
    decorator_id,
    value,
  }
});

const createFinalizeUpdateDecorator = (decorator_id, value) => dispatch => ({
  type: UPDATE_DECORATOR_SUCCESS,
  payload: {
    decorator_id,
    value,
  }
});

export const createUpdateDecorator = (decorator_id, data) => dispatch => {
  if (decorator_id && data) {
    if (data.local === true) {
      delete data['local'];
      return dispatch(createFinalizeUpdateDecoratorState(decorator_id, data));
    }
    return oauth('GET', 'decorator/put', {
      parent_id: decorator_id,
      ...data,
    }).then((resp) => {
        return dispatch(createFinalizeUpdateDecorator(decorator_id, data));
      }
    ).catch((err) => {
      console.log(err);
      return dispatch(createErrorUpdateDecorator(decorator_id, "Cannot update Decorator"));
    });
  }
  return dispatch(createErrorUpdateDecorator(decorator_id, "Cannot update Decorator"));
};


// Update Decoration Charge Item data
const createErrorUpdateDecorationChargeItemData = error => ({
  type: UPDATE_DECORATION_CHARGE_ITEM_DATA_FAILURE,
  payload: {
    error
  }
});


const createFinalizeUpdateDecorationChargeItemData = (data) => {
  return {
    type: UPDATE_DECORATION_CHARGE_ITEM_DATA_SUCCESS,
    payload: {
      error: '',
      data
    }
  }
};

const createFinalizeUpdateDecorationChargeItemListCharges = (charge_id, data) => {
  return {
    type: UPDATE_DECORATION_CHARGE_ITEM_LIST_CHARGES_SUCCESS,
    payload: {
      error: '',
      charge_id,
      data
    }
  }
};

export const createUpdateDecorationChargeItemData = (data) => dispatch => {
  if (data) {
    return dispatch(createFinalizeUpdateDecorationChargeItemData(data));
  }
  return dispatch(createErrorUpdateDecorationChargeItemData("Cannot update charge"));
};

export const createUpdateDecorationChargeItemListCharges = (charge_id, data) => dispatch => {
  return dispatch(createFinalizeUpdateDecorationChargeItemListCharges(charge_id, data));
};




// Load Decoration Decorators
// fetch decorators for existing decorations
const createErrorLoadDecoratorList = error => ({
  type: LOAD_DECORATORS_FAILURE,
  payload: {
    error
  }
});


const createFinalizeLoadDecoratorList = (type, decorators) => ({
  type: LOAD_DECORATORS_SUCCESS,
  payload: {
    type,
    decorators
  }
});

export const createLoadDecoratorList = (type='division') => dispatch => {
  return oauth('GET', 'decoration/decorators', {type}).then(
    ({
      json
    }) => dispatch(createFinalizeLoadDecoratorList(type, json.decorators))
  ).catch(() => dispatch(createErrorLoadDecoratorList("Cannot load Decorators")));
};


// Create Decorator
// fetch decorators for existing decorations
const createErrorAddDecorator = error => ({
  type: ADD_DECORATOR_FAILURE,
  payload: {
    error
  }
});


const createFinalizeAddDecorator = decorator => ({
  type: ADD_DECORATOR_SUCCESS,
  payload: {
    decorator
  }
});

export const createAddDecorator = () => dispatch => {
  return oauth('POST', 'decoration/create-decorator').then(
    ({
      json
    }) => dispatch(createFinalizeAddDecorator(json.decorator))
  ).catch(() => dispatch(createErrorAddDecorator("Cannot create Decorator")));
};

// Add decorator to state
const createFinalizeAddDecoratorState = (decorator_id, decorator_name) => dispatch => {
  dispatch({
    type: ADD_DECORATOR_STATE_SUCCESS,
    payload: {
      decorator_id,
      decorator_name
    }
  });

  return dispatch(createSetDecoratorId(decorator_id))
};

const createErrorAddDecoratorState = (error) => ({
  type: ADD_DECORATOR_STATE_FAILURE,
  payload: {
    error
  }
});

export const createAddDecoratorToState = ({decorator_id, decorator_name}) => dispatch => {
  if (!decorator_id || !decorator_name) {
    return dispatch(createErrorAddDecoratorState("Cannot add decorator"));
  } else {
    dispatch(createFinalizeAddDecoratorState(decorator_id, decorator_name))
  }
};


// Remove decorator from state
const createFinalizeDeleteDecorator = (decorator_id, options) => ({
  type: DELETE_DECORATOR_SUCCESS,
  payload: {
    decorator_id,
    options
  }
});

const createErrorDeleteDecorator = (decorator_id, error) => ({
  type: DELETE_DECORATOR_FAILURE,
  payload: {
    error
  }
});

export const createDeleteDecorator = (decorator_id, options={}) => dispatch => {
  if (!decorator_id) {
    return dispatch(createErrorDeleteDecorator(decorator_id, "Cannot remove decorator"));
  } else {
    return oauth('DELETE', 'decorator', {
      parent_id: decorator_id,
    }).then(
      (resp) => dispatch(createFinalizeDeleteDecorator(decorator_id, options))
    ).catch((err) => {
      console.log(err);
      return dispatch(createErrorDeleteDecorator(decorator_id, "Cannot remove decorator"));
    });
  }
};



// Load Decorations by data
const createRequestLoadDecorationsByData = (data) => ({
  type: LOAD_DECORATIONS_BY_DATA_REQUEST,
  payload: {
    ...data,
    loading: true,
    error: '',
  }
});

const createFinalizeLoadDecorationsByData = (decorations) => ({
  type: LOAD_DECORATIONS_BY_DATA_SUCCESS,
  payload: {
    loading: false,
    decorations
  }
});

const createErrorLoadDecorationsByData = (error) => ({
  type: LOAD_DECORATIONS_BY_DATA_FAILURE,
  payload: {
    loading: false,
    error
  }
});

export const createLoadDecorationsByData = (data={}) => dispatch => {
  if (!data) {
    return dispatch(createErrorLoadDecorationsByData("Cannot load decorations"));
  } else {
    dispatch(createRequestLoadDecorationsByData(data));
    return oauth('GET', 'decoration/get-decoration-by-data', data).then(
      (resp) => {
        if (resp.json && resp.json.error) {
          return dispatch(createErrorLoadDecorationsByData(resp.json.error));
        } else {
          return dispatch(createFinalizeLoadDecorationsByData(resp.json.decorations));
        }
      }
    ).catch(() => dispatch(createErrorLoadDecorationsByData('Could not load decorations.')));
  }
};


// Load Decoration Units by data
const createRequestLoadUnitsByData = (data) => ({
  type: LOAD_UNITS_BY_DATA_REQUEST,
  payload: {
    ...data,
    loading: true,
    error: '',
  }
});
const createFinalizeLoadUnitsByData = (units, data) => dispatch => {
  if (units && units.length == 1) {
    dispatch(createUpdateDecorationChargeItemData({unit: units[0].unit}));
    dispatch(createLoadDecorationChargesByData({unit: units[0].unit, parent_id: data.decoration_id}));
  }
  return dispatch({
    type: LOAD_UNITS_BY_DATA_SUCCESS,
    payload: {
      units,
      loading: false,
      error: '',
    }
  });
};

const createErrorLoadUnitsByData = (error) => ({
  type: LOAD_UNITS_BY_DATA_FAILURE,
  payload: {
    loading: false,
    error
  }
});

export const createLoadUnitsByData = (data={}) => dispatch => {
  if (!data) {
    return dispatch(createErrorLoadUnitsByData("Cannot load Units"));
  } else {
    dispatch(createRequestLoadUnitsByData(data));
    return oauth('GET', 'decoration/get-units-by-data', data).then(
      (resp) => {
        if (resp.error) {
          return dispatch(createErrorLoadUnitsByData(resp.error));
        } else {
          return dispatch(createFinalizeLoadUnitsByData(resp.json.units, data));
        }
      }
    ).catch(() => dispatch(createErrorLoadUnitsByData('Could not load Units.')));
  }
};



// Load Decoration Units by data
const createRequestLoadDecorationChargesByData = (data) => ({
  type: LOAD_DECORATION_CHARGES_BY_DATA_REQUEST,
  payload: {
    ...data,
    loading: true,
    error: '',
  }
});
const createFinalizeLoadDecorationChargesByData = (charges, units={}, options={}) => ({
  type: LOAD_DECORATION_CHARGES_BY_DATA_SUCCESS,
  payload: {
    charges,
    loading: false,
    error: '',
    ...units,
    ...options,
  }
});

const createErrorLoadDecorationChargesByData = (error) => ({
  type: LOAD_DECORATION_CHARGES_BY_DATA_FAILURE,
  payload: {
    loading: false,
    error
  }
});

export const createLoadDecorationChargesByData = (data={}) => dispatch => {
  if (!data) {
    return dispatch(createErrorLoadDecorationChargesByData("Cannot load Charges"));
  } else {

    dispatch(createRequestLoadDecorationChargesByData(data));

    return oauth('GET', 'decoration-charge/get-by-data', data).then(
      (resp) => {
        if (resp.json && resp.json.error) {
          return dispatch(createErrorLoadDecorationChargesByData(resp.json.error));
        } else {
          return dispatch(createFinalizeLoadDecorationChargesByData(resp.json.charges, {
            max_unit: resp.json.max ? resp.json.max : null,
            min_unit: resp.json.min ? resp.json.min : null,
          }, {updateUntil: false}));
        }
      }
    ).catch(() => dispatch(createErrorLoadDecorationChargesByData('Could not load Charges.')));
  }
};



// Load decorator Decorations List
// fetch decorations for a selected decorator
const createErrorLoadDecorationsList = (error) => ({
  type: LOAD_DECORATIONS_FAILURE,
  payload: {
    error
  }
});

const createFinalizeLoadDecorationsList = decorations => ({
  type: LOAD_DECORATIONS_SUCCESS,
  payload: {
    decorations
  }
});

export const createLoadDecorationsList = (decorator_id, with_matrix=false) => dispatch => {
  if (!decorator_id) { return; }
  return oauth('GET', 'decoration/toc', {decorator_id, with_matrix}).then(
    ({
      json
    }) => dispatch(createFinalizeLoadDecorationsList(json.decorations))
  ).catch(() => dispatch(createErrorLoadDecorationsList('Could not load decorations.')));
};

// Create empty decoration to add to state, not yet save to server
const createFinalizeCreateDecoration = decoration => ({
  type: CREATE_DECORATION_SUCCESS,
  payload: {
    decoration
  }
});

export const createDecoration = (decorator_id, {decoration_id, charge_name, charge_type, charge_desc, units, qty, should_update}) => (dispatch, getState) => {
  const decoration = {
    decorator_id,
    is_new: true,
    matrix: {
      cols: [],
      data: [],
      type: 'table',
    },
  };
  if (decoration_id) {
    decoration['decoration_id'] = decoration_id;
  } else { // create temporary decorator id
    decoration['decoration_id'] = 'dec_temp_' + getState().entities.decorations.length + '-' + parseInt(Math.random()*1000);
  }

  if (charge_name) {
    decoration['charge_name'] = charge_name;
  } else {
    decoration['charge_name'] = '';
  }
  if (charge_type) {
    decoration['charge_type'] = charge_type;
    if (charge_type == 'fixed') {
      decoration.matrix['type'] = 'list';
    }
  } else {
    decoration['charge_type'] = '';
  }
  if (charge_desc) {
    decoration['charge_desc'] = charge_desc;
  } else {
    decoration['charge_desc'] = '';
  }
  if (units) {
    decoration['units'] = units;
  } else {
    decoration['units'] = '';
  }

  if (qty) {
    decoration['qty'] = qty;
  } else {
    decoration['qty'] = 1;
  }

  if (should_update && !isNaN(should_update)) {
    decoration['should_update'] = should_update;
  } else {
    decoration['should_update'] = 1;
  }

  return dispatch(createFinalizeCreateDecoration(decoration));
};

// Add Decoration
const createFinalizeAddDecoration = (new_decoration_id, temp_decoration_id, value) => ({
  type: ADD_DECORATION_SUCCESS,
  payload: {
    new_decoration_id,
    decoration_id: temp_decoration_id,
    value,
  }
});

const createErrorAddDecoration = (decoration_id, error) => ({
  type: ADD_DECORATION_FAILURE,
  payload: {
    decoration_id,
    error
  }
});

export const createAddDecoration = ({decorator_id, charge_name, charge_type, charge_desc, units, decoration_id, qty}) => (dispatch, getState) => {
  if (!decorator_id || !charge_name || !charge_type) {
    return dispatch(createErrorAddDecoration(decoration_id, "Invalid input. Cannot add decoration."));
  }

  return oauth('POST', 'decoration', {
    decorator_id,
    charge_name,
    charge_type,
    charge_desc,
    units,
    qty,
  }).then(
    ({
      json
    }) => {
      if (typeof json.decoration_id == 'string') {
        const data = {};
        if (charge_type == 'fixed') {
          data['matrix_type'] = 'list'
        } else {
          data['matrix_type'] = 'table'
        }
        return dispatch(createFinalizeAddDecoration(json.decoration_id, decoration_id, data))
      } else if (typeof json.decoration_id == 'object') {
        return dispatch(createFinalizeAddDecoration(json.decoration_id.decoration_id, decoration_id, data));
      }
    }
  ).catch(() => dispatch(createErrorAddDecoration(decoration_id, "Cannot add decoration.")));
};

// Update Decoration
const createRequestUpdateDecoration = (decoration_id, value) => ({
  type: UPDATE_DECORATION_REQUEST,
  payload: {
    decoration_id,
    value
  }
});

const createFinalizeUpdateDecoration = (decoration_id, value) => ({
  type: UPDATE_DECORATION_SUCCESS,
  payload: {
    decoration_id,
    value
  }
});

const createErrorUpdateDecoration = (decoration_id, error) => ({
  type: UPDATE_DECORATION_FAILURE,
  payload: {
    decoration_id, 
    error
  }
});

export const createUpdateDecoration = (decoration_id, decoration) => (dispatch, getState) => {
  return dispatch(createRequestUpdateDecoration(decoration_id, decoration));
};

export const createUpdateDecorationRequest = (decoration) => (dispatch, getState) => {
  return oauth('PUT', 'decoration', {
    decoration_id: decoration.decoration_id,
    decorator_id: decoration.decorator_id,
    ...decoration
  }).then(
    (resp) => dispatch(createFinalizeUpdateDecoration(decoration.decoration_id, decoration))
  ).catch((err) => {
    console.error(err);
    return dispatch(createErrorUpdateDecoration(decoration.decoration_id, "Cannot update decoration"));
  });
};

// Delete Decoration
const createFinalizeDeleteDecoration = decoration_id => ({
  type: DELETE_DECORATION_SUCCESS,
  payload: {
    decoration_id
  }
});

const createErrorDeleteDecoration = (decoration_id, error) => ({
  type: DELETE_DECORATION_FAILURE,
  payload: {
    decoration_id, 
    error
  }
});

export const createDeleteDecoration = (decoration_id, is_new) => (dispatch, getState) => {
  if (is_new) {
    return dispatch(createFinalizeDeleteDecoration(decoration_id));
  }
  return oauth('DELETE', 'decoration', {decoration_id,})
    .then(
      (resp) => {
        if (resp.error) {
          return dispatch(createErrorDeleteDecoration(decoration_id, "Cannot delete decoration"));
        }
        return dispatch(createFinalizeDeleteDecoration(decoration_id))
      }
    ).catch(() => dispatch(createErrorDeleteDecoration(decoration_id, "Cannot delete decoration")));
};


// Copy Decoration
const createFinalizeCopyDecoration = decoration => ({
  type: COPY_DECORATION_SUCCESS,
  payload: {
    decoration
  }
});

const createErrorCopyDecoration = (decoration_id, error) => ({
  type: COPY_DECORATION_FAILURE,
  payload: {
    decoration_id, 
    error
  }
});

export const createCopyDecoration = (decoration_id) => (dispatch, getState) => {
  return oauth('GET', 'decoration/copy', {decoration_id,})
    .then(
      ({
        json
      }) => dispatch(createFinalizeCopyDecoration(json.decoration))
    ).catch((err) => {
      console.error(err);
      // dispatch(createErrorCopyDecoration(decoration_id, "Cannot copy decoration"));
    });
};


// Load Decoration Charge
const createFinalizeLoadDecorationCharges = decoration_id => ({
  type: LOAD_DECORATION_CHARGE_SUCCESS,
  payload: {
    decoration_id
  }
});

export const createLoadDecorationCharges = (decoration_id) => (dispatch, getState) => {
  return oauth('GET', 'decoration-charge', {decoration_id,})
    .then(
      ({
        json
      }) => dispatch(createFinalizeLoadDecorationCharges(json.decoration))
    );
};

// Add/Update Matrix Cell
const createUpdateMatrixCellState = (decoration_id, value, colData) => ({
  type: UPDATE_MATRIX_CELL_STATE,
  payload: {
    decoration_id,
    value,
    colData,
  }
});

const createErrorUpdateMatrixCell = (decoration_id, error) => ({
  type: UPDATE_MATRIX_CELL_FAILURE,
  payload: {
    decoration_id,
    error,
  }
});

const createFinalizeUpdateMatrixCell = (decoration_id, value, colData, other_data={}) => ({
  type: UPDATE_MATRIX_CELL_SUCCESS,
  payload: {
    decoration_id,
    value,
    colData,
    ...other_data,
  }
});

export const createUpdateMatrixCell = (decoration_id, value, colData) => (dispatch, getState) => {
  const decoration_idx = getState().entities.decorations.data.findIndex(v => v.decoration_id == decoration_id);
  const decoration = getState().entities.decorations.data[decoration_idx];
  const matrix_data = checkMatrixCell(decoration.matrix, value);

  if (matrix_data === false) {
    return dispatch(createErrorUpdateMatrixCell(decoration_id, "Must fill the values in the left & top most cell first"));
  }

  const params = {
    decoration_id: decoration_id,
    row_num: isNaN(value.rowObj.id) ? value.rowObj.id : parseInt(value.rowObj.id),
    col_num: isNaN(colData.idx) ? colData.idx : parseInt(colData.idx),
    cell_value: value.newValue,
    qty: colData.label,
    unit: value.rowObj['-'],
    price_by_type: value.rowObj.price_by_type ? value.rowObj.price_by_type : null,
    qty_parsed: parseInt(colData.label),
    unit_parsed: parseInt(value.rowObj['-']),
    cell_value_parsed: parseFloat(value.newValue),
    cols: JSON.stringify(getState().entities.decorations.data.filter(v => v.decoration_id == decoration_id)[0].matrix.cols),
    rows: JSON.stringify(getState().entities.decorations.data.filter(v => v.decoration_id == decoration_id)[0].matrix.data),
  };

  if (colData.until) {
    params['max_qty'] = colData.until;
  }

  // const last_row = decoration.matrix.data[decoration.matrix.data.length-1];
  // if(decoration.matrix.data 
  //     && decoration.matrix.data.length 
  //     && decoration.matrix.data.length > 1 
  //     && last_row['price_by_type'] == 'per_additional_unit'
  //     && params['row_num'] != last_row['row_num']
  // ) {
  //   params['move_existing'] = true;
  // }

  return oauth('POST', 'decoration-charge', params)
    .then((resp) => {
      if (resp.json && resp.json.error) {
        return dispatch(createErrorUpdateMatrixCell(decoration_id, resp.json.error));
      }
      return dispatch(createFinalizeUpdateMatrixCell(decoration_id, value, colData, {matrix_data}));
    })
    .catch(err => { 
      const err_msg = (err && err.json && err.json.error) ? err.json.error : "Cannot update matrix.";
      return dispatch(createErrorUpdateMatrixCell(decoration_id, err_msg));
    });
};



// Remove and Fix Matric rows data
const createErrorRemoveFixMatrixRow = (decoration_id, error) => ({
  type: REMOVE_FIX_MATRIX_ROW_FAILURE,
  payload: {
    decoration_id, 
    error
  }
});

const createFinalizeRemoveFixMatrixRow = (decoration_id, value) => ({
  type: REMOVE_FIX_MATRIX_ROW_SUCCESS,
  payload: {
    decoration_id,
    value
  }
});

export const createRemoveFixMatrixRow = (decoration_id, value) => (dispatch, getState) => {
  const params = {
    decoration_id: decoration_id,
    row_num: value,
    fix_matrix: true,
  };

  return  oauth('DELETE', 'decoration-charge', params)
    .then((resp) => {
      if (resp.json && resp.json.error) {
        return dispatch(createErrorRemoveFixMatrixRow(decoration_id, "Cannot remove Row"));
      }
      return dispatch(createFinalizeRemoveFixMatrixRow(decoration_id, value));
    })
    .catch(err => {
      return dispatch(createErrorRemoveFixMatrixRow(decoration_id, "Cannot remove Row"));
    });
};




// Remove Matrix Row(s)
const createErrorRemoveMatrixRow = (decoration_id, error) => ({
  type: REMOVE_MATRIX_ROW_FAILURE,
  payload: {
    decoration_id, 
    error
  }
});

const createFinalizeRemoveMatrixRow = (decoration_id, value) => ({
  type: REMOVE_MATRIX_ROW_SUCCESS,
  payload: {
    decoration_id,
    value
  }
});

export const createRemoveMatrixRow = (decoration_id, value, last_row_idx) => (dispatch, getState) => {
  const params = {
    decoration_id: decoration_id,
    row_nums: value,
    last_row_idx
  };

  return  oauth('DELETE', 'decoration-charge', params)
    .then((resp) => {
      if (resp.json && resp.json.error) {
        return dispatch(createErrorRemoveMatrixRow(decoration_id, "Cannot remove Row(s)"));
      }
      return dispatch(createFinalizeRemoveMatrixRow(decoration_id, value));
    })
    .catch(err => {
      return dispatch(createErrorRemoveMatrixRow(decoration_id, "Cannot remove Row(s)"));
    });
};


// Add Matrix Row(s)
const createRequestAddMatrixRow = (decoration_id, value) => ({
  type: ADD_MATRIX_ROW_REQUEST,
  payload: {
    decoration_id,
    value
  }
});

const createErrorAddMatrixRow = (decoration_id, error) => ({
  type: ADD_MATRIX_ROW_FAILURE,
  payload: {
    decoration_id, 
    error
  }
});

const createFinalizeAddMatrixRow = (decoration_id, value, data={}) => ({
  type: ADD_MATRIX_ROW_SUCCESS,
  payload: {
    decoration_id,
    value,
    data
  }
});

export const createAddMatrixRow = (decoration_id, value, data={}) => (dispatch, getState) => {
  return dispatch(createFinalizeAddMatrixRow(decoration_id, value, data));
};


// Remove Matrix Column(s)
const createErrorRemoveMatrixCol = (decoration_id, error) => ({
  type: REMOVE_MATRIX_COL_FAILURE,
  payload: {
    decoration_id, 
    error
  }
});

const createFinalizeRemoveMatrixCol = (decoration_id, value) => ({
  type: REMOVE_MATRIX_COL_SUCCESS,
  payload: {
    decoration_id,
    value
  }
});

export const createRemoveMatrixCol = (decoration_id, value, last_col_idx) => (dispatch, getState) => {
  const params = {
    decoration_id: decoration_id,
    col_nums: value,
    last_col_idx,
  };

  return  oauth('DELETE', 'decoration-charge', params)
    .then((resp) => {
      if (resp.json && resp.json.error) {
        return dispatch(createErrorRemoveMatrixCol(decoration_id, "Cannot remove Column(s)"));
      }
      return dispatch(createFinalizeRemoveMatrixCol(decoration_id, value));
    })
    .catch(err => { 
      return dispatch(createErrorRemoveMatrixCol(decoration_id, "Cannot remove Column(s)"));
    });
};


// Add Matrix Column(s)
const createRequestAddMatrixCol = (decoration_id, value) => ({
  type: ADD_MATRIX_COL_REQUEST,
  payload: {
    decoration_id,
    value
  }
});

const createErrorAddMatrixCol = (decoration_id, error) => ({
  type: ADD_MATRIX_COL_FAILURE,
  payload: {
    decoration_id, 
    error
  }
});

const createFinalizeAddMatrixCol = (decoration_id, value) => ({
  type: ADD_MATRIX_COL_SUCCESS,
  payload: {
    decoration_id,
    value
  }
});

export const createAddMatrixCol = (decoration_id, value) => (dispatch, getState) => {
  dispatch(createFinalizeAddMatrixCol(decoration_id, value));
};


// Update Matrix Column(s) definitions
const createRequestUpdateMatrixColsDef = (decoration_id, value) => ({
  type: UPDATE_MATRIX_COLS_DEF_REQUEST,
  payload: {
    decoration_id,
    value
  }
});

const createErrorUpdateMatrixColsDef = (decoration_id, error) => ({
  type: UPDATE_MATRIX_COLS_DEF_FAILURE,
  payload: {
    decoration_id, 
    error
  }
});

const createFinalizeUpdateMatrixColsDef = (decoration_id, value) => ({
  type: UPDATE_MATRIX_COLS_DEF_SUCCESS,
  payload: {
    decoration_id,
    value
  }
});

export const createUpdateMatrixColsDef = (decoration_id, value) => (dispatch, getState) => {
  return Promise.resolve(
    dispatch(createRequestUpdateMatrixColsDef(decoration_id, value))
  ).then((result) => {
    return dispatch(createUpdateMatrixColsDefSendRequest(decoration_id, value));
  });
};

export const createUpdateMatrixColsDefSendRequest = (decoration_id, value) => (dispatch, getState) => {
  const params = {
    decoration_id: decoration_id,
    cols: JSON.stringify(getState().entities.decorations.data.filter(v => v.decoration_id == decoration_id)[0].matrix.cols),
  };

  return  oauth('GET', 'decoration-charge/update-col', params)
    .then((resp) => {
      if (resp.json && resp.json.error) {
        return dispatch(createErrorUpdateMatrixColsDef(decoration_id, "Cannot update Column(s)"));
      }
      return dispatch(createFinalizeUpdateMatrixColsDef(decoration_id, value));
    })
    .catch(err => {
      console.error(err);
      return dispatch(createErrorUpdateMatrixColsDef(decoration_id, "Cannot update Column(s)"));
    });
}

// Update Matrix Row(s) definitions
const createRequestUpdateMatrixRowsDef = (decoration_id, value) => ({
  type: UPDATE_MATRIX_ROWS_DEF_REQUEST,
  payload: {
    decoration_id,
    value
  }
});

const createErrorUpdateMatrixRowsDef = (decoration_id, error) => ({
  type: UPDATE_MATRIX_ROWS_DEF_FAILURE,
  payload: {
    decoration_id, 
    error
  }
});

const createFinalizeUpdateMatrixRowsDef = (decoration_id, value) => ({
  type: UPDATE_MATRIX_ROWS_DEF_SUCCESS,
  payload: {
    decoration_id,
    value
  }
});

export const createUpdateMatrixRowsDef = (decoration_id, value) => (dispatch, getState) => {
  // const decoration_idx = getState().entities.decorations.data.findIndex(v => v.decoration_id == decoration_id);
  // const decoration = state.data[decoration_idx];
  dispatch(createRequestUpdateMatrixRowsDef(decoration_id, value))

  const params = {
    decoration_id: decoration_id,
    rows: JSON.stringify(getState().entities.decorations.data.filter(v => v.decoration_id == decoration_id)[0].matrix.data),
  };

  return  oauth('GET', 'decoration-charge/update-row', params)
    .then((resp) => {
      if (resp.json && resp.json.error) {
        return dispatch(createErrorUpdateMatrixRowsDef(decoration_id, "Cannot update Row(s)"));
      }
      return dispatch(createFinalizeUpdateMatrixRowsDef(decoration_id, value));
    })
    .catch(err => { 
      return dispatch(createErrorUpdateMatrixRowsDef(decoration_id, "Cannot update Row(s)"));
    });
};


const createFinalizeUpdateMatrixList = (decoration_id, value) => ({
  type: UPDATE_MATRIX_LIST,
  payload: {
    decoration_id,
    value
  }
});

export const createUpdateMatrixList = (decoration_id, value) => (dispatch, getState) => {
  return dispatch(createFinalizeUpdateMatrixList(decoration_id, value));
};




const createFinalizeUpdateMatrix = (decoration_id, value) => ({
  type: UPDATE_MATRIX_SUCCESS,
  payload: {
    decoration_id,
    value
  }
});

export const createUpdateMatrix = (decoration_id, value) => (dispatch, getState) => {
  const d = getState().entities.decorations.data.filter(v => v.decoration_id == decoration_id)[0];
  if (value.type && d.matrix.type != value.type) {
    value['data'] = [];
    value['cols'] = [];

    const params = {
      decoration_id: decoration_id,
      all: 'true',
    };
    return  oauth('GET', 'decoration-charge/delete', params)
      .then((resp) => {
        return dispatch(createFinalizeUpdateMatrix(decoration_id, value));
      });
  }

  return dispatch(createFinalizeUpdateMatrix(decoration_id, value));
};





// Add Decoration Fixed List Charge Item
const createStateAddListCharge = (decoration_id, value) => ({
  type: ADD_LIST_CHARGE_STATE,
  payload: {
    decoration_id,
    value
  }
});

const createRequestAddListCharge = (decoration_id, value) => ({
  type: ADD_LIST_CHARGE_REQUEST,
  payload: {
    decoration_id,
    value
  }
});

const createFinalizeAddListCharge = (decoration_id, value) => ({
  type: ADD_LIST_CHARGE_SUCCESS,
  payload: {
    decoration_id,
    value
  }
});

const createErrorAddListCharge = (decoration_id, error) => ({
  type: ADD_LIST_CHARGE_FAILURE,
  payload: {
    decoration_id, 
    error
  }
});


export const createAddListChargeToState = (decoration_id, charge_data={label: '', cell_value: '', qty: '', unit: ''}) => (dispatch, getState) => {
  charge_data['type'] = 'list-item';
  return dispatch(createStateAddListCharge(decoration_id, charge_data));
};

export const createAddListCharge = (decoration_id, charge_data={label: '', cell_value: null, qty: null, unit: null,}) => (dispatch, getState) => {
  if (!decoration_id || !charge_data) {
    return dispatch(createErrorAddListCharge(decoration_id, "Invalid input"));
  }

  charge_data['type'] = 'list-item';

  dispatch(createRequestAddListCharge(decoration_id, charge_data));

  return oauth('GET', 'decoration-charge/add-charge-list-item', {
    ...charge_data,
    cell_value: charge_data.cell_value ? charge_data.cell_value : '',
    parent_id: decoration_id,
  }).then(
    (resp) => {
      if (resp && resp.json && resp.json.charge_id) {
        const charge_id = resp.json.charge_id;
        const child_id = resp.json.child_id;

        dispatch(createFinalizeAddListCharge(decoration_id, {
          ...charge_data, 
          new_charge_id: (typeof charge_id == 'object') ? charge_id.charge_id : charge_id, 
          child_id: (typeof child_id == 'object') ? child_id.child_id : child_id,
        }))
      }
    }
  ).catch((err) => {
    console.error(err);
    // dispatch(createErrorAddListCharge(decoration_id, "Cannot add charge"))
  });
};


// Update Decoration Fixed List Charge Item
export const createStateUpdateListCharges = (decoration_id, value) => ({
  type: UPDATE_LIST_CHARGES_STATE,
  payload: {
    decoration_id,
    value
  }
});

// Update Decoration Fixed List Charge Item
export const createStateUpdateListCharge = (decoration_id, value) => ({
  type: UPDATE_LIST_CHARGE_STATE,
  payload: {
    decoration_id,
    value
  }
});

export const createRequestUpdateListCharge = (decoration_id, value) => ({
  type: UPDATE_LIST_CHARGE_REQUEST,
  payload: {
    decoration_id,
    value
  }
});

export const createFinalizeUpdateListCharge = (decoration_id, value) => ({
  type: UPDATE_LIST_CHARGE_SUCCESS,
  payload: {
    decoration_id,
    value
  }
});

export const createErrorUpdateListCharge = (decoration_id, error) => ({
  type: UPDATE_LIST_CHARGE_FAILURE,
  payload: {
    decoration_id, 
    error
  }
});

export const createUpdateListCharge = (decoration_id, charge_data={remote_update: true}) => (dispatch, getState) => {
  if (!decoration_id || !charge_data) {
    return dispatch(createErrorUpdateListCharge(decoration_id, "Invalid input"));
  }

  if (charge_data.charge_id && charge_data.charge_id.indexOf("temp_charge_") != -1) {
    return dispatch(createStateUpdateListCharge(decoration_id, charge_data));
  }

  dispatch(createRequestUpdateListCharge(decoration_id, charge_data));
  if (charge_data.remote_update !== false) {
    return oauth('GET', 'decoration-charge/update-charge-list-item', {
      ...charge_data,
      parent_id: decoration_id,
    }).then(
      (resp) => dispatch(createFinalizeUpdateListCharge(decoration_id, charge_data))
    ).catch((err) => {
      console.error(err);
      return dispatch(createErrorUpdateListCharge(decoration_id, "Cannot update charge"));
    });
  } else {
    dispatch(createFinalizeUpdateListCharge(decoration_id, charge_data))
  }
};


// Remove Decoration charge list item
const createRequestRemoveListChargeItem = (decoration_id, value) => ({
  type: REMOVE_DECORATION_CHARGE_ITEM_REQUEST,
  payload: {
    decoration_id,
    value
  }
});

const createFinalizeRemoveListChargeItem = (decoration_id, value) => ({
  type: REMOVE_DECORATION_CHARGE_ITEM_SUCCESS,
  payload: {
    decoration_id,
    value
  }
});

const createErrorRemoveListChargeItem = (decoration_id, error) => ({
  type: REMOVE_DECORATION_CHARGE_ITEM_FAILURE,
  payload: {
    decoration_id, 
    error
  }
});

export const createRemoveListChargeItem = (decoration_id, charge_data={}) => (dispatch, getState) => {
  if (!decoration_id || !charge_data || !charge_data.charge_id) {
    return;
  }

  dispatch(createRequestRemoveListChargeItem(decoration_id, charge_data));

  return oauth('DELETE', 'decoration-charge', {
    parent_id: decoration_id,
    decoration_id: decoration_id,
    charge_id: charge_data.charge_id,
  }).then(
    (resp) => dispatch(createFinalizeRemoveListChargeItem(decoration_id, charge_data))
  ).catch((err) => {
    console.log(err);
    return dispatch(createErrorRemoveListChargeItem(decoration_id, "Cannot remove charge"));
  });
};


// Remove Decoration charge list item
const createRequestRemoveDecorations = (decorator_id) => ({
  type: REMOVE_DECORATIONS_REQUEST,
  payload: {
    decorator_id
  }
});

const createFinalizeRemoveDecorations = (decorator_id) => ({
  type: REMOVE_DECORATIONS_SUCCESS,
  payload: {
    decorator_id
  }
});

const createErrorRemoveDecorations = (decorator_id, error) => ({
  type: REMOVE_DECORATIONS_FAILURE,
  payload: {
    decorator_id, 
    error
  }
});

export const createRemoveAllDecorations = (decorator_id) => (dispatch) => {
  if (!decorator_id) {
    return;
  }

  return oauth('DELETE', 'decoration', {
    parent_id: decorator_id,
    all: true,
  }).then(
    (resp) => {
      dispatch(createFinalizeSetDecoratorId(""));
      dispatch(createFinalizeRemoveDecorations(decorator_id));
    }
  ).catch((err) => {
    console.log(err);
    return dispatch(createErrorRemoveDecorations(decorator_id, "Cannot remove decortions"));
  });
}


