import _ from 'lodash'
import React, { Component } from 'react'

import { connect } from 'react-redux'

import { getProductConfigurations } from '../promostandards';
import { getConfigurationDecoration } from '../promostandard_utils';
import { getImprintDropdown, getProofOptions } from '../selectors/dropdowns'
import { closePopup, createArtworkLibraryPopup, createSelectFilePopup } from '../actions/popup'
import { createUpdateArtwork, createAddArtwork, createDeleteArtwork } from '../actions/artwork'
import { createDeleteTemp } from '../actions/temp'
import { getImageSrc } from '../utils'
import { TBD_FILE_ID } from './ArtworkLibrary'

import Form from './Form'
import Img from './Img'
import PS from './PromostandardsLabel';
import Loading from './Loading';

import { BASE_ZINDEX } from '../popup-factory'

const TEMP_FILE_REGISTER = 'artwork_file_id';

class EditArtworkPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      artwork: props.artwork,
    };

    _.bindAll(this, ['onFormRef', 'handleUpdateArtwork', 'handleFieldChange']);
  }

  componentDidMount() {
    if ('ps-products' === this.props.item.copied_from) {
      this.loadPSProductConfigurations();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { artwork_file_id, image, imprint_id } = nextProps.artwork;
    this.setState({
      artwork: Object.assign({}, this.state.artwork, {
        artwork_file_id, image, 
        imprint_id: this.state.artwork.imprint_id || imprint_id
      })
    });
  }

  async loadPSProductConfigurations() {
    const { product, item_location, item_decoration } = this.props;
    const ps_location_id = _.get(item_location, 'ext_location_id');
    const ps_decoration_id = _.get(item_decoration, 'ext_decoration_id');
    const configuration = await getProductConfigurations({
      productId: product.ext_product_id,
    }) || {};
    const decoration = getConfigurationDecoration(configuration, ps_location_id, ps_decoration_id);
    this.setState({ decoration });
  }

  componentWillUnmount() {
    this.props.onCleanUp();
  }

  onFormRef(form) {
    this._form = form;
  }

  handleUpdateArtwork(artwork, errors) {
    if (_.every(errors, (error) => error === null)) {  
      this.props.onUpdateArtwork(artwork, this.props.copy);
    }
  }

  handleFieldChange(value, field) {
    this.setState({
      artwork: {
        ...this.state.artwork,
        [field]: value,
      },
    });
  }

  renderLoading() {
    const { index, copy } = this.props;
    const { artwork } = this.state;
    return (
      <div id="add-art-modal" className="reveal large" style={{display: 'block', zIndex: BASE_ZINDEX + index}} aria-labelledby="modalTitle" role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3 id="modalTitle">{artwork.artwork_id && !copy ? 'Update Artwork' : 'Add Artwork'}</h3>
          </div>
        </div>
        <div className="row popup-content">
          <div className="small-12 columns">
            <Loading />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      locked, copy, imprints, proofs, account_id, is_project, job_id,
      onClosePopup, onDeleteArtwork, onOpenArtworkLibrary, onCreateSelectFilePopup, index,
    } = this.props;

    const { artwork, decoration } = this.state;
    const img_src = getImageSrc(artwork.image, 'medium');
    const canEditImprint = !artwork.item_decoration_id;

    const handleCancel = e => {
      e.preventDefault();
      onClosePopup();
    };

    const handleDelete = e => {
      e.preventDefault();
      onDeleteArtwork(artwork.artwork_id);
    };

    const handleSave = e => {
      e.preventDefault();
      this._form && this._form.submit()
    };

    const save_style = { position: 'fixed', right: '1rem', top: '1rem' };
    const delete_style = { position: 'fixed', right: '5rem', top: '1rem' };
    const cancel_style = locked ? { position: 'fixed', right: '1rem', top: '1rem' } :
      (artwork.artwork_id && canEditImprint && !copy ? { position: 'fixed', right: '9.8rem', top: '1rem' } :
      { position: 'fixed', right: '5rem', top: '1rem' });

    return (
      <div id="add-art-modal" className="reveal large" style={{display: 'block', zIndex: BASE_ZINDEX + index}} aria-labelledby="modalTitle" role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3 id="modalTitle">{artwork.artwork_id && !copy ? 'Update Artwork' : 'Add Artwork'}</h3>
          </div>
        </div>
        <Form className="row popup-content form" object={artwork}
          ref={this.onFormRef} onSubmit={this.handleUpdateArtwork}
          onFieldChange={this.handleFieldChange}
        >
          <div className="small-12 medium-6 columns"> 
            <Form.Select label="Imprint Type" field="imprint_id" 
              required={true} options={imprints} disabled={!canEditImprint} />
            <Form.Select label="Proof Required" field="proof_id" 
              required={true}  options={proofs}/>
            <Form.Textarea label="Logo Name" field="artwork_title" 
              required={true}/>
            <Form.TextInput label="Logo Size" field="artwork_size" 
              required={true}/>
            {!!decoration &&
              <div className="row">
                <div className="small-12 medium-8 medium-push-4 columns" style={{ marginTop: '-0.5rem', marginBottom: '1rem', fontSize: 'small' }}>
                  {decoration.geometry}
                </div>
              </div>}
            <Form.Textarea label="Logo Color" field="artwork_color"
              required={true}/>
            <Form.Select label="Repeat Logo?" field="artwork_repeat" 
              options={[
                { key: '0', value: 'No' },
                { key: '1', value: 'Yes' }
              ]}/>
            <Form.Textarea label="Supplier Notes" field="artwork_notes"/>
          </div>
          <div className="small-12 medium-6 columns">
            {!!account_id ?
              <Form.BaseField field="artwork_file_id" 
                Control="div" displayLabel={false}
                validate={() => {
                  return artwork.image || artwork.artwork_file_id === TBD_FILE_ID ? null : {message: 'Image is required'}
                }}
                onClick={e => {
                  !locked && is_project 
                    ? onCreateSelectFilePopup(TEMP_FILE_REGISTER, job_id, 'JOB', account_id) 
                      : !locked 
                        ? onOpenArtworkLibrary(TEMP_FILE_REGISTER)
                          : null;
                  this._form && this._form.cleanFieldError('artwork_file_id');
                }}
              >
                <div className="art-placeholder" style={{marginBottom: '1em'}}>
                  {artwork.image ? <Img src={img_src} /> : 
                    (TBD_FILE_ID === artwork.artwork_file_id ?
                    <img src="/images/404.png" /> :  
                    (locked ? 'No Image' : 'Select Image'))}
                </div>
              </Form.BaseField> :
            <div className="art-placeholder" style={{ marginBottom: '1em' }}>
              Client will select image
            </div>}
          </div>
        </Form>
        <a className="alert button" style={cancel_style} onClick={handleCancel}>{locked ? 'Done' : 'Cancel'}</a>
        {!locked && !copy && canEditImprint && artwork.artwork_id ? <a className="warning button" style={delete_style} onClick={handleDelete}>Delete</a> : null}
        {!locked ? <a className="button" style={save_style} onClick={handleSave}>Save</a> : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const base_imprints = getImprintDropdown(state).sort(
    (a, b) => {
      if (!!a.ext_artwork_id && !b.ext_artwork_id) {
        return -1;
      } else if (!!b.ext_artwork_id && !a.ext_artwork_id) {
        return 1;
      }
      if (a.imprint_name < b.imprint_name) {
        return -1;
      } else if (b.imprint_name < a.imprint_name) {
        return 1;
      }
      return 0;
    }
  );
  const artwork_file_id = state.temp[TEMP_FILE_REGISTER];
  const image = state.entities.files[artwork_file_id];
  const image_update = undefined !== artwork_file_id ? { artwork_file_id, image } : {};

  const proofs = [{
    key: '', value: 'Please select a proof type...',
  }].concat(getProofOptions(state));

  const default_artwork = {
    proof_id: proofs[0].key,
    artwork_title: '',
    artwork_size: '',
    artwork_color: '',
    artwork_repeat: 0,
    artwork_notes: ''
  };

  const artwork = Object.assign({}, default_artwork, ownProps.artwork, image_update);
  const locked = ownProps.locked;
  const item = state.entities.items[artwork.item_id];
  const product = _.get(state.entities.products, item.parent_id);
  const item_location = _.get(state.entities.item_locations, artwork.item_location_id);
  const item_decoration = _.get(state.entities.item_decorations, artwork.item_decoration_id);
  const imprint = _.get(state.entities.imprints, _.get(item_decoration, 'imprint_id')) || {};

  const imprints = [{
    key: '',
    value: 'Please select an imprint type...'
  }].concat(
    _.get(imprint, 'tenant_id') === 'PROMOSTANDARDS' ?
    [{
      key: imprint.imprint_id,
      value: <span><PS />{imprint.imprint_name}</span>
    }] :
    base_imprints.filter(i => !i.ext_artwork_id).map(i => ({
      key: i.imprint_id,
      value: i.imprint_name
    }))
  );

  let job_id = null
  const is_project = /\/project\//.test(state.router.location.pathname)
  if(is_project) {
    job_id = Object.keys(state.entities.projects)[0]
  }

  return {
    imprints,
    proofs,
    artwork: { ...artwork, imprint_id: artwork.imprint_id || imprints[0].key || imprint.imprint_id, },
    locked,
    item,
    product,
    item_location,
    item_decoration,
    is_project,
    job_id
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onCleanUp: () => {
      dispatch(createDeleteTemp(TEMP_FILE_REGISTER));
    },
    onClosePopup: () => {
      dispatch(closePopup());
    },
    onUpdateArtwork: (artwork, copy) => {
      if (artwork.artwork_id && !copy) {
        dispatch(createUpdateArtwork(artwork));
      } else {
        dispatch(createAddArtwork(artwork));
      }
      dispatch(closePopup());
    },
    onDeleteArtwork: artwork => {
      dispatch(createDeleteArtwork(artwork));
      dispatch(closePopup());
    },
    onOpenArtworkLibrary: file_register => {
      dispatch(createArtworkLibraryPopup(file_register, null, ownProps.account_id, ownProps.account_type));
    },
    onCreateSelectFilePopup: (register, parent_id, parent_type, client_id) => {
      dispatch(createSelectFilePopup(register, parent_id, parent_type, client_id))
    }
  };
};

const ConnectedEditArtworkPopup = connect(mapStateToProps, mapDispatchToProps)(EditArtworkPopup);
export default ConnectedEditArtworkPopup;
