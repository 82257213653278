import _ from 'lodash'
import React, { Component } from 'react'

export default class TextInput extends Component {
  constructor(props) {
    super(props);

    _.bindAll(this, ['handleFocus']);
  }

  handleFocus(e) {
    if (this.props.selectOnFocus) {
      e.target.select();
    }
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  render() {
    const {
      selectOnFocus,
      hasIcon,
      iconStyles={},
      IconElem=null,
      iconProps={},
      iconRight=false,
      containerStyles = {},
      ...props
    } = this.props;
    if (hasIcon) {
      return (
        <div style={{zIndex: '200', position: 'relative', paddingBottom: '13px', ...containerStyles}}>

          {!iconRight ? (IconElem ? 
            <IconElem width={'4%'} height={'4%'} {...iconProps} 
              style={{position: 'absolute', display: 'inline-block', left: '10px', top: '0.3rem', ...iconStyles}} /> :
            <i className={props.iconClass} {...iconProps} 
                style={{display: 'inline-block', position: 'absolute', colos: '#C2DBE2', fontSize: '1em', ...iconStyles}}></i>) : null}

          <input {...props} type="text" onFocus={this.handleFocus} 
            style={{display: 'inline-block', 
                    paddingRight: (iconRight ? (iconStyles.width ? parseFloat(iconStyles.width) + 15 : 10) : '0.5rem'), 
                    paddingLeft: (!iconRight ? (iconStyles.width ? parseFloat(iconStyles.width) + 15 : 10) : '0.5rem'), 
                    ...props.titleStyle
            }} />

          {iconRight ? (IconElem ? 
            <IconElem width={'4%'} height={'4%'} {...iconProps}
              style={{position: 'absolute', display: 'inline-block', width: '30px', height: '30px', right: '10px', top: '0.3rem', ...iconStyles}} /> :
            <i className={props.iconClass} {...iconProps}
                style={{display: 'inline-block', position: 'absolute', colos: '#C2DBE2', fontSize: '1em', ...iconStyles}}></i>) : null}
        </div>
      );
    }
    return (
      <input {...props} type="text" onFocus={this.handleFocus} />
    );
  }
}

TextInput.defaultProps = {
  selectOnFocus: false,
  //disableDrag: true,
};
