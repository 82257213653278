import React, { Component } from 'react'
import { BASE_ZINDEX } from '../popup-factory'


class SpinnerPopup extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { index, message = 'Loading...' } = this.props;
    return (
      <div className="reveal small" style={{ display: 'block', height: 'initial', zIndex: BASE_ZINDEX + index }}>
        <div className="row">
          <div className="small-4 columns">
            <img src="/images/gears.gif" />
          </div>
          <div className="small-8 columns">
            {message}
          </div>
        </div>
      </div>
    );
  }
}

export default SpinnerPopup;
