import { oauth } from '../utils'

export const LOAD_DIVISION_REQUEST = 'LOAD_DIVISION_REQUEST';
export const LOAD_DIVISION_SUCCESS = 'LOAD_DIVISION_SUCCESS';
export const LOAD_DIVISION_FAILURE = 'LOAD_DIVISION_FAILURE';

export const LOAD_DIVISION_ADDRESSES_REQUEST = 'LOAD_DIVISION_ADDRESSES_REQUEST';
export const LOAD_DIVISION_ADDRESSES_SUCCESS = 'LOAD_DIVISION_ADDRESSES_SUCCESS';
export const LOAD_DIVISION_ADDRESSES_FAILURE = 'LOAD_DIVISION_ADDRESSES_FAILURE';
export const LOAD_DIVISION_CONTACTS_REQUEST = 'LOAD_DIVISION_CONTACTS_REQUEST';
export const LOAD_DIVISION_CONTACTS_SUCCESS = 'LOAD_DIVISION_CONTACTS_SUCCESS';
export const LOAD_DIVISION_CONTACTS_FAILURE = 'LOAD_DIVISION_CONTACTS_FAILURE';

export const ADD_SUPPLIER_REQUEST = 'ADD_SUPPLIER_REQUEST';
export const ADD_SUPPLIER_SUCCESS = 'ADD_SUPPLIER_SUCCESS';
export const ADD_SUPPLIER_FAILURE = 'ADD_SUPPLIER_FAILURE';

export const LOAD_SUPPLIER_REQUEST = 'LOAD_SUPPLIER_REQUEST';
export const LOAD_SUPPLIER_SUCCESS = 'LOAD_SUPPLIER_SUCCESS';
export const LOAD_SUPPLIER_FAILURE = 'LOAD_SUPPLIER_FAILURE';

export const FETCH_SUPPLIERS_SUCCESS = 'FETCH_SUPPLIERS_SUCCESS';

const createAddSupplierRequest = supplier => ({
  type: ADD_SUPPLIER_REQUEST,
  payload: {
    supplier
  }
});

const createFinalizeAddSupplier = supplier => ({
  type: ADD_SUPPLIER_SUCCESS,
  payload: {
    supplier
  }
});

const createFailedAddSupplier = supplier => ({
  type: ADD_SUPPLIER_FAILURE,
  payload: {
    supplier
  }
});

export const createAddSupplier = supplier => dispatch => {
  dispatch(createAddSupplierRequest(supplier));

  return oauth('POST', 'account', Object.assign({ company_type: 'SUPPLIER' }, supplier)).then(
    ({ json }) => dispatch(createFinalizeAddSupplier(json.account)),
    ({ json }) => dispatch(createFailedAddSupplier(supplier))
  );
};

const createLoadSupplierRequest = supplier_type => ({
  type: LOAD_SUPPLIER_REQUEST,
  payload: {
    type: supplier_type
  }
});

const createFinalizeLoadSupplier = (supplier_type, suppliers) => ({
  type: LOAD_SUPPLIER_SUCCESS,
  payload: {
    type: supplier_type,
    suppliers
  }
});

const createFailedLoadSupplier = supplier_type => ({
  type: LOAD_SUPPLIER_FAILURE,
  payload: {
    type: supplier_type
  }
});

export const createLoadSupplier = supplier_type => dispatch => {
  dispatch(createLoadSupplierRequest(supplier_type));

  const data = {
    type: supplier_type,
    'max-results': 4294967296 // big number
  };

  oauth('GET', 'supplier/toc', data).then(
    ({ json }) => dispatch(createFinalizeLoadSupplier(supplier_type, json.suppliers)),
    ({ json }) => dispatch(createFailedLoadSupplier(supplier_type))
  );
}

const createLoadDivisionRequest = supplier_type => ({
  type: LOAD_DIVISION_REQUEST,
  payload: {
    type: supplier_type
  }
});

const createFinalizeLoadDivision = (supplier_type, divisions) => ({
  type: LOAD_DIVISION_SUCCESS,
  payload: {
    type: supplier_type,
    divisions
  }
});

const createFailedLoadDivision = supplier_type => ({
  type: LOAD_DIVISION_FAILURE,
  payload: {
    type: supplier_type
  }
});

export const createLoadDivision = (supplier_type, check_supplier_active=false) => dispatch => {
  dispatch(createLoadDivisionRequest(supplier_type));

  const data = {
    type: supplier_type,
    check_supplier_active: check_supplier_active === true ? 1 : 0,
    'max-results': 4294967296 // big number
  };

  oauth('GET', 'division/toc', data).then(
    ({ json }) => dispatch(createFinalizeLoadDivision(supplier_type, json.divisions)),
    ({ json }) => dispatch(createFailedLoadDivision(supplier_type))
  );
}

const createLoadDivisionAddressesRequest = division_id => ({
  type: LOAD_DIVISION_ADDRESSES_REQUEST,
  payload: {
    division_id: division_id
  }
});

const createFinalizeLoadDivisionAddresses = (division_id, addresses) => ({
  type: LOAD_DIVISION_ADDRESSES_SUCCESS,
  payload: {
    division_id: division_id,
    addresses
  }
});

const createFailedLoadDivisionAddresses = division_id => ({
  type: LOAD_DIVISION_ADDRESSES_FAILURE,
  payload: {
    division_id: division_id
  }
});

export const createLoadDivisionAddresses = division_id => dispatch => {
  dispatch(createLoadDivisionAddressesRequest(division_id));

  const data = {
    parent_type: 'SUPPLIER-ACCOUNT',
    parent_id: division_id
  };

  oauth('GET', 'address', data).then(
    ({ json }) => dispatch(createFinalizeLoadDivisionAddresses(division_id, json.addresses)),
    ({ json }) => dispatch(createFailedLoadDivisionAddresses(division_id))
  );
}

const createLoadDivisionContactsRequest = division_id => ({
  type: LOAD_DIVISION_CONTACTS_REQUEST,
  payload: {
    division_id: division_id
  }
});

const createFinalizeLoadDivisionContacts = (division_id, contacts) => ({
  type: LOAD_DIVISION_CONTACTS_SUCCESS,
  payload: {
    division_id: division_id,
    contacts
  }
});

const createFailedLoadDivisionContacts = division_id => ({
  type: LOAD_DIVISION_CONTACTS_FAILURE,
  payload: {
    division_id: division_id
  }
});

export const createLoadDivisionContacts = division_id => dispatch => {
  dispatch(createLoadDivisionContactsRequest(division_id));

  const data = {
    company_id: division_id,
    search_type: 'company-users',
    company_type: 'SUPPLIER',
    exclude_hidden: 1,
    exclude_default_company_users: 1
  };

  oauth('GET', 'user', data).then(
    ({ json }) => dispatch(createFinalizeLoadDivisionContacts(division_id, json.companyUsers)),
    ({ json }) => dispatch(createFailedLoadDivisionContacts(division_id))
  );
}

const createFinalizeSupplierAccount = supplier_account => dispatch => {
  
}

export const createSupplierAccount = (company_id, company_name, company_type) => dispatch => {
  const data = {
    company_id,
    company_name,
    company_type
  };

  oauth('POST', 'account', data).then(
    ({ json }) => dispatch(createFinalizeSupplierAccount(json.account))
  );
}

const finalizeFetchSuppliers= (suppliers) => ({
  type: FETCH_SUPPLIERS_SUCCESS,
  payload: {
    suppliers
  }
});

export const createFetchSuppliers = (keyword, tag_id, account_status_id, accounts_only) => dispatch => {
  const data = {
    entities_only: true,
    keyword,
    tag_id,
    account_status_id,
    accounts_only
  }

  oauth('GET', 'page/suppliers', data).then(
    ({ json }) => dispatch(finalizeFetchSuppliers(json.payload.suppliers))
  );
}
