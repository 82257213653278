import { oauth } from '../utils'
import { createFinalizeAddTax } from '../actions/tax';

export const CREATE_ADDRESS_REQUEST = 'CREATE_ADDRESS_REQUEST';
export const CREATE_ADDRESS_SUCCESS = 'CREATE_ADDRESS_SUCCESS';
export const CREATE_ADDRESS_FAILURE = 'CREATE_ADDRESS_FAILURE';

export const DELETE_ADDRESS_REQUEST = 'DELETE_ADDRESS_REQUEST';
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS';
export const DELETE_ADDRESS_FAILURE = 'DELETE_ADDRESS_FAILURE';

export const LOAD_ADDRESS_LIST_SUCCESS = 'LOAD_ADDRESS_LIST_SUCCESS';

export const UPDATE_ADDRESS_REQUEST = 'UPDATE_ADDRESS_REQUEST';
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';
export const UPDATE_ADDRESS_FAILURE = 'UPDATE_ADDRESS_FAILURE';

const createAddAddressRequest = address => ({
  type: CREATE_ADDRESS_REQUEST,
  payload: {
    address
  }
});

const createFinalizeAddAddress = address => ({
  type: CREATE_ADDRESS_SUCCESS,
  payload: {
    address
  }
});

const createFailedAddAddress = address => ({
  type: CREATE_ADDRESS_FAILURE,
  payload: {
    address
  }
});

export const createAddAddress = address => dispatch => {
  dispatch(createAddAddressRequest(address));

  return oauth('POST', 'address', address).then(
    ({ json }) => { 
      dispatch(createFinalizeAddAddress(json.address))
      if(json.tax) {
        dispatch(createFinalizeAddTax(json.tax))
      }
    },
    ({ json }) => dispatch(createFailedAddAddress(address))
  );
};

export const createCopyAddress = (address_id, parent_id, parent_type, address_type) => dispatch => {
  dispatch(createAddAddressRequest({ address_id, parent_id, parent_type, address_type }));
  return oauth('POST', 'address', { address_id, parent_id, parent_type, address_type }).then(
    ({ json }) => {
      dispatch(createFinalizeAddAddress(json.address))
      if(json.tax) {
        dispatch(createFinalizeAddTax(json.tax))
      }
    },
    ({ json }) => dispatch(createFailedAddAddress(address))
  );
};

const createDeleteAddressRequest = address => ({
  type: DELETE_ADDRESS_REQUEST,
  payload: {
    address
  }
});

const createFinalizeDeleteAddress = address => ({
  type: DELETE_ADDRESS_SUCCESS,
  payload: {
    address
  }
});

const createFailedDeleteAddress = address => ({
  type: DELETE_ADDRESS_FAILURE,
  payload: {
    address
  }
});

export const createDeleteAddress = address => dispatch => {
  dispatch(createDeleteAddressRequest(address));

  return oauth('DELETE', `address/${address.address_id}`).then(
    ({ json }) => dispatch(createFinalizeDeleteAddress(address)),
    ({ json }) => dispatch(createFailedDeleteAddress(address))
  );
};

const createFinalizeLoadAddressList = (company_id, addresses) => ({
  type: LOAD_ADDRESS_LIST_SUCCESS,
  payload: {
    company_id,
    addresses
  }
});

export const createLoadAddressList = (company_id, company_type) => dispatch => {
  return oauth('GET', 'address', { parent_id: company_id, parent_type: company_type }).then(
    ({ json }) => dispatch(createFinalizeLoadAddressList(company_id, json.addresses))
  );
};

const createUpdateAddressRequest = (id, field, previous_value, value) => ({
  type: UPDATE_ADDRESS_REQUEST,
  payload: {
    id,
    data: {
      [field]: value
    }
  }
});

export const createFinalizeUpdateAddress = (id, field, previous_value, value) => ({
  type: UPDATE_ADDRESS_SUCCESS,
  payload: {
    id,
    data: {
      [field]: value
    }
  }
});

const createFailedUpdateAddress = (id, field, previous_value, value) => ({
  type: UPDATE_NOTE_FAILURE,
  payload: {
    id,
    message: 'Unable to update address',
    data: {
      [field]: value
    }
  }
});

export const createUpdateAddress = (address_id, field, previous_value, value) => (dispatch, getState) => {
  dispatch(createUpdateAddressRequest(address_id, field, previous_value, value));

  oauth('PUT', `address/${address_id}`, {[field]: value}).then(
    ({ json }) => dispatch(createFinalizeUpdateAddress(address_id, field, previous_value, json.address[field])),
    ({ json }) => dispatch(createFailedUpdateAddress(address_id, field, previous_value, value))
  );
}

