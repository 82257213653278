import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { TabBar, Tab, Button, Theme, Popup, Select, Row, Col, ShowPopup } from '@commonsku/styles';
import Files from './Files'
import ReskuSelect from './Select'
import UploadFilesPopup from './UploadFilesPopup'

import { closePopup, createUploadFilesPopup } from '../actions/popup'
import { createAddTemp } from '../actions/temp'
import { createFinalizeAddProductImage } from '../actions/index'
import { addProof } from '../actions/purchase_order_proof'

import { getFolderOptions } from '../selectors/dropdowns'

import { BASE_ZINDEX } from '../popup-factory'

class SelectFilePopup extends Component {

  constructor(props) {
    super(props)

    this.state = {
      selectedTab: 'project',
      folder_id: 'all'
    }

    _.bindAll(this, ['handleChangeFolder'])
  }

  handleChangeFolder(folder_id) {
    this.setState({ folder_id })
  }

  renderTabs() {
    const { folders, parent_id, parent_type, client_id, resku, onCreateUploadFilesPopup, register } = this.props
    const { selectedTab, folder_id } = this.state
    const tabs = [
      {
        key: 'project',
        label: 'Project Files'
      },
      {
        key: 'client',
        label: 'Client Files'
      }
    ]
    const no_upload = register === 'message_file_id' || register === 'collaborate_file_id' || register === 'note_file_id'

    return (
      <Row>
        <Col xs={6}>
          <TabBar>
            {tabs.map((tab, index) => <Tab key={index}
              selected={tab.key == selectedTab}
              onClick={() => {this.setState({ selectedTab: tab.key })}}>
              {tab.label}
            </Tab>)}
          </TabBar>
        </Col>
        <Col xs={4}>
          {selectedTab === 'client' && resku ? 
            <ReskuSelect 
              options={folders} 
              value={folder_id} 
              change={this.handleChangeFolder}
              style={{ width: '150px', float: 'right', 'zIndex': 1008, marginTop: '10px' }}
            />
          : selectedTab === 'client' ?
            <div style={{ marginTop: '10px' }}>
              <Select 
                options={folders} 
                value={folders.filter(f => f.value === folder_id)} 
                onChange={option => this.handleChangeFolder(option.value)}
              />
            </div>
          : null}
        </Col>
        <Col xs={2}>
          {!no_upload && resku ?
            <Button style={{ float: 'right' }} type="secondary" onClick={(e) => onCreateUploadFilesPopup()}>Upload</Button>
          : !no_upload ?
            <ShowPopup popup={UploadFilesPopup} parent_id={parent_id} parent_type={parent_type} client_id={client_id} resku={false} render={({ onClick }) => {
              return <Button onClick={onClick} style={{ float: 'right' }}>Upload</Button>
            }} />
          : null}
        </Col>
      </Row>
    )
  }

  renderTabContent() {
    const { job_id, client_id, onSelectFile, register } = this.props
    const { selectedTab, folder_id } = this.state

    let tbd = false
    if(register !== 'message_file_id' && register !== 'collaborate_file_id' && register !== 'note_file_id') {
      if(selectedTab === 'project' || (selectedTab === 'client' && folder_id === 'all')) {
        tbd = true
      }
    }

    if(selectedTab === 'project') {
      return <Files type="project" job_id={job_id} onSelectFile={onSelectFile} tbd={tbd} />
    }

    return <Files type="client" client_id={client_id} folder_id={folder_id !== 'all' ? folder_id : null} onSelectFile={onSelectFile} tbd={tbd} />
  }

  render() {
    const { onClose, onClosePopup, resku, index } = this.props

    if(resku) {
      return (
        <div className="reveal large" style={{ display: 'block', zIndex: BASE_ZINDEX + index, height: 'initial', overflow: 'visible', transition: 'width 0.1s, height 0.1s' }} data-reveal aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
          <div className ="row">
            <h3 id="modalTitle">Select File</h3>
            <div className ="small-12">
              <a className="alert button" style={{ position: 'fixed', right: '1rem', top: '1rem' }} onClick={e => onClosePopup()}>Cancel</a>
            </div>
          </div>
          <div className="row" style={{ overflow: 'auto', height: '500px' }}>
            {this.renderTabs()}
            {this.renderTabContent()}
          </div>
        </div>
      )
    }

    return (
      <Theme>
        <Popup 
          style={{ padding: '5px 25px' }} 
          onClose={onClose} 
          title="Select File" 
          controls={<Button onClick={onClose}>Close</Button>}>
            <div className="row" style={{ overflow: 'auto' }}>
              {this.renderTabs()}
              {this.renderTabContent()}
            </div>
        </Popup>
      </Theme>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let folders = [{key: 'all', value: 'All Files'}].concat(getFolderOptions(state))

  return {
    job_id: Object.keys(state.entities.projects)[0],
    client_id: ownProps.client_id ? ownProps.client_id : Object.values(state.entities.projects)[0].account_id,
    folders: ownProps.resku ? folders : folders.map(f => ({value: f.key, label: f.value}))
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClosePopup: () => {
      dispatch(closePopup())
    },
    onSelectFile: (file) => {
      if(ownProps.parent_type === 'ITEM') {
        dispatch(createFinalizeAddProductImage(ownProps.parent_id, file.file_id))
      }else if (ownProps.parent_type === 'PURCHASE-ORDER'){
        dispatch(addProof(ownProps.parent_id, file))
      }else{
        // used in send_email.tpl 
        if(ownProps.register === 'user_file_id') {
          var attachments = document.querySelector('input[name=attachment_file_ids]');
          var attachmentList = document.querySelector('div.attachment-list');
          attachments.value = (attachments.value + "," + file.file_id);
          var html = "<p data-file-id='" + file.file_id + "'>" + file.file_display_name + " <a href='#' class='remove-file'>Remove</a></p>";
          attachmentList.insertAdjacentHTML('beforeend', html);
          attachmentList.style.display = 'block';
        }else{
          dispatch(createAddTemp(ownProps.register, file.file_id))
        }
      }
      
      if(ownProps.resku) {
        dispatch(closePopup())
      }else{
        ownProps.onClose()
      }
    },
    onCreateUploadFilesPopup: () => {
      if(ownProps.parent_type === 'ITEM') {
        dispatch(createUploadFilesPopup(ownProps.parent_id, ownProps.parent_type, ownProps.client_id, true, true))
      }else{
        dispatch(createUploadFilesPopup(ownProps.parent_id, ownProps.parent_type, ownProps.client_id))
      }
    }
  }
}


const ConnectedSelectFilePopup = connect(mapStateToProps, mapDispatchToProps)(SelectFilePopup);
export default ConnectedSelectFilePopup;
