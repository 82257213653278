import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import LabelledCheckbox from './LabelledCheckbox'
import LabelledInput from './LabelledInput'

import { closePopup } from '../actions/popup'
import {
  createAddItemColor,
  createAddItemSize,
  createAddItemSku,
  createDeleteItemColor,
  createDeleteItemSize,
  createDeleteItemSku,
  createDeleteAllItemColors,
  createDeleteAllItemSizes,
  createDeleteAllItemSkus
} from '../actions'

import { getFullOptionItem } from '../selectors'
import { BASE_ZINDEX } from '../popup-factory'

class ColorSizeSubsetPopup extends Component {

  constructor(props) {
    super(props);
this.state = {
      new_value: '',
      variant_list: this.props.variant_list,
      selected: this.props.selected
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const variants = this.state.variant_list.map(v => v[this.props.variant_id_name]);
    const item_variant_id_by_variant_id = nextProps.variant_list.reduce((o, v) => {
      o[v[this.props.variant_id_name]] = v[this.props.item_variant_id_name];
      return o;
    }, {});
    const checked_by_variant_id = nextProps.variant_list.reduce((o, v) => {
      o[v[this.props.variant_id_name]] = v.checked;
      return o;
    }, {});
    this.setState({
      variant_list: this.state.variant_list.concat(
        nextProps.variant_list.filter(v => !variants.includes(v[this.props.variant_id_name]))
      ).map(v => Object.assign({}, v, {
        checked: checked_by_variant_id[v[this.props.variant_id_name]] || false,
        [this.props.item_variant_id_name]: item_variant_id_by_variant_id[v[this.props.variant_id_name]]
      })),
      selected: nextProps.selected
    });
  }

  render() {
    const { onClosePopup, index, item_id, variant_id_name, variant_name, item_variant_id_name, textTransform, addNew } = this.props;
    const { variant_list, selected } = this.state;
    const handleToggleVariant = (item_variant_id, variant_id) => checked => {
      if (1 === checked) {
        this.props.onAddItemVariant(item_id, variant_id);
      } else {
        this.props.onDeleteItemVariant(item_variant_id);
      }
    };
    const onAddVariant = () => {
      if (this.state.new_value.length) {
        this.props.onAddItemVariant(item_id, null, this.state.new_value);
        this.setState({ new_value: '' });
      }
    };
    const all_selected = variant_list.reduce((s, v) => s && selected.includes(v[variant_id_name]), true);
    const handleToggleAllSelected = checked => {
      if (1 === checked) {
        this.props.onAddItemVariant(item_id, variant_list.filter(v => !selected.includes(v[variant_id_name])).map(v => v[variant_id_name]));
      } else {
        this.props.onDeleteAllItemVariants(item_id);
      }
    };

    return (
      <div className="reveal" style={{display: 'block', zIndex: BASE_ZINDEX + index}} 
        aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3 id="modalTitle">Pick Variations</h3>
          </div>
        </div>
        <div className="row column popup-content">
          {variant_list.length ?
            <LabelledCheckbox label="Select All" value={all_selected ? 1 : 0} onChange={handleToggleAllSelected} />
          : null}
          {variant_list.length ? variant_list.map((v, i) =>
              <LabelledCheckbox
                key={i}
                style={{ textTransform }}
                label={v[variant_name] + (v.on_product ? '' : ' *')}
                value={selected.includes(v[variant_id_name]) ? 1 : 0}
                onChange={handleToggleVariant(v[item_variant_id_name], v[variant_id_name])} />
            ) : <div>No Variation Available</div>
          }
          {addNew &&
          <div className="input-group">
            <input type="text" style={{ borderRadius: '3px' }} className="input-group-field" value={this.state.new_value} onChange={e => this.setState({ new_value: e.target.value })} onKeyUp={e => { if (13 === e.keyCode) { onAddVariant(); }} } />
            <div className="input-group-button" style={{ paddingLeft: '5px' }}>
              <button style={{ borderRadius: '3px' }} onClick={onAddVariant} className="button">Add</button>
            </div>
          </div>}
        </div>
        <button className="button alt" style={{position: 'absolute', right: '1rem', top: '0.5rem'}} aria-label="Close modal" type="button" onClick={e => {e.preventDefault(); onClosePopup();}}>Close</button>
      </div>
    );
  }
}

const getColorProps = (state, ownProps) => {
  const item = getFullOptionItem(state, { item_id: ownProps.item_id });
  const item_colors = (item.item_colors || []).map(c => c.color_id);
  const product_colors = (state.dropdowns.product_colors[item.parent_id] || []);
  const product_color_list = Array.from(new Set(item_colors.concat(product_colors).filter(c => c != 'TBD'))).map(
    c => state.entities.colors[c]
  ).sort((a, b) => a.color_name < b.color_name ? -1 : (a.color_name > b.color_name ? 1 : 0)).map(
    c => Object.assign({}, c, {
      checked: item_colors.includes(c.color_id),
      on_product: product_colors.includes(c.color_id),
      item_color_id: item_colors.includes(c.color_id) ? item.item_colors.filter(ic => ic.color_id === c.color_id)[0].item_color_id : null
    })
  );
  return {
    variant_list: product_color_list,
    selected: item_colors,
    variant_id_name: 'color_id',
    variant_name: 'color_name',
    item_variant_id_name: 'item_color_id',
    textTransform: 'capitalize',
    addNew: true
  };
};

const getSizeProps = (state, ownProps) => {
  const item = getFullOptionItem(state, { item_id: ownProps.item_id });
  const item_sizes = (item.item_sizes || []).map(c => c.size_id);
  const product_sizes = (state.dropdowns.product_sizes[item.parent_id] || []);
  const product_size_list = Array.from(new Set(item_sizes.concat(product_sizes).filter(c => c != 'TBD'))).map(
    c => state.entities.sizes[c]
  ).filter(c => c).map(
    c => Object.assign({}, c, {
      checked: item_sizes.includes(c.size_id),
      on_product: product_sizes.includes(c.size_id),
      item_size_id: item_sizes.includes(c.size_id) ? item.item_sizes.filter(ic => ic.size_id === c.size_id)[0].item_size_id : null
    })
  );
  return {
    variant_list: product_size_list,
    selected: item_sizes,
    variant_id_name: 'size_id',
    variant_name: 'size_name',
    item_variant_id_name: 'item_size_id',
    textTransform: 'uppercase',
    addNew: true
  };
};

const getSkuProps = (state, ownProps) => {
  const item = getFullOptionItem(state, { item_id: ownProps.item_id });
  const item_skus = (item.item_skus || []).map(c => c.product_sku_id);
  const product_skus = (state.dropdowns.product_skus[item.parent_id] || []);
  const product_sku_list = Array.from(new Set(item_skus.concat(product_skus).filter(c => c != 'TBD'))).map(
    c => state.entities.skus[c]
  ).filter(c => c).sort((a, b) => (a.description + a.sku_description) < (b.description + b.sku_description) ? -1 : ((a.description + a.sku_description) > (b.description + b.sku_description) ? 1 : 0)).map(
    c => Object.assign({}, c, {
      label: !!c.description ? (c.description + (!!c.sku_description ? (' (' + c.sku_description + ')') : '')) : c.sku_description,
      checked: item_skus.includes(c.product_sku_id),
      on_product: product_skus.includes(c.product_sku_id),
      item_sku_id: item_skus.includes(c.product_sku_id) ? item.item_skus.filter(ic => ic.product_sku_id === c.product_sku_id)[0].item_sku_id : null
    })
  );
  return {
    variant_list: product_sku_list,
    selected: item_skus,
    variant_id_name: 'product_sku_id',
    variant_name: 'label',
    item_variant_id_name: 'item_sku_id',
    addNew: false
  };
};

const mapStateToProps = (state, ownProps) => {
  switch (ownProps.params) {
    case 'color':
      return getColorProps(state, ownProps);
    case 'size':
      return getSizeProps(state, ownProps);
    case 'sku':
      return getSkuProps(state, ownProps);
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return Object.assign({
    onClosePopup: () => {
      dispatch(closePopup());
    }
  }, 'color' === ownProps.params ? {
    onAddItemVariant: (item_id, color_id, color_name) => dispatch(createAddItemColor(item_id, color_id, color_name)),
    onDeleteItemVariant: item_color_id => dispatch(createDeleteItemColor(item_color_id)),
    onDeleteAllItemVariants: item_id => dispatch(createDeleteAllItemColors(item_id)),
  } : ('size' === ownProps.params ? {
    onAddItemVariant: (item_id, size_id, size_name) => dispatch(createAddItemSize(item_id, size_id, size_name)),
    onDeleteItemVariant: item_size_id => dispatch(createDeleteItemSize(item_size_id)),
    onDeleteAllItemVariants: item_id => dispatch(createDeleteAllItemSizes(item_id))
  } : {
    onAddItemVariant: (item_id, sku_id) => dispatch(createAddItemSku(item_id, sku_id)),
    onDeleteItemVariant: item_sku_id => dispatch(createDeleteItemSku(item_sku_id)),
    onDeleteAllItemVariants: item_id => dispatch(createDeleteAllItemSkus(item_id))
  }));
}

const ConnectedColorSizeSubsetPopup = connect(mapStateToProps, mapDispatchToProps)(ColorSizeSubsetPopup);
export default ConnectedColorSizeSubsetPopup;

