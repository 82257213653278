import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Autosuggest from 'react-autosuggest'

import Form from './Form'

import { getSupplierAccountOptions } from '../selectors/dropdowns'
import { createAddSupplier } from '../actions/supplier'
import { closePopup } from '../actions/popup'
import { createAddTemp } from '../actions/temp'

import { BASE_ZINDEX } from '../popup-factory'

import { escapeRegexCharacters, oauth } from '../utils'

class AddSupplierPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      value: '',
      company_id: null,
      suggestions: [],
      companies: [],
      save: false
    };

    _.bindAll(this, ['onFormRef', 'onChange', 'onBlur', 'onSuggestionsFetchRequested', 'onSuggestionsClearRequested', 'getSuggestions', 'onSuggestionSelected']);
  }

  onFormRef(form) {
    this._form = form;
  }

  handleSearch = _.debounce(function (callback) {
    oauth('GET', 'company', { company_type: 'SUPPLIER', search: true, 'max-results': 10, company_name: this.state.value }).then(
      ({ json }) => this.setState({ companies: json.companies })
    ).then(callback);
  }, 500);

  onChange(e, { newValue, method }) {
    this.setState({
      value: newValue,
      save: newValue != '' ? true : false
    }, function() {
      this.onSuggestionsClearRequested()
    })
  }

  onBlur(e, { focusedSuggestion }) {
  }

  onSuggestionsFetchRequested({ value }) {
    let _this = this
    this.handleSearch(function() {
      _this.setState({
        suggestions: _this.getSuggestions(value),
        company_id: null
      })
    })
  }

  onSuggestionsClearRequested() {
    this.setState({
      suggestions: []
    });
  }

  onSuggestionSelected(e, { suggestion }) {
    this.setState({
      company_id: suggestion.company_id,
      save: suggestion.account_id ? false : true
    });
  }

  getSuggestions(value) {
    const escapedValue = escapeRegexCharacters(value.trim());
    if ('' === escapedValue) {
      return [];
    }
    const regex = new RegExp('^' + escapedValue, 'i');
    return this.state.companies.filter(c => regex.test(c.company_name)).slice(0, 10);
  }

  renderLoading() {
    return (
      <div className='row popup-content column'>
        <div className='small-12 columns'>
          <div className='small-12 text-center'><br /><img src='/images/gears.gif' /><br /></div>
        </div>
      </div>
    )
  }

  render() {
    const {
      onClosePopup,
      onAddSupplier,
      index
    } = this.props;

    const {
      value,
      suggestions,
      company_id,
      save
    } = this.state;

    const inputProps = {
      value,
      onChange: this.onChange,
      onBlur: this.onBlur
    };

    const handleCancel = e => {
      e.preventDefault();
      onClosePopup();
    };

    const handleSave = e => {
      e.preventDefault();
      if (!value) {
        return;
      }

      if (company_id) {
        onAddSupplier({ company_id });
      } else {
        onAddSupplier({ company_name: value });
      }
    };


    const getSuggestionValue = suggestion => suggestion.company_name;
    const renderSuggestion = suggestion => suggestion.company_name;
    const renderInputComponent = inputProps => (
      <input {...inputProps} />
    );

    return (
      <div className="reveal small" style={{ display: 'block', height: 'initial', overflow: 'initial', zIndex: BASE_ZINDEX + index }}>
        <div className="row">
          <div className="small-12 columns">
            <h3>Add New Supplier</h3>
          </div>
        </div>
        <div className="row">
          <div className="small-12 medium-4 columns">
            <strong>Company Name</strong>
          </div>
          <div className="small-12 medium-8 columns company-autocomplete">
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={inputProps}
              renderInputComponent={renderInputComponent}
              focusFirstSuggestion={true}
              onSuggestionSelected={this.onSuggestionSelected}
            />
          </div>
        </div>
        <a className="button alert" style={{ position: 'absolute', right: '5rem', top: '1rem' }} onClick={handleCancel}>Cancel</a>
        <a className="button" style={{ position: 'absolute', right: '1rem', top: '1rem' }} onClick={handleSave} disabled={!save}>Save</a>
      </div>
    );  
  }
}

const mapStateToProps = state => {
  return {
    suppliers: getSupplierAccountOptions(state)
  };
};

export const NEW_SUPPLIER_DIVISION_ID = 'NEW_SUPPLIER_DIVISION_ID';

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClosePopup: () => dispatch(closePopup()),
    onAddSupplier: supplier => dispatch(createAddSupplier(supplier)).then(result => {
      if(ownProps.redirect) {
        window.location.href = `/vendor.php?id=${result.payload.supplier.supplier_id}&division_id=${result.payload.supplier.division_id}`
      }else{
        dispatch(createAddTemp(NEW_SUPPLIER_DIVISION_ID, result.payload.supplier.division_id))
      }
    }).then(() =>
      dispatch(closePopup())
    )
  };
};

const ConnectedAddSupplierPopup = connect(mapStateToProps, mapDispatchToProps)(AddSupplierPopup);
export default ConnectedAddSupplierPopup;
