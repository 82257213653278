import { oauth } from '../utils'

export const CREATE_CONTACT_REQUEST = 'CREATE_CONTACT_REQUEST';
export const CREATE_CONTACT_SUCCESS = 'CREATE_CONTACT_SUCCESS';
export const CREATE_CONTACT_FAILURE = 'CREATE_CONTACT_FAILURE';
export const UPDATE_CONTACT_REQUEST = 'UPDATE_CONTACT_REQUEST';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const UPDATE_CONTACT_FAILURE = 'UPDATE_CONTACT_FAILURE';
export const DELETE_CONTACT_REQUEST = 'DELETE_CONTACT_REQUEST';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_FAILURE = 'DELETE_CONTACT_FAILURE';
export const UPDATE_CONTACT_PHONE_SUCCESS = 'UPDATE_CONTACT_PHONE_SUCCESS';
export const DELETE_CONTACT_PHONE_SUCCESS = 'DELETE_CONTACT_PHONE_SUCCESS';
export const LOAD_CONTACT_PROJECTS_SUCCESS = 'LOAD_CONTACT_PROJECTS_SUCCESS';
export const ADD_CONTACT_PHONE_SUCCESS = 'ADD_CONTACT_PHONE_SUCCESS';
export const LOAD_COMPANY_CONTACT_LIST_SUCCESS = 'LOAD_COMPANY_CONTACT_LIST_SUCCESS';

const createFinalizeLoadCompanyContactList = (company_id, contacts) => ({
  type: LOAD_COMPANY_CONTACT_LIST_SUCCESS,
  payload: {
    company_id,
    contacts
  }
});

export const createLoadCompanyContactList = (company_id, company_type) => dispatch => {
  return oauth('GET', 'contact', { parent_id: company_id, parent_type: company_type }).then(
    ({ json }) => dispatch(createFinalizeLoadCompanyContactList(company_id, json.contacts))
  );
};

const createAddContactRequest = contact => ({
  type: CREATE_CONTACT_REQUEST,
  payload: {
    contact
  }
});

const createFinalizeAddContact = contact => ({
  type: CREATE_CONTACT_SUCCESS,
  payload: {
    contact
  }
});

const createFailedAddContact = contact => ({
  type: CREATE_CONTACT_FAILURE,
  payload: {
    contact
  }
});

export const createAddContact = contact => dispatch => {
  dispatch(createAddContactRequest(contact));

  return oauth('POST', 'contact', contact).then(
    ({ json }) => dispatch(createFinalizeAddContact(json.contact)),
    ({ json }) => dispatch(createFailedAddContact(contact))
  );
};

export const createCopyContact = (contact_id, parent_id, parent_type) => dispatch => {
  dispatch(createAddContactRequest({ contact_id, parent_id, parent_type }));
  return oauth('POST', 'contact', { contact_id, parent_id, parent_type }).then(
    ({ json }) => dispatch(createFinalizeAddContact(json.contact)),
    ({ json }) => dispatch(createFailedAddContact(contact))
  );
};

const requestUpdateContact = (contact_id, field, previous_value, value) => ({
  type: UPDATE_CONTACT_REQUEST,
  payload: {
    id: contact_id,
    data: {
      [field]: value
    }
  }
})

const createDeleteContactRequest = contact => ({
  type: DELETE_CONTACT_REQUEST,
  payload: {
    contact
  }
});

const createFinalizeDeleteContact = contact => ({
  type: DELETE_CONTACT_SUCCESS,
  payload: {
    contact
  }
});

const createFailedDeleteContact = contact => ({
  type: DELETE_CONTACT_FAILURE,
  payload: {
    contact
  }
});

export const createDeleteContact = contact => dispatch => {
  dispatch(createDeleteContactRequest(contact));

  return oauth('DELETE', `contact/${contact.company_contact_id ? contact.company_contact_id : contact.contact_id}`).then(
    ({ json }) => dispatch(createFinalizeDeleteContact(contact)),
    ({ json }) => dispatch(createFailedDeleteContact(contact))
  );
};

const finalizeUpdateContact = (contact_id, field, previous_value, value) => ({
  type: UPDATE_CONTACT_SUCCESS,
  payload: {
    id: contact_id,
    data: {
      [field]: value
    }
  }
})

const failedUpdateContact = (contact_id, field, previous_value, value) => ({
  type: UPDATE_CONTACT_FAILURE,
  payload: {
    id: contact_id,
    message: 'Unable to update contact',
    data: {
      [field]: previous_value
    }
  }
})

export const createUpdateContact = (contact_id, client_id, field, previous_value, value) => (dispatch, getState) => {
  dispatch(requestUpdateContact(contact_id, field, previous_value, value));

  const data = {
    parent_id: client_id,
    parent_type: 'CLIENT',
    [field]: value
  };

  oauth('PUT', `contact/${contact_id}`, data).then(
    ({ json }) => dispatch(finalizeUpdateContact(contact_id, field, previous_value, json.contact[field])),
    ({ json }) => dispatch(failedUpdateContact(contact_id, field, previous_value, value))
  );
}

const finalizeUpdateContactPhone = (contact_id, phones) => ({
  type: UPDATE_CONTACT_PHONE_SUCCESS,
  payload: {
    id: contact_id,
    data: {
      phones
    }
  }
})

export const createUpdateContactPhone = (contact_id, client_id, phone_id, phone_type, phone_number, phone_extension) => (dispatch, getState) => {
  const data = {
    parent_id: client_id,
    parent_type: 'CLIENT',
    phone_id,
    phone_type,
    phone_number, 
    phone_extension
  };
 
  oauth('PUT', `contact/${contact_id}`, data).then(
    ({ json }) => dispatch(finalizeUpdateContactPhone(contact_id, json.contact.phones))
  );
}

const finalizeAddContactPhone = (contact_id, phone_id, phones) => ({
  type: ADD_CONTACT_PHONE_SUCCESS,
  payload: {
    id: contact_id,
    phone_id,
    data: {
      phones
    }
  }
})

export const createAddContactPhone = (contact_id, client_id, phone_id, phone_type, phone_number, phone_extension) => (dispatch, getState) => {
  const data = {
    parent_id: client_id,
    parent_type: 'CLIENT',
    phone_id,
    phone_type,
    phone_number, 
    phone_extension
  };
 
  oauth('PUT', `contact/${contact_id}`, data).then(
    ({ json }) => dispatch(finalizeAddContactPhone(contact_id, phone_id, json.contact.phones))
  );
}

const finalizeDeleteContactPhone = (contact_id, phone_id) => ({
  type: DELETE_CONTACT_PHONE_SUCCESS,
  payload: {
    id: contact_id,
    data: {
      phone_id
    }
  }
})

export const createDeleteContactPhone = (contact_id, phone_id) => (dispatch, getState) => {
  oauth('DELETE', `phone/${phone_id}`, {}).then(
    ({ json }) => dispatch(finalizeDeleteContactPhone(contact_id, phone_id))
  );
}

const finalizeUpdateContactSocial = (contact_id, contact_twitter, contact_facebook, contact_linkedin) => ({
  type: UPDATE_CONTACT_SUCCESS,
  payload: {
    id: contact_id,
    data: {
      contact_twitter,
      contact_facebook,
      contact_linkedin
    }
  }
})

export const createUpdateContactSocial = (contact_id, client_id, contact_twitter, contact_facebook, contact_linkedin) => (dispatch, getState) => {
  const data = {
    parent_id: client_id,
    parent_type: 'CLIENT',
    contact_twitter,
    contact_facebook,
    contact_linkedin
  };

  oauth('PUT', `contact/${contact_id}`, data).then(
    ({ json }) => dispatch(finalizeUpdateContactSocial(contact_id, json.contact.contact_twitter, json.contact.contact_facebook, json.contact.contact_linkedin))
  );
}

const finalizeUpdateContactTags = (contact_id, tags) => ({
  type: UPDATE_CONTACT_SUCCESS,
  payload: {
    id: contact_id,
    data: {
      tags
    }
  }
})

export const updateContactTags = (contact_id, client_id, contact_tags) => (dispatch, getState) => {
  const data = {
    parent_id: client_id,
    parent_type: 'CLIENT',
    contact_tags
  };

  oauth('PUT', `contact/${contact_id}`, data).then(
    ({ json }) => dispatch(finalizeUpdateContactTags(contact_id, json.contact.tags))
  );
}

const finalizeUpdateContactTitle = (contact_id, contact_first_name, contact_last_name, contact_position, company_name, contact_twitter, contact_facebook, contact_linkedin) => ({
  type: UPDATE_CONTACT_SUCCESS,
  payload: {
    id: contact_id,
    data: {
      contact_first_name,
      contact_last_name,
      contact_position,
      company_name,
      contact_twitter,
      contact_facebook,
      contact_linkedin
    }
  }
})

export const createUpdateContactTitle = (contact_id, client_id, contact_first_name, contact_last_name, contact_position, company_name, contact_twitter, contact_facebook, contact_linkedin) => (dispatch) => {
  const data = {
    parent_id: client_id,
    parent_type: 'CLIENT',
    contact_first_name,
    contact_last_name,
    contact_position,
    company_name,
    contact_twitter,
    contact_facebook,
    contact_linkedin
  };

  oauth('PUT', `contact/${contact_id}`, data).then(
    ({ json }) => dispatch(finalizeUpdateContactTitle(contact_id, json.contact.contact_first_name, json.contact.contact_last_name, json.contact.contact_position, json.contact.company_name, json.contact.contact_twitter, json.contact.contact_facebook, json.contact.contact_linkedin))
  );
}

export const loadContactProjects = (projects) => ({
  type: LOAD_CONTACT_PROJECTS_SUCCESS,
  payload: {
    projects
  }
})

