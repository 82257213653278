import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import LabelledSelect from './LabelledSelect'
import LabelledInput from './LabelledInput'

import { toTitleCase } from '../utils'

class ColorBreakdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      color_breakdowns: props.color_breakdowns,
      item_size_dropdown: props.item_size_dropdown
    };
  }

  componentDidMount() {
    if(this.props.item_color_dropdown.length == 1) {
      this.props.handleChangeValue(this.props.item_color_dropdown[0].key, 'color', null, null, this.props.idx);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    _.forEach(nextProps.item_size_dropdown, function(value, idx) {
      if(nextProps.color_breakdowns[nextProps.idx].sizes[value.key]) {
        if(nextProps.color_breakdowns[nextProps.idx + 1] && !nextProps.color_breakdowns[nextProps.idx].new) {
          value.quantity = 0;
        }else{
          value.quantity = nextProps.color_breakdowns[nextProps.idx].sizes[value.key]['quantity'];
        }
      }else{
        value.quantity = 0;
      }
    });

    this.setState({
      color_breakdowns: nextProps.color_breakdowns,
      item_size_dropdown: nextProps.item_size_dropdown
    });
  }

  render() {
    const {idx, color_breakdowns, currentItemId, item_color_dropdown, item_size_dropdown, has_breakdown, handleChangeValue, handleRemove} = this.props;

    return (
      <div style={{backgroundColor: '#f2f2f2'}}>
        <div style={{clear: 'both'}}></div>
        {item_color_dropdown.length > 0 ? 
          <div className="row">
            <div className="small-12 columns">
              {Object.values(this.state.color_breakdowns).length > 1 ? <a className="color-breakdown-delete" onClick={e => { e.preventDefault(); handleRemove(idx)}}>Remove</a> : null}
              <LabelledSelect label="Color" options={item_color_dropdown} value={this.state.color_breakdowns[idx].color.color_id} onChange={e => handleChangeValue(e, 'color', null, null, idx)} />
            </div>
          </div>
        : null}
        {item_color_dropdown.length > 0 && item_size_dropdown == 0 ? 
          <div className="row">
            <div className="small-12 columns">
              <LabelledInput labelOrientation="side" label="Quantity" type="number" value={this.state.color_breakdowns[idx].quantity_color} onChange={e => handleChangeValue(e, 'quantity_color', null, null, idx)} min="0"/>
            </div>
          </div>
        : null} 
        {item_size_dropdown.length > 0 ? 
          <div className="columns" style={{backgroundColor: '#f2f2f2'}}>
            {!this.state.color_breakdowns[idx].new ? 
              Object.values(this.state.color_breakdowns[idx].sizes).map(s =>
                <div key={s.size_id} style={{marginRight: '8px'}}>
                  <LabelledInput labelOrientation="side" label={s.size_name} type="number" value={s.quantity} onChange={e => handleChangeValue(e, 'size', s.size_id, s.size_name, idx)} min="0" />
                </div>
              ) 
            :
              this.state.item_size_dropdown.map(s => 
                <div key={s.key} style={{marginRight: '8px'}}>
                  <LabelledInput labelOrientation="side" label={s.value} type="number" value={s.quantity} onChange={e => handleChangeValue(e, 'size', s.key, s.value, idx)} min="0" />
                </div>
              )
            }
          </div>
        : null}
      </div>
    );
  }
}

export default ColorBreakdown;
