const queue = [];

const ASI_URL = 'https://espweb.asicentral.com/common/statshandler.ashx';
const TIMEOUT_DELAY = 8000;

const send = () => {
  const params = new URLSearchParams();
  let value;
  while (queue.length > 0) {
    value = queue.pop();
    params.append('log[]', JSON.stringify({ record: value }));
  }
  const request = new XMLHttpRequest();
  request.open('POST', ASI_URL);
  request.send(params);
}

const sendCallback = () => {
  if (queue.length) {
    send();
  }
  setTimeout(sendCallback, TIMEOUT_DELAY);
};

setTimeout(sendCallback, TIMEOUT_DELAY);

export const registerESPDisplayImpression = (esp_product_id, esp_ad_id, esp_ad_index, esp_account_id) => {
  if (!esp_product_id || !esp_ad_id || !esp_ad_index) {
    return;
  }
  const record = {
    immediate: true,
    product_id: esp_product_id,
    ad_id: esp_ad_id,
    adindex: esp_ad_index,
    adaction_cd: 'PRSI',
    hits_cnt: 1,
    moduleCode: 'PRDRES',
    moduleVersion: '-1',
    appCode: '$OMQ',
    appVersion: '1.0.0',
    schema: 'MONY01'
  };
  if (!!esp_account_id) {
    record.signon_id = esp_account_id;
  }
  queue.push(record);
};

export const registerESPClickImpression = (esp_product_id, esp_ad_id, esp_ad_index, esp_account_id) => {
  if (!esp_product_id || !esp_ad_id || !esp_ad_index) {
    return;
  }
  const record = {
    schemaCode: 'ACAD11',
    product_id: esp_product_id,
    ad_id: esp_ad_id,
    adindex: esp_ad_index,
    adaction_cd: 'WACS',
    hits_cnt: 1,
    moduleCode: 'PRDDET',
    moduleVersion: '-1',
    appCode: '$OMQ',
    appVersion: '1.0.0',
    schema: 'ACAD11',
    immediate: true,
    moduleVersionId: '-1'
  };
  if (!!esp_account_id) {
    record.signon_id = esp_account_id;
  }
  queue.push(record);
};
