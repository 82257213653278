import React, { Component } from 'react'
import { connect } from 'react-redux'

import Form from './Form'

import * as actions from '../actions/item'
import { createUpdate, createAddItemTaxAmount, createUpdateItemTaxAmount, createDeleteItemTaxAmount } from '../actions'
import { closePopup } from '../actions/popup'

import { getTaxOptions } from '../selectors/dropdowns'
import { getFullItem } from '../selectors'

import { BASE_ZINDEX } from '../popup-factory'
import { createFormSelect } from './Form';
import TaxSelect from './TaxSelect';

const FormTaxSelect = createFormSelect(TaxSelect);

class EditServicePopup extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {
      item,
      default_tax_id,
      taxes,
      onUpdateItem,
      onAddTaxAmount,
      onUpdateTaxAmount,
      onDeleteTaxAmount,
      onClosePopup,
      index,
      zip2tax = false
    } = this.props;

    const handleDeleteTaxAmount = tax_amount_id => e => {
      e.preventDefault();
      onDeleteTaxAmount(tax_amount_id);
    };

    const onChangeTax = tax_amount_id => tax_id => {
      onUpdateTaxAmount(tax_amount_id, tax_id);
    };

    const item_name = item.item_name !== '' ? item.item_name : 'Other Service';
    const disabled_title = ['Shipping', 'Freight', 'Fulfillment'].includes(item_name);

    return (
      <div id="service-modal" className ="reveal large" style={{display: 'block', zIndex: BASE_ZINDEX + index, overflow: 'visible' }} data-reveal aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className ="row">
          <div className ="small-12 columns">
            <h3 id="modalTitle">Service: {item_name}</h3>
          </div>
        </div>
        <div className ="row popup-content">
          <Form className ="small-12 columns popup-size_limit">
            <Form.TextInput label="Title" field="item_nam" value={item_name} disabled={disabled_title} onBlur={onUpdateItem(item.item_id, 'item_name', item.item_name)}/>
            <Form.Textarea label="Description" field="item_description" value={item.item_description} onBlur={onUpdateItem(item.item_id, 'item_description', item.item_description)}/>
            <Form.TextInput label="Quantity" field="total_units" value={item.total_units} onBlur={onUpdateItem(item.item_id, 'total_units', item.total_units)}/>
            <Form.TextInput label="Unit Cost" field="unit_cost" value={item.unit_cost} onBlur={onUpdateItem(item.item_id, 'unit_cost', item.unit_cost)}/>
            <Form.TextInput label="Margin" field="total_margin" value={item.total_margin} onBlur={onUpdateItem(item.item_id, 'total_margin', item.total_margin)}/>
            <Form.TextInput label="Retail Price" field="unit_price" value={item.unit_price} onBlur={onUpdateItem(item.item_id, 'unit_price', item.unit_price)}/>
            <Form.TextInput label="Total" field="total_total" value={item.total_total} disabled="true" />
            <div className="row field">
              <div className="small-12 medium-4 columns">
                <strong>Taxes</strong>
              </div>
              {item.tax_amounts.map((t, idx) => 
                [<FormTaxSelect key={`taxes.${idx}.tax_id`} zip2tax={zip2tax} value={t.tax_id} displayLabel={false} containerClassName={'small-12 medium-4 columns' + (idx !== 0 ? ' medium-offset-4' : '')} field={`taxes.${idx}.tax_id`} required={true} onChange={onChangeTax(t.tax_amount_id)} />,
                 <Form.TextInput key={`taxes.${idx}.amount`} value={t.amount} displayLabel={false} containerClassName="small-12 medium-3 columns" field={`taxes.${idx}.amount`} disabled={true} />,
                 !zip2tax && <div key={`taxes.${idx}.delete`} className="small-12 medium-1 columns" onClick={handleDeleteTaxAmount(t.tax_amount_id)}><a style={{fontSize: '1.5em'}}>&times;</a></div>]
              )}
            </div>
            {!zip2tax && <div className="small-12 medium-offset-4 medium-8 columns" style={{marginBottom: '30px'}}>
              <a onClick={e => { e.preventDefault(); onAddTaxAmount(item.item_id, default_tax_id)}}>+ add another tax</a>
            </div>}
          </Form>
        </div>
        <a className ="button" style={{position: 'absolute', right: '1rem', top: '1rem'}}  onClick={e => {e.preventDefault(); onClosePopup();}}>
          Done
        </a>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const item = getFullItem(state, ownProps);
  const order = state.entities.orders[item.order_id];
  const job = state.entities.projects[order.job_id];
  return {
    item,
    default_tax_id: order.tax_id,
    zip2tax: 1 == job.zip2tax,
    taxes: getTaxOptions(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const updateItem = createUpdate('item', {
    request: actions.UPDATE_ITEM_REQUEST,
    success: actions.UPDATE_ITEM_SUCCESS,
    failure: actions.UPDATE_ITEM_FAILURE,
    failure_message: 'Unable to update item'
  });
  return {
    onUpdateItem: (item_id, field, previous_value) => value => {
      dispatch(updateItem(item_id, field, previous_value, value));
    },
    onAddTaxAmount: (parent_id, tax_id) => {
      dispatch(createAddItemTaxAmount(parent_id, tax_id));
    },
    onUpdateTaxAmount: (tax_amount_id, tax_id) => {
      dispatch(createUpdateItemTaxAmount(tax_amount_id, tax_id));
    },
    onDeleteTaxAmount: (tax_amount_id) => {
      dispatch(createDeleteItemTaxAmount(tax_amount_id));
    },
    onClosePopup: () => {
      dispatch(closePopup());
    }
  }
}

const ConnectedEditServicePopup = connect(mapStateToProps, mapDispatchToProps)(EditServicePopup);
export default ConnectedEditServicePopup;
