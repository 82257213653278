import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getAbsoluteUrl } from '../utils'

class Iframe extends Component {
    constructor(props) {
        super(props);
        this.state = { height: 0 };
    }

    componentDidMount() {
        if(this.props.srcdoc) {
            const doc = this._iframe.contentWindow.document;
            doc.open();
            doc.write(this.props.srcdoc);
            doc.close();
            this._onresize = setTimeout(() => {
                this.onResize();
            }, 500);
        }
    }

    componentWillUnmount() {
        clearTimeout(this._onresize);
    }

    onResize() {
        const scrollHeight = this._iframe.contentWindow.document.body.scrollHeight;
        if (this.state.height < scrollHeight) {
            this.setState({ height: scrollHeight + 2 });
        }
    }

    render() {
        const white_background = {backgroundColor: 'white', height: '100vh'};

        return <iframe style={this.props.src && this.props.white_background ? white_background : this.props.src ? {height: '100vh'} : null} 
            src={this.props.src ? this.props.src : null} ref={(iframe) => { this._iframe = iframe }}
            frameBorder="0" width="100%" scrolling="auto"
            height={this.props.src ? null : this.state.height}
        />
    }
}

export default Iframe;