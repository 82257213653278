import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Row, Col, Artwork, Theme } from '@commonsku/styles'

import { createDisplayArtworkPopup, createValidateConfirmationPopup } from '../actions/popup'
import { createDeleteFile, createUpdateFile } from '../actions/file'

import { getImageSrc, formatDate, isImageByFileExt } from '../utils'

const TBD_FILE_ID = '00000000-0000-0000-0000-000000000000'

class Files extends Component {
  constructor(props) {
    super(props)

    this.state = {}

    _.bindAll(this, ['handleClickDownload', 'handleEditFile', 'handleSaveFile'])
  }

  handleClickDownload(url, file_name) {
    event.preventDefault()
    event.stopImmediatePropagation()

    //force file download
    fetch(url, { mode: 'cors' }).then(function(response) {
      return response.blob()
    }).then(function (blob) {
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = file_name
      link.click()
    })
  }

  handleEditFile(file) {
    this.setState({
      [file.file_id]: file.file_display_name 
    })
  }

  handleSaveFile(file) {
    const { onCreateUpdateFile } = this.props

    const updated_file = Object.assign({}, file, { file_display_name: this.state[file.file_id] })
    onCreateUpdateFile(updated_file)
    this.setState({ [file.file_id]: false })
  }

  renderImageFile(file, index) {
    const { onCreateDisplayArtworkPopup, onSelectFile, onCreateDeleteFile } = this.props

    if(onSelectFile) {
      return(
        <Col key={file.file_id} padded xs={6} sm={4} style={{ padding: '0.5rem' }}>
          <Artwork 
            date={formatDate(file.date_created)} 
            name={file.file_display_name} 
            picture={getImageSrc(file, 'medium')} 
            onDownload={e => this.handleClickDownload(getImageSrc(file, 'original'), file.file_display_name)}
            onClick={e => {event.preventDefault(); onSelectFile(file)}}
          />
          <br />
        </Col>
      )
    }

    return(
      <Col key={file.file_id} padded xs={6} sm={4} style={{ padding: '0.5rem' }}>
        <Artwork 
          date={formatDate(file.date_created)} 
          name={this.state[file.file_id] ? this.state[file.file_id] : file.file_display_name} 
          picture={getImageSrc(file, 'medium')} 
          onDownload={e => this.handleClickDownload(getImageSrc(file, 'original'), file.file_display_name)}
          onClick={e => onCreateDisplayArtworkPopup(file)}
          onDelete={e => onCreateDeleteFile(file.file_id)}
          onEdit={e => this.handleEditFile(file)}
          edit={this.state[file.file_id] ? true : false}
          onSave={e => this.handleSaveFile(file)}
          inputProps={{
            onChange: (e) => { this.setState({ [file.file_id]: e.target.value }) }
          }}
        />
        <br />
      </Col>
    )
  }

  renderOtherFile(file, index) {
    const { onSelectFile, onCreateDeleteFile } = this.props

    if(onSelectFile) {
      return (
        <Col padded key={file.file_id} xs={6} sm={4} style={{ padding: '0.5rem' }}>
          <Artwork 
            date={formatDate(file.date_created)} 
            name={file.file_display_name} 
            onDownload={e => this.handleClickDownload(getImageSrc(file, 'original'), file.file_display_name)} 
            onClick={e => {event.preventDefault(); onSelectFile(file)}}
          />
          <br />
        </Col>
      )
    }

    return (
      <Col padded key={file.file_id} xs={6} sm={4} style={{ padding: '0.5rem' }}>
        <Artwork 
          date={formatDate(file.date_created)} 
          name={this.state[file.file_id] ? this.state[file.file_id] : file.file_display_name} 
          onDownload={e => this.handleClickDownload(getImageSrc(file, 'original'), file.file_display_name)}
          onDelete={e => onCreateDeleteFile(file.file_id)}
          onEdit={e => this.handleEditFile(file)}
          edit={this.state[file.file_id] ? true : false}
          onSave={e => this.handleSaveFile(file)}
          inputProps={{
            onChange: (e) => { this.setState({ [file.file_id]: e.target.value }) }
          }}
        />
        <br />
      </Col>
    )
  }

  render() {
    const { files } = this.props

    return (
      <Theme>
        <Row>
          {files.filter(f => isImageByFileExt(f.file_display_name) || f.is_image).map((f, i) => 
            this.renderImageFile(f, i)
          )}
        </Row>
        <br />
        <Row>
          {files.filter(f => !isImageByFileExt(f.file_display_name) && !f.is_image).map((f, i) => 
            this.renderOtherFile(f, i)
          )}
        </Row>
      </Theme>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let files = []
  if(!ownProps.files) {
    switch(ownProps.type) {
      case 'project':
        files = files.concat(Object.values(state.entities.files).filter(f => f.parent_id === ownProps.job_id))
      break
      case 'client':
        files = Object.values(state.entities.files).filter(f => f.parent_id === ownProps.client_id)
        if(ownProps.folder_id) {
          files = files.filter(f => f.folder_id === ownProps.folder_id)
        }
      break
    }
  }else{
    files = ownProps.files
  }
  if(ownProps.exclude_file_ids) {
    files = files.filter(f => !ownProps.exclude_file_ids.includes(f.file_id))
  }
  files.sort((a, b) => (b.date_created > a.date_created) ? 1 : -1)

  let TBD = []
  if(ownProps.tbd) {
    TBD = [{ 
      file_id: TBD_FILE_ID,
      file_display_name: 'TBD.png', 
      date_created: '2005-01-01 00:00:00', 
      original_url: '/images/404.png',
      is_image: true,
      file_type: "image/png"
    }]
  }

  return {
    files: TBD.concat(files)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onCreateDisplayArtworkPopup: (file) => {
      dispatch(createDisplayArtworkPopup(file))
    },
    onCreateDeleteFile: (file_id) => dispatch(createValidateConfirmationPopup(() => dispatch(createDeleteFile(file_id)), [])),
    onCreateUpdateFile: file => dispatch(createUpdateFile(file))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Files)