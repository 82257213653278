import { bindAll, get, throttle, find } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import Autosuggest from 'react-autosuggest';

import { oauth } from '../utils';
import { getIdentityUtils } from '../utils'
import { assumeLoginName } from '../actions/header'
import { onClosePopup, closePopup } from '../actions/popup'
import { getOrderByNumber } from '../selectors'
import { BASE_ZINDEX } from '../popup-factory'
import config from '../config'

import CompanyUserSuggest from './CompanyUserSuggest'


class AssumeIdentityPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      value: '',
      suggestions: [],
    };

    bindAll(this, ['onSuggestionsClearRequested', '_onSuggestionsFetchRequested'])
    this.onSuggestionsFetchRequested = _.throttle(this._onSuggestionsFetchRequested, 500);
  }

  componentDidMount() {
    oauth('GET', 'company/company-commonsku-user-toc', {}).then((response) => {
      this.companies = get(response, 'json.companies', []);
    });
  }

  _onSuggestionsFetchRequested({ value }) {
    const { identity } = this.props;

    const target = (value || '').trim().toLowerCase();
    if (target.length < 2) {
      return;
    }
    const companies = this.companies || [];
    this.setState({
      suggestions: companies.filter((company) => {
        return (company.company_name || '').toLowerCase().indexOf(target) > -1;
      }),
    });
  }  

  onSuggestionsClearRequested() {
    this.setState({ suggestions: [] });
  }

  renderCompagePageLink(company) {
    const href = (company.company_type === 'TENANT' ? '/distributor_information.php?id=' : '/vendor.php?id=') + company.company_id;
    return <a className="button" style={{display: 'inline-block', margin: 5}} 
      target="_blank" href={href}
    >Go to Company Page</a>
  }

  renderConnectButton() {
    const { identity } = this.props;
    const { selectedCompany, selectedUser } = this.state;
    const { hasCapabilities } = getIdentityUtils(identity);
    const { company_type } = selectedCompany;
    if (
      (company_type === 'TENANT' && hasCapabilities(['ASSUME-DISTRIBUTOR'])) ||
      (company_type === 'SUPPLIER' && hasCapabilities(['ASSUME-SUPPLIER']))
    ) {
      return <button className="button" style={{display: 'inline-block', margin: 5}} 
        onClick={() => {
          assumeLoginName(selectedUser ? selectedUser.login_name : selectedCompany.csku_login_name);
        }}
      >Connect</button>
    }
  }

  renderButtons() {
    const { selectedCompany } = this.state;

    if (!selectedCompany) {
      return null;
    }
    return <div>
      { selectedCompany.csku_login_name ? this.renderConnectButton() : null }
      { this.renderCompagePageLink(selectedCompany) }
    </div>
  }

  renderUserSuggestions() {
    const { selectedCompany } = this.state;

    if (!selectedCompany) {
      return null;
    }

    return <CompanyUserSuggest company={selectedCompany} onSelected={(selectedUser) => {
      this.setState({ selectedUser });
    }}/>
  }

  render() {
    const { identity, onClosePopup } = this.props;
    const { isAdmin } = getIdentityUtils(identity);
    const { value, suggestions, selectedCompany } = this.state;

    return <div className="reveal tiny" 
      style={{ display: 'block', zIndex: BASE_ZINDEX, maxHeight: '95%', height: 'auto', overflow: 'visible' }}
    >
      <h3 className="small-12 columns">Switch Identity</h3>
      <div className="assume-identity small-12 columns" style={{ overflow: 'visible', minHeight: 130 }}>
        <Autosuggest
          style={{
            display: 'inline-block'
          }}
          suggestions={suggestions} 
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={suggestion => suggestion.company_name}
          onSuggestionSelected={(event, { suggestion }) => {
            this.setState({ selectedCompany: suggestion })
          }}
          renderSuggestion={(suggestion)=> {
            return <div className="suggestion-item">{suggestion.company_name}</div>
          }}
          inputProps={{
            value,
            style: {width: '100%'},
            placeholder: 'Company Name',
            onChange: (event, {newValue}) => {
              this.setState({
                value: newValue,
                selectedCompany: null,
                selectedUser: null,
              });
            },
          }}
        />
        { config.environment !== 'production' ? this.renderUserSuggestions() : null}
        { this.renderButtons() }
      </div>
      <a aria-label="Close" 
        style={{ 
          color: '#AAA', cursor: 'pointer', fontSize: '2.2rem', fontWeight: 'bold', position: 'absolute',
          top: '0.5rem', right: '1.2rem', textDecoration: 'none'
        }} 
        onClick={onClosePopup}>&#215;</a>
    </div>
  }
}

const mapStateToProps = (state) => {
  return {
    identity: state.identity,
  } 
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClosePopup: () => {
      dispatch(closePopup());
    },
  }
}

const ConnectedAssumeIdentityPopup = connect(mapStateToProps, mapDispatchToProps)(AssumeIdentityPopup);
export default ConnectedAssumeIdentityPopup;
