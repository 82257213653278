const config = {
  promostandards_api_url: 'https://promo.commonsku.com/api',
  integrationDomain: 'commonsku.com',
  inboundEmail: {
    integrationDomain: 'commonskumail.com',
    whitelist: [
      'vandelay', 'rightsleeve', 'metronbranding',
    ],
  },
  environment: 'production',
/*
  proof_whitelist: [
    '60d613ea-c3db-4265-9a46-442553c8619a', 
    '8cf06949-cc2e-4d48-a9e6-82c301faacd9',
    'bf680879-d897-4e67-a7fc-e1293ba112cb',
    '688661eb-8de8-4379-9e16-fa493940ac6e',
    'e34cf40c-0687-4ad8-8b8e-ea42a4269ced',
    '1c11d7d9-c039-4ab2-9dcc-b6acbc64d006',
    'bd801039-36c9-4fa6-8a74-03808facf843',
    'e518263b-33d0-4135-962b-33631bb1fe01',
    '44d1d43e-8882-43c8-9ffe-490f70b6cfbd',
    'cf26d59c-0e9a-4037-b0ce-f4692ae9bf1d',
    '67610719-d17b-4872-9d4e-92d1bf7cfa94',
    'bb6a41e8-0328-45e4-a01c-70148d2cca21',
    'fe5b761a-5f48-41a9-92f4-a4c49ee6f721',
    '1bde0aca-b3b2-4e7d-abea-41617d6be292',
    '3631271d-24ff-4bb8-a459-3e2160ed1cd9',
    'f5915bda-b845-4981-a94a-bc62932728c5',
    'd825cb61-6b20-4f40-8923-fb65d61a90c5',
    '14faa880-3f25-4a4e-a35e-bd17f82046cd',
    'dea2213f-3e2f-4698-8906-198f3aec9b37',
    '70f6f51e-626b-4ca1-832c-9afb44992775',
    '3fbb9b10-8eca-4f38-bccc-fd412e63e5a0',
    'b2bea1fc-bbb5-11e1-a791-1231392238ea',
    '571af73a-ad04-4ba7-b8bd-0c63cca6b5c2',
    '1fe8efba-0c99-44e6-a4ca-ab91089e3118',
    '923bdbb9-d5c6-46bb-a621-5b401138b3c8',
    '2a4801b3-7b8c-4e40-8368-469e01e807f0',
    '60ad21a2-f7f9-4892-aa4a-5bfa0b6e0f8a',
    '747151f2-dc71-4a53-baca-c418575525a5',
    '533c1521-b574-4492-b9ef-626f85509810',
    '7b2787a8-fd3e-4e77-bd83-04006ec0857a',
    '9ce9b6a5-6938-4187-9d06-6028cfa8799a',
    '6b25355c-2b49-4cab-bf7c-28b10b0fd270',
    '13038904-2b45-4e1e-ae2b-cab8d14b0434',
    '01f8623e-9ce0-405c-8939-c5037d593070'
  ],
*/
};

export default config;
