import React, { Component } from 'react';

import LabelledSelect from './LabelledSelect';
import LabelledInput from './LabelledInput';

class CartSkuSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: {},
      quantities: props.skus.reduce((o, s) => ({ ...o, [s.product_sku_id]: (props.quantities || {})[s.product_sku_id] || 0 }), {})
    };
  }

  getOptionsFromSkus(skus) {
    const options = skus.reduce(
      (o, sku) => o.concat(sku.options),
      []
    ).reduce(
      (o, option) => ({
        ...o,
        [option.option_axis]: Array.from(new Set((o[option.option_axis] || []).concat(option.option_name)))
      }),
      {}
    );
    return options;
  }

  getSkuFromOptions(skus, value) {
    const options = this.getOptionsFromSkus(skus || []);
    const isSet = (value, option) => {
      return value[option.option_axis] === option.option_name;
    };
    const sku = skus.filter(sku => sku.options.every(o => isSet(value, o))).map(sku => sku.product_sku_id);
    return sku;
  }

  onSetQuantity(axis, option, quantity) {
    const { skus } = this.props;
    const { value } = this.state;

    const sku = this.getSkuFromOptions(skus, { ...value, [axis]: option });
    if (1 == sku.length) {
      this.setState(state => ({
        quantities: {
          ...state.quantities,
          [sku[0]]: parseInt(quantity, 10)
        },
        allow_save: Object.values(state.quantities).reduce((t, q) => t + q, 0) + quantity > 0
      }), () => this.props.onChange(this.state.quantities)); 
    }
  }

  onSelectOption(axis, option) {
    const { skus } = this.props;
    const options = this.getOptionsFromSkus(skus);
    this.setState(state => ({
      value: {
        ...state.value,
        [axis]: option
      }
    }));
  }

  render() {
    const { skus } = this.props;
    const { value, quantities } = this.state;
    const options = this.getOptionsFromSkus(skus);
    const axes = Object.keys(options).sort();
    const firstAxis = axes.shift();
    const firstAxisOptions = options[firstAxis].map(o => ({ key: o, value: o }));
    const filteredOptions = value[firstAxis] ?
      this.getOptionsFromSkus(
        skus.filter(
          sku => !!sku.options.filter(o => o.option_axis === firstAxis && o.option_name === value[firstAxis]).length
        )
      ) : 
      []; 
    return (
      <div className="row popup-content column" style={{paddingBottom: '100px'}}>
        <div style={{ backgroundColor: '#f2f2f2' }}>
          <div style={{ clear: 'both' }}></div>
          {axes.length > 0 &&
            <div className="row">
              <div className="small-12 columns">
                <LabelledSelect
                  label={firstAxis}
                  labelStyle={{ textTransform: 'capitalize' }}
                  options={firstAxisOptions}
                  value={value[firstAxis]}
                  onChange={this.onSelectOption.bind(this, firstAxis)}
                />
              </div>
            </div>}
          {axes.length > 0 && value[firstAxis] ?
            <div className="row column">
              {axes.map(axis =>
                <div key={`axis-${axis}`} className="row">
                  {filteredOptions[axis].map(option =>
                    <div key={`axis-${axis}-option-${option}`} style={{ marginRight: '8px' }}>
                      <LabelledInput
                        labelOrientation="side"
                        label={option}
                        type="number"
                        value={quantities[this.getSkuFromOptions(skus, { ...value, [axis]: option })[0]]}
                        min={0}
                        onChange={this.onSetQuantity.bind(this, axis, option)}
                      />
                    </div>
                  )}
                </div>
              )}
            </div> : (0 === axes.length && 
            <div className="row column">
              <div className="row">
                {options[firstAxis].map(option =>
                  <div key={`option-${option}`} style={{ marginRight: '8px' }}>
                    <LabelledInput
                      labelOrientation="side"
                      label={option}
                      type="number"
                      value={quantities[this.getSkuFromOptions(skus, { ...value, [firstAxis]: option })[0]]}
                      min={0}
                      onChange={this.onSetQuantity.bind(this, firstAxis, option)}
                    />
                  </div>
                )}
              </div>
            </div>)}
        </div>
      </div>
    );
  }
}

export default CartSkuSelect;
