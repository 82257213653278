import React from 'react';

const psStyle = {
  marginRight: '0.5rem'
};

const PromostandardsLabel = ({ style }) => (
  <span style={{ ...psStyle, ...style }}><img src="/images/promostandard-icon.png" /></span>
);

export default PromostandardsLabel;
