import React from 'react';
import { connect } from 'react-redux';

import { BASE_ZINDEX } from '../popup-factory';
import { closePopup } from '../actions/popup';

import { getProduct } from '../selectors';
import { createUpdate } from '../actions';
import * as actions from '../actions/item';

import { toTitleCase } from '../utils';

const updateItem = createUpdate('item', {
  request: actions.UPDATE_ITEM_REQUEST,
  success: actions.UPDATE_ITEM_SUCCESS,
  failure: actions.UPDATE_ITEM_FAILURE,
  failure_message: 'Unable to update item'
});

const COLUMN_CLASSES = {
  color: 0,
  size: 1,
  dimension: 2
};
const columnSort = (a, b) => (COLUMN_CLASSES[b] || Object.keys(COLUMN_CLASSES).length) - (COLUMN_CLASSES[a] || Object.keys(COLUMN_CLASSES).length);

class ClientDisplayOptionsPopup extends React.Component {

  constructor(props) {
    super(props);

    ['handleClosePopup', 'handleToggleOptionOverride'].forEach(
      method => this[method] = this[method].bind(this)
    );
  }

  handleClosePopup(e) {
    e.preventDefault();
    this.props.dispatch(closePopup());
  }

  handleToggleOptionOverride(axis) {
    return e => {
      e.preventDefault();
      const { item } = this.props;
      const client_display = item.client_display || {};
      const option_overrides = client_display.option_overrides || {};
      this.props.dispatch(updateItem(item.item_id, 'client_display', item.client_display, { ...client_display, option_overrides: { ...option_overrides, [axis]: !option_overrides[axis] }}));
    };
  }

  render() {
    const { item, product, index } = this.props;
    const axes = Array.from(new Set(product.skus.reduce(
      (o, part) => o.concat(part.options.map(option => option.option_axis)),
      []
    ))).sort(columnSort);

    const option_overrides = (item.client_display || {}).option_overrides || {};

    return (
      <div
        className="reveal small"
        style={{ overflowY: 'visible', display: 'block', zIndex: BASE_ZINDEX + index, height: 'initial' }}
        role="dialog"
      >
        <div className="row">
          <h3>Display To Client</h3>
           <a className="button" style={{ position: 'fixed', top: '1rem', right: '1rem' }} onClick={this.handleClosePopup}>Close</a>
        </div>
        <div className="row small-12 columns">
          {axes.map(
            a => <p key={a}>
              <input style={{ marginRight: '0.5rem' }} type="checkbox" value={1} onChange={this.handleToggleOptionOverride(a)} checked={option_overrides[a]} />
              Show TBD instead of {toTitleCase(a)} 
            </p>
          )} 
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const item = state.entities.items[ownProps.item_id];
  const product = getProduct(state, { product_id: item.parent_id });
  return {
    item,
    product
  };
};

export default connect(mapStateToProps)(ClientDisplayOptionsPopup);
