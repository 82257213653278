import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getOrderByNumber, getCollectionImages } from '../selectors';

import { createSpinnerPopup, closePopup } from '../actions/popup';
import { createSelectImagePopup } from '../actions/popup';
import { updateOrderStatus } from '../actions/order';
import { createAddCollectionImage } from '../actions/collection';

import { BASE_ZINDEX } from '../popup-factory';
import { shuffle } from '../utils';

import CollectionBadge from './CollectionBadge';

class SelectCollectionImagesReminderPopup extends Component {

  constructor(props) {
    super(props);

    this.handleCommonskuChoice = this.handleCommonskuChoice.bind(this);
  }

  handleCommonskuChoice(e) {
    e.preventDefault();
    const { images, collection: { collection_images } } = this.props;
    const used_file_ids = collection_images.filter(i => i).map(i => i.file_id);
    const available_images = shuffle(images.filter(i => !used_file_ids.includes(i.file_id)));
    const choices = collection_images.map((i, index) => i ? null : { display_order: index}).filter(i => i).map((choice, index) => ({
      ...choice,
      file_id: available_images[index].file_id
    }));
    this.props.onCommonskuChoice(choices);
  }

  render() {
    const { collection, images, onCommonskuChoice, onPublish, onChangeImage, onClosePopup, index } = this.props;

    const canCommonskuChoose = images.length >= collection.collection_images.length;
    const imageCount = collection.collection_images.filter(i => !!i).length;
    const hasAllImages = collection.collection_images.reduce((a, i) => a && !!i, true);
    return (
      <div className="reveal large" style={{display: 'block', height: 'auto', zIndex: BASE_ZINDEX + index}} role="dialog">
        <div className="row">
          <div className="small-12 columns">
            {hasAllImages ?
              <h3>Great! Now you're ready to publish</h3> :
              <h3>Your collection could use some {imageCount > 0 && 'more'} images</h3>}
            <a className="button" style={{ position: 'fixed', top: '1rem', right: '1rem' }} onClick={() => onClosePopup()}>Close</a>
          </div>
        </div>
        <div className="row full-width popup-content">
          <div className="small-12 medium-8 columns">
            {hasAllImages ? [
              <p key="0">Your collection is ready to publish.</p>,
              <a key="1" className="button large" onClick={onPublish}>Publish</a>
            ]: [
              <p key="0">It's important that you choose four images to make your collection stand out when distributors are looking for it.  Click the squares on the right to choose your own images{canCommonskuChoose && ' or the button below to let commonsku choose images based on the products in this collection'}.</p>,
              canCommonskuChoose && <a key="1" className="button large" onClick={this.handleCommonskuChoice}>Let commonsku choose</a>
            ]}
          </div>
          <div className="small-12 medium-4 columns">
            <CollectionBadge collection={collection} onChangeImage={onChangeImage(images)} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  images: getCollectionImages(state, { order_id: ownProps.collection.order_id }),
  collection: getOrderByNumber(state, { form_number: ownProps.collection.form_number, order_type: 'COLLECTION' }),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCommonskuChoice: choices => {
    dispatch(createSpinnerPopup('Choosing some images...'));
    return Promise.all(choices.map(c => dispatch(createAddCollectionImage(ownProps.collection.order_id, c.file_id, c.display_order)))).then(() => dispatch(closePopup()));
  },
  onChangeImage: images => (selected_id, index) => () => dispatch(createSelectImagePopup(ownProps.collection.order_id, images, selected_id, index)),
  onPublish: () => dispatch(updateOrderStatus(ownProps.collection.order_id, ownProps.collection.status_id, 'Published')).then(() => dispatch(closePopup())),
  onClosePopup: () => dispatch(closePopup())
});

const ConnectedSelectCollectionImagesReminderPopup = connect(mapStateToProps, mapDispatchToProps)(SelectCollectionImagesReminderPopup);
export default ConnectedSelectCollectionImagesReminderPopup;
