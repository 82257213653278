import React, { Component } from 'react'
import { connect } from 'react-redux'

import { closePopup } from '../actions/popup'
import { BASE_ZINDEX } from '../popup-factory'
import { createAddItem, createAddSuggestionItem, createAddItemCancel } from '../actions'
import { createFetchProductDetails } from '../actions/product'
import Product from './Product'

class SelectVariantPopup extends Component {

  render() {
    const { loading, loaded, products, onAddProduct, onClosePopup, index } = this.props;
    return (
      <div className="reveal large" style={{display: 'block', zIndex: BASE_ZINDEX + index}} role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3>Add Product</h3>
            <p>Please select the variant you wish to use</p>
          </div>
        </div>
        <div className="row full-width mega-modal-content popup-content">
          {products.map((p, idx) =>
            <Product key={p.product_id} className={idx === products.length - 1 ? 'end' : ''} product={p} loading={loading.includes(p.product_id)} loaded={loaded.includes('' + p.product_id)} type="others" onAddProduct={onAddProduct} />
          )}
        </div>
        <button className="close-button" aria-label="Close modal" type="button" onClick={e => onClosePopup()}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const loading = state.display.loading.add_item;
  const loaded = state.display.loading.added_item;
  return {
    loading,
    loaded
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const suggestion_id = ownProps.suggestion_id ? ownProps.suggestion_id : null;
  const event = ownProps.event ? ownProps.event : null;
  return {
    onAddProduct: product_id => {
      let result;
      if ('MESSAGE' === ownProps.order_id) {
        result = dispatch(createFetchProductDetails(product_id, ownProps.product_source, 'MESSAGE'));
      } else if (ownProps.suggestion_id) {
        result = dispatch(createAddSuggestionItem(ownProps.order_id, ownProps.parent_type, product_id, ownProps.product_source, event ? 'collaborate' : 'message-suggest', suggestion_id, event));
      } else {
        result = dispatch(createAddItem(ownProps.order_id, ownProps.parent_type, product_id, ownProps.product_source, ownProps.target_type, ownProps.display_order));
      }
      dispatch(closePopup());
      if (ownProps.success_callback) {
        result.then(ownProps.success_callback);
      }
    },
    onClosePopup: () => {
      dispatch(closePopup());
      dispatch(createAddItemCancel());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectVariantPopup);
