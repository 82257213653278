import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { formatFriendlyDateTime, formatAMPM, formatMoney, getImageSrc, toTitleCase, download } from '../utils'

import { createAddComment, createUpdateNote } from '../actions/message'
import { createUpdateDisplayCommenting } from '../actions/display'
import { createEmailMessagePopup, createEditMessagePopup } from '../actions/popup'

import TruncatedText from './TruncatedText'
import SuggestionProduct from './SuggestionProduct'
import Feed from './Feed'
import MentionTextarea from './MentionTextarea'
import Img from './Img'

const NOTE_TYPE_ICON_MAP = {
  CALL: 'fi-telephone',
  MEETING: 'fi-torsos-all',
  NOTE: 'fi-clipboard-notes',
  PROMOSTANDARDS: 'fi-clipboard-notes'
};

class Post extends Component {

  constructor(props) {
    super(props);

    this.state = {
      inputValue: ''
    };

    _.bindAll(this, ['handleChangeInput', 'handleAddComment', 'handleToggleReminder']);
  }

  handleChangeInput(value) {
    this.setState({ inputValue: value });
  }

  renderComments(comments) {
    if (comments && comments.length) {
      const {
        job_id, 
        comment_allowed
      } = this.props;

      return (
        <div className="large-12 columns comments">
          <Feed 
            posts={comments} 
            job_id={job_id}
            comment={true} 
            comment_allowed={comment_allowed}
            show_add_comment={this.props.commenting === this.props.post.message_id} />
        </div>
      )
    }
  }

  handleAddComment(text, parent_id, parent_type, recipient_id) {
    let patt = /(@[A-z0-9]+)/g;
    let user_ids_arr = [];
    let m;
    
    do {
      m = patt.exec(text);
      if(m && this.props.mention_users.filter(u => u.mask == m[0].slice(1)).length != 0) {
        user_ids_arr.push(this.props.mention_users.filter(u => u.mask == m[0].slice(1))[0]['user_id']);
      }
    } while(m);
    let tagged_users = user_ids_arr.toString();
    this.props.onAddComment(text, parent_id, parent_type, recipient_id, tagged_users);
    this.setState({ inputValue: '' });
  }

  renderAvatar() {
    const { post } = this.props;
    const note_subtype = post.note && post.note.note_subtype;
    const avatar = 'PROMOSTANDARDS' === note_subtype ? '/images/promostandards-big-icon.png' : (post.actor.user_image_paths ? (post.actor.user_image_paths.small.startsWith('http') ? post.actor.user_image_paths.small : `/${post.actor.user_image_paths.small}`) : '/images/404.png');
    return (
      <div className="small-2 medium-1 columns">
        <img src={avatar} className="avatar" />
      </div>
    );
  }

  renderSuggestions() {
    const { job_id, post, has_orders, add_to_order } = this.props;
    const suggestions = !post.event_parent_id ? post.suggestions || {} : {};

    return Object.values(suggestions).map(s => 
      <SuggestionProduct 
         key={s.product_id} 
         job_id={job_id}
         suggestion={s} 
         message_id={post.message_id}
       />
    );
  }

  isAddCommentVisible() {
    const { comment, comment_allowed, post, commenting, show_add_comment } = this.props;
    const parent_id = (comment ? (post.original_parent_id || post.parent_id) : post.message_id);

    return parent_id === commenting && (comment ? show_add_comment : _.isEmpty(post.comments) && _.isEmpty(post.replies));
  }

  renderAddComment() {
    if (this.isAddCommentVisible()) {
      const { mention_users, post, comment } = this.props;
      const { inputValue } = this.state;
      const actor = post.actor;
      const handleAddComment = e => {
        e.preventDefault();
        this.handleAddComment(inputValue, comment ? (post.original_parent_id || post.parent_id) : post.message_id, comment ? (post.original_parent_type || post.parent_type) : post.parent_type, actor.user_id);
      };
      return (
        <div>
          <div className="row no-maxWidth" style={{'marginTop' : '0px'}}>
            <div className="small-9 medium-9 column">
              <MentionTextarea name="comment-input" 
                placeholder="Comment on this message..." 
                value={inputValue} 
                mention_users={mention_users} 
                onChange={this.handleChangeInput}
              />
            </div>
            <div className="small-3 medium-3 column">
              <button name="add-comment" className="button alt full-width" onClick={handleAddComment} disabled={inputValue != '' ? false : true}>Comment</button>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  getAuthor() {
    const { post: { actor, supplier } } = this.props;
    const author = (actor.user_first_name === undefined || actor.user_last_name === undefined ? 'Anonymous' : `${actor.user_first_name} ${actor.user_last_name}`) + (actor.supplier_name ? ` from ${actor.supplier_name}` : '');
    return author;
  }

  getRecipient() {
    const { post: { event_parent_id, event_supplier_id, supplier } } = this.props;
    if ('all' === event_supplier_id) {
      return 'all';
    }
    if (supplier) {
      if (event_parent_id) {
        return `all (reply from ${supplier.supplier_name})`;
      } else {
        return `${supplier.contact_first_name} ${supplier.contact_last_name} from ${supplier.supplier_name}`;
      }
    }
  }

  renderHeader() {
    const { comment, post } = this.props;
    const author_style = Object.assign({ marginBottom: 0 }, comment ? {} : { fontWeight: 'bold' });
    const recipient = this.getRecipient();
    return (
      <div className="small-12 columns">
        <h5 style={author_style} className="author">
          <span>{this.getAuthor()}{recipient ? ` > ${recipient}` : null}</span>
          {post.details_type === 'EMAIL' && post.email ?
            <span style={{fontWeight: 'normal', fontSize: 'small'}}> &mdash; Email to: {post.email.email_to_recipients}</span> :
          post.details_parent_form_number && post.details_parent_order_type && post.details_parent_type !== 'JOB' ?
            <span style={{fontWeight: 'normal', fontSize: 'small'}}> &mdash; {post.details_parent_order_type} ({post.details_parent_form_number})</span> : null}
          {this.renderNoteType()}
        </h5>
      </div>
    );
  }

  renderNoteDetails() {
    const { post: { company_url, company_name, date_complete, note, details_parent_type } } = this.props;
    if (note && note.note_type && details_parent_type !== 'JOB' && details_parent_type !== 'ORDER') {
      const date_complete = formatAMPM(note.date_complete);
      return <p style={{marginBottom: '0.2rem'}}>{this.getAuthor()} added a {note.note_type.toLowerCase()} to <a href={`/${company_url}`} target="_blank">{company_name}</a>{note.contact_full_name ? ` for ${note.contact_full_name}` : ''}{'NOTE' !== note.note_type && date_complete ? ` at ${date_complete}` : null}</p>;
    }
  }

  getEventMessage() {
    const { post: { order_type, form_number, job_name, total_total, created_by, client_rep_id, client_rep_first_name, client_rep_last_name, event_order_id, event_action, event_payload } } = this.props;
    const order_link = `/${order_type.toLowerCase().replace(' ', '_')}.php?id=${event_order_id}`;
    const message = `${this.getAuthor()} ${'create' === event_action ? 'created' : 'put'} <a target="_blank" href="${order_link}" style={{ wordWrap: 'break-word' }}>${toTitleCase(order_type)} #${form_number} - ${job_name} ${'PRESENTATION' !== order_type && 'OPPORTUNITY' !== order_type ? `($${formatMoney(total_total)})` : ''}</a>${'Approval' === event_payload ? ' to approved' : 'Production' === event_payload ? ' into production' : ''}${created_by !== client_rep_id ? ` on behalf of <a href="user.php?id=${client_rep_id}">${client_rep_first_name} ${client_rep_last_name}</a>` : ''}`;
    return message;
  }

  renderEmailMessage() {
    const { post, onViewEmailMessage } = this.props;
    const text = _.filter(
      (post.text || post.message_text || '')
        .replace(/(href=['"])(?!https?:)/, "$1/")
        .replace(/<a /, '<a target="_blank" ')
        .split(),
      (line) => {
        return !!line;
      }
    ).join(' ');
    return <div className="small-12 columns">
      <p style={{marginBottom: '0.2rem'}}>Subject: {post.subject}</p>
      <TruncatedText html={text} limit={300}/>
      <a className="button" style={{}} onClick={(e) => {
        e.preventDefault();
        onViewEmailMessage(post);
      }}>View Email</a>
    </div>
  }

  renderMessage() {
    const { post: { details_type, text, message_text, event_id } } = this.props;
    const message = (text ? text : message_text || '').replace(/(href=['"])(?!https?:)/, "$1/").replace(/<a /, '<a target="_blank" ');
    return details_type === 'EMAIL' ? this.renderEmailMessage() : (
      <div className="small-12 columns">
        {this.renderNoteDetails()}
        <p style={{marginBottom: '0.2rem'}} dangerouslySetInnerHTML={{
          __html: event_id && !message ? this.getEventMessage() : message
        }} />
      </div>
    );
  }

  renderFiles() {
    const { post: { files }, tenant_id } = this.props;
    if (!files || 0 === files.length) {
      return null;
    }
    if (_.isArray(files)) {
      return <div className="large-12 columns">{files.map(f => this.renderFile(f, tenant_id))}</div>;
    } else {
      return <div className="large-12 columns">{this.renderFile(files)}</div>;
    }
  }

  renderFile(file, tenant_id) {
    const handleDownload = e => {
      e.preventDefault();
      download(getImageSrc(file, 'original'), file.file_display_name);
    };
    if (file.file_type.match(/image/)) {
      return (
        <div className="small-6 medium-4 large-3 columns">
          <Img src={getImageSrc(file, 'small')} />
          <br />
          <a onClick={handleDownload}><i className="fi-download"></i> download</a>
        </div>
      );
    } else {
      return (
        <div className="small-6 medium-4 large-3 columns">
          <a onClick={handleDownload}>Download {file.file_display_name}</a>
        </div>
      );
    }
  }

  handleToggleReminder(reminder) {
    return e => {
      e.preventDefault();
      const { onUpdateNote } = this.props;
      const new_value = reminder.reminder_complete == 1 ? 0 : 1;
      onUpdateNote(reminder.message_id, reminder.note_id, 'reminder_complete', reminder.reminder_complete)(new_value);
    };
  }

  getReminder() {
    const { post, post: { note } } = this.props;
    return note && note.date_reminder && note.date_reminder !== '0000-00-00 00:00:00' && note.reminder_user_full_name ?  {
      message_id: post.message_id,
      note_id: note.note_id,
      date_reminder: note.date_reminder,
      reminder_user_full_name: note.reminder_user_full_name,
      reminder_complete: note.reminder_complete,
      message_text: (post.message_text || post.text || note.text),
      reminder_user: note.reminder_user
    } : null;
  }

  renderReminder() {
    const reminder = this.getReminder();
    if (reminder) {
      return (
        <div className="small-12 columns">
          <input
            type="checkbox"
            style={{marginBottom: '0.5rem', marginRight: '0.2rem'}}
            checked={reminder.reminder_complete == 1}
            onChange={this.handleToggleReminder(reminder)}
          />
          Task for {reminder.reminder_user_full_name} on {reminder.date_reminder.replace(' 00:00:00', '')}
        </div>
      );
    }
  }

  renderFooter() {
    const { post, comment, comment_allowed, job_id } = this.props;
    const replyCount = post.replies ? Object.values(post.replies).length : null;
    const commentCount = post.comments ? Object.values(post.comments).length : null;
    const reminder = this.getReminder();

    const handleComment = e => {
      e.preventDefault();
      if (this.props.commenting) {
        this.props.onStopCommenting();
      } else {
        this.props.onStartCommenting(comment ? (post.original_parent_id || post.parent_id) : post.message_id);
      }
    };

    return (
      <div className="small-12 columns" style={{fontSize: '0.8em'}}>
        <span style={{color: '#aaa'}}>{formatFriendlyDateTime(post.date || post.date_created)}</span>
        {!post.event_supplier_id && !comment && comment_allowed ?
          <a onClick={handleComment} style={{marginLeft: '1rem'}}>Comment</a>
        : post.event_supplier_id && !comment && comment_allowed && replyCount > 0 || post.event_supplier_id && !comment && comment_allowed && commentCount > 0 ? 
          <a onClick={handleComment} style={{marginLeft: '1rem'}}>Comment</a>
        : null}
        {reminder ? <a style={{marginLeft: '1rem'}} onClick={e => {e.preventDefault(); this.props.onCreateEditMessagePopup(reminder, 'task', job_id) }}>Edit</a> : null}
      </div>
    );
  }

  renderNoteType() {
    const { post: { note } } = this.props;
    if (note) {
      return <i className={NOTE_TYPE_ICON_MAP[note.note_type]} style={{ float: 'right' }}></i>;
    }
  }

  render() {
    const { post } = this.props;

    return (
      <li className="post">
        <div className="row no-maxWidth collapse">
          {this.renderAvatar()}
          <div className="small-10 medium-11 columns">
            <div className="row no-maxWidth">
              {this.renderHeader()}
              {this.renderMessage()}
              {this.renderFiles()}
              {this.renderSuggestions()}
              {this.renderReminder()}
              {this.renderFooter()}
              {this.renderComments(post.replies ? Object.values(post.replies) : null)}
              {this.renderComments(post.comments ? Object.values(post.comments) : null)}
            </div>
          </div>
        </div>
        {this.renderAddComment()}
      </li>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const tenant_id = state.identity.tenant_id;
  const commenting = state.display.commenting;

  return {
    tenant_id,
    mention_users: Object.values(state.entities.users).filter(u => u.mask != false),
    commenting
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onStartCommenting: parent_id => dispatch(createUpdateDisplayCommenting(parent_id)),
    onStopCommenting: () => dispatch(createUpdateDisplayCommenting(false)),
    onAddComment: (text, parent_id, parent_type, recipient_id, tagged_users) => {
      dispatch(createUpdateDisplayCommenting(false));
      dispatch(createAddComment(text, parent_id, parent_type, recipient_id, tagged_users));
    },
    onUpdateNote: (message_id, note_id, field, previous_value) => value => {
      dispatch(createUpdateNote(message_id, note_id, field, previous_value, value));
    },
    onViewEmailMessage: (message) => {
      dispatch(createEmailMessagePopup(message));
    },
    onCreateEditMessagePopup: (message, message_type, job_id) => {
      dispatch(createEditMessagePopup(message, message_type, job_id));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Post)
