import React, { Component } from 'react';
import { connect } from 'react-redux';

import { closePopup } from '../actions/popup';
import { BASE_ZINDEX } from '../popup-factory';
import { createAddItem, createAddItemCancel } from '../actions';
import { createFetchProductDetails } from '../actions/product';
import CollectionItem from './CollectionItem';

class SelectCollectionItemPopup extends Component {

  render() {
    const { collection, loading, loaded, onAddItem, onClosePopup, index } = this.props;
    const items = collection.items.filter(i => 'OPTION' === i.parent_type).map(i => ({
      ...i,
      currency_symbol: collection.currency_symbol,
      options: collection.items.filter(o => o.option_item_id === i.item_id)
    }));

    return (
      <div className="reveal large" style={{display: 'block', zIndex: BASE_ZINDEX + index}} role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3>Add Item from Collection <a href={`/collection/${collection.order_id}`} target="_blank">{collection.job_name}</a></h3>
            <p>Please select the item you wish to use</p>
          </div>
        </div>
        <div className="row full-width mega-modal-content popup-content" style={{ height: 'calc(600px - 2rem)' }}>
          {items.map((i, idx) =>
            <CollectionItem key={i.item_id} className={idx === items.length - 1 ? 'end' : ''} item={i} loading={loading.includes(i.item_id)} loaded={loaded.includes(i.item_id)} type="others" onAddProduct={onAddItem} />
          )}
        </div>
        <button className="close-button" aria-label="Close modal" type="button" onClick={e => onClosePopup()}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.display.loading.add_item,
  loaded: state.display.loading.added_item
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAddItem: item_id => {
    return dispatch(createAddItem(ownProps.order_id, 'PRODUCT', item_id, null, 'COPY', ownProps.insertIndex));
  },
  onClosePopup: () => dispatch(closePopup())
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectCollectionItemPopup);
