import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getAddress } from '../selectors';
import { getAddressOptions, getAddressDropdown } from '../selectors/dropdowns';

import { closePopup, createAddAddressPopup } from '../actions/popup';
import { createAddAddress, createUpdateAddress, createCopyAddress, createDeleteAddress } from '../actions/address';
import { updateShop } from '../actions/shop'

import { BASE_ZINDEX } from '../popup-factory';

import Select from './Select';
import Address from './Address';
import TaxSelect from './TaxSelect';
import LabelledCheckbox from './LabelledCheckbox'

class AddressListPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      address_id: '',
      address_name: '',
      address_line_1: '',
      address_line_2: '',
      address_city: '',
      address_state: '',
      address_country: '',
      address_postal: ''
    };

    this.handleAddAddress = this.handleAddAddress.bind(this);
    this.onChangeAddress = this.onChangeAddress.bind(this);
    this.handleClosePopup = this.handleClosePopup.bind(this);
  }

  onChangeAddress(address_id) {
    if ('ADD' === address_id) {
      this.setState({
        address_id,
        address_name: '',
        address_line_1: '',
        address_line_2: '',
        address_city: '',
        address_state: '',
        address_country: '',
        address_postal: ''
      }, function() {
        this.handleAddAddress()
      });
    } else {
      const address = this.props.getAddress(address_id);
      this.setState({
        ...address,
        address_id
      }, function() {
        this.handleAddAddress()
      });
    }
  }

  handleAddAddress() {
    const {
      address_id,
      address_name,
      address_line_1,
      address_line_2,
      address_city,
      address_state,
      address_country,
      address_postal,
    } = this.state;

    if (!address_id) {
      return;
    }
    if ('ADD' === address_id) {
      this.setState({
        address_id: '',
        address_name: '',
        address_line_1: '',
        address_line_2: '',
        address_city: '',
        address_state: '',
        address_country: '',
        address_postal: ''
      }, () => this.props.onAddNewAddress());
    } else {
      this.setState({
        address_id: '',
        address_name: '',
        address_line_1: '',
        address_line_2: '',
        address_city: '',
        address_state: '',
        address_country: '',
        address_postal: ''
      }, () => this.props.onAddClientAddress(address_id));
    }
  }

  handleClosePopup(e) {
    const { addresses, address_type, allow_credit_card_checkout, onClosePopup, onUpdateShop } = this.props;

    if(address_type === 'SHIPPING') {
      if(!addresses.length) {
        onUpdateShop('allow_credit_card_checkout', allow_credit_card_checkout)(0)
        onUpdateShop('shipping_address_type', 'LIST')('TYPED')
      }else{
        for(let address of addresses) {
          if(address.tax_id == '' || !address.tax_id) {
            onUpdateShop('allow_credit_card_checkout', allow_credit_card_checkout)(0)
            onUpdateShop('shipping_address_type', 'LIST')('TYPED')
            break;
          }
        }
      }
    }

    onClosePopup()
    onClosePopup()
  }
  
  render() {
    const { addresses, client_addresses, address_type, allow_credit_card_checkout, zip2tax, onRemoveAddress, onClosePopup, onCreateUpdateAddress, title, index } = this.props;
    const { address_id, address_name, address_line_1, address_line_2, address_city, address_state, address_country, address_postal } = this.state;

    const onChange = field => e => this.setState({ [field]: e.target.value });

    return (
      <div className="reveal large" style={{ display: 'block', zIndex: BASE_ZINDEX + index, height: 'initial', maxHeight: '100%' }}>
        <div className="row small-12 columns">
          <h3>{title || ('BILLING' === address_type ? 'Billing Address List' : 'SHIPPING' === address_type ? 'Shipping Address List' : 'Address List')}</h3>
          <a className="button" style={{ position: 'absolute', right: '1rem', top: '1rem' }} onClick={e => {e.preventDefault(); this.handleClosePopup(e)}}>Done</a>
        </div>
        <div className="row small-12 medium-8 columns" style={{ padding: '10px 0px', marginRight: '15px', float: 'left' }}>
          <Select className="small-8" style={{ float: 'left', marginLeft: '15px', marginRight: '10px' }} options={client_addresses} value={address_id} placeholder="Select from client" change={this.onChangeAddress} />
          {/* <a className="button small" onClick={e => {e.preventDefault(); this.handleAddAddress()}} disabled={!address_id}>Add</a> */}
          {address_id && 'ADD' !== address_id &&
            <div className="small-12 columns">
              <Address {...this.state} />
            </div>}
        </div>
        <div className="row small-12 columns" style={{ overflow: 'auto', height: '400px' }}>
          <table>
            <thead>
              <tr>
                <td>Location</td>
                {address_type === 'SHIPPING' && allow_credit_card_checkout == 1 ?
                  <td style={{ width: "250px"}}>Tax</td>
                : null}
                {address_type === 'SHIPPING' && allow_credit_card_checkout == 1 ? <td>Tax on Shipping</td> : null}
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              {addresses.length ? addresses.map((a, i) =>
                <tr key={a.address_id}>
                  <td>
                    <b>{a.address_name}</b>
                    <Address {...a} />
                  </td>
                  {address_type === 'SHIPPING' && allow_credit_card_checkout == 1 ?
                    <td style={{ width: "250px"}}>
                      <TaxSelect value={a.tax_id} zip2tax={1 == zip2tax} onChange={onCreateUpdateAddress(a.address_id, 'tax_id', a.tax_id)} />
                    </td>
                  : null}
                  {address_type === 'SHIPPING' && allow_credit_card_checkout == 1 ?
                    <td>
                      <LabelledCheckbox value={a.ignore_tax_on_shipping == 0 ? 1 : 0} onChange={e => onCreateUpdateAddress(a.address_id, 'ignore_tax_on_shipping', a.ignore_tax_on_shipping)(e == 0 ? 1 : 0)} />
                    </td>
                  : null}
                  <td>
                    <a onClick={e => onRemoveAddress(a, i)}>delete location</a>
                  </td>
                </tr>
              ) : <tr><td>No addresses yet</td></tr>}
            </tbody>
          </table>
          <br /><br />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const shop_addresses = getAddressDropdown(state, ownProps).filter(a => a.address_type === ownProps.address_type && a.parent_type === 'SHOP');
  const shop_address_map = shop_addresses.reduce((o, a) => { o[a.address_name + a.address_line_1] = a; return o; }, {});
  return {
    addresses: shop_addresses,
    client_addresses: [{ key: 'ADD', value: 'Add New Client Address' }].concat(getAddressDropdown(state, { parent_id: ownProps.client_id }).filter(a => !shop_address_map[a.address_name + a.address_line_1] && [ownProps.address_type, 'BOTH'].indexOf(a.address_type) !== -1).map(a => ({ key: a.address_id, value: a.address_name }))),
    getAddress: address_id => getAddress(state, { address_id })
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClosePopup: () => dispatch(closePopup()),
  onRemoveAddress: (address, index) => {
    dispatch(createDeleteAddress(address))
    if (index == 0) {
      dispatch(updateShop(ownProps.parent_id, `${ownProps.address_type.toLowerCase()}_address_type`, 'LIST', 'TYPED'))
    }
  },
  onAddClientAddress: address_id => dispatch(createCopyAddress(address_id, ownProps.parent_id, 'SHOP', ownProps.address_type)),
  onAddNewAddress: () => {
    dispatch(createAddAddressPopup(null, null, null, ownProps.client_id, 'CLIENT', ownProps.parent_id, 'SHOP', ownProps.address_type));
  },
  onCreateUpdateAddress: (address_id, field, previous_value) => value => dispatch(createUpdateAddress(address_id, field, previous_value, value)),
  onUpdateShop: (field, previous_value) => value => dispatch(updateShop(ownProps.parent_id, field, previous_value, value))
});

const ConnectedAddressListPopup = connect(mapStateToProps, mapDispatchToProps)(AddressListPopup);
export default ConnectedAddressListPopup;
