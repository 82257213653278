import React, { Component } from 'react'

export class Link extends Component {
    constructor(props) {
        super(props)
        this.state = { hover: false,}

        this.toggleHover = this.toggleHover.bind(this);
    }

    toggleHover() {
        this.setState({hover: !this.state.hover})
    }

    render() {
        let hoverStyles = {
            visibility: 'hidden',
        };
        if (this.state.hover) {
            hoverStyles = {hoverStyles: 'visible'};
        }

        return(
            <div onMouseEnter={this.toggleHover}
                onMouseLeave={this.toggleHover}
            >
                <a style={{...this.props.style, ...hoverStyles}} 
                    {...this.props}>{this.props.children}</a>
            </div>
        );
    }
}
