import React, { Component } from 'react'
import { connect } from 'react-redux'

import { closePopup } from '../actions/popup'

import { BASE_ZINDEX } from '../popup-factory'

class CreditHoldPopup extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { onClosePopup, index } = this.props;

        return (
            <div id='credit-hold-modal' className='reveal small' style={{ display: 'block', zIndex: BASE_ZINDEX + index, height: '185px' }} aria-labelledby='modalTitle' aria-hidden='true' role='dialog'>
                <div className='row'>
                    <div className='small-12 columns'>
                        <h4 style={{ width: '90%' }}>This client is currently on Credit Hold</h4>
                        <a className='button' style={{ position: 'fixed', right: '1rem', top: '1rem' }} onClick={e => onClosePopup()}>Close</a>
                    </div>
                </div>
                <div className='row popup-content columns'>
                    <p>Work can continue on this Client, but Purchase Orders cannot be created until the Credit Hold has been removed.</p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => {
    return {
        onClosePopup: () => {
            dispatch(closePopup())
        }
    }
}


const ConnectedCreditHoldPopup = connect(mapStateToProps, mapDispatchToProps)(CreditHoldPopup);
export default ConnectedCreditHoldPopup;