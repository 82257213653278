import React, { Component } from 'react';
import { connect } from 'react-redux';

import { closePopup } from '../actions/popup';
import { createAddTemp } from '../actions/temp';

import { BASE_ZINDEX } from '../popup-factory';
import { getAbsoluteUrl } from '../utils';

class SelectCollectionSupplierPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      search: ''
    };

    this.handleChangeSearch = this.handleChangeSearch.bind(this);
  }

  handleChangeSearch(e) {
    this.setState({ search: e.target.value });
  }

  render() {
    const { suppliers, index, onClosePopup, onSelectSupplier } = this.props;
    const { search } = this.state;

    const regex = new RegExp(search, 'i');
    const selected_suppliers = suppliers.filter(s => s.supplier_name.match(regex));

    const handleCancel = e => {
      e.preventDefault();
      onClosePopup();
    };

    const company_icon_style = {
      width: '100px',
      height: '100px',
      float: 'left',
      borderRadius: '50%',
      background: 'white',
      margin: '1rem',
      transition: 'all .5s',
      border: '4px solid #76BBD1',
      overflow: 'hidden'
    };

    return (
      <div className="reveal large" style={{ display: 'block', zIndex: BASE_ZINDEX + index, maxHeight: '75%', overflow: 'hidden'}} aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="row small-12 columns">
          <h3>Select Supplier</h3>
          <input type="text" value={search} onChange={this.handleChangeSearch} placeholder="Search for supplier" />
        </div>
        <div className="row small-12 columns" style={{ overflowY: 'auto', height: '80%' }}>
            {selected_suppliers.map(s =>
              <div key={s.supplier_id} style={company_icon_style}>
                <img style={{ top: '50%', transform: 'translateY(-50%)', position: 'relative' }} src={getAbsoluteUrl(s.supplier_img)} onClick={e => onSelectSupplier(s.supplier_id)} alt={s.supplier_name} title={s.supplier_name} />
              </div>
            )}
        </div>
        <a className="button hide-for-small-only" style={{ position: 'absolute', right: '1rem', top: '1rem' }} onClick={handleCancel}>Cancel</a>
        <a className="close-button show-for-small-only" onClick={handleCancel}>&times;</a>
      </div>
    );
  }
}

const mapStateToProps = null;

const mapDispatchToProps = dispatch => ({
  onClosePopup: () => dispatch(closePopup()),
  onSelectSupplier: supplier_id => {
    dispatch(createAddTemp('supplier_id', supplier_id));
    dispatch(closePopup());
  }
});

const ConnectedSelectCollectionSupplierPopup = connect(mapStateToProps, mapDispatchToProps)(SelectCollectionSupplierPopup);
export default ConnectedSelectCollectionSupplierPopup;
