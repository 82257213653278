import React, { Component } from 'react'
import { connect } from 'react-redux'

import Select from './Select'

import { closePopup } from '../actions/popup'
import { createAddSuggestionItem } from '../actions'

import { BASE_ZINDEX } from '../popup-factory'

class AddProductToOrderPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      order_id: '',
      parent_type: this.props.sales_orders.length == 0 && this.props.estimates.length == 0 ? 'OPTION' : 'PRODUCT'
    }

    this.handleChangeOrder = this.handleChangeOrder.bind(this);
  }

  handleChangeOrder(e) {
    this.setState({ order_id: e })
  }

  render() {
    const { order_id, parent_type, parent_id, suggestion_id, event, onCreateAddItem, presentations, estimates, sales_orders, onClosePopup, index } = this.props; 
    const orders = [{key:'', value:'Pick your orders'}].concat(sales_orders.length != 0 ? sales_orders : estimates.length != 0 && sales_orders.length == 0 ? estimates : presentations.length != 0 && estimates.length == 0 && sales_orders.length == 0 ? presentations : null);
    
    return (
      <div id="add-product-to-order-modal" className="reveal large" style={{display: 'block', zIndex: BASE_ZINDEX + index}} aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3 id="modalTitle">Add Product to Order</h3>
          </div>
        </div>
        <div className="row">
          <Select value={this.state.order_id} options={orders} change={e => this.handleChangeOrder(e)} />
        </div>
        <button className="tiny button" onClick={e => {e.preventDefault(); onCreateAddItem(this.state.order_id, this.state.parent_type, parent_id, suggestion_id, event); onClosePopup(); }}>Import</button>
        <button className="close-button" aria-label="Close modal" type="button" onClick={e => {e.preventDefault(); onClosePopup();}}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let presentations = Object.values(state.entities.orders).filter(o => o.order_type === 'PRESENTATION').map(o => ({key: o.order_id, value: o.form_number}));
  let estimates = Object.values(state.entities.orders).filter(o => o.order_type === 'ESTIMATE').map(o => ({key: o.order_id, value: o.form_number}));
  let sales_orders = Object.values(state.entities.orders).filter(o => o.order_type === 'SALES ORDER').map(o => ({key: o.order_id, value: o.form_number}));

  return {
    presentations,
    estimates,
    sales_orders
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClosePopup: () => {
      dispatch(closePopup());
    },
    onCreateAddItem: (order_id, parent_type, parent_id, suggestion_id, message_id) => {
      dispatch(createAddSuggestionItem(order_id, parent_type, parent_id, null, 'collaborate', suggestion_id, message_id));
    },
  }
}

const ConnectedAddProductToOrderPopup = connect(mapStateToProps, mapDispatchToProps)(AddProductToOrderPopup);
export default ConnectedAddProductToOrderPopup;

