import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import YouTube from 'react-youtube'

import { closePopup, createTourMilestonePopup } from '../actions/popup'

import { BASE_ZINDEX } from '../popup-factory'

class AddYouTubePopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      tab: 'sage'
    }

    this.handleResize = this.handleResize.bind(this);
    this.handleTab = this.handleTab.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize(e) {
    if(window.innerWidth != this.state.windowWidth) {
      this.setState({ windowWidth: window.innerWidth });
    }
    if(window.innerHeight != this.state.windowHeight) {
      this.setState({ windowHeight: window.innerHeight });
    }
  }

  handleTab(value) {
    this.setState({ tab: value });
  }

  render() {
    const { index, video_id, milestone, next, onClosePopup, onCreateTourMilestonePopup } = this.props;
    const opts = {
      height: this.state.windowHeight/1.3,
      width: this.state.windowWidth/2,
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 1
      }
    };
    const yt_popup_style = {
      display: 'block', 
      height: 'inherit', 
      zIndex: BASE_ZINDEX + index, 
      width: 'auto', 
      maxWidth: 'none', 
      border: 'none'
    };
    const src = "../media/" + video_id;
    const inactive = {
      width: '130px',
      height: '60px',
      padding: '10px',
      opacity: 0.3,
      boxShadow: '10px 10px 10px'
    };
    const active = {
      width: '130px',
      height: '60px',
      padding: '10px',
      boxShadow: '10px 10px 10px'
    };
    return (
      <div className="reveal large" style={yt_popup_style} aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="row popup-content" style={{margin: '30px', maxWidth: 'none'}}>
          {/* <YouTube videoId={video_id} opts={opts} /> */}
          {video_id === 'create-presentation-0' ? 
            <div>
              <ul className="tabs">
                <li className="tab-title" style={{float: 'left', padding: '10px'}} onClick={e => {e.preventDefault(); this.handleTab('sage')}}><img src="../images/logo-sage.png" style={this.state.tab === 'sage' ? active : inactive} /></li>
                <li className="tab-title" style={{float: 'left', padding: '10px'}} onClick={e => {e.preventDefault(); this.handleTab('dc')}}><img src="../images/logo-dc.png" style={this.state.tab === 'dc' ? active : inactive} /></li>
                <li className="tab-title" style={{float: 'left', padding: '10px'}} onClick={e => {e.preventDefault(); this.handleTab('esp')}}><img src="../images/logo-esp.gif" style={this.state.tab === 'esp' ? active : inactive} /></li>
              </ul>
              <div className="tabs-content">
                {this.state.tab === 'sage' ? 
                  <video width={this.state.windowWidth/2} height={this.state.windowHeight/1.3} controls autoPlay>
                    <source src="../media/trial_sage.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video> 
                : null}
                {this.state.tab === 'esp' ? 
                  <video width={this.state.windowWidth/2} height={this.state.windowHeight/1.3} controls autoPlay>
                    <source src="../media/trial_esp.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video> 
                : null}
                {this.state.tab === 'dc' ? 
                  <video width={this.state.windowWidth/2} height={this.state.windowHeight/1.3} controls autoPlay>
                    <source src="../media/trial_dc.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video> 
                : null}
              </div> 
            </div>
          :
            <video width={this.state.windowWidth/2} height={this.state.windowHeight/1.3} controls autoPlay>
              <source src={src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          }
        </div>
        <button className="close-button" aria-label="Close modal" type="button" style={{color: 'black', fontSize: '3em', margin: '-5px'}} onClick={e => {e.preventDefault(); onCreateTourMilestonePopup(); onClosePopup(-1);}}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClosePopup: (index) => {
      dispatch(closePopup(index));
    },
    onCreateTourMilestonePopup: () => {
      dispatch(createTourMilestonePopup(ownProps.milestone, ownProps.next));
    }
  }
}

const ConnectedAddYouTubePopup = connect(mapStateToProps, mapDispatchToProps)(AddYouTubePopup);
export default ConnectedAddYouTubePopup;

