import { bindAll, get, throttle, find } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import Autosuggest from 'react-autosuggest';

import { oauth } from '../utils';


export default class CompanyUserSuggest extends Component {

  constructor(props) {
    super(props);

    this.state = {
      value: get(props, 'company.csku_login_name') || '',
      suggestions: [],
    };
    this.items = [];

    bindAll(this, ['onSuggestionsClearRequested', '_onSuggestionsFetchRequested'])
    this.onSuggestionsFetchRequested = _.throttle(this._onSuggestionsFetchRequested, 500);
  }

  componentDidMount() {
    const { company } = this.props;
    const { company_id, company_type } = company || {};

    oauth('GET', 'user', {search_type: 'company-users', company_id, company_type}).then((response) => {
      this.items = get(response, 'json.companyUsers', []);
    });
  }

  _onSuggestionsFetchRequested({ value }) {
    const { identity } = this.props;

    const target = (value || '').trim().toLowerCase();
    this.setState({
      suggestions: this.items.filter((item) => {
        const login_name = item.login_name || '';
        return login_name && (!target || (login_name.toLowerCase().indexOf(target) > -1));
      }),
    });
  }  

  onSuggestionsClearRequested() {
    this.setState({ suggestions: [] });
  }

  render() {
    const { onSelected } = this.props;
    const { value, suggestions } = this.state;

    return <Autosuggest
      style={{
        display: 'inline-block'
      }}
      suggestions={suggestions} 
      onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
      onSuggestionsClearRequested={this.onSuggestionsClearRequested}
      getSuggestionValue={suggestion => suggestion.login_name}
      onSuggestionSelected={(event, { suggestion }) => {
        onSelected(suggestion);
      }}
      shouldRenderSuggestions={() => true}
      renderSuggestion={(suggestion)=> {
        return <div className="suggestion-item">{suggestion.login_name}</div>
      }}
      inputProps={{
        value,
        style: {width: '100%'},
        placeholder: 'Login Email',
        onChange: (event, {newValue}) => {
          this.setState({
            value: newValue,
          });
        },
      }}
    />
  }
}