import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getOrderByNumber } from '../selectors'
import { getClientOptions } from '../selectors/dropdowns'

import { createSpinnerPopup, closePopup } from '../actions/popup'
import { copyOrder } from '../actions/project'
import { createAddTemp } from '../actions/temp';

import Form from './Form'
import { OptionItemOptionRow, OptionItemCostRow } from './OptionItemDetailedSummary'

import { BASE_ZINDEX } from '../popup-factory'
import { getImageSrc, getIdentityUtils, titleCase, oauth } from '../utils'

const ORDER_TYPE_CONVERSIONS = {
  'PRESENTATION': [
    { key: 'PRESENTATION', value: 'Presentation' },
    { key: 'ESTIMATE', value: 'Estimate' },
    { key: 'SALES ORDER', value: 'Sales Order' },
    { key: 'SHOP', value: 'Shop' }
  ],
  'ESTIMATE': [
    { key: 'ESTIMATE', value: 'Estimate' },
    { key: 'PRESENTATION', value: 'Presentation' },
    { key: 'SALES ORDER', value: 'Sales Order' },
    { key: 'SHOP', value: 'Shop' }
  ],
  'SALES ORDER': [
    { key: 'SALES ORDER', value: 'Sales Order' },
    { key: 'ESTIMATE', value: 'Estimate' },
    { key: 'PRESENTATION', value: 'Presentation' },
    { key: 'SHOP', value: 'Shop' }
  ],
  'INVOICE' : [
    { key: 'INVOICE', value: 'Invoice' },
    { key: 'SHOP', value: 'Shop' }
  ]
};

class CopyOrderPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      client_id: this.props.order.client_id,
      order_type: this.props.upgrade_type || this.props.order.order_type,
      items: this.props.items.reduce((o, i) => { o[i.item_id] = true; return o; }, {}),
      all_products: true,
      accept_shop_charges: false
    };

    this.onFormRef = this.onFormRef.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.onCopyOrder = this.onCopyOrder.bind(this);
    this.onToggleItem = this.onToggleItem.bind(this);
    this.onToggleSelectAll = this.onToggleSelectAll.bind(this);
  }

  onFormRef(form) {
    this._form = form;
  }

  componentDidMount() {
    const _this = this
    oauth('GET', 'tenant/is-accepted-shop-charges', {}).then(({ json }) => {
      _this.setState({ accept_shop_charges: json.accept_shop_charges == 1 ? true : false })
    })
  }

  handleQuantityChange(e) {
    this.setState({ items: Object.assign({}, this.state.items, { [e.target.name]: e.target.value }) });
  }

  handleFieldChange(value, field) {
    if ('order_type' === field && 'SHOP' === value) {
      this.props.onCreateShop();
      this.props.onClosePopup();
      return;
    }

    if ('order_type' === field && 'PRESENTATION' === this.props.order.order_type) {
      if (['PRESENTATION', 'SHOP'].indexOf(value) !== -1 || ['PRESENTATION', 'SHOP'].indexOf(this.state.order_type) !== -1) {
        this.setState({ items: this.props.items.reduce((o, i) => { o[i.item_id] = null; return o; }, {}) });
      }
    }
    this.setState({ [field]: value });
  }

  onCopyOrder(values, errors) {
    if (!_.every(errors, error => error === null)) {
      return;
    }
    const items = this.props.order.items.filter(i => this.state.items[i.item_id] || 'SERVICE' === i.parent_type || 'SEPARATOR' === i.parent_type).map(i => true === this.state.items[i.item_id] || 'SERVICE' === i.parent_type || 'SEPARATOR' === i.parent_type ? i.item_id : { item_id: i.item_id, quantity: this.state.items[i.item_id] });
    this.props.onCopyOrder(Object.assign({}, values, { items }));
  }

  onToggleItem(item) {
    this.setState({ items: Object.assign({}, this.state.items, { [item.item_id]: !this.state.items[item.item_id] }) });
  }

  onToggleSelectAll() {
    const any_selected = Object.values(this.state.items).reduce((s, i) => s || i, false);
    this.setState({ all_products: !any_selected, items: Object.keys(this.state.items).reduce((o, i) => { o[i] = !any_selected; return o; }, {}) });
  }

  renderSelectItem(item, index) {
    const default_image = item.item_images.map(ii => ii.image)[0];
    return (
      <tr key={item.item_id} style={{ display: 'table', width: '100%' }}>
        <td style={{ width: '70px', textAlign: 'center' }}><input type="checkbox" checked={this.state.items[item.item_id]} onChange={e => this.onToggleItem(item)} /></td>
        <td><img style={{ maxWidth: '80px', width: '100%', float: 'left', marginRight: '0.1rem' }} src={getImageSrc(default_image)} /><span>{item.item_name}</span></td>
      </tr>
    );
  }

  renderUpgradeOption(item, index) {
    const visible_options = item.options.filter(o => o.hidden == 0);
    const options = visible_options.length ?
      visible_options.map(o =>
        <OptionItemOptionRow key={o.item_id} index={index + 1} base_item={item} option_item={o} convert={true} handleChangeQuantity={this.handleQuantityChange} />
      ) : [
        <OptionItemOptionRow key={item.item_id} index={index + 1} base_item={item} convert={true} handleChangeQuantity={this.handleQuantityChange} />
      ];
    const body_style = { borderTop: '1px solid #ddd' };
    return (
      <tbody key={item.item_id} style={{ width: '100%' }}>
        {options.concat(item.item_costs.map(ic =>
          <OptionItemCostRow key={ic.item_cost_id} convert={true} base_item={item} item_cost={ic} />
        ))}
      </tbody>
    );
  }

  renderUpgradePresentationHeader() {
    return (
      <thead style={{ display: 'table', width: '100%' }}>
        <tr>
          <th style={{ width: '10%' }}>Quantity</th>
          <th style={{ width: '60%' }} colSpan="2">Products</th>
          <th style={{ width: '10%' }}>Units</th>
          <th style={{ width: '10%' }}>Margin</th>
          <th style={{ width: '10%' }}>Amount</th>
        </tr>
      </thead>
    );
  }

  renderCopyHeader() {
    const { order } = this.props;
    const { all_products, order_type } = this.state;
    const all_selected = Object.values(this.state.items).reduce((s, i) => s && i, true);
    const upgrade_presentation = order.order_type === 'PRESENTATION' && order_type !== 'PRESENTATION' && order_type !== 'SHOP';
    return (
      <thead style={{ display: 'table', width: '100%' }}>
        <tr>
          <th style={{ width: '70px', textAlign: 'center', paddingTop: '12px', paddingBottom: '6px' }}>{!upgrade_presentation ? <input type="checkbox" name="check-all" checked={all_selected} onChange={this.onToggleSelectAll} /> : null}</th>
          <th>{all_products && !upgrade_presentation ? 'All ' : ''}Products</th>
        </tr>
      </thead>
    );
  }

  renderUpgradePresentation() {
    const { items } = this.props;
    const { order_type } = this.state;
    return [
      <table key="header" style={{ marginBottom: '0px' }}>
        {this.renderUpgradePresentationHeader()}
      </table>,
      <table key="body" style={{ overflowY: 'auto', maxHeight: '400px', display: 'block' }}>
        <thead key="fake-header">
          <tr style={{ height: '0px' }}>
            <th style={{ width: '10%', padding: 0 }}></th>
            <th style={{ width: '60%', padding: 0 }} colSpan="2"></th>
            <th style={{ width: '10%', padding: 0 }}></th>
            <th style={{ width: '10%', padding: 0 }}></th>
            <th style={{ width: '10%', padding: 0 }}></th>
          </tr>
        </thead>
        {items.filter(i => order_type === 'SHOP' ? i.copied_from !== 'sage-products' : i.item_id).map((i, index) => this.renderUpgradeOption(i, index))}
      </table>
    ];
  }

  renderCopy() {
    const { items } = this.props;
    const { all_products, order_type } = this.state;
    return (
      <table>
        {this.renderCopyHeader()}
        {!all_products ? 
          <tbody style={{ overflowY: 'auto', display: 'block', maxHeight: '400px' }}>
            {items.filter(i => order_type === 'SHOP' ? i.copied_from !== 'sage-products' : i.item_id).map((i, index) => this.renderSelectItem(i, index))}
          </tbody> : null}
      </table>
    );
  }

  render() {
    const { clients, order, items, identity, onClosePopup, index, upgrade_type, shop_type } = this.props;
    const { all_products, order_type } = this.state;
    const all_selected = Object.values(this.state.items).reduce((s, i) => s && i, true);
    const { hasCapabilities, hasUserFlags } = getIdentityUtils(identity);
    const order_types = hasCapabilities(['CREATE-SHOP'], true) && this.state.accept_shop_charges ? ORDER_TYPE_CONVERSIONS[order.order_type] : ORDER_TYPE_CONVERSIONS[order.order_type].filter(o => o.key !== 'SHOP');
    const upgrade_presentation = order.order_type === 'PRESENTATION' && order_type !== 'PRESENTATION' && order_type !== 'SHOP';
    const has_sage_products = items.filter(i => i.copied_from === 'sage-products').length > 0
    const show_client_select = shop_type !== 'marketing'

    return (
      <div id="convert-modal" className={'reveal ' + (!upgrade_presentation && all_products ? 'small' : 'large')} style={{ display: 'block', zIndex: BASE_ZINDEX + index, height: 'initial', overflow: 'visible', transition: 'width 0.1s, height 0.1s' }} data-reveal aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className ="row">
          <div className ="small-12 columns">
            <h3 id="modalTitle">Copy {titleCase(order.order_type)}</h3>
            <a className="alert button" style={{ position: 'fixed', right: '5.5rem', top: '1rem' }} onClick={e => onClosePopup()}>Cancel</a>
            <a className="button" style={{position: 'fixed', right: '1rem', top: '1rem' }} onClick={(e) => {e.preventDefault(); this._form && this._form.submit();}}>Copy</a>
          </div>
          {'INVOICE' === order_type ?
          <div className="small-12 columns" style={{ marginBottom: '1rem' }}>
            Copied invoices cannot have bills and commissions.  If you require bills and commissions, copy the sales order and upgrade it to an invoice.
          </div> : null}
        </div>
        <Form
          className="row popup-content form"
          ref={this.onFormRef}
          object={{ order_id: order.order_id, order_type }}
          onFieldChange={this.handleFieldChange}
          onSubmit={this.onCopyOrder}
          style={{ overflow: 'visible' }}
        >
          {!upgrade_type ? <Form.Select label="Type" field="order_type" value={order_type} options={order_types} required={true} disabled={upgrade_type === 'SHOP'} /> : null}
          {show_client_select ? <Form.Select label="Client" field="client_id" value={this.state.client_id} options={order_type === 'SHOP' ? [{ key: '', value: 'None' }].concat(clients) : clients} searchable={true} required={true} /> : null}
          <Form.TextInput label={order_type === 'SHOP' ? 'Shop Name' : 'Project Name'} field="job_name" value={this.state.job_name} required={true} placeholder={order_type === 'SHOP' ? 'New Shop Name' : 'New Project Name'} />
          {order_type === 'SHOP' && has_sage_products ? 
            <div className="row columns">
              <p style={{ color: 'red' }}>Note: All Sage products will not be converted as per Sage agreement with commonsku</p>
            </div>
          : null}
          <div className="small-12 columns popup-size-limit">
            {upgrade_presentation ? this.renderUpgradePresentation() : this.renderCopy()}
          </div>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const parent_type = 'PRESENTATION' === ownProps.order.order_type ? 'OPTION' : 'PRODUCT';
  const order = getOrderByNumber(state, { form_number: ownProps.order.form_number, order_type: ownProps.order.order_type });

  return {
    clients: getClientOptions(state),
    order, 
    items: order.items.filter(i => i.hidden == 0 && i.parent_type === parent_type),
    identity: state.identity
  } 
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onCopyOrder: ({ items, order_id, job_name, client_id, order_type }) => {
      dispatch(createSpinnerPopup('Copying Order...'));
      return dispatch(copyOrder(order_id, job_name, client_id, order_type, items, ownProps.shop_type)).then(
        () => dispatch(closePopup()),
      );
    }, 
    onClosePopup: () => {
      dispatch(closePopup());
    },
    onCreateShop: () => dispatch(createAddTemp('create_shop', ownProps.order))
  }
}


const ConnectedCopyOrderPopup = connect(mapStateToProps, mapDispatchToProps)(CopyOrderPopup);
export default ConnectedCopyOrderPopup;
