import React from 'react'
import { findDOMNode } from 'react-dom'

import { formatMoney, getImageSrc } from '../utils'
import BaseItemSummary from './BaseItemSummary'
import DraggableItem from './DraggableItem'
import DragItemTypes from '../DragItemTypes'
import Img from './Img'

class ProductItemSummary extends BaseItemSummary {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.root = findDOMNode(this);
  }

  componentDidUpdate() {
    this.root = findDOMNode(this);
  }

  renderActions() {
    return (
      <span className="actions">
        {['ESTIMATE', 'PRESENTATION', 'SHOP'].includes(this.props.order_type) ? this.renderToggleAction('hidden', 'hide', 'show') : null}
        {this.renderEditAction()}
        {this.renderCopyAction()}
        {this.renderCopyToOrderAction()}
        {this.renderDeleteAction()}
      </span>
    );
  }

  renderItem() {
    const { item } = this.props;
    const product_url = `/product.php?id=${item.parent_id}`;
    const vendor_url = `/vendor.php?id=${item.supplier_id}&division_id=${item.division_id}`;
    const phone = (item.division_phones || [])[0] ? item.division_phones[0] : null;
    const default_image = item.item_images.map(ii => ii.image)[0];
    return (
      <tr>
        <td className="description">
          <div className="image">
            <Img src={getImageSrc(default_image)} onMouseOver={this.handleMouseOver('item')} onMouseOut={this.handleMouseOut('item')} />
          </div>
          <a className="product-sku" href={product_url} target="_blank" onClick={e => e.stopPropagation()}>{item.item_sku}</a>
          <div><a className="product-vendor" href={vendor_url} target="_blank" onClick={e => e.stopPropagation()}>{item.division_name}</a>{phone && phone.phone_number ? ` (${phone.phone_number}${phone.phone_extension ? ' x' + phone.phone_extension : ''})` : null}</div>
          <span className="product-name">{item.item_name}</span>
          {this.renderActions()}
        </td>
        <td className="quantity">{parseInt(item.total_units, 10)}</td>
        <td className="margin">{parseFloat(item.total_margin).toFixed(2)}%</td>
        <td className="tax">{item.tax_amounts.map(t => t.label).reduce((taxes, tax) => `${taxes} ${tax}`, '')}</td>
        <td className="amount" onMouseOver={this.handleMouseOver('breakdown')} onMouseOut={this.handleMouseOut('breakdown')}>${formatMoney(item.total_subtotal)}</td>
      </tr>
    );
  }
}

const BaseProductItemSummary = ProductItemSummary;
export { BaseProductItemSummary };
export default DraggableItem(DragItemTypes.ITEM, ProductItemSummary);
