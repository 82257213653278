import { each, isEqual, bindAll } from 'lodash'
import React, { Component } from 'react'

class LazyRichTextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = { loaded: false, value: null };
    bindAll(this, ['onChange'], ['onBlur']);
  }

  componentDidMount() {
    import(/* webpackChunkName: "react-rte" */ 'react-rte').then(loaded => {
      const { value } = this.props;
      const { createValueFromString, createEmptyValue } = loaded;
      this.setState({ 
        loaded,
        value: value ? createValueFromString(value, 'html') : createEmptyValue(),
      });
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!isEqual(nextState, this.state)) {
      return true;
    }
    return nextProps.value !== this.props.value;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { loaded, value } = this.state;
    if (loaded && nextProps.value !== value.toString('html')) {
      this.setState({ 
        value: loaded.createValueFromString(nextProps.value, 'html'),
      });
    }
  }

  onChange(value) {
    const { onChange } = this.props;

    this.setState({ value }, () => {
      if (onChange) {
        onChange(value.toString('html'));
      }
    }); 
  }

  // react-rte is returning wrong objects from onBlur 
  // https://github.com/sstur/react-rte/issues/46
  onBlur() {
    const { onBlur } = this.props;

    if (onBlur) {
      onBlur(this.state.value.toString('html'));
    }
  }

  render() {
    const { value: _value, onChange, onBlur, ...props } = this.props;
    const { loaded, value } = this.state;
    if (!loaded || !value) {
      return null;
    }
    const { default: Component } = loaded;

    const toolbarConfig = {
      // Optionally specify the groups to display (displayed in the order listed).
      display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS'],
      INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Strikethrough', style: 'STRIKETHROUGH' },
        { label: 'Underline', style: 'UNDERLINE' }
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' }
      ]
    };

    return <Component {...props} toolbarConfig={toolbarConfig} value={value} onChange={this.onChange} onBlur={this.onBlur} />;
  }
}

export default LazyRichTextEditor;
