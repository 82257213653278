import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
  chain, values, first, get, map, find, keyBy, sortBy, toNumber, min, max, uniq, pickBy, throttle, bindAll,
  isUndefined, isEmpty, 
} from 'lodash';

import Select from './Select';
import Loading from './Loading';

import { formatMoney } from '../utils';
import { getInventoryLevels, getParts } from '../promostandards';
import { createFetchProduct } from '../actions/product';

const SIZE_CLASSES = {
  'OSFA': 0,
  '4XS': 1,
  '3XS': 2,
  '2XS': 3,
  'XS': 4,
  'S': 5,
  'M': 6,
  'L': 7,
  'XL': 8,
  '2XL': 9,
  '3XL': 10,
  '4XL': 11,
  '5XL': 12,
  'CUSTOM': 13
};

const sizeSort = (a, b) => (SIZE_CLASSES[a] || Object.keys(SIZE_CLASSES).length) - (SIZE_CLASSES[b] || Object.keys(SIZE_CLASSES).length);
const defaultSort = (a, b) => a < b ? -1 : (a > b ? 1 : 0);
const axisSort = {
  size: sizeSort
}
const COLUMN_CLASSES = {
  color: 0,
  size: 1,
  dimension: 2
};
const columnSort = (a, b) => (COLUMN_CLASSES[b] || Object.keys(COLUMN_CLASSES).length) - (COLUMN_CLASSES[a] || Object.keys(COLUMN_CLASSES).length);

const columnStyle = {
  color: {
    width: '20%'
  },
  size: {
    width: '20%'
  },
  dimension: {
    width: '20%'
  },
  description: {
    width: '20%'
  },
  sku: {
    width: '10%'
  },
  inventory: {
    width: '10%'
  },
  cost: {
    position: 'static',
    width: '20%'
  }
};

const CostMatrixTooltip = ({ base_costs, top }) => {
  const costs = sortBy(base_costs, 'min_quantity');
  return (
    <div className="tooltip cost-tooltip" style={{ top }}>
      <table style={{ width: '100%', margin: 0 }}>
        <tbody>
          <tr>
            <th>Quantity</th>
            {costs.map(c => <td key={c.min_quantity}>{c.min_quantity}</td>)}
          </tr>
          <tr>
            <th>Net Cost</th>
            {costs.map(c => <td key={c.min_quantity}>${formatMoney(c.price)}</td>)}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

class ScrollContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
    this.onScrollListener = throttle(this._onScrollListener, 300);
    bindAll(this, ['onScrollListener']);
  }

  componentDidMount() {
    this.scrollTarget.addEventListener('scroll', this.onScrollListener);
  }

  componentWillUnmount() {
    this.scrollTarget.removeEventListener('scroll', this.onScrollListener);
  }

  async _onScrollListener(event) {
    const { scrollThreshold = 0.8, onEndReached = () => {} } = this.props;
    const { loading } = this.state;
    const target = event.target;
    const bottom = target.scrollTop + target.clientHeight;
    if (
      !loading && 
      (
        target.scrollHeight - bottom < target.clientHeight * 3 &&
        (target.scrollHeight - bottom < target.clientHeight || target.scrollHeight * scrollThreshold < bottom)
      )
    ) {
      this.setState({ loading: true });
      await onEndReached();
      this.setState({ loading: false });
    }
  }

  render() {
    const { style = {}, children } = this.props;
    return <div ref={ref => this.scrollTarget = ref } style={{...style, overflowY: 'auto' }}>
      {children}
    </div>
  }
}

class SelectSku extends Component {

  constructor(props) {
    super(props);
    this.partRef = {};
    this.state = {
      offset: 0,
      count: 0,
      skus: [],
      filters: {},
      value: props.value || [],
      inventory: {},
      inventoryLoaded: false,
      hoverCost: null
    };

    ['toggleValue'].forEach(
      method => this[method] = this[method].bind(this)
    )
  }

  componentDidMount() {
    const { product, loadProduct } = this.props;
    if (!product) {
      loadProduct();
    } else {
      this.fetchParts();
      this.reloadInventory();
    }
  }

  componentDidUpdate(prevProps) {
    const { product_id, product, loadProduct } = this.props;
    if (product_id !== prevProps.product_id || product != prevProps.product) {
      if (!product) {
        loadProduct();
      } else {
        this.fetchParts(0);
        this.reloadInventory();
      }
    }
  }

  async fetchParts(offset) {
    const { product, currency_id } = this.props;
    // offset = isUndefined(offset) ? this.state.offset : offset;
    // if (offset === 0 || offset < this.state.count) {
    const { results, count } = await getParts({ 
      product: product.ext_product_id, currency: currency_id, 
      fields: JSON.stringify({sku: null, options: null, costs: null, description: null, id: null}),
      v: 1,
      // ordering: 'ps_part_id', limit: 20, offset,
    });

    const skus = values({
      ...keyBy(this.state.skus, 'id'),
      ...keyBy(results, 'id'),
    });
    this.setState({
      count,
      offset: offset + results.length,
      skus
    }, () => {
      if (skus.length === 1) {
        this.toggleValue(skus[0].sku);
      }
    })
    // }
  }

  async reloadInventory() {
    const { product } = this.props;
    const response = await getInventoryLevels({ productId: product.ext_product_id });
    const inventory = response.reduce(
      (o, p) => {
        const quantity = get(p, 'quantity_available.Quantity');
        return isEmpty(quantity) ? o : {
          ...o,
          [p.ps_part_id]: `${quantity.value} ${quantity.uom}`, 
        }
      },
      {}
    );
    this.setState({ inventory, inventoryLoaded: true });
  }

  toggleValue(sku) {
    const { multi = false, onSkusSelected = () => {} } = this.props;
    const { skus, value } = this.state;
    let newValue = [];
    if (isUndefined(sku)) {
      if (value.length !== skus.length) {
        newValue = map(skus, 'sku');
      }
    } else {
      newValue = multi 
        ? (value.includes(sku) ? value.filter(v => v !== sku) : value.concat(sku)) 
        : [sku]
      ;
    }
    newValue = skus.filter(sku => newValue.includes(sku.sku)).map(sku => sku.options.reduce(
      (t, o) => ({ ...t, [o.option_axis]: o.option_name }),
      { sku: sku.sku }
    )).sort(
      (a, b) => sizeSort(a.size, b.size)
    ).sort(
      (a, b) => defaultSort(a.color, b.color)
    ).map(
      sku => sku.sku
    );
  
    this.setState({ value: newValue });
    onSkusSelected(newValue);
  }

  render() {
    const { product, currency_id, multi = false, selectAll = false, maxHeight = '12rem' } = this.props;
    const { skus } = this.state;
    if (!product || isEmpty(skus)) {
      return (
        <div style={{ textAlign: 'center' }}>
          <Loading />
        </div>
      );
    }
    const { filters, value, inventory, inventoryLoaded, hoverCost } = this.state;
    
    const options = {};
    map(skus, (sku) => {
      map(sku.options, ({ option_axis, option_name }) => {
        if (option_axis === 'dimension') {
          return;
        }
        if (!options[option_axis]) {
          options[option_axis] = [];
        }
        options[option_axis].push(option_name);
      });
    });
    map(options, (v, k) => {
      options[k] = uniq(v);
    });
    
    const getCost = (sku) => {
      const costs = map(sku.costs[currency_id] || sku.costs['USD'] || first(values(sku.costs)), (price) => toNumber(price.price));
      const minCost = min(costs);
      const maxCost = max(costs);
      if (typeof minCost !== 'undefined') {
        if (maxCost > minCost) {
          return <span>${formatMoney(minCost)} &ndash; ${formatMoney(maxCost)}</span>;
        }
        return `$${formatMoney(minCost)}`;
      }
      return 'Unknown';
    };

    const onMouseEnterCost = sku => () => this.setState({ hoverCost: sku });
    const onMouseLeaveCost = () => this.setState({ hoverCost: null });

    const axes = Object.keys(options).sort(columnSort);
    const parts = chain(skus)
      .map(part => ({
        sku: part.sku,
        description: part.description,
        inventory: inventory[part.sku] || (inventoryLoaded ? 'Unknown' : 'Checking...'),
        cost: getCost(part),
        costs: part.costs[currency_id] || part.costs['USD'] || first(values(part.costs)),
        ...part.options.reduce(
          (o, op) => ({
            ...o,
            [op.option_axis]: op.option_name
          }),
          {}
        )
      }))
      .filter(pickBy(filters))
      .value()
      .sort((a, b) => axes.concat('sku').map(axis => (axisSort[axis] || defaultSort)(a[axis], b[axis])).reduce((t, s) => t || s, 0))
    ;

    const hasDescription = find(skus, 'description');
    const numCols = hasDescription ? axes.length + 4 : axes.length + 3;

    const selectButton = <button className="button select-sku" style={{ marginBottom: 0, float: 'right', width: multi ? '40%' : '50%' }}>{multi ? 'Add' : 'Select'}</button>;
    const selectedButton = <button className="button unselect-sku" style={{ marginBottom: 0, float: 'right', width: multi ? '40%' : '50%' }}>{multi ? '✓ Added' : '✓ Selected'}</button>;

    return (
      <table className="outer">
        <thead>
          <tr>
            {axes.map(a => <th key={a} style={columnStyle[a]}>
              <Select
                options={[{ key: undefined, value: 'All' }].concat(options[a].sort(axisSort[a]).map(o => ({ key: o, value: o })))}
                change={o => this.setState({ filters: { ...filters, [a]: o } })}
                value={filters[a]}
              />
            </th>)}
            {hasDescription && <th style={columnStyle.description}>&nbsp;</th>}
            <th style={columnStyle.sku}>&nbsp;</th>
            <th style={columnStyle.inventory}>&nbsp;</th>
            <th style={columnStyle.cost}>
              {(multi && selectAll) ? 
                <a className="button" style={{ float: 'right' }} onClick={() => {
                  this.toggleValue();
                }}>{value.length === skus.length ? 'Unselect All' : 'Select All'}</a> :
                <span>&nbsp;</span>
              }
            </th>
          </tr>
          <tr>
            {axes.map(a => <th key={a} style={{ ...columnStyle[a], textTransform: 'capitalize' }}>{a}</th>)}
            {hasDescription && <th style={columnStyle.description}>Description</th>}
            <th style={columnStyle.sku}>SKU</th>
            <th style={columnStyle.inventory}>Inventory</th>
            <th style={columnStyle.cost}>Net Cost</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td ref={ref => this.containerRef = ref} colSpan={numCols} style={{ position: 'relative', padding: 0 }}>
              <div style={{ maxHeight, overflowY: 'auto' }}>
                <table>
                  <tbody style={{ border: 'none' }}>
                    {parts.map(part =>
                      <tr
                        key={part.sku}
                        onClick={() => skus.length > 1 && this.toggleValue(part.sku)}
                      >
                        {axes.map(a => <td key={a} style={columnStyle[a]}>{part[a]}</td>)}
                        {hasDescription && <td style={columnStyle.description}>{part.description}</td>}
                        <td style={columnStyle.sku}>{part.sku}</td>
                        <td style={columnStyle.inventory}>{part.inventory}</td>
                        <td
                          ref={ref => this.partRef[part.sku] = ref} 
                          className="tooltip-target" style={columnStyle.cost}
                        >
                          <span
                            onMouseEnter={onMouseEnterCost(part.sku)}
                            onMouseLeave={onMouseLeaveCost} 
                            style={{ display: 'inline-block', marginTop: '0.5rem' }}
                          >
                            {part.cost}
                          </span>
                          {('Unknown' !== part.cost && hoverCost === part.sku) && 
                          <CostMatrixTooltip base_costs={part.costs} 
                            top={(this.partRef[part.sku].getBoundingClientRect().bottom - this.containerRef.getBoundingClientRect().top) + 'px'} 
                          />}
                          {value.includes(part.sku) ? selectedButton : selectButton}
                        </td>
                      </tr>
                    )}
                    {0 === parts.length && <tr>
                      <td colSpan={axes.length + 3}>No available parts</td>
                    </tr>}
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  product: ownProps.product || state.entities.products[ownProps.product_id]
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadProduct: () => dispatch(createFetchProduct(ownProps.product_id))
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectSku);



