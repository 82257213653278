import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BASE_ZINDEX } from '../popup-factory';

import { closePopup } from '../actions/popup';
import { createAddItemLocation } from '../actions';

import { getBasicItemLocations } from '../selectors/items';


class AddItemLocationPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      item_location_title: '',
      updating: false
    };

    ['handleChangeTitle', 'handleAddItemLocation', 'handleCancel'].forEach(
      method => this[method] = this[method].bind(this)
    );
  }

  handleChangeTitle(e) {
    e.preventDefault();
    this.setState({ item_location_title: e.target.value });
  }

  handleAddItemLocation(e) {
    e.preventDefault();
    const { dispatch, item_id } = this.props;
    const { item_location_title } = this.state;
    this.setState(
      { updating: true },
      () => dispatch(createAddItemLocation(item_id, null, null, null, null, item_location_title)).then(
        () => dispatch(closePopup())
      )
    );
  }

  handleCancel(e) {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(closePopup());
  }

  render() {
    const { index, num_locations } = this.props;
    const { item_location_title, updating } = this.state;
    return (
      <div className="reveal tiny" style={{ display: 'block', height: 'initial', zIndex: BASE_ZINDEX + index }}>
        <div className="row">
          <div className="small-12 columns">
            <h3>New Artwork Location</h3>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <div className="flex-label">
              <div className="number-label">{num_locations + 1}</div>
              <input
                type="text"
                value={item_location_title}
                onChange={this.handleChangeTitle}
                placeholder={`Location ${num_locations + 1}`}
              />
            </div>
          </div>
          <div className="small-12 columns" style={{ textAlign: 'center' }}>
            <button
              className="button large"
              disabled={item_location_title.length === 0 || updating}
              style={{ marginRight: '1rem' }}
              onClick={this.handleAddItemLocation}
            >
              {updating && <img src="/images/gears-small.gif" style={{ maxHeight: '1rem' }} />}
              Add
            </button>
            <button
              className="button large secondary"
              style={{ marginLeft: '1rem', opacity: 0.25 }}
              disabled={updating}
              onClick={this.handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  num_locations: getBasicItemLocations(state, ownProps).length
});

const ConnectedAddItemLocationPopup = connect(mapStateToProps)(AddItemLocationPopup);

export default ConnectedAddItemLocationPopup;
