import React from 'react';
import { connect } from 'react-redux';

import { BASE_ZINDEX } from '../popup-factory';

import { createAddThirdPartyShippingAccount } from '../actions/shipping';
import { closePopup } from '../actions/popup';

import Form from './Form';

class AddThirdPartyShippingAccountPopup extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      account_name: '',
      account_number: '',
      account_postal: ''
    };

    ['onClose', 'onCreateAccount', 'onFieldChange'].forEach(
      method => this[method] = this[method].bind(this)
    );
  }

  onClose() {
    this.props.dispatch(closePopup());
  }

  onCreateAccount() {
    const {
      account_name,
      account_number,
      account_postal
    } = this.state;

    const {
      courier_id,
      client_id,
      dispatch
    } = this.props;

    if (!account_name || !account_number || !account_postal) {
      return;
    }
    dispatch(createAddThirdPartyShippingAccount(client_id, courier_id, account_name, account_number, account_postal));
  }

  onFieldChange(value, field) {
    this.setState({ [field]: value });
  }

  render() {
    const { client_id, client_name, courier, distributor_name, index } = this.props;
    return (
      <div className="reveal" style={{ display: 'block', height: 'initial', overflow: 'visible', zIndex: BASE_ZINDEX + index }}>
        <div className="row">
          <div className="small-12 columns">
            <h3>Add Third Party Shipping Account<a style={{ fintSize: 'medium', float: 'right' }} href="https://commonsku.drift.help/article/3rd-party-shipping-accounts/?v=1571419634874" target="_blank"><i className="fi-info"></i></a></h3>
            <p>Provide account details for {client_name}'s {courier.courier_label} account.</p>
            <p>For {distributor_name} third party shipping accounts talk to your commonsku administrator</p>
          </div>
        </div>
        <Form className="row popup-content form"
          object={this.state}
          onFieldChange={this.onFieldChange}
        >
          <div className="small-12 columns">
            <div className="field row">
            </div>
            <Form.TextInput label="Account Name" field="account_name" required={true} />
            <Form.TextInput label="Account Number" field="account_number" required={true} />
            <Form.TextInput label="Postal / Zip Code" field="account_postal" required={true} />
          </div>
        </Form>
        <div className="row">
          <div className="small-12 columns">
            <a className="button" onClick={this.onCreateAccount} style={{ float: 'right', marginLeft: '1rem' }}>Create</a>
            <a className="button" onClick={this.onClose} style={{ float: 'right' }}>Cancel</a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const client_id = Object.values(state.entities.projects)[0].account_id;
  const client_name = state.entities.clients[client_id].client_name;
  const distributor_name = state.identity.company_name;
  const courier_id = state.entities.ship_methods[ownProps.ship_method_id].courier_id;
  const courier = state.entities.couriers[courier_id];
  return {
    courier_id,
    courier,
    client_id,
    client_name,
    distributor_name
  };
};

const ConnectedAddThirdPartyShippingAccountPopup = connect(mapStateToProps)(AddThirdPartyShippingAccountPopup);

export default ConnectedAddThirdPartyShippingAccountPopup;
