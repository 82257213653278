import React from 'react';

const Address = ({ address_company, address_line_1, address_line_2, address_line_3, address_line_4, address_city, address_state, address_postal, address_country, style, className }) => {
  const address = [address_company, address_line_1, address_line_2, address_line_3, address_line_4, [address_city, address_state, address_country].filter(a => a).join(', '), address_postal].filter(a => a);
  return (
    <p className={className} style={style}>
      {address.map((a, idx) => (idx === a.length - 1) ? <span key={idx}>{a}</span> : <span key={idx}>{a}<br/></span>)}
    </p>
  );
};

export default Address;
