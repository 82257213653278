import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getOrderByNumber } from '../selectors'

import { createSpinnerPopup, closePopup } from '../actions/popup'
import { upgradeToEstimate } from '../actions/convert'
import { upgradeToSalesOrder } from '../actions/convert'

import { getImageSrc } from '../utils'
import { BASE_ZINDEX } from '../popup-factory'

import { OptionItemOptionRow, OptionItemCostRow } from './OptionItemDetailedSummary'

class UpgradePresentationPopup extends Component {

  constructor(props) {
    super(props);

    this.state = this.getStateFromProps(props);
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.order !== prevProps.order) {
      this.setState(this.getStateFromProps(this.props))
    }
  }

  getStateFromProps(props) {
    return {
      items: props.order.items.filter(i => i.parent_type === 'OPTION' && i.hidden != 1),
      newItems : props.order.items.filter(
        i => i.parent_type !== 'OPTION' && i.parent_type !== 'PRODUCT' && i.hidden != 1
      ),
    };
  }

  handleChange(e) {
    this.setState({newItems: this.state.newItems.filter(i => i.item_id !== e.target.name).concat([{ item_id: e.target.name, quantity: e.target.value }])});
  }

  render() {
    const { order, target_type, onClosePopup, onUpgradeToEstimate, onUpgradeToSalesOrder, capabilities, index} = this.props;
    const newItems = this.state.newItems;
    const hidden = {
      display: 'none'
    }
    const body_style = { borderTop: '1px solid #ddd' };
    const hasCapability = capability => capabilities.includes(capability);
    return (
      <div id="convert-modal" className ="reveal large" style={{display: 'block', zIndex: BASE_ZINDEX + index}} data-reveal aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className ="row">
          <div className ="small-12 columns">
            <h3 id="modalTitle">Convert Presentation</h3>
            {target_type == 'ESTIMATE' && hasCapability('CREATE-ESTIMATE') ? <a className ="button" style={{ position: 'fixed', right: '1rem', top: '1rem' }} onClick={e => {e.preventDefault(); onUpgradeToEstimate(newItems, order.order_id, 'UPGRADE');}}>Convert to Estimate</a> : null}
            {target_type == 'SALES ORDER' && hasCapability('CREATE-SALES-ORDER') ? <a className ="button" style={{ position: 'fixed', right: '1rem', top: '1rem' }} onClick={e => {e.preventDefault(); onUpgradeToSalesOrder(newItems, order.order_id, 'UPGRADE-SALES-ORDER');}}>Convert to Sales Order</a> : null}
            <a className="alert button" style={{ position: 'fixed', right: 'ESTIMATE' === target_type ? '11rem' : '12.3rem', top: '1rem' }} onClick={e => onClosePopup()}>Cancel</a>
          </div>
        </div>
        <div className ="row popup-content">
          <div className ="small-12 columns popup-size-limit">
            <table className ="convert-products-table">
              <thead>
                <tr>
                  <th width="12%">Quantity</th>
                  <th colSpan="2">Products</th>
                  <th>Units</th>
                  <th>Margin</th>
                  <th>Amount</th>
                </tr>
              </thead>
              {this.state.items.map((item, index) => {
                const visible_options = item.options.filter(o => o.hidden != 1);
                const options = visible_options.length ? 
                  visible_options.map(o =>
                    <OptionItemOptionRow key={o.item_id} index={index + 1} base_item={item} option_item={o} convert={true} handleChangeQuantity={this.handleChange} />
                  ) : [<OptionItemOptionRow key={item.item_id} index={index} base_item={item} convert={true} handleChangeQuantity={this.handleChange} />];
                return <tbody key={index} style={body_style}>
                  {options.concat(item.item_costs.map(ic =>
                    <OptionItemCostRow key={ic.item_cost_id} convert={true} base_item={item} item_cost={ic} />
                  ))}</tbody>;
              })}
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const order = getOrderByNumber(state, { form_number: ownProps.form_number, order_type: 'PRESENTATION' })
  return {
    order,
    capabilities: state.identity.capabilities
  } 
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onUpgradeToEstimate: (items, parent_id, target_type) => {
      dispatch(createSpinnerPopup('Creating Estimate...'));
      return dispatch(upgradeToEstimate(items, parent_id, target_type, ownProps.job_id, ownProps.job_number)).then(
        () => {
          dispatch(closePopup());
          dispatch(closePopup());
        }
      );
    }, 
    onUpgradeToSalesOrder: (items, parent_id, target_type) => {
      dispatch(createSpinnerPopup('Creating Sales Order...'));
      return dispatch(upgradeToSalesOrder(items, parent_id, target_type, ownProps.job_id, ownProps.job_number)).then(
        () => {
          dispatch(closePopup());
          dispatch(closePopup());
        }
      );
    }, 
    onClosePopup: () => {
      dispatch(closePopup());
    },
  }
}

const ConnectedUpgradePresentationPopup = connect(mapStateToProps, mapDispatchToProps)(UpgradePresentationPopup);
export default ConnectedUpgradePresentationPopup;
