import React, { Component } from 'react'
import { connect } from 'react-redux'

import Select from './Select'
import TextArea from 'react-autosize-textarea'

import { getUserOptions } from '../selectors/dropdowns'

import { closePopup } from '../actions/popup'
import { createUpdateCommission, createAddCommission } from '../actions/commission'
import { formatMoney, isNumeric } from '../utils'

import { BASE_ZINDEX } from '../popup-factory'

class EditCommissionPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      commission: props.commission
    };

    this.handleChangeRep = this.handleChangeRep.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);
    this.handleChangeSource = this.handleChangeSource.bind(this);
    this.handleChangeNotes = this.handleChangeNotes.bind(this);
    this.handleChangeRate = this.handleChangeRate.bind(this);
    this.handleBlurRate = this.handleBlurRate.bind(this);
    this.handleChangeAmount = this.handleChangeAmount.bind(this);
    this.handleBlurAmount = this.handleBlurAmount.bind(this);
  }

  handleChangeRep(sales_rep_id) {
    this.setState(Object.assign({}, this.state, { commission: Object.assign({}, this.state.commission, { sales_rep_id }) }));
  }

  handleChangeType(commission_type) {
    this.setState(Object.assign({}, this.state, { commission: Object.assign({}, this.state.commission, { commission_type }) }));
  }

  handleChangeSource(commission_source) {
    const commission_rate = parseFloat(this.state.commission.commission_rate || 0).toFixed(2);
    const margin = 'MARGIN' === commission_source ? this.props.commission.total_margin_amount : this.props.commission.margin_subtotal;
    const commission_amount = formatMoney(parseFloat(commission_rate) * margin / 100);
    this.setState(Object.assign({}, this.state, { commission: Object.assign({}, this.state.commission, { commission_source, commission_source, commission_amount }) }));
  }

  handleChangeRate(e) {
    const commission_rate = e.target.value;
    if (!isNumeric(commission_rate || 0)) {
      return;
    }
    const margin = 'MARGIN' === this.state.commission.commission_source ? this.props.commission.total_margin_amount : this.props.commission.margin_subtotal;
    const commission_amount = formatMoney(parseFloat(commission_rate || 0) * margin / 100);
    
    this.setState(Object.assign({}, this.state, { commission: Object.assign({}, this.state.commission, { commission_rate, commission_amount }) }));
  }

  handleBlurRate(e) {
    const commission_rate = parseFloat(this.state.commission.commission_rate || 0).toFixed(2);
    const margin = 'MARGIN' === this.state.commission.commission_source ? this.props.commission.total_margin_amount : this.props.commission.margin_subtotal;
    const commission_amount = formatMoney(parseFloat(commission_rate) * margin / 100);
    
    this.setState(Object.assign({}, this.state, { commission: Object.assign({}, this.state.commission, { commission_rate, commission_amount }) }));
  }

  handleChangeAmount(e) {
    const commission_amount = e.target.value;
    if (!isNumeric(commission_amount || 0)) {
      return;
    }
    const margin = 'MARGIN' === this.state.commission.commission_source ? this.props.commission.total_margin_amount : this.props.commission.margin_subtotal;
    const commission_rate = formatMoney(parseFloat(commission_amount || 0) * 100 / margin);
    this.setState(Object.assign({}, this.state, { commission: Object.assign({}, this.state.commission, { commission_rate, commission_amount }) }));
  }

  handleBlurAmount(e) {
    const commission_amount = formatMoney(this.state.commission.commission_amount || 0);
    const margin = 'MARGIN' === this.state.commission.commission_source ? this.props.commission.total_margin_amount : this.props.commission.margin_subtotal;
    const commission_rate = formatMoney(parseFloat(commission_amount) * 100 / margin);
    this.setState(Object.assign({}, this.state, { commission: Object.assign({}, this.state.commission, { commission_rate, commission_amount }) }));
  }

  handleChangeNotes(e) {
    const commission_notes = e.target.value;
    this.setState(Object.assign({}, this.state, { commission: Object.assign({}, this.state.commission, { commission_notes }) }));
  }

  render() {
    const { onClosePopup, users, onAddCommission, onUpdateCommission, index } = this.props;
    const commission = this.state.commission;
    const type_options = [
      { key: 'ORDER', value: 'Order Rep' },
      { key: 'CLIENT', value: 'Client Rep' },
      { key: 'COMBINED', value: 'Client + Order Rep' }
    ];
    const source_options = [
      { key: 'MARGIN', value: 'Margin' },
      { key: 'SUBTOTAL', value: 'Subtotal' }
    ];
    return (
      <div id="commission-modal" className="reveal large" style={{display: 'block', zIndex: BASE_ZINDEX + index}} aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="small-12 columns">
          <h3>{commission.commission_id === undefined ? 'Add' : 'Update'} Commission</h3>
        </div>
        <div className="row columns popup-content">
          <div className="small-12 columns popup-content">
            <div className="row">
              <div className="small-6 columns"><strong>Commissionable Subtotal</strong></div>
              <div className="small-6 columns">${formatMoney(commission.margin_subtotal)}</div>
            </div>
            <div className="row">
              <div className="small-6 columns"><strong>Margin Amount</strong></div>
              <div className="small-6 columns">${formatMoney(commission.total_margin_amount)}</div>
            </div>
            <div className="row">
              <div className="small-6 columns"><strong>Sales Rep</strong></div>
              <div className="small-6 columns"><Select value={commission.sales_rep_id} options={users} change={this.handleChangeRep} /></div>
            </div>
            <div className="row">
              <div className="small-6 columns"><strong>Type</strong></div>
              <div className="small-6 columns"><Select value={commission.commission_type} options={type_options} change={this.handleChangeType} /></div>
            </div>
            <div className="row">
              <div className="small-6 columns"><strong>Source</strong></div>
              <div className="small-6 columns"><Select value={commission.commission_source} options={source_options} change={this.handleChangeSource} /></div>
            </div>
            <div className="row">
              <div className="small-6 columns"><strong>Rate</strong></div>
              <div className="small-6 columns"><div className="input-group"><input type="text" className="text-right input-group-field" value={commission.commission_rate} onChange={this.handleChangeRate} onBlur={this.handleBlurRate} /><span className="input-group-label">%</span></div></div>
            </div>
            <div className="row">
              <div className="small-6 columns"><strong>Amount</strong></div>
              <div className="small-6 columns"><div className="input-group"><span className="input-group-label">$</span><input type="text" className="text-right input-group-field" value={commission.commission_amount} onChange={this.handleChangeAmount} onBlur={this.handleBlurAmount} /></div></div>
            </div>
            <div className="row">
              <div className="small-6 columns"><strong>Notes</strong></div>
              <div className="small-6 columns"><TextArea value={commission.commission_notes} onChange={this.handleChangeNotes} ></TextArea></div>
            </div>
            <div className="row">
              <div className="small-12 columns">
                <a className="button" style={{width: '100%'}} onClick={e => { e.preventDefault(); commission.commission_id === undefined ? onAddCommission(commission) : onUpdateCommission(commission);}} >{commission.commission_id === undefined ? 'Add' : 'Update'}</a>
              </div>
            </div>
          </div>
        </div>
        <button className="close-button" aria-label="Close modal" type="button" onClick={e => {e.preventDefault(); onClosePopup();}}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: getUserOptions(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateCommission: commission => {
      dispatch(createUpdateCommission(commission));
      dispatch(closePopup());
    },
    onAddCommission: commission => {
      dispatch(createAddCommission(commission));
      dispatch(closePopup());
    },
    onClosePopup: () => {
      dispatch(closePopup());
    }
  };
};

const ConnectedEditCommissionPopup = connect(mapStateToProps, mapDispatchToProps)(EditCommissionPopup);
export default ConnectedEditCommissionPopup;
