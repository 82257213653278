import _ from 'lodash'
import { createSelector } from 'reselect'

const getAccounts = state => state.entities.accounts;
const getAddresses = state => state.entities.addresses;
const getBillItemTypes = state => state.entities.bill_item_types;
const getClients = state => state.entities.clients;
const getCommissions = state => state.entities.commissions;
const getContacts = state => state.entities.contacts;
const getCurrencies = state => state.entities.currencies;
const getDepartments = state => state.entities.departments;
const getDivisions = state => state.entities.divisions;
const getEventTypes = state => state.entities.event_types;
const getEmailTemplates = state => state.entities.email_templates;
const getFeedback = state => state.entities.feedback;
const getFiles = state => state.entities.files;
const getFolders = state => state.entities.folders;
const getImprints = state => state.entities.imprints;
const getIndustries = state => state.entities.industries;
const getMessages = state => state.entities.messages;
const getOrders = state => state.entities.orders;
const getPurchaseOrders = state => state.entities.purchase_orders;
const getProjects = state => state.entities.projects;
const getProofs = state => state.entities.proofs;
const getServices = state => state.entities.services;
const getShipMethods = state => state.entities.ship_methods;
const getStatuses = state => state.entities.statuses;
const getAccountStatuses = state => state.entities.account_statuses;
const getSupplierAccounts = state => state.entities.supplier_accounts;
export const getTags = state => state.entities.tags;
const getTaggedResources = state => state.entities.tagged_resources;
const getTaxes = state => state.entities.taxes;
const getTerms = state => state.entities.terms;
const getThemes = state => state.entities.themes;
const getUsers = state => state.entities.users;
const getSuppliers = state => state.entities.suppliers;
const getCategories = state => state.entities.categories;
const getTenants = state => state.entities.tenants;
const getJobTemplates = state => state.entities.job_templates;
const getShopTemplates = state => state.entities.shop_templates;
const getSkus = state => state.entities.skus;
const getTeams = state => state.entities.teams;
const getCouriers = state => state.entities.couriers;
const getThirdPartyShippingAccounts = state => state.entities.third_party_shipping_accounts;

const getAllAddressList = state => state.dropdowns.addresses;
const getBillItemTypeList = state => state.dropdowns.bill_item_types;
const getClientList = state => state.dropdowns.clients;
const getAllContactList = state => state.dropdowns.contacts;
const getCurrencyList = state => state.dropdowns.currencies;
const getDepartmentList = state => state.dropdowns.departments;
const getEventTypeList = state => state.dropdowns.event_types;
const getEmailTemplateList = state => state.dropdowns.email_templates;
const getFolderList = state => state.dropdowns.folders;
const getHeaderList = state => state.dropdowns.headers;
const getImprintList = state => state.dropdowns.imprints;
const getIndustryList = state => state.dropdowns.industries;
const getProofList = state => state.dropdowns.proofs;
const getServiceList = state => state.dropdowns.services;
const getAllShipMethodList = state => state.dropdowns.ship_methods;
const getSupplierAccountList = state => state.dropdowns.supplier_accounts;
const getTagList = state => state.dropdowns.tags;
const getProductTagList = state => _.uniq(Object.values(state.entities.tagged_resources).filter(v => v.resource_type === 'PRODUCT').sort((a, b) => {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
}).map(v => v.tag_id));
const getTaxList = state => state.dropdowns.taxes;
const getTermsList = state => state.dropdowns.terms;
const getThemeList = state => state.dropdowns.themes;
const getUserList = state => state.dropdowns.users;
const getPersonalDivisionList = state => state.dropdowns.divisions.personal;
const getCompanyDivisionList = state => state.dropdowns.divisions['supplier-direct'];
const getEspDivisionList = state => state.dropdowns.divisions.esp;
const getDistributorCentralDivisionList = state => state.dropdowns.divisions.dc;
const getTaggedDivisionList = state => state.dropdowns.divisions.tagged;
const getSageDivisionList = state => state.dropdowns.divisions.sage;
const getPromostandardsDivisionList = state => state.dropdowns.divisions.promostandards;
const getBookmarkDivisionList = state => state.dropdowns.divisions.bookmark;
const getAllDivisionList = state => state.dropdowns.divisions;
const getPresentationStatusList = state => state.dropdowns.statuses.PRESENTATION;
const getEstimateStatusList = state => state.dropdowns.statuses.ESTIMATE;
const getShopStatusList = state => state.dropdowns.statuses.SHOP;
const getSalesOrderStatusList = state => state.dropdowns.statuses['SALES ORDER'];
const getInvoiceStatusList = state => state.dropdowns.statuses.INVOICE;
const getPurchaseOrderStatusList = state => state.dropdowns.statuses['PURCHASE ORDER'];
const getBillStatusList = state => state.dropdowns.statuses.BILL;
const getCollectionStatusList = state => state.dropdowns.statuses.COLLECTION;
const getAllStatusList = state => state.dropdowns.statuses;
const getAllOrderList = state => state.dropdowns.orders;
const getClientId = (state, ownProps) => ownProps.client_id;
const getIgnoreOrderId = (state, ownProps) => ownProps.order_id;
const getOrderList = createSelector(getClientId, getIgnoreOrderId, getAllOrderList, (client_id, order_id, orders) => orders[client_id] ? orders[client_id].filter(o => o !== order_id) : undefined);
const getSupplierList = state => state.dropdowns.suppliers;
const getDivisionContactsList = state => state.dropdowns.division_contacts;
const getCategoriesList = state => state.dropdowns.categories;
const getJobTemplatesList = state => state.dropdowns.job_templates;
const getShopTemplatesList = state => state.dropdowns.shop_templates;
const getAllSkuList = state => state.dropdowns.product_skus;
const getTeamList = state => state.dropdowns.teams;
const getCourierList = state => state.dropdowns.couriers;
const getThirdPartyShippingAccountList = state => state.dropdowns.third_party_shipping_accounts;

const getOrderType = (state, ownProps) => ownProps.order_type;
const getStatusList = createSelector(getOrderType, getAllStatusList, (order_type, statuses) => statuses[order_type]);
const getAccountStatusList = state => state.dropdowns.account_statuses;
const getSupplierType = (state, ownProps) => ownProps.supplier_type;
const getDivisionList = createSelector(getSupplierType, getAllDivisionList, (supplier_type, divisions) => divisions[supplier_type]);
const getAddressParent = (state, ownProps) => ownProps.parent_id;
const getAddressList = createSelector(getAddressParent, getAllAddressList, (parent_id, addresses) => addresses[parent_id]);
const getContactParent = (state, ownProps) => ownProps.parent_id;
const getContactList = createSelector(getContactParent, getAllContactList, (parent_id, contacts) => contacts[parent_id]);
const getTenantList = state => state.dropdowns.tenants;
const getShipMethodParent = (state, ownProps) => ownProps.parent_id;
const getShipMethodList = createSelector(getShipMethodParent, getAllShipMethodList, (parent_id, ship_methods) => ship_methods[parent_id]);

const getProductId = (state, ownProps) => ownProps.product_id;
const getProductSkuList = createSelector(getProductId, getAllSkuList, (product_id, skus) => skus[product_id]);

const fillDropdown = (values, details) => {
  if (undefined === values) {
    return [];
  }
  return values.map(k => details[k]).filter(v => v !== undefined);
}

export const getAddressDropdown = createSelector(getAddressList, getAddresses, fillDropdown);
export const getBillItemTypeDropdown = createSelector(getBillItemTypeList, getBillItemTypes, fillDropdown);
export const getClientDropdown = createSelector(getClientList, getClients, fillDropdown);
export const getContactDropdown = createSelector(getContactList, getContacts, fillDropdown);
export const getCurrencyDropdown = getCurrencyList;
export const getDepartmentDropdown = createSelector(getDepartmentList, getDepartments, fillDropdown);
export const getEventTypeDropdown = createSelector(getEventTypeList, getEventTypes, fillDropdown);
export const getEmailTemplateDropdown = createSelector(getEmailTemplateList, getEmailTemplates, fillDropdown);
export const getFolderDropdown = createSelector(getFolderList, getFolders, fillDropdown);
export const getHeaderDropdown = createSelector(getHeaderList, getFiles, fillDropdown);
export const getImprintDropdown = createSelector(getImprintList, getImprints, fillDropdown);
export const getIndustryDropdown = createSelector(getIndustryList, getIndustries, fillDropdown);
export const getProofDropdown = createSelector(getProofList, getProofs, fillDropdown);
export const getShipMethodDropdown = createSelector(getShipMethodList, getShipMethods, fillDropdown);
export const getSupplierAccountDropdown = createSelector(getSupplierAccountList, getSupplierAccounts, fillDropdown);
export const getTagDropdown = createSelector(getTagList, getTags, fillDropdown);
export const getProductTagDropdown = createSelector(getProductTagList, getTags, fillDropdown);
export const getTaxDropdown = createSelector(getTaxList, getTaxes, fillDropdown);
export const getTermsDropdown = createSelector(getTermsList, getTerms, fillDropdown);
export const getThemeDropdown = createSelector(getThemeList, getThemes, fillDropdown);
export const getUserDropdown = createSelector(getUserList, getUsers, fillDropdown);
export const getPresentationStatusDropdown = createSelector(getPresentationStatusList, getStatuses, fillDropdown);
export const getEstimateStatusDropdown = createSelector(getEstimateStatusList, getStatuses, fillDropdown);
export const getShopStatusDropdown = createSelector(getShopStatusList, getStatuses, fillDropdown);
export const getSalesOrderStatusDropdown = createSelector(getSalesOrderStatusList, getStatuses, fillDropdown);
export const getInvoiceStatusDropdown = createSelector(getInvoiceStatusList, getStatuses, fillDropdown);
export const getPurchaseOrderStatusDropdown = createSelector(getPurchaseOrderStatusList, getStatuses, fillDropdown);
export const getBillStatusDropdown = createSelector(getBillStatusList, getStatuses, fillDropdown);
export const getCollectionStatusDropdown = createSelector(getCollectionStatusList, getStatuses, fillDropdown);
export const getOrderDropdown = createSelector(getOrderList, getOrders, (values, details) => { if (undefined === values) { return undefined; } return values.map(k => details[k]).filter(v => v !== undefined); });
export const getStatusDropdown = createSelector(getStatusList, getStatuses, fillDropdown);
export const getAccountStatusDropdown = createSelector(getAccountStatusList, getAccountStatuses, fillDropdown);
export const getServiceDropdown = createSelector(getServiceList, getServices, fillDropdown);
export const getDivisionDropdown = createSelector(getDivisionList, getDivisions, fillDropdown);
export const getPersonalDivisionDropdown = createSelector(getPersonalDivisionList, getDivisions, fillDropdown);
export const getCompanyDivisionDropdown = createSelector(getCompanyDivisionList, getDivisions, fillDropdown);
export const getEspDivisionDropdown = createSelector(getEspDivisionList, getDivisions, fillDropdown);
export const getSageDivisionDropdown = createSelector(getSageDivisionList, getDivisions, fillDropdown);
export const getPromostandardsDivisionDropdown = createSelector(getPromostandardsDivisionList, getDivisions, fillDropdown);
export const getBookmarkDivisionDropdown = createSelector(getBookmarkDivisionList, getDivisions, fillDropdown);
export const getDistributorCentralDivisionDropdown = createSelector(getDistributorCentralDivisionList, getDivisions, fillDropdown);
export const getTaggedDivisionDropdown = createSelector(getTaggedDivisionList, getDivisions, fillDropdown);
export const getSupplierDropdown = createSelector(getSupplierList, getSuppliers, fillDropdown);
export const getDivisionContactsDropdown = createSelector(getDivisionContactsList, getContacts, fillDropdown);
export const getCategoryDropdown = createSelector(getCategoriesList, getCategories, fillDropdown);
export const getTenantDropdown = createSelector(getTenantList, getTenants, fillDropdown);
export const getJobTemplateDropdown = createSelector(getJobTemplatesList, getJobTemplates, fillDropdown);
export const getShopTemplateDropdown = createSelector(getShopTemplatesList, getShopTemplates, fillDropdown);
export const getProductSkuDropdown = createSelector(getProductSkuList, getSkus, fillDropdown);
export const getTeamDropdown = createSelector(getTeamList, getTeams, fillDropdown);
export const getCourierDropdown = createSelector(getCourierList, getCouriers, fillDropdown);
export const getThirdPartyShippingAccountDropdown = createSelector(getThirdPartyShippingAccountList, getThirdPartyShippingAccounts, fillDropdown);

export const getAddressOptions = createSelector(getAddressDropdown, values => values.map(v => ({ key: v.address_id, value: v.address_name })));
export const getBillItemTypeOptions = createSelector(getBillItemTypeDropdown, values => values.map(v => ({ key: v.bill_item_type_id, value: v.bill_item_type_name })));
export const getClientOptions = createSelector(getClientDropdown, values => values.map(v => ({ key: v.client_id, value: v.client_name })));
export const getContactOptions = createSelector(getContactDropdown, values => values.map(v => ({ key: v.contact_id, value: `${v.contact_first_name} ${v.contact_last_name}` })));
export const getContactEmailOptions = createSelector(getContactDropdown, values => values.filter(v => v.contact_email).map(v => ({ key: v.contact_id, value: `${v.contact_first_name} ${v.contact_last_name} <${v.contact_email}>` })));
export const getCurrencyOptions = createSelector(getCurrencyDropdown, values => values.map(v => ({ key: v, value: v })));
export const getDepartmentOptions = createSelector(getDepartmentDropdown, values => values.map(v => ({key: v.department_id, value: v.department_name})));
export const getEmailTemplateOptions = createSelector(getEmailTemplateDropdown, values => values.map(v => ({ key: v.email_template_id, value: v.email_template_title })));
export const getEventTypeOptions = createSelector(getEventTypeDropdown, values => values.map(v => ({ key: v.event_type_id, value: v.event_type })));
export const getFolderOptions = createSelector(getFolderDropdown, values => values.map(v => ({ key: v.folder_id, value: v.folder_name })));
export const getImprintOptions = createSelector(getImprintDropdown, values => values.map(v => ({ key: v.imprint_id, value: v.imprint_name })));
export const getIndustryOptions = createSelector(getIndustryDropdown, values => values.map(v => ({ key: v.industry_id, value: v.industry_name })));
export const getJobTemplateOptions = createSelector(getJobTemplateDropdown, values => values.map(v => ({ key: v.job_template_id, value: v.job_template_name })));
export const getShopTemplateOptions = createSelector(getShopTemplateDropdown, values => values.map(v => ({ key: v.shop_template_id, value: v.shop_template_name })));
export const getOrderOptions = createSelector(getOrderDropdown, values => values ? values.map(v => ({ key: v.order_id, value: `${v.form_number} - ${v.job_name}`})) : values);
export const getProofOptions = createSelector(getProofDropdown, values => values.map(v => ({ key: v.proof_id, value: v.proof_name })));
export const getShipMethodOptions = createSelector(getShipMethodDropdown, values => values.map(v => ({ key: v.ship_method_id, value: v.ship_method_name })));
export const getStatusOptions = createSelector(getStatusDropdown, values => values.map(v => ({ key: v.status_id, value: v.status_name })));
export const getAccountStatusOptions = createSelector(getAccountStatusDropdown, values => values.map(v => ({ key: v.account_status_id, value: v.account_status_name })));
export const getSupplierAccountOptions = createSelector(getSupplierAccountDropdown, values => values.map(v => ({ key: v.division_id, value: v.division_name })));
export const getTagOptions = createSelector(getTagDropdown, values => values.map(v => ({ key: v.tag_id, value: v.label })));
export const getProductTagOptions = createSelector(getProductTagDropdown, values => values.map(v => ({ key: v.tag_id, value: v.label })));
export const getTaxOptions = createSelector(
  getTaxDropdown, 
  values => {
    const isDefaultEmptyTax = ({ label, editable }) => {
      return label === 'E' && editable === '0';
    }
    return values
      .sort((v1, v2) => {
        if (isDefaultEmptyTax(v1)) {
          return -1;
        }
        if (isDefaultEmptyTax(v2)) {
          return 1;
        }
        return (parseFloat(v1.percent) || 0) - (parseFloat(v2.percent) || 0);
      }).map(v => {
        return ({ 
          key: v.tax_id, value: `${!!v.zip2tax_id ? v.description : v.label} (${parseFloat(v.percent)}%)` 
        })
      });
  }
);
export const getTermsOptions = createSelector(getTermsDropdown, values => values.map(v => ({ key: v.terms_id, value: v.terms_name })));
export const getThemeOptions = createSelector(getThemeDropdown, values => values.map(v => ({ key: v.theme_id, value: v.theme_name })));
export const getUserOptions = createSelector(getUserDropdown, values => values.map(v => ({ key: v.user_id, value: `${v.contact_first_name} ${v.contact_last_name}` })));
export const getUserContactOptions = createSelector(getUserDropdown, values => values.map(v => ({ key: v.default_contact_id, value: `${v.contact_first_name} ${v.contact_last_name}` })));
export const getServiceOptions = createSelector(getServiceDropdown, values => values.map(v => ({ key: v.service_id, value: v.service_name})));
export const getDivisionOptions = createSelector(getDivisionDropdown, values => values.map(v => ({ key: v.division_id, value: v.division_name })));
export const getPersonalDivisionOptions = createSelector(getPersonalDivisionDropdown, values => values.map(v => ({ key: v.division_id, value: v.division_name })));
export const getCompanyDivisionOptions = createSelector(getCompanyDivisionDropdown, values => values.map(v => ({ key: v.division_id, value: v.division_name })));
export const getEspDivisionOptions = createSelector(getEspDivisionDropdown, values => values.map(v => ({ key: v.ext_division_id, value: v.division_name, division_id: v.division_id, company_id: v.company_id })));
export const getSageDivisionOptions = createSelector(getSageDivisionDropdown, values => values.map(v => ({ key: v.division_id, value: v.division_name, division_id: v.division_id, company_id: v.company_id })));
export const getPromostandardsDivisionOptions = createSelector(getPromostandardsDivisionDropdown, values => values.map(v => ({
  key: v.division_id, value: v.division_name, division_id: v.division_id, company_id: v.company_id, ext_division_id: v.ext_division_id,
  default_currency_id: v.default_currency_id,
})));
export const getBookmarkDivisionOptions = createSelector(getBookmarkDivisionDropdown, values => values.map(v => ({ key: v.division_id, value: v.division_name })));
export const getDistributorCentralDivisionOptions = createSelector(getDistributorCentralDivisionDropdown, values => values.map(v => ({ key: v.ext_division_id, value: v.division_name, division_id: v.division_id, company_id: v.company_id })));
export const getTaggedDivisionOptions = createSelector(getTaggedDivisionDropdown, values => values.map(v => ({ key: v.division_id, value: v.division_name })));
export const getSupplierOptions = createSelector(getSupplierDropdown, values => values.map(v => ({ key: v.supplier_id, value: v.supplier_name })));
export const getPSSuppliersOptions = createSelector(getSupplierDropdown, values => values.map(v => ({ key: v.promo_api_name, value: v.division_name })));
export const getSupplierCollaborateOptions = createSelector(getSupplierDropdown, values => values.filter(v => v.on_collaborate == 1).map(v => ({ key: v.supplier_id, value: v.supplier_name })));
export const getSupplierCollectionOptions = createSelector(getSupplierDropdown, values => values.filter(v => v.has_collections == 1).map(v => ({ key: v.supplier_id, value: v.supplier_name })));
export const getDivisionContactsOptions = createSelector(getDivisionContactsDropdown, values => values.filter(v => v.contact_first_name != 'commonsku').map(v => ({ key: v.contact_email, value: `${v.contact_first_name} ${v.contact_last_name}`  })));
export const getCategoryOptions = createSelector(getCategoryDropdown, values => values.map(v => ({ key: v.category_id, value: v.category_name })));
export const getUserContactImages = createSelector(getUserDropdown, getFiles, (users, files) => users.reduce((o, v) => { o[v.default_contact_id] = files[v.user_image]; return o; }, {}));
export const getUserContactSignatures = createSelector(getUserDropdown, users => users.reduce((o, v) => { o[v.default_contact_id] = v.user_email_signature; return o; }, {}));
export const getEmailTemplateTexts = createSelector(getEmailTemplateDropdown, values => values.reduce((o, v) => { o[v.email_template_id] = v.email_template_text; return o; }, {}));
export const getTenantOptions = createSelector(getTenantDropdown, values => values.map(v => ({ key: v.tenant_id, value: v.tenant_name })));
export const getProductSkuOptions = createSelector(getProductSkuDropdown, values => values.map(v => ({ key: v.product_sku_id, value: v.sku })));
export const getTeamOptions = createSelector(getTeamDropdown, values => values.map(v => ({ key: v.team_id, value: v.team_name, label: v.team_name })));
export const getCourierOptions = createSelector(getCourierDropdown, values => values.map(v => ({ key: v.courier_id, value: v.courier_label })));
export const getThirdPartyShippingAccountOptions = createSelector(getThirdPartyShippingAccountDropdown, value => values.map(v => ({ key: v.third_party_account_id, value: `${v.account_name} ${v.account_number} (${v.account_postal})` })));