import React, { Component } from 'react';
import { connect } from 'react-redux';
import BaseSelect, { Async } from 'react-select';

import { createFinalizeAddTax } from '../actions/tax';
import { getCompanyData } from '../selectors';
import { getTaxOptions } from '../selectors/dropdowns';
import { oauth, isNumeric } from '../utils';

class TaxSelect extends Component {

  constructor(props) {
    super(props);

    this.state = { tax_results: [] };

    this.loadTaxes = this.loadTaxes.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    if(this.props.value && this.props.zip2tax) {
      this.onChange({ value: this.props.value })
    }
  }

  /* UNSAFE_componentWillReceiveProps(nextProps) {
    if(this.props.base_taxes !== nextProps.base_taxes) {
      this.forceUpdate()
    }
  } */

  loadTaxes(input) {
    const { base_taxes, value } = this.props;
    const { tax_results } = this.state;
    const taxes = base_taxes.concat(tax_results.map(t => ({ value: t.tax_id, label: `${t.description} (${parseFloat(t.percent)}%)` })));
    if (!input || !input.length) {
      return Promise.resolve({ options: taxes.filter(t => t.value === value || t.label === 'Exempt (0%)' || t.label === 'Tax Included') });
    }
    return oauth('GET', 'tax', { zip2tax: input }).then(
      ({ json }) => {
        this.setState({ tax_results: json.taxes });
        return {
          options: base_taxes.filter(t => t.label === 'Exempt (0%)' || t.label === 'Tax Included').concat(json.taxes.map(t => ({ value: t.tax_id, label: `${t.description} (${parseFloat(t.percent)}%)` })))
        };
      }
    );
  }

  onChange(e) {
    const { onAddTax, onChange } = this.props;
    const tax_details = this.state.tax_results.filter(t => e.value === t.tax_id)[0];
    if (tax_details) {
      return onAddTax(tax_details).then(() => onChange(e.value));
    } else {
      onChange(e.value);
    }
  }

  render() {
    const {
      value,
      disabled,
      base_taxes,
      zip2tax = false
    } = this.props;

    if (zip2tax) {
      const taxes = base_taxes.concat(this.state.tax_results.map(t => ({ value: t.tax_id, label: `${t.description} (${parseFloat(t.percent)}%)` })));
      return (
        <Async
          value={value}
          cache={false}
          clearable={false}
          options={taxes.filter(t => t.value === value || t.label === 'Exempt (0%)' || t.label === 'Tax Included')}
          disabled={disabled}
          loadOptions={this.loadTaxes}
          filterOptions={(options, filter, currentValues) => { return options; }}
          onChange={this.onChange}
        />
      );
    }
    return (
      <BaseSelect
        value={value}
        clearable={false}
        options={base_taxes}
        disabled={disabled}
        onChange={this.onChange}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  base_taxes: ownProps.tax_included ? [{ value: '', label: 'Tax Included' }].concat(getTaxOptions(state).map(t => ({ value: t.key, label: t.value }))) : getTaxOptions(state).map(t => ({ value: t.key, label: t.value }))
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAddTax: tax => Promise.resolve(dispatch(createFinalizeAddTax(tax)))
});

export default connect(mapStateToProps, mapDispatchToProps)(TaxSelect);
