import React, { Component } from 'react';
import { connect } from 'react-redux'

import { BASE_ZINDEX } from '../popup-factory';

import { closePopup } from '../actions/popup';

import { oauth, parseMysqlDate } from '../utils'

class PartialPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
        table_header: [],
        item_breakdowns: [],
        loading: true
    }
  }

  componentDidMount() {
    this.fetchPartialCSVView();
  }

  fetchPartialCSVView() {
    const data = {
      fetch_partial: true,
      order_ids: this.props.order_ids
    }

    const _this = this
    oauth('POST', 'order', data).then(({ json }) => {
      _this.setState({ item_breakdowns: json.item_breakdowns, table_header: json.table_header, loading: false })
    });
  }

  renderLoading() {
    return (
      <div className='row popup-content column'>
          <div className='small-12 columns'>
              <div className='small-12 text-center'><br /><img src='/images/gears.gif' /><br /></div>
          </div>
      </div>
    )
  }

  renderRow(lb, index) {
    return (
      <tr key={index}>
        {Object.values(lb).map((v, i) => 
          <td key={i} style={[7, 8, 9, 11, 12].indexOf(i) !== -1 ? { textAlign: 'right' } : null}>{i == 0 ? parseMysqlDate(v) : v}</td>
        )}
      </tr>
    )
  }

  render() {
    const { order_ids, onClosePopup, index } = this.props;

    return (
      <div className="reveal large" style={{ display: 'block', zIndex: BASE_ZINDEX + index, height: 'initial', overflow: 'auto', marginTop: '50px', maxWidth: '100rem' }}>
        <div className="row small-12 columns">
          <h3 style={{ textAlign: 'center' }}>Checkouts</h3>
           <a className='alert button' style={{ position: 'fixed', right: '1rem', top: '1rem' }} onClick={e => onClosePopup()}>Close</a>
        </div>
        {this.state.loading ?
          this.renderLoading()
        :
          <table style={{ fontSize: '12px' }}>
            <thead>
              <tr>
                {this.state.table_header.map((k, i) =>
                  <td key={i} style={[7, 8, 9, 11, 12].indexOf(i) !== -1 ? { textAlign: 'right' } : null}>{k}</td>
                )}
              </tr>
            </thead>
            <tbody>
              {this.state.item_breakdowns.map((lb, index) =>
                  this.renderRow(lb, index)
              )}
            </tbody>
          </table>
        }
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onClosePopup: () => {
      dispatch(closePopup());
    }
  }
}

const ConnectedPartialPopup = connect(null, mapDispatchToProps)(PartialPopup);
export default ConnectedPartialPopup;

