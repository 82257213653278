import React from 'react'

const BasicDropdownMenu = ({ options, position }) => {
   const style = Object.assign({}, {position: 'absolute'}, position);
   return (
    <div className="dropdown-menu" style={style}>
      <ul>
        {options.map(option =>
          <li key={option.key} onClick={option.onClick}>
            {option.value}
          </li>
        )}
      </ul>
    </div>
  );
}

export default BasicDropdownMenu;
