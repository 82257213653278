import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import Form from './Form'

import { getSupplierAccountOptions, getImprintDropdown, getDivisionOptions } from '../selectors/dropdowns'

import { createAddProduct } from '../actions/project'
import { closePopup, createUploadProductImagePopup, createAddSupplierPopup } from '../actions/popup'
import { createDeleteTemp } from '../actions/temp'

import { BASE_ZINDEX } from '../popup-factory'

import { NEW_SUPPLIER_DIVISION_ID } from './AddSupplierPopup'

class AddNewProductPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      show_more_details: false,
      show_pricing: false,
      division_id: props.identity.company_type === 'SUPPLIER' && props.identity.divisions.length === 1 ? props.identity.divisions[0].division_id : props.new_division_id,
      imprints: [],
      colors: [],
      sizes: [],
      file_error: false
    }

    _.bindAll(this, ['onFormRef', 'hasCapability', 'handleAddMoreDetails', 'handleAddPricing', 'onAddProduct', 'handleKeyPress', 'onAddValue', 'handleFieldChange']);
  }

  onFormRef(form) {
    this._form = form;
  }

  handleFieldChange(value, field) {
    this.setState({ [field]: value });
  }

  hasCapability(capability) {
    return this.props.identity.capabilities.includes(capability);
  }

  handleAddMoreDetails(e) {
    e.preventDefault();
    this.setState({ show_more_details: true });
  }

  handleAddPricing(e) {
    e.preventDefault();
    this.setState({ show_pricing: true });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.new_division_id) {
      this.setState({ division_id: nextProps.new_division_id });
    }
    if (nextProps.file_blob) {
      this.setState({ file_error: false });
    }
  }

  onAddProduct(values, errors) {
    const {
      order_id,
      file_blob,
      order_type
    } = this.props;

    const file = (file_blob && typeof file_blob === 'object' && file_blob[0].size) ?
      file_blob[0] :
      (typeof file_blob === 'string') ?
      file_blob :
      null;

    if (!file) {
      this.setState({ file_error: true });
      return;
    }

    if (!_.every(errors, (error) => error === null)) {
      return;
    }

    const pricing = _.range(1, 7).map(k =>
     ({ quantity: this.state['quantity' + k], cost: this.state['cost' + k] })
    ).filter(v =>
      _.isInteger(parseInt(v.quantity, 10))
    ).reduce((o, p, index) => {
      o['quantity' + (index + 1)] = p.quantity;
      o['cost' + (index + 1)] = p.cost;
      return o;
    }, {});

    this.props.onAddProduct(
      order_id,
      this.state.colors,
      values.division_id ? values.division_id : this.state.division_id,
      this.state.imprints,
      _.size(pricing) ? { [this.state.division_id]: pricing } : {},
      values.product_additional_information,
      values.product_description,
      values.product_name,
      values.product_packaging,
      values.product_supplier_code,
      values.product_url,
      this.state.sizes,
      file,
      order_type,
      this.props.identity.company_type === 'SUPPLIER' ? true : false
    );
  }

  handleKeyPress(field) {
    return e => {
      if ('Enter' === e.key) {
        this.setState({[field]: ''});
        this.onAddValue(field)(e.target.value);
      }
    };
  }

  onAddValue(field) {
    return value => {
      if (!value) {
        return;
      }
      switch (field) {
        case 'color':
          this.setState({ colors: _.uniq(this.state.colors.concat(value)) });
          break;
        case 'size':
          this.setState({ sizes: _.uniq(this.state.sizes.concat(value)) });
          break;
        case 'imprint_id':
          this.setState({ imprints: _.uniq(this.state.imprints.concat(value)) });
          break;
      };
    }
  }

  renderMoreDetails() {
    const imprint_names = this.props.imprints.filter(i => this.state.imprints.includes(i.key)).map(i => i.value);
    return [
      <Form.TextInput key="product_url" label="Product Webpage" field="product_url" value={this.state.product_url} />,
      <Form.Textarea key="product_additional_information" label="Product Additional Information" field="product_additional_information" value={this.state.product_additional_information} />,
      <Form.Textarea key="product_packaging" label="Product Packaging" field="product_packaging" value={this.state.product_packaging} />,
      <Form.Select key="imprint_id" label="Imprint Type" field="imprint_id" options={[{key: '', value: ''}].concat(this.props.imprints)} value={this.state.imprint_id} onChange={this.onAddValue('imprint_id')} />,
      imprint_names.length ?
      <div key="imprints" className="row">
        {imprint_names.map((i, idx) =>
          <div key={idx} className="small-12 columns">
            <a>{i}</a>
          </div>
        )}
      </div> : null,
      <Form.TextInput key="color_text" label="Product Colors" field="color" value={this.state.color} onKeyPress={this.handleKeyPress('color')} />,
      this.state.colors.length ?
      <div key="colors" className="row">
        {this.state.colors.map((c, idx) =>
          <div key={idx} className="small-12 columns">
              <a>{c}</a>
          </div>
        )}
      </div> : null,
      <Form.TextInput key="size_text" label="Product Sizes" field="size" value={this.state.size} onKeyPress={this.handleKeyPress('size')} />,
      this.state.sizes.length ?
      <div key="sizes" className="row">
        {this.state.sizes.map((s, idx) =>
          <div key={idx} className="small-12 columns">
              <a>{s}</a>
          </div>
        )}
      </div> : null,
    ];
  }

  renderPricing() {
    return (
      <table style={{width: '100%'}}>
        <tbody>
          <tr>
            <th>Qty:</th>
            <td>
              <Form.TextInput field="quantity1" displayLabel="false" containerClassName="field" className="" value={this.state.quantity1} />
            </td>
            <td>
              <Form.TextInput field="quantity2" displayLabel="false" containerClassName="field" className="" value={this.state.quantity2} />
            </td>
            <td>
              <Form.TextInput field="quantity3" displayLabel="false" containerClassName="field" className="" value={this.state.quantity3} />
            </td>
            <td>
              <Form.TextInput field="quantity4" displayLabel="false" containerClassName="field" className="" value={this.state.quantity4} />
            </td>
            <td>
              <Form.TextInput field="quantity5" displayLabel="false" containerClassName="field" className="" value={this.state.quantity5} />
            </td>
            <td>
              <Form.TextInput field="quantity6" displayLabel="false" containerClassName="field" className="" value={this.state.quantity6} />
            </td>
          </tr>
          <tr>
            <th>Net:</th>
            <td>
              <Form.TextInput field="cost1" displayLabel="false" containerClassName="field" className="" value={this.state.cost1} />
            </td>
            <td>
              <Form.TextInput field="cost2" displayLabel="false" containerClassName="field" className="" value={this.state.cost2} />
            </td>
            <td>
              <Form.TextInput field="cost3" displayLabel="false" containerClassName="field" className="" value={this.state.cost3} />
            </td>
            <td>
              <Form.TextInput field="cost4" displayLabel="false" containerClassName="field" className="" value={this.state.cost4} />
            </td>
            <td>
              <Form.TextInput field="cost5" displayLabel="false" containerClassName="field" className="" value={this.state.cost5} />
            </td>
            <td>
              <Form.TextInput field="cost6" displayLabel="false" containerClassName="field" className="" value={this.state.cost6} />
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  render() {
    const { order_id, order_type, suppliers, imprints, onAddProduct, onCreateUploadProductImagePopup, onAddNewSupplier, onClosePopup, file_blob, index, identity, divisions } = this.props;
    const can_add_supplier = this.hasCapability('CREATE-UPSTREAM-ACCOUNT');
    const add_style = { marginLeft: '0.2rem', lineHeight: 0.65, verticalAlign: 'top', padding: '0.85em' };
    const select_supplier_style = can_add_supplier ? { width: 'calc(100% - 44px)', display: 'inline-block' } : {};
    const handleClickAddSupplier = e => {
      e.preventDefault();
      onAddNewSupplier();
    };
    const add_button = can_add_supplier ? <a className="button hollow" style={add_style} onClick={handleClickAddSupplier}>+</a> : null;

    const handleCancel = e => {
      e.preventDefault();
      onClosePopup();
    };

    const handleSave = e => {
      e.preventDefault();
      this._form.submit();
    };

    return (
      <div id="replace-header-modal" className="reveal large" style={{display: 'block', zIndex: BASE_ZINDEX + index}} aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3 id="modalTitle">Add New Product</h3>
          </div>
        </div>
        <div className="row popup-content">
          <Form
            className="small-12 columns popup-size-limit"
            ref={this.onFormRef}
            onFieldChange={this.handleFieldChange}
            onSubmit={this.onAddProduct}
          >
            <div className="collapse row">
              <div className="small-12 medium-6 columns">
                {identity.company_type === 'TENANT' ? 
                  <Form.Select label="Supplier" field="division_id" style={select_supplier_style} options={suppliers} value={this.state.division_id} required={true} extra={add_button} /> 
                : identity.company_type === 'SUPPLIER' && identity.divisions.length > 1 ?
                  <Form.Select label="Supplier" field="division_id" style={select_supplier_style} options={identity.divisions.map(d => ({ key: d.division_id, value: d.division_name }))} value={this.state.division_id} required={true} />
                : null}
                <Form.TextInput label="Product Supplier Code" field="product_supplier_code" value={this.state.product_supplier_code} required={true} />
                <Form.TextInput label="Product Name" field="product_name" value={this.state.product_name} required={true} />
                <Form.Textarea label="Product Description" field="product_description" value={this.state.product_description} required={true} />
                {this.state.show_more_details ? this.renderMoreDetails() : null}
              </div>
              <div className="small-12 medium-6 columns">
                {this.state.file_error ? <div className="field-error" style={{color: 'red', textAlign: 'right'}}>Product image required</div> : null}
                <div className="art-placeholder" onClick={onCreateUploadProductImagePopup} style={{textAlign: 'center'}}>
                  {file_blob && typeof file_blob === 'object' ? 
                    (file_blob[0].preview ? 
                      <img src={file_blob[0].preview} /> :
                      <img src={`${S3_URL}/404.png`} />)
                  : typeof file_blob === 'string' ? 
                    <img src={file_blob} />
                  : 'Upload Image'}
                </div>
              </div>
            </div>
            {this.state.show_pricing ? this.renderPricing() : null}
            <div style={{ marginTop: '1rem', marginBottom: '2rem' }} className="row">
              {!this.state.show_more_details ? 
                <div className="small-12 medium-6 columns" style={{ textAlign: 'center' }}><a onClick={this.handleAddMoreDetails}>+add more details</a></div>
              : null}
              {!this.state.show_pricing ? 
                <div className="small-12 medium-6 columns" style={{ textAlign: 'center' }}><a onClick={this.handleAddPricing}>+add pricing</a></div>
              : null}
            </div>
          </Form>
        </div>
        <a className ="button alert" style={{position: 'absolute', right: '5rem', top: '1rem'}}  onClick={handleCancel}>Cancel</a>
        <a className ="button" style={{position: 'absolute', right: '1rem', top: '1rem'}}  onClick={handleSave}>Save</a>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const file_blob = state.temp.TEMP_NEW_PRODUCT_IMAGE;
  const new_division_id = state.temp[NEW_SUPPLIER_DIVISION_ID];
  const new_props = {...ownProps, supplier_type: 'supplier-direct'}

  return {
    identity: state.identity,
    suppliers: getSupplierAccountOptions(state),
    imprints: getImprintDropdown(state).filter(i => !i.ext_artwork_id).map(i => ({ key: i.imprint_id, value: i.imprint_name })),
    new_division_id,
    file_blob,
    divisions: getDivisionOptions(state, new_props),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onAddProduct: (order_id, colors, division_id, imprints, pricing, product_additional_information, product_description, product_name, product_packaging, product_supplier_code, product_url, sizes, file, order_type, is_supplier) =>
      dispatch(createAddProduct(order_id, colors, division_id, imprints, pricing, product_additional_information, product_description, product_name, product_packaging, product_supplier_code, product_url, sizes, file, order_type, is_supplier)).then(() => {
      dispatch(closePopup());
      dispatch(createDeleteTemp('TEMP_NEW_PRODUCT_IMAGE'));
      return dispatch(createDeleteTemp(NEW_SUPPLIER_DIVISION_ID));
    }),
    onCreateUploadProductImagePopup: () => {
      dispatch(createUploadProductImagePopup());
    },
    onAddNewSupplier: () => dispatch(createAddSupplierPopup(false)),
    onClosePopup: () => Promise.resolve(dispatch(closePopup())).then(() => {
      dispatch(createDeleteTemp(NEW_SUPPLIER_DIVISION_ID));
      dispatch(createDeleteTemp('TEMP_NEW_PRODUCT_IMAGE'));
    })
  }
}

const ConnectedAddNewProductPopup = connect(mapStateToProps, mapDispatchToProps)(AddNewProductPopup);
export default ConnectedAddNewProductPopup;

