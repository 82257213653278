let localConfig;
try {
  localConfig = require('./config.local').default;
} catch (e) {
  localConfig = {};
}

const config = {
  // also used in web/js/distributor_promostandards.js
  promostandards_api_url: 'https://promo.commonsku.com/api',
  inboundEmail: {
    integrationDomain: 'commonskudev.com',
    whitelist: [
      //'vandelay', 'rightsleeve', 'metronbranding',
    ],
  },
  ...localConfig,
};

export default config;
