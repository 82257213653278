import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getImageSrc } from '../utils';

import { getGalleryOrderByShopId } from '../selectors';

import { createLoadOrder } from '../actions/order';
import { createUpdateShopTemplateData } from '../actions/shop';
import { closePopup } from '../actions/popup'

import { BASE_ZINDEX } from '../popup-factory'

import Img from './Img'

class SelectFeaturedShopItemPopup extends Component {

  UNSAFE_componentWillMount() {
    const { gallery: { loaded, order_id }, loadOrder } = this.props;
    if (!loaded) {
      loadOrder(order_id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { gallery: { loaded, order_id }, loadOrder } = nextProps;
    if (!loaded && order_id !== this.props.order.order_id) {
      loadOrder(order_id);
    }
  }

  renderLoading() {
    const { onClosePopup, index } = this.props;

    return (
      <div className="main-content">
        <h3>Products</h3>
        <div className="row small-12 small-centered columns">
          <img style={{ marginTop: '2rem' }} src="/images/gears.gif" />
        </div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div className="small-12 small-centered columns">
        <img style={{ marginTop: '2rem' }} src="/images/gears.gif" />
      </div>
    );
  }

  renderImages() {
    const { gallery, onSelectItem } = this.props;
    return gallery.items.filter(i => 'OPTION' === i.parent_type).map(i => 
      <div key={i.item_id} className="small-6 medium-3 large-2 columns end" style={{ cursor: 'pointer' }} onClick={e => onSelectItem(i.item_id)}>
        <Img src={getImageSrc(i.images[0], 'medium')}/>
      </div>
    );
  }

  render() {
    const { onClosePopup, index, gallery, loaded } = this.props;

    return (
      <div id="replace-header-modal" className="reveal large" style={{display: 'block', zIndex: BASE_ZINDEX + index}} aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3 id="modalTitle">Select Featured Product</h3>
          </div>
        </div>
        <div className="row popup-content">
          {gallery.loaded ? this.renderImages() : this.renderLoading()}
        </div>
        <button className="close-button" aria-label="Close modal" type="button" onClick={e => {e.preventDefault(); onClosePopup();}}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  gallery: getGalleryOrderByShopId(state, { shop_id: ownProps.shop_id })
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadOrder: order_id => dispatch(createLoadOrder(order_id)),
  onClosePopup: () => dispatch(closePopup()),
  onSelectItem: item_id => {
    dispatch(createUpdateShopTemplateData(ownProps.shop_id, ownProps.shop_template_id, ownProps.name, item_id));
    return dispatch(closePopup());
  }
});

const ConnectedSelectFeaturedShopItemPopup = connect(mapStateToProps, mapDispatchToProps)(SelectFeaturedShopItemPopup);
export default ConnectedSelectFeaturedShopItemPopup;

