import React, { Component } from 'react';
import { connect } from 'react-redux';

import { BASE_ZINDEX } from '../popup-factory';

import { closePopup } from '../actions/popup';

const DEFAULT_COLOR = '#5ca3b6';

class IntroPopup extends Component {

  render() {
    const { introduction, onClosePopup, template_color = DEFAULT_COLOR, index, shop_contact } = this.props;

    return (
      <div className="shop shop-intro-popup" style={{ zIndex: BASE_ZINDEX + index, position: 'absolute' }}>
        <div id="popup-contact">
          <div className="contact">
            <div className="contact-container">
              <div className="row" style={{ maxHeight: '90vh', width: '90vw' }}>
                <div className="small-12 medium-6 large-8 columns" style={{ background: 'white', maxHeight: '90vh', overflow: 'auto', minHeight: '240px' }}>
                {introduction && introduction.replace(/<(?:.|\n)*?>/gm, '') !== '' ? 
                  <div dangerouslySetInnerHTML={{ __html: introduction }} />
                : null}
                </div>
                <div className="small-12 medium-6 large-4 columns contact-info" style={{ maxHeight: 'none' }}>
                  <a className="close-order" onClick={onClosePopup} style={{backgroundColor: template_color }}>Start Shopping</a>
                  <p>If you have any questions, please contact:</p>
                  <p><strong>{shop_contact.contact_name}</strong></p>
                  <p><a style={{color: template_color}} href={`mailto:${shop_contact.contact_email}`}>{shop_contact.contact_email}</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClosePopup: () => dispatch(closePopup())
});

const ConnectedIntroPopup = connect(null, mapDispatchToProps)(IntroPopup);
export default ConnectedIntroPopup;

