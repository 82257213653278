import _ from 'lodash'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import { closePopup } from '../actions/popup'
import { createUploadFile } from '../actions/file'

import { Row, Col, Button, LabeledCheckbox, Progress, Input, Theme, Popup, TabBar, Tab, Dropzoned } from '@commonsku/styles';

import { BASE_ZINDEX } from '../popup-factory'

class UploadFilesPopup extends Component {

  constructor(props) {
    super(props)

    this.state = {
      uploaded_files: [],
      add_to_client_library: false,
      url: '',
      selectedTab: 'computer',
      all_uploaded: true
    }

    _.bindAll(this, ['handleUpload', 'markFilesAsClientFiles', 'handleClose', 'showProgress'])

    this.handleUpload = this.handleUpload.bind(this)
  }

  checkIfAllUploaded() {
    const progresses = Object.assign({}, this.state)
    delete progresses.uploaded_files
    delete progresses.add_to_client_library
    delete progresses.url
    delete progresses.selectedTab
    delete progresses.all_uploaded

    if(Object.values(progresses).filter(p => p != 100 && typeof p !== 'string').length) {
      this.setState({ all_uploaded: false })
    }else{
      this.setState({ all_uploaded: true })
    }
  }

  handleUpload(entities, type) {
    const { onCreateUploadFile, parent_id, parent_type } = this.props
    const { uploaded_files } = this.state

    if(type === 'url') {
      const file = {
        name: entities
      }
      this.setState({
        uploaded_files: uploaded_files.concat([file])
      }, function() {
        let counter = this.state.uploaded_files.length - 1
        onCreateUploadFile(parent_id, parent_type, entities, this.showProgress, counter).then(action => {
          if(action.type === 'UPLOAD_FILE_FAILURE') {
            this.setState({ [`${entities}-${counter}`]: 'The file failed to upload.' })
          }
        })
        this.setState({ url: '' })
      })
    }else{
      this.setState({
        uploaded_files: uploaded_files.concat(entities)
      }, function() {
        let counter = this.state.uploaded_files.length - 1
        _.each(entities, (file) => {
          onCreateUploadFile(parent_id, parent_type, file, this.showProgress, counter).then(action => {
            if(action.type === 'UPLOAD_FILE_FAILURE') {
              this.setState({ [`${file.name}-${counter}`]: 'The file failed to upload.' })
            }
          })
          counter++
        })
      })
    }
  }

  showProgress(value, file_name, index) {
    this.setState({ [`${file_name}-${index}`]: value }, function() {
      if(value == 100) {
        this.checkIfAllUploaded()
      }
    })
  }

  markFilesAsClientFiles() {
    const { add_to_client_library } = this.state

    this.setState({ add_to_client_library: !add_to_client_library })
  }

  handleUploadClientFiles() {
    const { onCreateUploadFile, client_id } = this.props
    const { uploaded_files } = this.state

    _.each(uploaded_files, (file, key) => {
      if(this.state[`${file.name}-${key}`] == 100) {
        onCreateUploadFile(client_id, 'CLIENT', file)
      }
    })
  }

  handleClose() {
    const { onClose, resku, onClosePopup } = this.props
    const { add_to_client_library } = this.state

    if(add_to_client_library) {
      this.handleUploadClientFiles()
    }

    if(resku) {
      onClosePopup()
    }else{
      onClose()
    }
  }

  renderFileRow(file, index) {
    return (
      <Row key={index} style={{ marginBottom: '2rem' }}>
        <Col xs={4}>{file.name}</Col>
        <Col xs={8}>
          {typeof this.state[`${file.name}-${index}`] === 'number' ? 
            <Progress value={this.state[`${file.name}-${index}`]} max={100} />
          : 
            <Progress error={this.state[`${file.name}-${index}`]} />
          }
        </Col>
      </Row>
    )
  }

  renderTabs() {
    const { selectedTab } = this.state
    const tabs = [
      {
        key: 'computer',
        label: 'From Computer'
      },
      {
        key: 'web',
        label: 'From Web'
      }
    ]

    return (
      <Row>
        <TabBar>
          {tabs.map((tab, index) => <Tab key={index}
            selected={tab.key == selectedTab}
            onClick={() => {this.setState({ selectedTab: tab.key })}}>
            {tab.label}
          </Tab>)}
        </TabBar>
      </Row>
    )
  }

  renderTabContent() {
    const { selectedTab } = this.state

    if(selectedTab === 'web') {
      return this.renderFromWeb();
    }

    return this.renderFromComputer();
  }

  renderFromComputer() {
    //onDragOver is needed to stop react-dnd from interacting with react-dropzone
    //https://github.com/react-dnd/react-dnd/issues/457
    return (
      <Row style={{ paddingTop: '5px' }} onDragOver={e=> e.stopPropagation()}>
        <Dropzoned
          placeholder="Drag or click to upload file" 
          onDrop={files => this.handleUpload(files, 'file')}
        />
      </Row>
    )
  }

  renderFromWeb() {
    const { url } = this.state

    return (
      <Row> 
        <Col xs={12} sm={8}>
          <Input 
            placeholder="http://www..." 
            value={url} 
            onChange={e => this.setState({ url: e.target.value })}
            style={{ marginTop: '15px' }}
          />
          <br />
        </Col>     
        <Col xs={12} sm={4}>
          <Button 
            onClick={e => {this.handleUpload(url, 'url')}}
            disabled={url === ''}>Select</Button>
          <br />
        </Col>
      </Row>
    )
  }

  renderAddToClientLibrary() {
    const { add_to_client_library } = this.state

    return (
      <Row style={{ float: 'right' }}>
        <LabeledCheckbox label="Add to client library" 
          checked={add_to_client_library}
          onChange={e => this.markFilesAsClientFiles()} />
      </Row>
    )
  }

  renderPopupContent() {
    const { uploaded_files } = this.state
    const { client_id, parent_type, web_upload } = this.props

    return (
      <Fragment>
        {web_upload ? this.renderTabs() : null}
        {this.renderTabContent()}
        <br />
        <Fragment>
          {uploaded_files.map((file, index) => 
            this.renderFileRow(file, index)
          )}
        </Fragment>
        {client_id && parent_type !== 'USER' && uploaded_files.length ? 
          this.renderAddToClientLibrary()
        : null}
      </Fragment>
    )
  }

  render() {
    const { parent_type, resku, index } = this.props
    const { all_uploaded } = this.state

    if(resku) {
      return (
        <div className="commonsku-styles reveal large" style={{ display: 'block', zIndex: BASE_ZINDEX + index, height: 'initial', overflow: 'visible', transition: 'width 0.1s, height 0.1s' }} data-reveal aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
          <div className ="row">
            <div className ="small-12">
              <h3 id="modalTitle">Upload {parent_type === 'ITEM' ? 'Images' : 'Files'}</h3>
              <a className="button" style={{ position: 'fixed', right: '1rem', top: '1rem' }} 
                onClick={e => {this.handleClose()}}
                disabled={!all_uploaded}>Done</a>
            </div>
          </div>
          {this.renderPopupContent()}
        </div>
      )
    }

    return (
      <Theme>
        <Popup 
          style={{ padding: '5px 25px' }} 
          title={parent_type === 'ITEM' ? 'Upload Image' : 'Upload File'}
          controls={<Button onClick={this.handleClose} disabled={!all_uploaded}>Done</Button>}>
            {this.renderPopupContent()}
        </Popup>
      </Theme>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onClosePopup: () => {
      dispatch(closePopup());
    },
    onCreateUploadFile: (parent_id, parent_type, file, progress_cb, index) => {
      if(progress_cb) {
        return dispatch(createUploadFile(parent_id, parent_type, file, null, progress_cb, index))
      }
      return dispatch(createUploadFile(parent_id, parent_type, file))
    }
  }
}


const ConnectedUploadFilesPopup = connect(null, mapDispatchToProps)(UploadFilesPopup);
export default ConnectedUploadFilesPopup;
