import { createSelector } from 'reselect';
import { formatMoney, getUserImageSrc, recalculateMargin, applyMargin } from '../utils';

const SIZE_CLASSES = {
  'OSFA': 0,
  'ONESIZE': 0,
  'N/A': 0,
  '4XS': 1,
  '3XS': 2,
  '2XS': 3,
  'XS': 4,
  'XSMALL': 4,
  'S': 5,
  'SMALL': 5,
  'M': 6,
  'MEDIUM': 6,
  'REGULAR': 6,
  'STANDARD': 6,
  'MD': 6,
  'L': 7,
  'LARGE': 7,
  'XL': 8,
  'XLARGE': 8,
  '2XL': 9,
  'XXLARGE': 9,
  '2XLARGE': 9,
  '3XL': 10,
  'XXXL': 10,
  'XXXLARGE': 10,
  '3XLARGE': 10,
  '4XL': 11,
  '5XL': 12,
  'CUSTOM': 13
};
const UNKNOWN_SIZE = 15;

const getItems = state => state.entities.items;
const getItemId = (state, ownProps) => ownProps.item_id;
const getProducts = state => state.entities.products;
const getProductId = (state, ownProps) => ownProps.product_id;
const getDivisions = state => state.entities.divisions;
const getDivisionId = (state, ownProps) => ownProps.division_id;
const getWarnings = state => state.entities.item_warnings;
const getBreakdowns = state => state.entities.breakdowns;
const getCosts = state => state.entities.item_costs;
const getAdjustments = state => state.entities.item_retail_adjustments;
const getItemCostId = (state, ownProps) => ownProps.item_cost_id;
const getComments = state => state.entities.comments;
const getUsers = state => state.entities.users;
const getLocations = state => state.entities.item_locations;
const getArtworks = state => state.entities.artworks;
const getLocationId = (state, ownProps) => ownProps.item_location_id;
const getArtworkId = (state, ownProps) => ownProps.artwork_id;
const getFiles = state => state.entities.files;
const getOrders = state => state.entities.orders;
const getImages = state => state.entities.item_images;
const getOrderType = (state, ownProps) => ownProps.order_type;
const getOrderNumber = (state, ownProps) => ownProps.form_number;
const getSkus = state => state.entities.skus;
const getDecorations = state => state.entities.item_decorations;
const getDecorationId = (state, ownProps) => ownProps.item_decoration_id;

const sizeSort = (a, b) => (SIZE_CLASSES[a.name.toUpperCase().replace(/[- ]/g, '')] || UNKNOWN_SIZE) - (SIZE_CLASSES[b.name.toUpperCase().replace(/[- ]/g, '')] || UNKNOWN_SIZE);

const createSort = field_name => (a, b) => {
  if (a[field_name] < b[field_name]) {
    return -1;
  } else if (a[field_name] > b[field_name]) {
    return 1;
  } else {
    return 0;
  }
};

export const getItemColors = createSelector(
  getItems,
  getItemId,
  (items, item_id) => items[item_id].item_colors.map(
    ic => ({ id: ic.color_id, name: ic.color_name })
  ).filter(
    c => c.name !== 'TBD'
  ).sort(createSort('name'))
);

export const getItemSizes = createSelector(
  getItems,
  getItemId,
  (items, item_id) => items[item_id].item_sizes.map(
    i => ({ id: i.size_id, name: i.size_name })
  ).filter(
    s => s.name !== 'TBD'
  ).sort(sizeSort)
);

export const getItemSkus = createSelector(
  getItems,
  getItemId,
  (items, item_id) => items[item_id].item_skus.map(
    i => ({ id: i.product_sku_id, name: i.sku_description, item_sku_id: i.item_sku_id, sku: i.sku, options: i.options }) 
  ).sort(createSort('name'))
);

export const getItem = createSelector(
  getItems,
  getItemId,
  (items, item_id) => !!items[item_id] && ({
    ...items[item_id],
    isPS: 'ps-products' === items[item_id].copied_from && !!items[item_id].ext_fob_id
  })
);

export const getOptionItems = createSelector(
  getItems,
  getBreakdowns,
  getItemId,
  (items, breakdowns, item_id) => Object.values(items).filter(
    i => i.option_item_id === item_id
  ).sort(
    (a, b) => parseInt(a.display_order, 10) - parseInt(b.display_order, 10)
  ).map(
    i => {
      const breakdown = Object.values(breakdowns).filter(b => b.item_id === i.item_id)[0];
      return {
        item_id: i.item_id,
        breakdown_id: breakdown.breakdown_id,
        hidden: i.hidden == 1,
        quantity: parseInt(breakdown.quantity, 0),
        unit_cost: formatMoney(breakdown.unit_cost, 4),
        total_margin: formatMoney(i.total_margin, 2),
        unit_price: formatMoney(i.unit_price, 2),
        costs_changed: i.costs_changed
      };
    }
  )
);

export const getProduct = createSelector(
  getProducts,
  getProductId,
  (products, product_id) => products[product_id]
);

export const getDivision = createSelector(
  getDivisions,
  getDivisionId,
  (divisions, division_id) => divisions[division_id]
);

export const getItemWarnings = createSelector(
  getWarnings,
  getItemId,
  (warnings, item_id) => Object.values(warnings).filter(w => w.item_id === item_id)
);

export const getItemRunCosts = createSelector(
  getItems,
  getCosts,
  getLocations,
  getItemId,
  (items, costs, locations, item_id) => Object.values(costs).filter(
    c => c.item_id === item_id && c.quantity === null
  ).map(
    c => ({
      item_cost_id: c.item_cost_id,
      item_cost_title: c.item_cost_title,
      isPS: !!c.ext_cost_id,
      item_location_id: c.item_location_id,
      options: Object.values(costs).filter(
        oc => oc.option_item_cost_id === c.item_cost_id && items[oc.item_id].parent_type === 'PRODUCT'
      ).map(
        oc => ({
          item_cost_id: oc.item_cost_id,
          item_id: oc.item_id,
          total_units: items[oc.item_id].total_units,
          hidden: items[oc.item_id].hidden == 1,
          unit_cost: formatMoney(oc.unit_cost, 4),
          isPS: !!oc.ext_cost_id
        })
      ).sort(
        (a, b) => parseInt(a.display_order, 10) - parseInt(b.display_order, 10)
      ),
      location_title: (locations[c.item_location_id] || {}).item_location_title,
      location_index: (Object.values(locations).filter(
        l => l.item_id === item_id
      ).sort(
        (a, b) => parseInt(a.display_order, 10) - parseInt(b.display_order, 10)
      ).map(
        (l, index) => ({
          ...l,
          index: index + 1
        })
      ).filter(
        l => l.item_location_id === c.item_location_id
      )[0] || {}).index
    })
  ).sort(
    (a, b) => parseInt(a.display_order, 10) - parseInt(b.display_order, 10)
  )
);

export const getItemFixedCosts = createSelector(
  getCosts,
  getLocations,
  getItemId,
  (costs, locations, item_id) => Object.values(costs).filter(
    c => c.item_id === item_id && c.quantity !== null
  ).map(
    c => ({
      item_cost_id: c.item_cost_id,
    })
  ).sort(
    (a, b) => parseInt(a.display_order, 10) - parseInt(b.display_order, 10)
  )
);

export const getItemAdjustments = createSelector(
  getAdjustments,
  getItems,
  getProducts,
  getItemId,
  (adjustments, items, products, item_id) => Object.values(adjustments).filter(
    a => a.item_id === item_id
  ).map(
    a => ({
      ...a,
      product_id: items[a.item_id].parent_id,
      value: !!a.axis ? { axis: a.axis, option: a.option } : { sku: a.sku }
    })
  ).sort(
    (a, b) => parseInt(a.display_order, 10) - parseInt(b.display_order, 10)
  )
);

export const getItemCost = createSelector(
  getCosts,
  getItems,
  getLocations,
  getItemCostId,
  (costs, items, locations, item_cost_id) => {
    const cost = costs[item_cost_id];
    return {
      item_cost_id,
      item_cost_title: cost.item_cost_title,
      unit_cost: formatMoney(cost.unit_cost),
      total_margin: formatMoney(cost.total_margin),
      unit_price: formatMoney(cost.unit_price),
      total_units: cost.quantity || items[cost.item_id].total_units,
      location_title: (locations[cost.item_location_id] || {}).item_location_title,
      location_index: (Object.values(locations).filter(
        l => l.item_id === cost.item_id
      ).sort(
        (a, b) => parseInt(a.display_order, 10) - parseInt(b.display_order, 10)
      ).map(
        (l, index) => ({
          ...l,
          index: index + 1
        })
      ).filter(
        l => l.item_location_id === cost.item_location_id
      )[0] || {}).index,
      ext_cost_id: cost.ext_cost_id
    }
  }
);

export const getItemComments = createSelector(
  getComments,
  getUsers,
  getItemId,
  (comments, users, item_id) => Object.values(comments).filter(
    c => c.presentation_item_id === item_id
  ).map(
    c => ({
      ...c,
      image: Object.values(users).filter(u => u.login_name === c.commenter_email).map(u => getUserImageSrc(u))[0]
    })
  ).sort(
    (a, b) => Date.parse(b.date_created) - Date.parse(a.date_created)
  )
);

export const getItemDecoration = createSelector(
  getDecorations,
  getLocations,
  getDecorationId,
  (decorations, locations, item_decoration_id) => {
    const decoration = decorations[item_decoration_id];
    if (!decoration) {
      return null;
    }
    return {
      ...decoration,
      ext_location_id: locations[decoration.item_location_id].ext_location_id
    };
  }
);

export const getItemLocations = createSelector(
  getLocations,
  getDecorations,
  getArtworks,
  getFiles,
  getItemId,
  (locations, decorations, artworks, files, item_id) => Object.values(locations).filter(
    l => l.item_id === item_id
  ).map(
    l => ({
      ...l,
      artworks: Object.values(artworks).filter(
        a => a.item_location_id === l.item_location_id
      ).map(
        a => ({
          ...a,
          image: files[a.artwork_file_id]
        })
      ),
      decorations: Object.values(decorations).filter(
        d => d.item_location_id === l.item_location_id
      ),
      location_index: (Object.values(locations).filter(
        l2 => l2.item_id === item_id
      ).sort(
        (a, b) => parseInt(a.display_order, 10) - parseInt(b.display_order, 10)
      ).map(
        (l2, index) => ({
          ...l2,
          index: index + 1
        })
      ).filter(
        l2 => l2.item_location_id === l.item_location_id
      )[0] || {}).index
    })
  ).sort(
    (a, b) => parseInt(a.display_order, 10) - parseInt(b.display_order, 10)
  )
);

export const getBasicItemLocations = createSelector(
  getLocations,
  getItemId,
  (locations, item_id) => Object.values(locations).filter(l => l.item_id === item_id)
);

export const getLocation = createSelector(
  getLocations,
  getLocationId,
  (locations, item_location_id) => locations[item_location_id]
);

export const getArtwork = createSelector(
  getArtworks,
  getFiles,
  getArtworkId,
  (artworks, files, artwork_id) => ({
    ...artworks[artwork_id],
    image: files[artworks[artwork_id].artwork_file_id]
  })
);

export const getOrder = createSelector(
  getItems,
  getOrders,
  getItemId,
  (items, orders, item_id) => orders[items[item_id].order_id]
);

export const getOrderByNumber = createSelector(
  getOrders,
  getOrderType,
  getOrderNumber,
  (orders, order_type, form_number) => Object.values(orders).filter(
    o => o.form_number === form_number && o.order_type === order_type
  )[0]
);

export const getOrderByItemLocationId = createSelector(
  getOrders,
  getItems,
  getLocations,
  getLocationId,
  (orders, items, locations, item_location_id) => orders[items[locations[item_location_id].item_id].order_id]
);

export const getItemImages = createSelector(
  getImages,
  getFiles,
  getItemId,
  (images, files, item_id) => Object.values(images).filter(
    ii => ii.item_id === item_id
  ).map(
    ii => ({
      ...ii,
      image: files[ii.file_id]
    })
  ).sort(
    (a, b) => parseInt(a.display_order, 10) - parseInt(b.display_order, 10)
  )
);
