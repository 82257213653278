import axios from 'axios';
import { get, memoize } from 'lodash'
import config from './config'

import Cookies from 'universal-cookie'

const getAuthorizationToken = () => {
  let access_token;
  if (typeof(localStorage) !== 'undefined') {
    access_token = localStorage.getItem('csku.access_token');
  }
  if (!access_token) {
    const cookies = new Cookies();
    access_token = cookies.get('access_token');
  }
  return access_token;
}

const memoizeGet = memoize(
    (url, data = {}) => {
        const cookies = new Cookies();
        const token = getAuthorizationToken();
        return axios.get(url, {
            ...data,
            headers: {
                Authorization: `Bearer ${token}`,
                ...data.headers || {},
            },
            params: {
                assume_login_name: cookies.get('assume_login_name'),
                ...data.params || {},
            },
        });
    },
    (url, data) => JSON.stringify({url, data})
);

export const getProductConfigurations = ({ productId }) => {
    return memoizeGet(`${config.promostandards_api_url}/products/${productId}/configuration`).then((response) => {
        return response.data;
    });
};

export const getPart = ({ partId }) => {
    return memoizeGet(`${config.promostandards_api_url}/parts/${partId}`).then((response) => {
        return response.data;
    })
};

export const getParts = (params) => {
    return memoizeGet(`${config.promostandards_api_url}/parts`, {params}).then((response) => {
        return response.data;
    })
};

export const getInventoryLevels = ({ productId }) => {
    const url = `${config.promostandards_api_url}/products/${productId}/inventory_levels`;
    return memoizeGet(url).then((response) => {
        return response.data;
    });
}

export const getCompanies = (params) => {
    const url = `${config.promostandards_api_url}/companies`;
    return memoizeGet(url, params).then((response) => {
        return response.data;
    });
}

export const checkCredential = async ({ company, username, password }, retry = 3) => {
    const url = `${config.promostandards_api_url}/promo_standards/check_credential`;
    const response = await axios.post(url, {
        company, username, password
    });
    if (!response.data) {
        if (retry > 0) {
            return checkCredential({ company, username, password }, retry - 1);
        } else {
            return {
                ErrorMessage: {
                    code: 999,
                    description: 'Server not responding',
                }
            };
        }
    }
    const code = get(response.data, ['ErrorMessage', 'code']);
    if ([100, 104, 105, 110, 120, 999].indexOf(code) === -1) {
        response.data.ErrorMessage = null;
    }
    return response.data;
}