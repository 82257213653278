import React, { Component } from 'react'

import Post from './Post'

export default class Feed extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      posts, job_id, comment, comment_allowed, show_add_comment = false
    } = this.props;

    return (
      <ul className={['feed', (comment ? 'comments' : '')].join(' ')}>
        {posts.map((post, idx) =>
          <Post
            key={idx}
            post={post}
            job_id={job_id}
            comment_allowed={comment_allowed}
            comment={comment}
            show_add_comment={show_add_comment && idx === (posts.length - 1)}
          />
        )}
      </ul>	
    );
  }
}
