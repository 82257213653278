import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import Form from './Form'

import { updatePSEndpoint } from '../actions'
import { closePopup } from '../actions/popup'

import { BASE_ZINDEX } from '../popup-factory'

class EditPSEndpointPopup extends Component {
    constructor(props) {
        const { commonsku_username, commonsku_password, promo_api_username, promo_api_password, note, active, problem } = props.tenant_endpoint

        super(props)

        this.state = {
            commonsku_username,
            commonsku_password,
            promo_api_username,
            promo_api_password,
            note,
            active,
            problem,
        }

        this.onFormRef = this.onFormRef.bind(this)
        this.handleFieldChange = this.handleFieldChange.bind(this)
        this.handleEditEndpoint = this.handleEditEndpoint.bind(this)
    }

    onFormRef(form) {
        this._form = form;
    }

    handleFieldChange(value, field) {
        if (this._form) {
            if(field === 'active' || field === 'problem') {
                if(value) {
                    this.setState({ [field]: 'yes' })
                    return
                }else{
                    this.setState({ [field]: 'no' })
                    return
                }
            }

            this.setState({ [field]: value })
        }
    }

    handleEditEndpoint(values, errors) {
        if (!_.every(errors, (error) => error === null)) {
            return
        }

        const { endpoint_id, endpoint_code, tenant_id } = this.props.tenant_endpoint 

        if (['ODRSTAT', 'OSN'].indexOf(endpoint_code) !== -1) {
            this.props.onEditEndpoint(
                endpoint_id,
                tenant_id,
                null,
                null,
                values.promo_api_username,
                values.promo_api_password,
                values.note,
                values.active,
                values.problem
            )
        } else if (['INV', 'CRED'].indexOf(endpoint_code) !== -1) {
            this.props.onEditEndpoint(
                endpoint_id,
                tenant_id,
                values.commonsku_username,
                values.commonsku_password,
                null,
                null,
                values.note,
                values.active,
                values.problem
            )
        }

        this.props.onClosePopup()
    }

    render() {
        const {
            tenant_endpoint,
            onClosePopup,
            index } = this.props;

        return (
            <div id='ps-test-modal' className='reveal small' style={{ display: 'block', zIndex: BASE_ZINDEX + index }} aria-labelledby='modalTitle' aria-hidden='true' role='dialog'>
                <div className='row'>
                    <div className='small-12 columns'>
                        <h3 id='modalTitle'>Edit Promostandards Endpoint</h3>
                        <a className='alert button' style={{ position: 'fixed', right: '5rem', top: '1rem' }} onClick={e => onClosePopup()}>Cancel</a>
                        <a className='button' style={{ position: 'fixed', right: '1rem', top: '1rem' }} onClick={(e) => { e.preventDefault(); this._form && this._form.submit() }}>Save</a>
                    </div>
                </div>
                <div className='row popup-content'>
                    <Form className='row popup-content form'
                        ref={this.onFormRef}
                        onFieldChange={this.handleFieldChange}
                        onSubmit={this.handleEditEndpoint}
                    >
                        <Form.TextInput label='Division' field="division_name" value={tenant_endpoint.division_name} disabled />
                        <Form.TextInput label='Supplier' field='supplier_name' value={tenant_endpoint.supplier_name} disabled />
                        <Form.TextInput label='Endpoint Url' field='endpoint_url' value={tenant_endpoint.endpoint_url} disabled />
                        <Form.TextInput label='Version' field='endpoint_version' value={tenant_endpoint.endpoint_version} disabled />
                        <Form.TextInput label='Type' field='endpoint_code' value={tenant_endpoint.endpoint_code} disabled />
                        {['INV', 'CRED'].indexOf(tenant_endpoint.endpoint_code) !== -1 ?
                            [<Form.TextInput key="username" label='Commonsku Username' field='commonsku_username' value={this.state.commonsku_username} />, 
                            <Form.TextInput key="password" label='Commonsku Password' field='commonsku_password' value={this.state.commonsku_password} />]
                            : null}
                        {['ODRSTAT', 'OSN'].indexOf(tenant_endpoint.endpoint_code) !== -1 && tenant_endpoint.tenant_id ?
                            [<Form.TextInput key="username" label='Username' field='promo_api_username' value={this.state.promo_api_username} />,
                            <Form.TextInput key="password" label='Password' field='promo_api_password' value={this.state.promo_api_password} />]
                            : null}
                        <Form.Textarea label='Note' field='note' value={this.state.note} />
                        <Form.Checkbox label='Live' field='live' value={tenant_endpoint.live === 'live' ? 1 : 0} disabled />
                        <Form.Checkbox label='Active' field='active' value={this.state.active === 'yes' ? 1 : 0} />
                        <Form.Checkbox label='Problem' field='problem' value={this.state.problem === 'yes' ? 1 : 0} />
                    </Form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onClosePopup: () => {
            dispatch(closePopup())
        },
        onEditEndpoint: (endpoint_id, tenant_id, commonsku_username, commonsku_password, promo_api_username, promo_api_password, note, active, problem) => {
            dispatch(updatePSEndpoint(endpoint_id, tenant_id, commonsku_username, commonsku_password, promo_api_username, promo_api_password, note, active, problem))
        }
    }
}


const ConnectedEditPSEndpointPopup = connect(mapStateToProps, mapDispatchToProps)(EditPSEndpointPopup);
export default ConnectedEditPSEndpointPopup;