import React, { Component } from 'react';
import { connect } from 'react-redux';

import { BASE_ZINDEX } from '../popup-factory';
import { closePopup } from '../actions/popup';
import Img from './Img';
import EditCompanyShippingAndBillingForm from './EditCompanyShippingAndBillingForm';

class EditCompanyShippingAndBillingPopup extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }
  render() {
    const { index, closePopup, identity } = this.props;
    const { company_id, company_billing_address_id, company_shipping_address_id } = identity;

    return <div className="reveal large" 
      style={{display: 'block', zIndex: BASE_ZINDEX + index, overflow: 'auto'}} 
      aria-labelledby="modalTitle" aria-hidden="true" role="dialog"
    >
      <div>
        <div className="small-12 columns">
          <Img className="small-3 columns" src="/images/commonsku-Logo-Dark.png"/>
          <div className="small-9 columns" style={{padding: '20px 5px 0 5px'}}>
            <div style={{fontWeight: 'bold'}}>
              Our records indicate we do not have a billing or shipping address on file. <br/>
              Please provide the addresses below:
            </div>
            <div>PLEASE NOTE: THIS POP-UP WILL CONTINUE UNTIL YOU ENTER YOUR ADDRESS</div>
          </div>
        </div>
        <EditCompanyShippingAndBillingForm company_id={company_id} 
          shipping_address_id={company_shipping_address_id} billing_address_id={company_billing_address_id}/>
        <div className="small-5 columns small-offset-7">
          <button className="btn button" style={{marginRight: 40}} aria-label="Close modal" onClick={() => {closePopup()}}>
            Remind me in 2 hours
          </button>
          <button className="btn button"  onClick={() => {
            this.formRef.current.validateAndSave();
          }}>Update</button>
        </div>
      </div>
    </div>
  }
}

const ConnectedEditCompanyShippingAndBillingPopup = connect(
  ({ identity }) => {
    return { identity }
  }, 
  {
    closePopup
  }
)(EditCompanyShippingAndBillingPopup);
export default ConnectedEditCompanyShippingAndBillingPopup;