import React, { Component } from 'react'
import { connect } from 'react-redux'
 
import { closePopup } from '../actions/popup'
import { regeneratePurchaseOrders } from '../actions/purchase_order'
import { isNumeric } from '../utils'

import { BASE_ZINDEX } from '../popup-factory'

class RegeneratePurchaseOrdersPopup extends Component {

  constructor(props) {
    super(props);
    const shipping = this.determineShipping(props); 
    this.state = {
      shipping,
      selected: shipping.reduce((o, s) => {
        o[s.shipping_id] = s.default_value;
        return o;
      }, {})
    };
  }

  determineShipping(props) {
    const po_ids = props.purchase_orders.map(po => po.purchase_order_id);
    const extra_pos = props.purchase_orders.filter(po => !po.ext_po_id && !props.shipping.reduce((pos, s) => pos.concat(s.purchase_orders), []).map(spo => spo.purchase_order_id).includes(po.purchase_order_id));
    const shipping = Object.values(props.shipping.filter(s =>
      !s.purchase_orders.length || s.purchase_orders.map(po => po.purchase_order_id).some(po_id => po_ids.includes(po_id))
    ).reduce((o, s) => {
      const generate_phrase = s.purchase_orders.length ? "Don't regenerate" : "Don't generate";
      o[s.shipping_id] = {
        shipping_id: s.shipping_id,
        form_number: s.purchase_orders.map(po => po.form_number).join(', '),
        details: `${s.origin.division_name} > ${s.destination.destination_name}`,
        default_value: s.purchase_orders.length ? s.purchase_orders[0].form_number : 'new',
        options: [
          { key: 'new', value: 'Create new PO#' },
          { key: 'keep', value: generate_phrase },
          ...(s.purchase_orders.filter(po => !po.ext_po_id).map(po =>
            ({ key: po.form_number, value: `Regenerate as #${po.form_number}` })
          )),
          ...(extra_pos.map(po =>
            ({ key: po.form_number, value: `Regenerate as #${po.form_number}` })
          )) 
        ]
      };
      return o;
    },
    props.purchase_orders.reduce((o, po) => {
      o[po.shipping_id] = {
        shipping_id: po.shipping_id,
        form_number: po.form_number,
        details: `${po.origin.division_name} > ${po.destination.destination_name}`,
        default_value: 'delete',
        options: [
          { key: 'keep', value: "Do not delete" },
          { key: 'delete', value: 'Delete' }
        ]
      };
      return o;
    }, {})));
    return shipping;
  }

  UNSAFE_componentWillReceiveProps(props) {
    const shipping = this.determineShipping(props);
    this.setState({
      shipping,
      selected: shipping.reduce((o, s) => {
        o[s.shipping_id] = s.default_value;
        return o;
      }, {})
    });
  }

  render() {
    const { order_id, onClosePopup, onRegeneratePurchaseOrders, index } = this.props;
    const { shipping } = this.state;

    const handleSelect = shipping_id => e => {
      let selected = Object.assign({}, this.state.selected, {[shipping_id]: e.target.value});
      // Deal with shipping with same form number
      selected = Object.assign(selected, shipping.filter(s => s.form_number === e.target.value && s.shipping_id !== shipping_id).reduce((o, po) => {
        o[po.shipping_id] = 'delete';
        return o;
      }, {}));
      // Deal with POs with no items on them
      if ('keep' === e.target.value) {
        // Find all state.shipping with state.selected = shipping.form_number of this
        const form_number = shipping.filter(s => s.shipping_id === shipping_id)[0].form_number;
        selected = Object.assign(selected, Object.keys(this.state.selected).filter(s_id => this.state.selected[s_id] === form_number && s_id !== shipping_id).reduce((o, s_id) => {
          o[s_id] = 'new';
          return o;
        }, {}));
      }
      this.setState({ selected });
    };

    return (
      <div id="regenerate-pos-modal" className="reveal large" style={{display: 'block', zIndex: BASE_ZINDEX + index}} aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3 id="modalTitle">Regenerate Purchase Orders</h3>
          </div>
        </div>
        <div className="row popup-content">
          <div className="small-12 columns">
            <table>
              <thead>
                <tr>
                  <th>Existing PO #</th>
                  <th>PO Details</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {shipping.map((po, idx) =>
                <tr key={idx}>
                  <td>{po.form_number}</td>
                  <td>{po.details}{po.default_value === 'delete' ? <b><br />There are no items currently associated with this PO.</b> : null}</td>
                  <td>
                    <fieldset>
                      {po.options.map((o, i) =>
                        <span key={i}>
                          <label>
                            <input
                              type="radio"
                              value={o.key}
                              checked={this.state.selected[po.shipping_id] === o.key}
                              onChange={handleSelect(po.shipping_id)}
                            />
                            {o.value}
                          </label>
                        </span>
                      )}
                    </fieldset>
                  </td>
                </tr>
              )}
              </tbody>
            </table>
            <a className="button" onClick={e => onRegeneratePurchaseOrders(order_id, this.state.selected)}>Regenerate</a>
          </div>
        </div>
        <button className="close-button" aria-label="Close modal" type="button" onClick={e => onClosePopup()}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onRegeneratePurchaseOrders: (order_id, shipping) => {
      dispatch(regeneratePurchaseOrders(order_id, shipping));
      dispatch(closePopup());
    },
    onClosePopup: () => {
      dispatch(closePopup());
    }
  };
};

const ConnectedRegeneratePurchaseOrdersPopup = connect(mapStateToProps, mapDispatchToProps)(RegeneratePurchaseOrdersPopup);
export default ConnectedRegeneratePurchaseOrdersPopup;
