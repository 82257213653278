import React, { Component } from 'react';
import { connect } from 'react-redux';

import { BASE_ZINDEX } from '../popup-factory';

import { getAddressDropdown, getClientOptions, getContactDropdown } from '../selectors/dropdowns';

import { closePopup, createCreateClientPopup } from '../actions/popup';
import { createDeleteTemp } from '../actions/temp';
import { createAddClient, createLoadClientList } from '../actions/client';
import { createLoadCompanyContactList } from '../actions/contact';
import { createLoadAddressList } from '../actions/address';
import { assignOrderClient } from '../actions/order';
import { loadShopOrders } from '../actions/shop';

import Address from './Address';
import Form from './Form';
import SpinnerPopup from './SpinnerPopup';

class AssignToClientPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      client_id: props.client_id,
      billing_contact_id: props.billing_contact_id,
      billing_address_id: props.billing_address_id,
      shipping_contact_id: props.shipping_contact_id,
      shipping_address_id: props.shipping_address_id,
      loaded: !!props.clients.length,
      extra_clients: []
    };

    this.onFieldChange = this.onFieldChange.bind(this);
    this.onAssignClient = this.onAssignClient.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (!this.state.loaded) {
      this.props.onLoadClients();
    }
    if (!!this.state.client_id) {
      this.props.onLoadContacts(this.state.client_id);
      this.props.onLoadAddresses(this.state.client_id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.clients.length) {
      this.setState({ loaded: true });
    }
    if (nextProps.client_id && nextProps.client_id !== this.props.client_id) {
      this.setState({ client_id: nextProps.client_id });
    }
    if (nextProps.billing_contact_id && nextProps.billing_contact_id !== this.props.billing_contact_id) {
      this.setState({ billing_contact_id: nextProps.billing_contact_id });
    }
    if (nextProps.billing_address_id && nextProps.billing_address_id !== this.props.billing_address_id) {
      this.setState({ billing_address_id: nextProps.billing_address_id });
    }
    if (nextProps.shipping_contact_id && nextProps.shipping_contact_id !== this.props.shipping_contact_id) {
      this.setState({ shipping_contact_id: nextProps.shipping_contact_id });
    }
    if (nextProps.shipping_address_id && nextProps.shipping_address_id !== this.props.shipping_address_id) {
      this.setState({ shipping_address_id: nextProps.shipping_address_id });
    }
  }

  onFieldChange(value, field) {
    switch (field) {
      case 'client_id':
        this.props.onLoadContacts(value);
        this.props.onLoadAddresses(value);
        this.setState({ client_id: value });
        break;
      case 'billing_contact_id':
      case 'billing_address_id':
      case 'shipping_contact_id':
      case 'shipping_address_id':
        this.setState({ [field]: value });
        break;
    }
  }

  onAssignClient(values, errors) {
    if (!Object.values(errors).every(e => e === null)) {
      return;
    }
    const extraClient = this.state.extra_clients.filter(c => c.key === this.state.client_id);
    const {
      client_id,
      billing_contact_id,
      shipping_contact_id,
      billing_address_id,
      shipping_address_id
    } = this.state;
    if (1 === extraClient.length) {
      this.props.onAssignClient(null, billing_contact_id, shipping_contact_id, billing_address_id, shipping_address_id, extraClient[0].value);
    } else {
      this.props.onAssignClient(client_id, billing_contact_id, shipping_contact_id, billing_address_id, shipping_address_id);
    }
  }

  renderLoading() {
    return <SpinnerPopup index={this.props.index} />;
  }

  render() {
    const { clients, default_contact, index, order, onClosePopup, onCreateNewClient, getBillingContacts, getShippingContacts, getBillingAddresses, getShippingAddresses } = this.props;
    const { client_id, billing_contact_id, billing_address_id, shipping_contact_id, shipping_address_id, extra_clients, loaded } = this.state;

    if (!loaded) {
      return this.renderLoading();
    }

    const handleAssignClient = e => {
      e.preventDefault();
      this._form.submit();
    };

    const billing_contacts = getBillingContacts(client_id);
    const shipping_contacts = getShippingContacts(client_id);
    const billing_addresses = getBillingAddresses(client_id);
    const shipping_addresses = getShippingAddresses(client_id);

    const billing_contact = billing_contacts.filter(c => c.contact_id === billing_contact_id)[0];
    const shipping_contact = shipping_contacts.filter(c => c.contact_id === shipping_contact_id)[0];
    const billing_contact_options = billing_contacts.map(c => ({ key: c.contact_id, value: `${c.contact_first_name} ${c.contact_last_name}` }));
    const shipping_contact_options = shipping_contacts.map(c => ({ key: c.contact_id, value: `${c.contact_first_name} ${c.contact_last_name}` }));

    const billing_address = billing_addresses.filter(a => a.address_id === billing_address_id)[0];
    const shipping_address = shipping_addresses.filter(a => a.address_id === shipping_address_id)[0];
    const billing_address_options = billing_addresses.map(a => ({ key: a.address_id, value: a.address_line_1 }));
    const shipping_address_options = shipping_addresses.map(a => ({ key: a.address_id, value: a.address_line_1 }));

    return (
      <div className="reveal large" style={{ display: 'block', zIndex: BASE_ZINDEX + index, maxHeight: '95%', overflow: 'visible' }}>
        <div className="row small-12 columns">
          <h3>Assign to Client</h3>
          <a className="alert button" style={{ position: 'fixed', right: '6rem', top: '1rem' }} onClick={onClosePopup}>Cancel</a>
          <a className="button" style={{ position: 'fixed', right: '1rem', top: '1rem' }} onClick={handleAssignClient}>Assign</a>
        </div>
        <div className="row" style={{ height: '85%', overflowY: 'auto' }}>
          <Form
            className="small-12 columns form"
            style={{ marginTop: '1rem' }}
            ref={ref => this._form = ref}
            onFieldChange={this.onFieldChange}
            onSubmit={this.onAssignClient}
          >
            <div className="small-12 medium-6 columns">
              <div className="row small-12 columns collapse">
                <h4>Client entered data</h4>
              </div>
              <div className="row collapse" style={{ marginTop: '1rem' }}>
                <div className="small-12 medium-4 columns">
                  <label style={{ fontSize: 'initial' }}>Client:</label>
                </div>
                <div className="small-12 medium-8 columns">
                  {order.billing_address_company}&nbsp;
                </div>
              </div>
              <div className="row collapse" style={{ marginTop: '1rem' }}>
                <div className="small-12 medium-4 columns">
                  <label style={{ fontSize: 'initial' }}>Billing contact:</label>
                </div>
                <div className="small-12 medium-8 columns">
                  {order.billing_contact_first_name} {order.billing_contact_last_name}
                  <br />
                  {order.billing_contact_email}
                </div>
              </div>
              <div className="row collapse" style={{ marginTop: '1rem' }}>
                <div className="small-12 medium-4 columns">
                  <label style={{ fontSize: 'initial' }}>Billing address:</label>
                </div>
                <div className="small-12 medium-8 columns">
                  <Address
                    address_line_1={order.billing_address_line_1}
                    address_line_2={order.billing_address_line_2}
                    address_city={order.billing_address_city}
                    address_state={order.billing_address_state}
                    address_postal={order.billing_address_postal}
                    address_country={order.billing_address_country}
                  />
                </div>
              </div>
              <div className="row collapse" style={{ marginTop: '1rem' }}>
                <div className="small-12 medium-4 columns">
                  <label style={{ fontSize: 'initial' }}>Shipping contact:</label>
                </div>
                <div className="small-12 medium-8 columns">
                  {order.shipping_contact_first_name} {order.shipping_contact_last_name}
                  <br />
                  {order.shipping_contact_email}
                </div>
              </div>
              <div className="row collapse" style={{ marginTop: '1rem' }}>
                <div className="small-12 medium-4 columns">
                  <label style={{ fontSize: 'initial' }}>Shipping address:</label>
                </div>
                <div className="small-12 medium-8 columns">
                  <Address
                    address_line_1={order.shipping_address_line_1}
                    address_line_2={order.shipping_address_line_2}
                    address_city={order.shipping_address_city}
                    address_state={order.shipping_address_state}
                    address_postal={order.shipping_address_postal}
                    address_country={order.shipping_address_country}
                  />
                </div>
              </div>
            </div>
            <div className="small-12 medium-6 columns">
              <div className="row small-12 columns collapse">
                <h4>Assign the following data</h4>
              </div>
              <div className="row collapse">
                <Form.Select
                  field="client_id"
                  label="Client"
                  labelClassName="small-12 medium-4 columns"
                  controlClassName="small-12 medium-8 columns"
                  value={client_id}
                  options={extra_clients.concat(clients)}
                  placeholder="Select Client"
                  searchable={true}
                  required={true}
                  creatable={true}
                  promptTextCreator={label => 'Create New Client'}
                  onNewOptionClick={option => onCreateNewClient(option.value)}
                  extra={this.props.base_client_id && this.props.base_client_id === client_id ? <p>(Matched by email)</p> : (this.props.new_client_id && this.props.new_client_id === client_id && <p>(New client)</p>)}
                />
              </div>
              <div className="row collapse">
                <Form.Select
                  field="billing_contact_id"
                  label="Billing Contact"
                  labelClassName="small-12 medium-4 columns"
                  controlClassName="small-12 medium-8 columns"
                  value={billing_contact_id}
                  options={billing_contact_options}
                  placeholder="Select Contact"
                  searchable={true}
                  required={true}
                  extra={billing_contact && <p>{billing_contact.contact_first_name} {billing_contact.contact_last_name}<br />{billing_contact.contact_email}</p>}
                />
              </div>
              <div className="row collapse">
                <Form.Select
                  field="billing_address_id"
                  label="Billing Address"
                  labelClassName="small-12 medium-4 columns"
                  controlClassName="small-12 medium-8 columns"
                  value={billing_address_id}
                  options={billing_address_options}
                  placeholder="Select Address"
                  searchable={true}
                  required={true}
                  extra={billing_address && <Address {...billing_address} />}
                />
              </div>
              <div className="row collapse">
                <Form.Select
                  field="shipping_contact_id"
                  label="Shipping Contact"
                  labelClassName="small-12 medium-4 columns"
                  controlClassName="small-12 medium-8 columns"
                  value={shipping_contact_id}
                  options={shipping_contact_options}
                  placeholder="Select Contact"
                  searchable={true}
                  required={true}
                  extra={shipping_contact && <p>{shipping_contact.contact_first_name} {shipping_contact.contact_last_name}<br />{shipping_contact.contact_email}</p>}
                />
              </div>
              <div className="row collapse">
                <Form.Select
                  field="shipping_address_id"
                  label="Shipping Address"
                  labelClassName="small-12 medium-4 columns"
                  controlClassName="small-12 medium-8 columns"
                  value={shipping_address_id}
                  options={shipping_address_options}
                  placeholder="Select Address"
                  searchable={true}
                  required={true}
                  extra={shipping_address && <Address {...shipping_address} />}
                />
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const possible_billing_contacts = ownProps.order.possible_billing_contacts;
  const possible_shipping_contacts = ownProps.order.possible_shipping_contacts;
  const default_billing_contact = state.entities.contacts[ownProps.order.billing_contact_id];
  const default_shipping_contact = state.entities.contacts[ownProps.order.shipping_contact_id];
  const new_client_id = state.temp.NEW_CLIENT;
  const base_client_id = possible_billing_contacts.length > 0 ? possible_billing_contacts[0].company_id : (possible_shipping_contacts.length > 1 ? possible_shipping_contacts[0].company_id : '');
  return {
    base_client_id,
    new_client_id,
    billing_contact_id: possible_billing_contacts.length > 0 ? possible_billing_contacts[0].contact_id : ownProps.order.billing_contact_id,
    shipping_contact_id: possible_shipping_contacts.length > 0 ? possible_shipping_contacts[0].contact_id : ownProps.order.shipping_contact_id,
    billing_address_id: ownProps.order.billing_address_id, 
    shipping_address_id: ownProps.order.shipping_address_id,
    client_id: new_client_id ? new_client_id : base_client_id,
    clients: getClientOptions(state),
    getBillingContacts: client_id => {
      const billing_contact = {
        contact_id: ownProps.order.billing_contact_id,
        contact_first_name: ownProps.order.billing_contact_first_name,
        contact_last_name: ownProps.order.billing_contact_last_name,
        contact_email: ownProps.order.billing_contact_email
      };
      if (!client_id) {
        return [billing_contact];
      }
      const contacts = getContactDropdown(state, { parent_id: client_id });
      if (!contacts.filter(c => c.contact_id === ownProps.order.billing_contact_id).length) {
        return [billing_contact].concat(contacts);
      }
      return contacts;
    },
    getShippingContacts: client_id => {
      const shipping_contact = {
        contact_id: ownProps.order.shipping_contact_id,
        contact_first_name: ownProps.order.shipping_contact_first_name,
        contact_last_name: ownProps.order.shipping_contact_last_name,
        contact_email: ownProps.order.shipping_contact_email
      };
      if (!client_id) {
        return [shipping_contact];
      }
      const contacts = getContactDropdown(state, { parent_id: client_id });
      if (!contacts.filter(c => c.contact_id === ownProps.order.shipping_contact_id).length) {
        return [shipping_contact].concat(contacts);
      }
      return contacts;
    },
    getBillingAddresses: client_id => {
      const billing_address = {
        address_id: ownProps.order.billing_address_id,
        address_company: ownProps.order.billing_address_company,
        address_name: ownProps.order.billing_address_name,
        address_line_1: ownProps.order.billing_address_line_1,
        address_line_2: ownProps.order.billing_address_line_2,
        address_city: ownProps.order.billing_address_city,
        address_state: ownProps.order.billing_address_state,
        address_postal: ownProps.order.billing_address_postal,
        address_country: ownProps.order.billing_address_country
      };
      if (!client_id) {
        return [billing_address];
      }
      const addresses = getAddressDropdown(state, { parent_id: client_id });
      if (!addresses.filter(a => a.address_id === ownProps.order.billing_address_id).length) {
        return [billing_address].concat(addresses);
      }
      return addresses;
    },
    getShippingAddresses: client_id => {
      const shipping_address = {
        address_id: ownProps.order.shipping_address_id,
        address_company: ownProps.order.shipping_address_company,
        address_name: ownProps.order.shipping_address_name,
        address_line_1: ownProps.order.shipping_address_line_1,
        address_line_2: ownProps.order.shipping_address_line_2,
        address_city: ownProps.order.shipping_address_city,
        address_state: ownProps.order.shipping_address_state,
        address_postal: ownProps.order.shipping_address_postal,
        address_country: ownProps.order.shipping_address_country
      };
      if (!client_id) {
        return [shipping_address];
      }
      const addresses = getAddressDropdown(state, { parent_id: client_id });
      if (!addresses.filter(a => a.address_id === ownProps.order.shipping_address_id).length) {
        return [shipping_address].concat(addresses);
      }
      return addresses;
    }
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClosePopup: () => {
    dispatch(createDeleteTemp('NEW_CLIENT'));
    dispatch(closePopup());
  },
  onLoadClients: () => dispatch(createLoadClientList()),
  onLoadContacts: client_id => dispatch(createLoadCompanyContactList(client_id, 'CLIENT')),
  onLoadAddresses: client_id => dispatch(createLoadAddressList(client_id, 'CLIENT')),
  onCreateNewClient: client_name => dispatch(createCreateClientPopup(client_name, ownProps.client_rep_id)),
  onAssignClient: (client_id, billing_contact_id, shipping_contact_id, billing_address_id, shipping_address_id, client_name = null) => {
    const assign = client_id => dispatch(assignOrderClient(ownProps.order.job_id, ownProps.order.order_id, client_id, billing_contact_id, shipping_contact_id, billing_address_id, shipping_address_id)).then(
      () => dispatch(loadShopOrders(ownProps.order.shop_id))
    ).then(
      () => {
        dispatch(createDeleteTemp('NEW_CLIENT'))
        dispatch(closePopup())
        location.reload(true)
      }
    );
    if (client_name) {
      return dispatch(createAddClient(client_name)).then(
        action => assign(action.payload.client.client_id)
      );
    } else {
      return assign(client_id);
    }
  }
});

const ConnectedAssignToClientPopup = connect(mapStateToProps, mapDispatchToProps)(AssignToClientPopup);
export default ConnectedAssignToClientPopup;
