import React, { Component } from 'react'
import { connect } from 'react-redux'

import CartItemBreakdown from './CartItemBreakdown'
import CartSkuSelect from './CartSkuSelect';
import LabelledInput from './LabelledInput'
import LabelledTextArea from './LabelledTextArea'

import { createAddTemp, createDeleteTemp } from '../actions/temp'
import { closePopup } from '../actions/popup'
import { createSmartCheckout } from '../actions'

import { getImageSrc } from '../utils'

import { BASE_ZINDEX } from '../popup-factory'

class CartSummaryPopup extends Component {

  constructor(props) {
    super(props);

    this.state = Object.values(props.cart).reduce((o, c) => { o[c.item_id] = false; return o;}, {});
    this.state.changes = {};
    this.state.in_quote = false;
    this.state.receiver_email = '';
    this.state.comment = '';
    this.state.finish_checkout = false;

    this.handleRemoveQuoteItem = this.handleRemoveQuoteItem.bind(this);
    this.toggleCartItemBreakdown = this.toggleCartItemBreakdown.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.handleQuote = this.handleQuote.bind(this);
    this.handleCheckout = this.handleCheckout.bind(this);
    this.handleChangeValue = this.handleChangeValue.bind(this);
  }

  componentDidMount() {
    /* if (!this.props.isLoggedIn) {
      let client_email = window.localStorage.getItem('commonsku_client_email');
      if (client_email) {
        this.setState({ receiver_email: client_email });
      }
    } */
  }

  handleRemoveQuoteItem(item_id) {
    this.props.onCreateDeleteTemp(item_id);
  }

  toggleCartItemBreakdown(item_id) {
    this.setState({ [item_id]: !this.state[item_id]});
  }

  onClickSave(item_id) {
    this.setState({ [item_id]: false });
  }

  handleQuote() {
    this.setState({ in_quote: true });
  }

  handleCheckout() {
    let items_arr = Object.values(this.props.cart).map(i => ({...i, skus: i.skus ? Object.keys(i.skus).filter(s => i.skus[s] > 0).reduce((o, s) => ({ ...o, [s]: i.skus[s] }), {}) : null })).concat(this.props.title_items);

    this.props.onCreateSmartCheckout(items_arr, this.props.job_id, this.props.order_id, 'UPGRADE-SALES-ORDER', this.props.sales_rep_id, this.state.receiver_email, this.state.comment);
    this.setState({ finish_checkout: true });

    setTimeout(function() { 
      location.reload(); 
    }, 3500); 
  }

  handleChangeValue(e, field) {
    if(field === 'receiver_email' && !this.props.isLoggedIn) {
      //window.localStorage.setItem('commonsku_client_name', e);
    }

    this.setState({ [field]: e });
  }

  render() {
    const { cart, items, job_id, order_id, sales_rep_id, form_number, company_name, username, title_items, isLoggedIn, onCreateAddTemp, onCreateDeleteTemp, onClosePopup, index } = this.props;
    const left_button_style = { position: 'absolute', right: '9rem', top: '0.5rem' }
    const right_button_style = { position: 'absolute', right: '1rem', top: '0.5rem' }

    return (
      <div className="reveal gallery-popup" style={{display: 'block', zIndex: BASE_ZINDEX + index}} aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="row">
          <div className="small-12 columns">
            {!this.state.finish_checkout ? 
              <h3 id="modalTitle">{this.state.in_quote ? 'Submit Order' : 'Your Order'}</h3>
            : null}
          </div>
        </div>
        <div className="row column popup-content">
            {this.state.in_quote && !this.state.finish_checkout ? 
              <div>
                <LabelledInput label="Email Address" placeholder="Enter your email address" type="text" value={this.state.receiver_email} onChange={e => this.handleChangeValue(e, 'receiver_email')} />
                <LabelledTextArea label="Comments" placeholder="Enter your comments" type="text" value={this.state.comment} onChange={e => this.handleChangeValue(e, 'comment')} />
                <button className="button alt" style={{display: 'block', margin: 'auto', padding: '20px 40px', fontSize: '18px'}} onClick={this.handleCheckout} disabled={this.state.receiver_email != '' ? false : true}>Confirm Checkout</button>
              </div>
            : this.state.finish_checkout ? 
              <div>
                <h3>Thank you</h3>
                <div>{`Your order has been successfully submitted. Your ${company_name} sales rep ${username} will contact you when your order is reviewed.`}</div>
              </div>
            :
              <div className="cart-summary" style={{color: '#777'}}>
                {cart ? Object.values(cart).filter(c => c.item_id).map(c =>
                  <div className="row collapse" key={c.item_id}>
                      <div className="small-2 columns">
                        <img src={getImageSrc(items[c.item_id].images[0], 'small')} style={{marginTop: '-15px'}}/>
                      </div>
                      <div className="small-5 columns" style={{paddingLeft: '10px'}}>
                        <a style={{color: 'black', fontWeight: 'bold'}}>{c.item_name}</a>
                        {/* <br />
                        {c.color_breakdowns.map((b, index) => 
                          <p key={b.color.color_id} style={{display: 'inline'}}>{b.color.color_name && b.color.color_name != 'none' ? `${b.color.color_name} ` : null}{b.quantity_color ? b.quantity_color : null}{b.color.color_name != '' && index != c.color_breakdowns.length - 1 ? ', ' : null}</p>
                        )} */}
                      </div>
                      <div className="small-2 columns">
                        <span style={{float: 'right', marginRight: '20px', fontWeight: 'bold'}}>{c.total_quantity}</span>
                      </div>
                      <div className="small-3 columns">
                        <button className="button tiny" onClick={e => {e.preventDefault(); this.toggleCartItemBreakdown(c.item_id)}}>{this.state[c.item_id] ? 'Cancel' : 'Edit'}</button>
                        <button className="button tiny" onClick={e => {e.preventDefault(); this.handleRemoveQuoteItem(c.item_id);}}>Remove</button>
                      </div>
                      {this.state[c.item_id] &&
                        <button className="button tiny" style={{float: 'right', width: '20%', backgroundColor: '#5CA3B6', marginTop: '-10px', marginRight: '1.2rem' }} onClick={() => {
                          onCreateAddTemp(c.item_id, this.state.changes[c.item_id]);
                          this.onClickSave(c.item_id);
                        }} disabled={!this.state.changes[c.item_id]}>Save</button>}
                      {this.state[c.item_id] ? 
                        (cart[c.item_id].skus ?
                        <CartSkuSelect skus={items[c.item_id].item_skus} quantities={cart[c.item_id].skus} onChange={quantities => {
                          const changes = {
                            item_id: c.item_id,
                            item_name: items[c.item_id].item_name,
                            total_quantity: Object.values(quantities).reduce((t, q) => t + q, 0),
                            skus: quantities
                          };
                          this.setState(state => ({ changes: { ...state.changes, [c.item_id]: changes } }));
                        }} /> :
                        <CartItemBreakdown key={c.item_id} currentItemId={c.item_id} currentItemType='OPTION' onChange={changes => {
                          this.setState(state => ({ changes: { ...state.changes, [c.item_id]: changes } }));
                        }} />)
                      : null}
                  </div>
                ) : null}
              </div>
            }
        </div>
        {!this.state.finish_checkout ?
          <button className="button" style={this.state.in_quote && !this.state.finish_checkout ? right_button_style : left_button_style} aria-label="Close modal" type="button" onClick={e => { e.preventDefault(); onClosePopup(); }}>Continue Shopping</button>
        : null}
        {!this.state.in_quote ?
          <button className="button alt" style={right_button_style} onClick={this.handleQuote}>Submit Order</button>
        : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const company_name = state.identity ? state.identity.company_name : state.sales_rep ? state.sales_rep.network_name : null;
  const username = state.identity ? `${state.identity.user_first_name} ${state.identity.user_last_name}` : state.sales_rep ? state.sales_rep.contact_full_name : null;
  const title_items = Object.values(state.items).filter(i => i.parent_type === 'SEPARATOR');

  const items = Object.values(state.items).map(i => ({ ...i, images: (i.item_images || []).map(ii => (i.files || []).filter(f => f.file_id === ii.file_id)[0]) })).reduce((o, i) => { o[i.item_id] = i; return o; }, {});

  const cart = Object.keys(state.temp).filter(k => items[k]).reduce((o, i) => { o[i] = state.temp[i]; return o; }, {});
  const isLoggedIn = ownProps.isLoggedIn;
  return {
    cart,
    items,
    job_id: state.job_id,
    order_id: state.order_id,
    sales_rep_id: state.sales_rep_id,
    form_number: state.form_number,
    company_name,
    username,
    title_items,
    isLoggedIn
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClosePopup: () => {
      dispatch(closePopup());
    },
    onCreateAddTemp: (key, value) => {
      dispatch(createAddTemp(key, value));
    },
    onCreateDeleteTemp: (key) => {
      dispatch(createDeleteTemp(key));
    },
    onCreateSmartCheckout: (items, job_id, parent_id, target_type, sales_rep_id, receiver_email, comment) => {
      dispatch(createSmartCheckout(items, job_id, parent_id, target_type, sales_rep_id, receiver_email, comment));
    }
  }
}

const ConnectedCartSummaryPopup = connect(mapStateToProps, mapDispatchToProps)(CartSummaryPopup);
export default ConnectedCartSummaryPopup;

