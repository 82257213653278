import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DragSource, DropTarget } from 'react-dnd';

import { Row, Col, Artwork, Theme, Tab, TabBar, Button } from '@commonsku/styles'
import Files from './Files'
import Select from './Select'

import { createUploadProductImagePopup, createUploadFilesPopup, closePopup } from '../actions/popup';
import { createAddItemImage, createDeleteItemImage, createUpdateItemImage } from '../actions';

import { getItemImagesByItemId, getProductImagesByItemId } from '../selectors';

import { getFolderOptions } from '../selectors/dropdowns'

import { BASE_ZINDEX } from '../popup-factory';
import { getImageSrc } from '../utils';
import DragItemTypes from '../DragItemTypes';

const imageDragSource = {
  beginDrag(props) {
    return {
      id: props.item.item_image_id,
      originalIndex: props.findIndex(props.item.item_image_id)
    };
  },

  endDrag(props, monitor) {
    const { id: droppedId, originalIndex } = monitor.getItem();
    const didDrop = monitor.didDrop();
    if (!didDrop) {
      props.moveImage(droppedId, originalIndex);
    } else {
      props.dropImage(droppedId);
    }
  }
};

const imageDropTarget = {
  hover(props, monitor) {
    const { id: draggedId } = monitor.getItem();
    const overId = props.item.item_image_id;

    if (draggedId !== overId) {
      const overIndex = props.findIndex(overId);
      props.moveImage(draggedId, overIndex);
    }
  }
};

const DraggableImage = DropTarget(DragItemTypes.IMAGE, imageDropTarget, connect => ({
  connectDropTarget: connect.dropTarget()
}))(DragSource(DragItemTypes.IMAGE, imageDragSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
}))(({
  item,
  isDragging,
  connectDragSource,
  connectDropTarget,
}) => connectDragSource( 
  connectDropTarget(
    <div>
      <Artwork picture={getImageSrc(item.image, 'medium')} />
    </div>
  )
)));

class SelectItemImagesPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      hover: false,
      item_images: props.item_images,
      selectedTab: 'item',
      folder_id: 'all'
    };

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.findIndex = this.findIndex.bind(this);
    this.moveImage = this.moveImage.bind(this);
    this.dropImage = this.dropImage.bind(this);
    this.handleChangeFolder = this.handleChangeFolder.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.item_images.length !== this.state.item_images.length) {
      this.setState({ item_images: nextProps.item_images });
    }
  }

  onMouseEnter(item_image_id) {
    return e => this.setState({ hover: item_image_id });
  }

  onMouseLeave() {
    this.setState({ hover: false });
  }

  findIndex(item_image_id) {
    return this.state.item_images.map(ii => ii.item_image_id).indexOf(item_image_id);
  }

  moveImage(item_image_id, index) {
    this.setState((prevState, props) => {
      const item_images = prevState.item_images.slice();
      const origIndex = item_images.map(ii => ii.item_image_id).indexOf(item_image_id);
      item_images.splice(index, 0, item_images.splice(origIndex, 1)[0]);
      return {
        item_images
      };
    })
  }

  dropImage(item_image_id) {
    const index = this.findIndex(item_image_id);
    this.props.onUpdateItemImage(item_image_id, index);
  }

  handleChangeFolder(folder_id) {
    this.setState({ folder_id })
  }

  renderTabs() {
    const { folders, onCreateUploadFilesPopup, onCreateUploadProductImagePopup, locked, is_project, client_id } = this.props
    const { selectedTab, folder_id } = this.state
    let tabs = [
      {
        key: 'item',
        label: 'Item Files'
      }
    ]
    if(is_project){
      tabs.push({
        key: 'project',
        label: 'Project Files'
      })
    }
    if(client_id) {
      tabs.push({
        key: 'client',
        label: 'Client Files'
      })
    }

    return (
      <Row>
        <Col xs={6}>
          <TabBar>
            {tabs.map((tab, index) => <Tab key={index}
              selected={tab.key == selectedTab}
              onClick={() => {this.setState({ selectedTab: tab.key })}}>
              {tab.label}
            </Tab>)}
          </TabBar>
        </Col>
        <Col xs={4}>
          {selectedTab === 'client' ? 
            <Select 
              options={folders} 
              value={folder_id} 
              change={this.handleChangeFolder}
              style={{ width: '150px', float: 'right', 'zIndex': 1008, marginTop: '10px' }}
            />
          : null}
        </Col>
        <Col xs={2}>
          {!locked && 
            <Button style={{ float: 'right' }} type="secondary" onClick={(e) => is_project ? onCreateUploadFilesPopup(client_id) : onCreateUploadProductImagePopup()}>Upload</Button>
          }
        </Col>
      </Row>
    )
  }

  renderTabContent() {
    const { job_id, client_id, onAddProductImage, product_images } = this.props
    const { selectedTab, folder_id, item_images } = this.state

    const item_image_file_ids = item_images.map(ii => ii.file_id);
    const available_product_images = product_images.filter(i => !item_image_file_ids.includes(i.file_id));

    let tbd = false
    if(selectedTab === 'project' || (selectedTab === 'client' && folder_id === 'all')) {
      tbd = true
    }

    switch(selectedTab) {
      case 'project':
        return <Files type="project" job_id={job_id} onSelectFile={onAddProductImage} exclude_file_ids={item_image_file_ids} tbd={tbd} />
      case 'client':
        return <Files type="client" client_id={client_id} folder_id={folder_id !== 'all' ? folder_id : null} onSelectFile={onAddProductImage} exclude_file_ids={item_image_file_ids} tbd={tbd} />
      default:
        return <Files type="item" onSelectFile={onAddProductImage} files={available_product_images} tbd={tbd} />
    }
  }

  render() {
    const {
      onDeleteItemImage, 
      onClosePopup, 
      index, 
      locked = true 
    } = this.props;
    const { item_images, hover } = this.state;

    const imageContainerStyle = {
      position: 'relative'
    };

    return (
      <div className="reveal large" style={{ display: 'block', height: 'auto', zIndex: BASE_ZINDEX + index }} role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3>{!locked && 'Select '}Files</h3>
            <a className="button" style={{ position: 'fixed', top: '1rem', right: '1rem' }} onClick={() => onClosePopup()}>Close</a>
          </div>
        </div>
        <div className="row full-width popup-content" style={{ height: 'calc(600px - 2rem)' }}>
          <Theme>
            <div className="row">
              {!locked && item_images.length > 1 && <p>Drag images to rearrange</p>}
              {item_images.map((i, index) =>
                <div key={i.item_image_id} className="small-6 medium-4 large-2 columns end" style={imageContainerStyle} onMouseEnter={this.onMouseEnter(i.item_image_id)} onMouseLeave={this.onMouseLeave}>
                  {locked || item_images.length === 1 ?
                    <Artwork picture={getImageSrc(i.image, 'medium')} /> 
                  : 
                    <DraggableImage item={i} findIndex={this.findIndex} moveImage={this.moveImage} dropImage={this.dropImage} />}
                  {!locked && item_images.length > 1 && hover === i.item_image_id && <a className="button" style={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }} onClick={() => onDeleteItemImage(i.item_image_id)}>Remove</a>}
                </div>
              )}
            </div>
            {!locked &&
              <Row>
                <Col xs={12}>
                  <p>Click images below to add to item</p>
                </Col>
                <Col xs={12}>
                  {this.renderTabs()}
                </Col>
                <Col xs={12}>
                  {this.renderTabContent()}
                </Col>
              </Row>
            }
          </Theme>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const is_project = /\/project\//.test(state.router.location.pathname)
  let job_id = null
  let client_id = ownProps.account_id
  if(is_project) {
    job_id = Object.keys(state.entities.projects)[0]
  }
  let folders = [{key: 'all', value: 'All Files'}].concat(getFolderOptions(state))

  return {
    item_images: getItemImagesByItemId(state, ownProps),
    product_images: getProductImagesByItemId(state, ownProps),
    is_project,
    folders,
    job_id,
    client_id
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCreateUploadProductImagePopup: () => dispatch(createUploadProductImagePopup(ownProps.item_id, ownProps.account_id, ownProps.account_type, false)),
  onAddProductImage: file => dispatch(createAddItemImage(ownProps.item_id, file.file_id)),
  onDeleteItemImage: item_image_id => dispatch(createDeleteItemImage(item_image_id)),
  onUpdateItemImage: (item_image_id, index) => dispatch(createUpdateItemImage(item_image_id, index)),
  onClosePopup: () => dispatch(closePopup()),
  onCreateUploadFilesPopup: (client_id) => dispatch(createUploadFilesPopup(ownProps.item_id, 'ITEM', client_id, true, true))
});

const ConnectedSelectItemImagesPopup = connect(mapStateToProps, mapDispatchToProps)(SelectItemImagesPopup);
export default ConnectedSelectItemImagesPopup;
