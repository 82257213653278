import { oauth } from '../utils'
import { push } from 'connected-react-router'
import { AddOrderToProject } from './project'
import { UPDATE_ORDER_SUCCESS } from './order'

const createUpdatePreviousOrder = (order_id, data) => ({
  type: UPDATE_ORDER_SUCCESS,
  payload: {
    id: order_id,
    data
  }
});

export const upgradeEstimate = (items, parent_id, target_type, job_id, project_job_number) => (dispatch) => {
  var data = {
    items,
    parent_id,
    target_type,
    job_id
  };

  return oauth('POST', 'order', data).then(
    ({ json }) => {
      dispatch(createUpdatePreviousOrder(parent_id, json.previous_order));
      dispatch(AddOrderToProject(json.order, json.taxes));
      dispatch(push('/project/' + project_job_number + '/sales-order/' + json.order['form_number']));
      dispatch(push('/project/' + project_job_number + '/sales-order/' + json.order['form_number']));
    }
  ); 
}

export const upgradeToEstimate = (items, parent_id, target_type, job_id, project_job_number) => (dispatch) => {
  var data = {
    items,
    parent_id,
    target_type,
    job_id
  };

  return oauth('POST', 'order', data).then(
    ({ json }) => {
      dispatch(createUpdatePreviousOrder(parent_id, json.previous_order));
      dispatch(AddOrderToProject(json.order, json.taxes));
      dispatch(push('/project/' + project_job_number + '/estimate/' + json.order['form_number']));
      dispatch(push('/project/' + project_job_number + '/estimate/' + json.order['form_number']));
    }
  ); 
}

export const upgradeToSalesOrder = (items, parent_id, target_type, job_id, project_job_number) => (dispatch) => {
  var data = {
    items,
    parent_id,
    target_type,
    job_id
  };

  return oauth('POST', 'order', data).then(
    ({ json }) => {
      dispatch(createUpdatePreviousOrder(parent_id, json.previous_order));
      dispatch(AddOrderToProject(json.order, json.taxes));
      dispatch(push('/project/' + project_job_number + '/sales-order/' + json.order['form_number']));
      dispatch(push('/project/' + project_job_number + '/sales-order/' + json.order['form_number']));
    }
  ); 
}

export const upgradeToInvoice = (parent_id, target_type, job_id, project_job_number) => (dispatch) => {
  var data = {
    parent_id,
    target_type,
    job_id
  };

  return oauth('POST', 'order', data).then(
    ({ json }) => {
      dispatch(createUpdatePreviousOrder(parent_id, json.previous_order));
      dispatch(AddOrderToProject(json.order, json.taxes));
      dispatch(push('/project/' + project_job_number + '/invoice/' + json.order['form_number']));
      dispatch(push('/project/' + project_job_number + '/invoice/' + json.order['form_number']));
    }
  ); 
}

