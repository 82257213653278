import { get, map, isEmpty, uniq, find } from 'lodash';

const VALID_Y_UOMS = ['Colors', 'Inches', 'Stitches', 'SquareInches'];

export const getUoms = (decoration) => {
  const uoms = {};
  map(get(decoration, 'charges') || [], (charge) => {
    map(charge.prices, ({ y_uom, y_min_qty }) => {
      uoms[y_uom] = (uoms[y_uom] || []).concat(y_min_qty);
    });
  });
  map(uoms, (quantities, y) => {
    if (VALID_Y_UOMS.includes(y)) {
      uoms[y] = uniq(quantities).sort((a, b) => a - b);
    } else {
      delete uoms[y];
    }
  });
  return uoms;
};

export const isPSChargeMandatory = (charge) => {
  const prices = get(charge, 'prices') || [];
  return !isEmpty(prices) && !find(prices, { x_min_qty: 0 });
};

const HIT_MANDATORY_CHARGES = [
  'SILK SCREEN',
  'TRANSFER',
  'EMBROIDERED',
  'LASER ENGRAVE',
  'DEBOSS',
  'PAD PRINT',
  'SETUP CHARGE',
  'DIRECT PRINT',
  '4 COLOR PROCESS MACHINE (X-JET)',
  'FOUR COLOR PROCESS',
  'HEAT TRANSFER',
  'DOME PROCESS',
  'EMBROIDERY CHARGE',
  'APPAREL DIRECT PRINT',
  'CLEAR INSERT',
  'SILVER GLITTER',
  'SILVER MIRROR',
  'WHITE INSERT',
  'DE-BOSS',
  'HP PRINTER',
  'TAPE CHARGE',
  'CB DRINKWARE',
  'RUN CHARGE',
  'FLEXOGRAPHY',
  'PAD PRINT - 2C',
  'ETCH PROCESS',
  'DIRECT IMPRINT',
  'DIRECT TO GARMENT CHARGE',
  'SETUP',
  'EMBROIDERY',
  'DIRECT TO GARMENT',
  '4-COLOR PROCESS ON A WHITE LABEL',
  '4-COLOR PROCESS ON A CLEAR LABEL',
  'COLORBRITE DRINKWARE',
  'SILK-SCREEN',
  'DYE-SUBLIMATION',
  'SUBLIMATION PROCESS',
  '4 COLOR PROCESS',
  '4-COLOR PROCESS',
  '3D EMBROIDERY',
  'OFFSET PRINTING',
  'DYE SUBLIMATION',
  'CALENDARS FOIL STAMP',
  'COLORBRITE',
  'SANDBLASTING',
  'CB DRINKWARE SMALL',
  'CB DRINKWARE LARGE',
  'LASER ENGRAVING',
  'PAD-PRINT',
  'OVERSIZED VERTICAL IMPRINT'
];

export const isChargeMandatory = (quirks, division_id, charge) => {
  if (!!charge && !!quirks && !!quirks[division_id]) {
    if (!!quirks[division_id]['MANDATORY-COST-CONVENTION']) {
      const prices = get(charge, 'prices') || [];
      return !isEmpty(prices) && !find(prices, { x_min_qty: 0 });
    } else if (!!quirks[division_id]['HIT-MANDATORY-COST-CONVENTION']) {
      return 'SETUP' === charge.charge_type.toUpperCase() || HIT_MANDATORY_CHARGES.includes(charge.charge_name.toUpperCase());
    }
  }
  return false;
};

export const getConfigurationDecoration = (configuration, ext_location_id, ext_decoration_id) => {
  const location = find(get(configuration, 'locations'), ({ ps_location_id }) => `${ps_location_id}` == ext_location_id);
  return find(get(location, 'decorations'), ({ ps_decoration_id }) => `${ps_decoration_id}` == ext_decoration_id);
}

const UOM_MAP = {
  'Colors': {
    zero: 'Colors',
    singular: 'Color',
    multiple: 'Colors'
  },
  'Stitches': {
    zero: 'Stitches',
    singular: 'Stitch',
    multiple: 'Stitches'
  },
  'Inches': {
    zero: 'Inches',
    singular: 'Inch',
    multiple: 'Inches'
  },
  'SquareInches': {
    zero: 'Square Inches',
    singular: 'Square Inch',
    multiple: 'Square Inches'
  },
  'Other': {
    zero: '',
    singular: '',
    multiple: ''
  }
};

export const getUomName = (quantity, uom) => {
  const key = quantity ==  0 ? 'zero' : (quantity == 1 ? 'singular' : 'multiple');
  return UOM_MAP[uom][key];
};

