import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getContact } from '../selectors';
import { getContactOptions } from '../selectors/dropdowns';

import { closePopup } from '../actions/popup';
import { createAddContact, createCopyContact } from '../actions/contact';

import { BASE_ZINDEX } from '../popup-factory';

import Select from './Select';

class AddShopContactPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      contact_id: '',
      contact_name: '',
      contact_email: ''
    };

    this.handleAddContact = this.handleAddContact.bind(this);
    this.onChangeContact = this.onChangeContact.bind(this);
  }

  onChangeContact(contact_id) {
    if ('ADD' === contact_id) {
      this.setState({
        contact_id,
        contact_name: '',
        contact_email: ''
      });
    } else {
      const contact = this.props.getContact(contact_id);
      this.setState({
        ...contact,
        contact_id
      });
    }
  }

  handleAddContact(e) {
    e.preventDefault();
    const {
      contact_id,
      contact_name,
      contact_email
    } = this.state;

    if (!contact_id) {
      return;
    }
    if ('ADD' === contact_id) {
      if (!contact_name.trim() || !contact_email.trim()) {
        return;
      }
      this.setState({
        contact_id: '',
        contact_name: '',
        contact_email: ''
      }, () => this.props.onAddNewContact(contact_name, contact_email));
    } else {
      this.setState({
        contact_id: '',
        contact_name: '',
        contact_email: ''
      }, () => this.props.onAddClientContact(contact_id));
    }
  }

  render() {
    const { client_contacts, index, onClosePopup } = this.props;
    const { contact_id, contact_name, contact_email } = this.state;

    return (
      <div className="reveal tiny" style={{ display: 'block', zIndex: BASE_ZINDEX + index, height: 'initial', overflow: 'visible' }}>
        <div className="row small-12 columns">
          <h3>Add Contact</h3>
          <a className="button" style={{ position: 'absolute', right: '1rem', top: '1rem' }} onClick={onClosePopup}>Done</a>
        </div>
        <div className="row small-12 columns">
          <Select options={client_contacts} value={contact_id} placeholder="Select from client" change={this.onChangeContact} />
          <input type="text" disabled={'ADD' !== contact_id} value={contact_name} placeholder={'ADD' === contact_id ? 'Enter Full Name' : ''} onChange={e => this.setState({ contact_name: e.target.value })} />
          <input type="text" disabled={'ADD' !== contact_id} value={contact_email} placeholder={'ADD' === contact_id ? 'Enter Email Address' : ''} onChange={e => this.setState({ contact_email: e.target.value })} />
          <a className="button" onClick={this.handleAddContact}>Add</a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  client_contacts: [{ key: 'ADD', value: 'Add New' }].concat(getContactOptions(state, { parent_id: ownProps.client_id })),
  getContact: contact_id => {
    const contact = getContact(state, { contact_id }) || {};
    return {
      contact_id,
      contact_name: `${contact.contact_first_name} ${contact.contact_last_name}`,
      contact_email: contact.contact_email
    };
  }
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClosePopup: () => dispatch(closePopup()),
  onAddClientContact: contact_id => dispatch(createCopyContact(contact_id, ownProps.shop_id, 'SHOP')),
  onAddNewContact: (contact_name, contact_email) => dispatch(createAddContact({
    contact_name,
    contact_email,
    parent_id: ownProps.shop_id,
    parent_type: 'SHOP',
  }))
});

const ConnectedAddShopContactPopup = connect(mapStateToProps, mapDispatchToProps)(AddShopContactPopup);
export default ConnectedAddShopContactPopup;
