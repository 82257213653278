import React, { Component } from 'react';
import { connect } from 'react-redux';

import { BASE_ZINDEX } from '../popup-factory';

import { closePopup } from '../actions/popup';
import { createAddClient } from '../actions/client';
import { createAddTemp } from '../actions/temp';

import { getIndustryOptions, getUserOptions } from '../selectors/dropdowns';

import Form from './Form';

class CreateClientPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      client_name: props.client_name,
      sales_rep_id: props.client_rep_id
    };

    this.onFieldChange = this.onFieldChange.bind(this);
    this.onCreateClient = this.onCreateClient.bind(this);
    this.handleCreateClient = this.handleCreateClient.bind(this);
  }

  handleCreateClient(e) {
    e.preventDefault();
    this._form.submit();
  }

  onFieldChange(value, field) {
    this.setState({ [field]: value });
  }

  onCreateClient(values, errors) {
    if (!Object.values(errors).every(e => e === null)) {
      return;
    }

    const { onCreateClient } = this.props;
    const { client_name, industry_id, sales_rep_id } = this.state;
    onCreateClient(client_name, industry_id, sales_rep_id);
  }

  render() {
    const { index, industry_options, users, onClosePopup } = this.props;
    const { client_name, industry_id, sales_rep_id } = this.state;

    return (
      <div className="reveal small" style={{ display: 'block', zIndex: BASE_ZINDEX + index, height: 'initial', overflow: 'visible' }}>
        <div className="row small-12 columns">
          <h3>Create Client</h3>
          <a className="alert button" style={{ position: 'fixed', right: '6rem', top: '1rem' }} onClick={onClosePopup}>Cancel</a>
          <a className="button" style={{ position: 'fixed', right: '1rem', top: '1rem' }} onClick={this.handleCreateClient}>Create</a>
        </div>
        <div className="row small-12 columns">
          <Form
            className="row small-12 columns"
            ref={ref => this._form = ref}
            onFieldChange={this.onFieldChange}
            onSubmit={this.onCreateClient}
          >
            <Form.TextInput
              field="client_name"
              label="Client Name"
              value={client_name}
              placeholder="Client Name"
              required={true}
            />
            <Form.Select
              field="industry_id"
              label="Industry"
              value={industry_id}
              options={industry_options}
              placeholder="Industry"
              searchable={true}
              required={true}
            />
            <Form.Select
              field="sales_rep_id"
              label="Client Rep"
              value={sales_rep_id}
              options={users}
              placeholder="Client Rep"
              searchable={true}
              required={true}
            />
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  industry_options: getIndustryOptions(state),
  users: getUserOptions(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClosePopup: () => dispatch(closePopup()),
  onCreateClient: (client_name, industry_id, sales_rep_id) => dispatch(createAddClient(client_name, industry_id, sales_rep_id)).then(
    action => {
      dispatch(createAddTemp('NEW_CLIENT', action.payload.client.client_id));
      dispatch(closePopup());
    }
  )
});

const ConnectedCreateClientPopup = connect(mapStateToProps, mapDispatchToProps)(CreateClientPopup);
export default ConnectedCreateClientPopup;
