import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Dropzone from 'react-dropzone';

import Form from './Form'

import { closePopup, createAddThirdPartyShippingAccountPopup, createEditAddressPopup, createSelectFilePopup } from '../actions/popup'
import { createLoadDivisionAddresses } from '../actions/supplier'
import { createUpdateShipping, createLoadShipMethods } from '../actions/shipping'
import { createAddTemp, createDeleteTemp } from '../actions/temp'
import { createUploadFile } from '../actions/file';
import { getCompanyData } from '../selectors'
import { 
  getShipMethodOptions, getSupplierAccountOptions, getAddressOptions, getThirdPartyShippingAccountDropdown
} from '../selectors/dropdowns'
import { formatShortDate, parseDate, getIdentityUtils, oauth } from '../utils'
import { BASE_ZINDEX } from '../popup-factory'

const TEMP_SUPPLIER_REGISTER = 'division_id';
const TEMP_FILE_REGISTER = 'split_shipping_file_id'

const formatAddress = ({ address_line_1, address_line_2, address_city, address_state, address_postal }) => (
  <div className="small">
    {address_line_1}
    <br />
    {address_line_2}
    <br />
    {address_city}, {address_state}
    <br />
    {address_postal}
  </div>
)

const sortThirdPartyAccounts = (a, b) => {
  const cmp = (a, b) => {
    if (a.account_name < b.account_name) {
      return -1;
    }
    if (a.account_name > b.account_name) {
      return 1;
    }
    return 0;
  };
  if (!a.client_id) {
    if (!b.client_id) {
      return cmp(a, b);
    }
    return -1;
  }
  if (!b.client_id) {
    return 1;
  }
  return cmp(a, b);
};

class EditShippingPopup extends Component {

  constructor(props) {
    super(props);

    const vendor_details = this.getVendorDetails();
    const decorator_details = this.getDecoratorDetails();
    const vendor_address_id = vendor_details.address_id || this.props.items[0].default_shipping_address_id;
    const vendor_destination_type = 'SUPPLIER' === vendor_details.destination_type ? ('VENDOR-DECORATOR' === vendor_details.source_type ? 'OTHER' : 'SUPPLIER') : vendor_details.destination_type || 'CLIENT';
    const default_vendor_third_party_account_id = !!vendor_details.third_party_account_id ? vendor_details.third_party_account_id : (vendor_details.ship_method_id ? props.default_third_party_shipping_accounts[props.getShipMethodDetails(vendor_details.ship_method_id).courier_id] : 'NONE');
    const decorator_ship_method_id = decorator_details.ship_method_id ? decorator_details.ship_method_id : this.props.decorator_ship_methods[0].key;
    const default_decorator_third_party_account_id = !!decorator_details.third_party_account_id ? decorator_details.third_party_account_id : (decorator_ship_method_id ? props.default_third_party_shipping_accounts[props.getShipMethodDetails(decorator_ship_method_id).courier_id] : 'NONE');
    this.state = {
      updating: false,
      vendor_address_id: vendor_address_id,
      vendor_source_parent_id: vendor_details.source_parent_id,
      vendor_destination_parent_id: this.getDestinationParentId(this.props),
      vendor_destination_type,
      decorator_address_id: decorator_details.address_id,
      decorator_destination_parent_id: decorator_details.destination_parent_id,
      decorator_destination_type: decorator_details.destination_type,
      vendor_ship_method_id: vendor_details.ship_method_id,
      vendor_third_party_account_id: default_vendor_third_party_account_id,
      vendor_ship_date: this.getFormatShipdate(vendor_details),
      vendor_firm: vendor_details.firm,
      vendor_ship_notes: vendor_details.ship_notes,
      vendor_ship_method_other: vendor_details.ship_method_other,
      vendor_ship_method_details: vendor_details.ship_method_details,
      vendor_split_shipping_id: vendor_details.split_shipping_id,
      vendor_is_rush: vendor_details.is_rush,
      decorator_ship_method_id,
      decorator_third_party_account_id: default_decorator_third_party_account_id,
      decorator_ship_date: this.getFormatShipdate(decorator_details),
      decorator_firm: decorator_details.firm,
      decorator_ship_notes: decorator_details.ship_notes,
      vendor_address: this.formatAddress(this.props.addresses, vendor_address_id),
      decorator_address: this.formatAddress(this.props.addresses, decorator_details.address_id),
      decorator_ship_method_other: decorator_details.ship_method_other,
      decorator_ship_method_details: decorator_details.ship_method_details,
      decorator_split_shipping_id: decorator_details.split_shipping_id,
      decorator_is_rush: decorator_details.is_rush,
      split_ship_name: vendor_details.split_shipping ? vendor_details.split_shipping.file_display_name : (decorator_details.split_shipping ? decorator_details.split_shipping.file_display_name : null),
      dateAfterSO: {},
    };

    _.bindAll(this, ['onFormRef', 'handleFieldChange', 'onUpdateShipping', 'handleRemoveSplitShipping', 'handleAddSplitShipping']);

  }

  componentDidMount() {
    const { items, psEligible } = this.props;

    if (this.state.vendor_destination_parent_id) {
      this.loadSupplierAddresses(this.props);
    }
    if (psEligible) {
      this.props.loadSupplierShipMethods(items[0].division_id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { division_addresses } = nextProps;
    if (['SUPPLIER', 'OTHER'].includes(this.state.vendor_destination_type) && (division_addresses.length || nextProps.addresses)) {
      const vendor_address_id = (
        _.find(division_addresses, {key: this.state.vendor_address_id}) || 
        _.find(division_addresses) || {}
      ).key || '';
      this.setState({
        updating: false,
        vendor_address_id,
        vendor_address: this.formatAddress(nextProps.addresses, vendor_address_id),
      });
    }

    const getThirdPartyAccountId = (ship_method_id, current_third_party_account_id) => {
      if (!ship_method_id) {
        return null;
      }
      const ship_method_details = nextProps.getShipMethodDetails(ship_method_id);
      if (!ship_method_details) {
        return null;
      }
      const third_party = ship_method_details.third_party;
      const courier_id = ship_method_details.courier_id;
      if (!third_party) {
        return current_third_party_account_id;
      }
      if (third_party === 'UNAVAILABLE') {
        return null;
      }
      const third_party_account_id = nextProps.default_third_party_shipping_accounts[courier_id];
      if (third_party_account_id) {
        return third_party_account_id;
      }
      if (current_third_party_account_id) {
        const tpa_courier_id = nextProps.third_party_accounts.filter(tpa => current_third_party_account_id === tpa.third_party_account_id).map(tpa => tpa.courier_id)[0];
        if (tpa_courier_id === courier_id && third_party !== 'UNAVAILABLE') {
          return current_third_party_account_id;
        }
      }
      return 'NONE';
    }

    const vendor_details = this.getVendorDetails();
    let vendor_ship_method_id;
    let vendor_third_party_account_id;
    let vendor_ship_method_details;
    if (nextProps.vendor_ship_methods.length !== this.props.vendor_ship_methods.length) {
      if (!this.props.psEligible && (!vendor_details.ship_method_id || nextProps.vendor_ship_methods.filter(sm => sm.key === vendor_details.ship_method_id).length === 0)) {
        vendor_ship_method_id = nextProps.vendor_ship_methods[0].key;
        vendor_third_party_account_id = getThirdPartyAccountId(vendor_ship_method_id, this.state.vendor_third_party_account_id);
        this.setState({
          vendor_ship_method_id,
          vendor_third_party_account_id
        });
      } else {
        if (nextProps.vendor_ship_methods.filter(sm => sm.key === this.state.vendor_ship_method_id).length === 0) {
          vendor_ship_method_id = vendor_details.ship_method_id;
          vendor_third_party_account_id = getThirdPartyAccountId(vendor_ship_method_id, this.state.vendor_third_party_account_id);
          this.setState({
            vendor_ship_method_id,
            vendor_third_party_account_id
          });
        } else {
          vendor_third_party_account_id = getThirdPartyAccountId(this.state.vendor_ship_method_id, this.state.vendor_third_party_account_id);
          this.setState({ vendor_third_party_account_id });
        }
      }
    } else {
      vendor_third_party_account_id = getThirdPartyAccountId(this.state.vendor_ship_method_id, this.state.vendor_third_party_account_id);
      this.setState({ vendor_third_party_account_id });
    }
    const decorator_details = this.getDecoratorDetails();
    let decorator_ship_method_id;
    let decorator_third_party_account_id;
    if (nextProps.decorator_ship_methods.length !== this.props.decorator_ship_methods.length) {
      if (!decorator_details.ship_method_id || nextProps.decorator_ship_methods.filter(sm => sm.key === decorator_details.ship_method_id).length === 0) {
        decorator_ship_method_id = nextProps.decorator_ship_methods[0].key;
        decorator_third_party_account_id = getThirdPartyAccountId(decorator_ship_method_id, this.state.decorator_third_party_account_id);
        this.setState({
          decorator_ship_method_id,
          decorator_third_party_account_id
        });
      } else {
        if (nextProps.decorator_ship_methods.filter(sm => sm.key === this.state.decorator_ship_method_id).length === 0) {
          decorator_ship_method_id = decorator_details.ship_method_id;
          decorator_third_party_account_id = getThirdPartyAccountId(decorator_ship_method_id, this.state.decorator_third_party_account_id);
          this.setState({
            decorator_ship_method_id,
            decorator_third_party_account_id
          });
        } else {
          decorator_third_party_account_id = getThirdPartyAccountId(this.state.decorator_ship_method_id, this.state.decorator_third_party_account_id);
          this.setState({ decorator_third_party_account_id });
        }
      }
    } else {
      decorator_third_party_account_id = getThirdPartyAccountId(this.state.decorator_ship_method_id, this.state.decorator_third_party_account_id);
      this.setState({ decorator_third_party_account_id });
    }
    if (
      this.getDestinationParentId(nextProps) !== 
      this.getDestinationParentId(this.props)
    ) {
      this.loadSupplierAddresses(nextProps);
    }

    if(nextProps.split_shipping_files) {
      this.handleAddSplitShipping(nextProps.split_shipping_files)
    }
  }

  onFormRef(form) {
    this._form = form;
  }

  formatAddress(addresses, address_id) {
    return addresses[address_id] ? formatAddress(addresses[address_id]) : undefined;
  }

  loadSupplierAddresses(props) {
    const {
      items,
      divisions,
      loadSupplierAddresses,
    } = props;
    const vendor_destination_parent_id = this.getDestinationParentId(props);
    const vendor_destination_type = _.get(
      items, '0.vendor_details.destination_type', 'CLIENT');

    if (vendor_destination_parent_id && 
      (vendor_destination_type === 'SUPPLIER' || vendor_destination_type === 'OTHER')) {
      const supplier_account_id = _.get(
        divisions, vendor_destination_parent_id + '.supplier_account_id');
      if (supplier_account_id) {
        loadSupplierAddresses(supplier_account_id);
      }
    }
  }

  getDestinationParentId(props) {
    const { items, client } = props;
    return _.get(items, 
      '0.vendor_details.destination_parent_id', client.client_id);
  }

  validateSOInHandsDate() {
    const ihd = parseDate(this.props.in_hands_date);
    const { 
      vendor_ship_date, decorator_ship_date, vendor_destination_type 
    } = this.state;
    const dateAfterSO = {};
    if (parseDate(vendor_ship_date) > ihd) {
      dateAfterSO.vendor = true;
    }
    if ('SUPPLIER' === vendor_destination_type && 
      parseDate(decorator_ship_date) > ihd
    ) {
      dateAfterSO.decorator = true;
    }
    if (!_.isEqual(this.state.dateAfterSO, dateAfterSO)) {
      this.setState({ dateAfterSO });
    }
  }

  handleFieldChange(value, field) {
    if (value === this.state[field]) {
      return;
    }

    this.setState({[field]: value}, () => {
      switch (field) {
        case 'vendor_ship_date':
          this._form.validateField('decorator_ship_date');
          this.validateSOInHandsDate();
          break;
        case 'decorator_ship_date':
        case 'vendor_destination_type':
          this._form.validateField('vendor_ship_date');
          this.validateSOInHandsDate();
          break;
      }
    });

    switch (field) {
      case 'vendor_ship_method_id':
        this.setState({
          vendor_third_party_account_id: 'NONE'
        });
        break;
      case 'decorator_ship_method_id':
        this.setState({
          decorator_third_party_account_id: 'NONE'
        });
        break;
      case 'vendor_ship_date':
        this._vendor_ship_date_changed = true;
        break;
      case 'vendor_destination_parent_id':
        this.setState({
          vendor_address_id: '',
          vendor_address: undefined,
          updating: true
        });
        this.props.loadSupplierAddresses(this.props.divisions[value].supplier_account_id);
        break;
      case 'vendor_destination_type':
        const vendor_details = this.getVendorDetails();
        if (value !== 'SUPPLIER' && !this.state.vendor_ship_date) {
          this.setState({ 
            vendor_ship_date: this.getFormatShipdate(vendor_details),
          });
        }
        if ('CLIENT' === value) {
          this.setState({
            vendor_destination_parent_id: this.props.client.client_id,
            vendor_address_id: this.props.items[0].default_shipping_address_id,
            vendor_address: this.formatAddress(this.props.addresses, this.props.items[0].default_shipping_address_id)
          });
        } else if ('TENANT' === value) {
          this.setState({
            vendor_destination_parent_id: this.props.company.company_id,
            vendor_address_id: this.props.tenant_default_address_id ? this.props.tenant_default_address_id : this.props.company_addresses[0].key,
            vendor_address: this.formatAddress(this.props.addresses, this.props.tenant_default_address_id ? this.props.tenant_default_address_id : this.props.company_addresses[0].key)
          });
        } else {
          const addresses = this.props.getAddresses(value);
          const address_id = addresses.length ? addresses[0].key : '';
          this.setState({
            vendor_destination_parent_id: '',
            vendor_address_id: '',
            vendor_address: undefined,
            updating: true,
            decorator_destination_type: 'CLIENT',
            decorator_destination_parent_id: this.props.client.client_id,
            decorator_address_id: this.props.items[0].default_shipping_address_id,
            decorator_address: this.formatAddress(this.props.addresses, this.props.items[0].default_shipping_address_id)
          });
        }
        break;
      case 'decorator_destination_type':
        if ('CLIENT' === value) {
          this.setState({
            decorator_destination_parent_id: this.props.client.client_id,
            decorator_address_id: this.props.items[0].default_shipping_address_id,
            decorator_address: this.formatAddress(this.props.addresses, this.props.items[0].default_shipping_address_id)
          });
        } else if ('TENANT' === value) {
          this.setState({
            decorator_destination_parent_id: this.props.company.company_id,
            decorator_address_id: this.props.tenant_default_address_id ? this.props.tenant_default_address_id : this.props.company_addresses[0].key,
            decorator_address: this.formatAddress(this.props.addresses, this.props.tenant_default_address_id ? this.props.tenant_default_address_id : this.props.company_addresses[0].key)
          });
        }
        break;
      case 'vendor_address_id':
        this.setState((state) => ({ vendor_address: this.formatAddress(this.props.addresses, value), vendor_split_shipping_id: 'SPLIT-SHIPPING' !== state.vendor_address_id ? null : state.vendor_split_shipping_id }));
        break;
      case 'decorator_address_id':
        this.setState((state) => ({ decorator_address: this.formatAddress(this.props.addresses, value), decorator_split_shipping_id: 'SPLIT-SHIPPING' !== state.decorator_address_id ? null : state.decorator_split_shipping_id }));
        break;
    }
  }

  handleRemoveSplitShipping() {
    this.setState({
      temp_split_ship_file: null,
      split_ship_name: null,
      vendor_split_shipping_id: '',
      decorator_split_shipping_id: ''
    });
  }

  handleAddSplitShipping(files) {
    const file = files[0];
    this.setState({
      temp_split_ship_file: file.file_id,
      split_ship_name: file.file_display_name,
      vendor_split_shipping_id: '',
      decorator_split_shipping_id: ''
    });
  }

  getShipdateOption(ship_details) {
    return _.get(ship_details, 'shipdate_option') || 
      this.props.company_data.default_shipdate_option
    ;
  }

  getShipdateFieldName(ship_details) {
    return this.getShipdateOption(ship_details) === 'IN-HANDS-DATE' 
      ? 'date_inhandsdate' : 'date_shipdate';
  }

  getFormatShipdate(ship_details) {
    let date = ship_details[this.getShipdateFieldName(ship_details)];
    return this.formatShortDate(date || Date.now());
  }

  formatShortDate(date) {
    if (_.isString(date)) {
      date = date && parseDate(date);
    }
    return date ? formatShortDate(date/1000) : null;
  }

  getVendorDetails() {
    return _.get(this.props.items, '0.vendor_details') || {}
  }

  getDecoratorDetails() {
    return _.get(this.props.items, '0.decorator_details') || {};
  }

  renderShipdate(ship_details, shipdate_field, firm_field) {
    const { 
      quirks,
      psEligible,
      in_hands_date,
      company_data: { default_shipdate_option },
    } = this.props;
    const { dateAfterSO } = this.state;
    const shipdate_option = this.getShipdateOption(ship_details);
    let shipdateMessage = 'ship date';
    if (shipdate_option === 'IN-HANDS-DATE') {
      shipdateMessage = 'in hands date';
    }
    const hasRush = division_id => psEligible && quirks && quirks[division_id] && quirks[division_id]['HAS-RUSH'];
    const rush_field = hasRush('vendor_ship_date' === shipdate_field ? this.state.vendor_source_parent_id : this.state.vendor_destination_parent_id);
    const validate = (value, p, error) => {
      const { vendor_ship_date, decorator_ship_date, vendor_destination_type } = {
        ...this.state,
        [shipdate_field]: value,
      };
      if ('SUPPLIER' === vendor_destination_type && 
        parseDate(vendor_ship_date) > parseDate(decorator_ship_date)
      ) {
        let errorMessage = '';
        if (parseDate(vendor_ship_date) > parseDate(decorator_ship_date)) {
          errorMessage = `Vendor ${shipdateMessage} should before decorator ${shipdateMessage}`
        } else {
          if (shipdate_field === 'vendor_ship_date') {
            errorMessage = `Vendor ${shipdateMessage} should before sales order in hands date.`
          } else {
            errorMessage = `Decorator ${shipdateMessage} should before sales order in hands date.`
          }
        }
        return {
          ...(error || {}),
          message: <div>
            <span>Error: Inconsistent Date </span>
          </div>,
        };
      }
      return error;
    }
    const label = shipdate_option !== 'IN-HANDS-DATE' 
      ? 'Shipping Date' : 'In Hands Date'
    ;
    return <div className="field row">
        <div className="small-12 medium-4 columns">
          <strong>{label}</strong>
        </div>
        <div className="small-12 medium-8 columns">
          <div className="row collapse">
            {shipdate_field === 'vendor_ship_date' && dateAfterSO.vendor ? <span>{
              `Warning: After sales order in hands date.`
            }</span> : null}
            {shipdate_field === 'decorator_ship_date' && dateAfterSO.decorator ? <span>{
              `Warning: After sales order in hands date.`
            }</span> : null}
            <Form.DateInput field={shipdate_field}
              containerClassName={
                `field small-12 medium-${firm_field && rush_field ? '6' : ((!firm_field && !rush_field) ? '12' : '9')} columns`}
              containerStyle={{ paddingTop: 0 }}
              label={label}
              displayLabel={false}
              required={true}
              validate={validate}
            />
            {!firm_field ? null : <Form.Checkbox field={firm_field} 
              containerClassName="field small-12 medium-3 columns"
              containerStyle={{ paddingTop: '0.5rem' }}
              style={{ marginLeft: '10px' }}
              displayLabel={false}
              extra={<span style={{ marginLeft: 5 }}>Firm</span>}
            />}
            {rush_field && <Form.Checkbox field={'vendor_ship_date' === shipdate_field ? 'vendor_is_rush' : 'decorator_is_rush'}
              containerClassName="field small-12 medium-3 columns"
              containerStyle={{ paddingTop: '0.5rem' }}
              style={{ marginLeft: '10px' }}
              displayLabel={false}
              extra={<span style={{ marginLeft: 5 }}>Rush</span>}
            />}
          </div>
        </div>
      </div>
  }

  renderShippingAccount(field, ship_method_id) {
    const {
      getShipMethodDetails,
      onAddThirdPartyShippingAccount,
      third_party_accounts,
      company_avatar,
      canAddThirdPartyAccount
    } = this.props;

    if (!ship_method_id) {
      return null;
    }
    const shipMethodDetails = getShipMethodDetails(ship_method_id);
    if (!shipMethodDetails || 'UNAVAILABLE' === shipMethodDetails.third_party || !shipMethodDetails.courier_id) {
      return null;
    }
    const imgStyle = { maxWidth: '1.5rem', maxHeight: '1.5rem', marginRight: '0.25rem' };
    const options = ('AVAILABLE' === shipMethodDetails.third_party ? [{ key: 'NONE', value: 'Supplier Account' }] : []).concat(third_party_accounts.filter(tpa => tpa.courier_id === shipMethodDetails.courier_id).map(v => ({ key: v.third_party_account_id, value: !!v.client_id ? `${v.account_name} ${v.account_number} (${v.account_postal})` : <span><img style={imgStyle} src={company_avatar} alt="Distributor Shipping Account" />{v.account_name} {v.account_number} ({v.account_postal})</span> })));

    const handleClickAdd = e => {
      e.preventDefault();
      onAddThirdPartyShippingAccount(ship_method_id);
    };
    const extra = canAddThirdPartyAccount ? <a style={{ float: 'right', verticalAlign: 'top' }} className="button hollow" onClick={handleClickAdd}>+</a> : null;
    const style = canAddThirdPartyAccount ? { 'display': 'inline-block', width: '80%' } : null;
    return <Form.Select
      options={options}
      label="Shipping Account"
      field={field}
      required={'REQUIRED' === shipMethodDetails.third_party}
      placeholder={'REQUIRED' === shipMethodDetails.third_party && 'Choose a 3rd party account'}
      style={style}
      extra={extra}
    />;
  }

  renderSplitShip() {
    const { onCreateSelectFilePopup, items, job_id } = this.props

    return (
      <div className="row">
          <div className="small-12 medium-4 columns">
            &nbsp;
          </div>
          <div className="medium-8 columns">
            {!this.state.split_ship_name 
              ? <a className="button" onClick={e => {e.preventDefault(); onCreateSelectFilePopup(TEMP_FILE_REGISTER, job_id, 'JOB')}}>Upload Split Shipping</a> 
                : <p>{this.state.split_ship_name}&nbsp;<a onClick={this.handleRemoveSplitShipping}>&times;</a></p>}
          </div>
      </div>
    );
  }

  render() {
    const {
      vendor_ship_methods,
      decorator_ship_methods,
      client,
      company,
      client_addresses,
      company_addresses,
      division_addresses,
      supplier_accounts,
      in_hands_date,
      getAddresses,
      onClosePopup,
      index,
      items,
      psEligible,
      quirks,
      getShipMethodDetails,
      third_party_accounts
    } = this.props;
    const { vendor_destination_type, dateAfterSO, vendor_ship_method_id, decorator_ship_method_id } = this.state;

    const vendor_ship_to_options = [
      { key: 'CLIENT', value: `Client (${client.client_name})` },
      { key: 'SUPPLIER', value: 'Decorator' },
      { key: 'TENANT', value: company.company_name },
      { key: 'OTHER', value: 'Other Supplier' }
    ];

    const decorator_ship_to_options = [
      { key: 'CLIENT', value: `Client (${client.client_name})` },
      { key: 'TENANT', value: company.company_name }
    ];

    const hasShipNotes = division_id => !quirks || !quirks[division_id] || !quirks[division_id]['EXCLUDE-SHIP-NOTES'];
    const hasSplitShipping = division_id => quirks && quirks[division_id] && !!quirks[division_id]['SPLIT-SHIPPING-FILE'];

    const allClientAddresses = psEligible ? [{ key: 'SPLIT-SHIPPING', 'value': <span style={{ color: '#02C0DA' }}>Split Shipping</span> }].concat(client_addresses) : client_addresses;

    return (
      <div id="edit-shipping-modal" className="reveal large" style={{display: 'block', overflow: 'visible', zIndex: BASE_ZINDEX + index, height: '95%', maxHeight: 'initial' }} aria-labelledby="modalTitle" role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3 id="modalTitle">
              Editing shipping information&nbsp;&nbsp;&nbsp;
              <small 
                className={
                  dateAfterSO.vendor || dateAfterSO.decorator 
                    ? 'inconsistent-date' : ''
                } 
                style={{ fontSize: '16px', color: 'black', }}>
                Sales Order In Hands Date: {in_hands_date}
              </small>
            </h3>
            {psEligible && <div style={{ margin: '-1rem 0 1rem 0.5rem' }}>{items.length === 1 ? 'This item is' : 'These items are'} eligible for electronic purchase orders.</div>}
            <a className="alert button" 
              style={{ position: 'fixed', right: '5rem', top: '1rem' }} 
              onClick={e => onClosePopup()}>Cancel</a>
            <a className="button" 
              style={{position: 'fixed', right: '1rem', top: '1rem' }} 
              onClick={(e) => {
                e.preventDefault(); this._form && this._form.submit();
              }}>Save</a>
          </div>
        </div>
        <Form className="row popup-content form" style={{ height: '85%' }} ref={this.onFormRef}
          object={this.state}
          onFieldChange={this.handleFieldChange}
          onSubmit={this.onUpdateShipping}
        >
          <div className="small-12 medium-6 columns">
            <Form.Select searchable={true} options={supplier_accounts} disabled={psEligible}
              label="Supplier" field="vendor_source_parent_id"
            />
            {this.renderShipdate(
              this.getVendorDetails(), 'vendor_ship_date', 
              'SUPPLIER' === vendor_destination_type ? null : 'vendor_firm'
            )}
            <div className="field row">
              <div className="small-12 medium-4 columns">
                <strong>Ship To</strong>
              </div>
              <div className="small-12 medium-8 columns">
                {vendor_ship_to_options.map(v => 
                  <Form.Radio
                    key ={v.key}
                    label={v.value}
                    getValue={e => e.target.checked ? v.key : null}
                    checked={v.key == vendor_destination_type}
                    field="vendor_destination_type"
                    displayRadio={true}
                  />
                )}
              </div>
            </div>
            {'SUPPLIER' !== vendor_destination_type && 'OTHER' !== vendor_destination_type ?
              <Form.Select options={
                'CLIENT' === vendor_destination_type ? (hasSplitShipping(this.state.vendor_source_parent_id) ?
                  allClientAddresses : client_addresses) : company_addresses
              }
                label="Address" field="vendor_address_id"
                extra={this.state.vendor_address}
                required={true}
              /> : null
            }
            {'SPLIT-SHIPPING' === this.state.vendor_address_id && this.renderSplitShip()}
            <Form.Select options={vendor_ship_methods}
              label="Shipping Method" field="vendor_ship_method_id" required={true}
            />
            {this.renderShippingAccount('vendor_third_party_account_id', vendor_ship_method_id)}
            {(!psEligible || hasShipNotes(this.state.vendor_source_parent_id)) && <Form.Textarea 
              label="Supplier Notes" field="vendor_ship_notes"
            />}
            {!psEligible && <Form.TextInput
              label="Shipping Method (Others)" field="vendor_ship_method_other"
            />}
            {!psEligible && <Form.Textarea 
              label="Shipping Method (Details)" field="vendor_ship_method_details"
            />}
          </div>

          { 'SUPPLIER' === vendor_destination_type ?
          <div className="small-12 medium-6 columns active">
            <Form.Select searchable={true} options={supplier_accounts}
              label="Decorator" field="vendor_destination_parent_id"
              required={true}
            />
            <Form.Select options={division_addresses}
              label="Decorator Address" field="vendor_address_id"
              extra={this.state.vendor_address}
              required={true}
            />
            <div className="field row">
              <div className="small-12 medium-4 columns">
                <strong>Ship To</strong>
              </div>
              <div className="small-12 medium-8 columns">
                {decorator_ship_to_options.map(d => {
                  return <Form.Radio
                    key ={d.key}
                    label={d.value}
                    getValue={e => e.target.checked ? d.key : null}
                    checked={d.key == this.state.decorator_destination_type}
                    field="decorator_destination_type"
                    displayRadio={true}
                  />
                }
                )}
              </div>
            </div>
            <Form.Select options={
              'CLIENT' === this.state.decorator_destination_type ? (hasSplitShipping(this.state.vendor_destination_parent_id) ?
                allClientAddresses : client_addresses)  : company_addresses
            }
              label="Client Address" field="decorator_address_id"
              extra={this.state.decorator_address}
            />
            {'SPLIT-SHIPPING' === this.state.decorator_address_id && this.renderSplitShip()}
            {this.renderShipdate(
              this.getDecoratorDetails(), 'decorator_ship_date', 'decorator_firm'
            )}
            <Form.Select options={decorator_ship_methods}
              label="Shipping Method" field="decorator_ship_method_id"
            />
            <Form.Textarea 
              label="Decorator Notes" field="decorator_ship_notes"
            />
            <Form.TextInput 
              label="Shipping Method (Others)" field="decorator_ship_method_other"
            />
            <Form.Textarea 
              label="Shipping Method (Details)" field="decorator_ship_method_details"
            />
          </div> : 'OTHER' === vendor_destination_type ?
          <div className="small-12 medium-6 columns active">
            <Form.Select searchable={true} options={supplier_accounts}
              label="Supplier" field="vendor_destination_parent_id"
              required={true}
            />
            <Form.Select options={division_addresses}
              label="Supplier Address" field="vendor_address_id"
              extra={this.state.vendor_address}
              required={true}
            />
          </div> : null}
        </Form>
      </div>
    );
  }

  onUpdateShipping(values, errors) {
    if (!_.every(errors, (error) => error === null)) {
      return;
    }

    const { vendor_destination_type } = this.state;

    const item_ids = this.props.items.map(i => i.item_id);
    const vendor_details = this.getVendorDetails();
    const decorator_details = this.getDecoratorDetails();
    let vendor_shipping = {
      source_type: vendor_destination_type === 'SUPPLIER' ? 'VENDOR' : 'VENDOR-DECORATOR',
      [this.getShipdateFieldName(vendor_details)]: this.state.vendor_ship_date,
      firm: vendor_destination_type === 'SUPPLIER' ? false : this.state.vendor_firm,
      source_parent_id: this.state.vendor_source_parent_id,
      destination_type: vendor_destination_type === 'OTHER' ? 'SUPPLIER' : vendor_destination_type,
      shipdate_option: this.getShipdateOption(vendor_details),
      destination_parent_id: (
        'SUPPLIER' === vendor_destination_type || 'OTHER' === vendor_destination_type 
          ? this.state.vendor_destination_parent_id : 
        ('TENANT' === vendor_destination_type 
          ? this.props.company.company_id : this.props.client.client_id)),
      address_id: this.state.vendor_address_id,
      ship_method_id: this.state.vendor_ship_method_id,
      ship_notes: this.state.vendor_ship_notes,
      ship_method_other: this.state.vendor_ship_method_other,
      ship_method_details: this.state.vendor_ship_method_details,
      split_shipping_id: this.state.vendor_split_shipping_id,
      is_rush: this.state.vendor_is_rush,
      third_party_account_id: this.state.vendor_third_party_account_id === 'NONE' ? null : this.state.vendor_third_party_account_id
    };

    if (vendor_details.shipping_id) {
      vendor_shipping.shipping_id = vendor_details.shipping_id;
    }
    let decorator_shipping;
    if (vendor_destination_type === 'SUPPLIER') {
      decorator_shipping = {
        source_type: 'DECORATOR',
        [this.getShipdateFieldName(decorator_details)]: this.state.decorator_ship_date,
        shipdate_option: this.getShipdateOption(decorator_details),
        firm: this.state.decorator_firm,
        source_parent_id: this.state.vendor_destination_parent_id,
        destination_type: this.state.decorator_destination_type,
        destination_parent_id: ('SUPPLIER' === this.state.decorator_destination_type ? this.state.decorator_destination_parent_id : ('TENANT' === this.state.decorator_destination_type ? this.props.company.company_id : this.props.client.client_id)),
        address_id: this.state.decorator_address_id,
        ship_method_id: this.state.decorator_ship_method_id,
        ship_notes: this.state.decorator_ship_notes,
        ship_method_other: this.state.decorator_ship_method_other,
        ship_method_details: this.state.decorator_ship_method_details,
        split_shipping_id: this.state.decorator_split_shipping_id,
        is_rush: this.state.decorator_is_rush,
        third_party_account_id: this.state.decorator_third_party_account_id === 'NONE' ? null : this.state.decorator_third_party_account_id
      };
      if (decorator_details.shipping_id) {
        decorator_shipping.shipping_id = decorator_details.shipping_id;
      }
    }

    let validate_address_id = this.state.vendor_address_id
    if (this.props.psEligible) {
      if ('SPLIT-SHIPPING' === validate_address_id) {
        this.submitShipping(item_ids, vendor_shipping, decorator_shipping)
      } else if (this.props.getAddress(validate_address_id).split_shipping !== 1) {
        this.validateAddress(validate_address_id, item_ids, vendor_shipping, decorator_shipping)
      } else {
        this.submitShipping(item_ids, vendor_shipping, decorator_shipping)
      }
    }else{
      this.submitShipping(item_ids, vendor_shipping, decorator_shipping)
    }
  }

  validateAddress(address_id, item_ids, vendor_shipping, decorator_shipping) {
    oauth('POST', 'address', {
      action: 'validate-address',
      address_id
    }).then(({ json }) => {
      if(json.result !== true) {
        this.props.onCreateEditAddressPopup(address_id, this.props.psEligible, json.result)
      }else{
        this.submitShipping(item_ids, vendor_shipping, decorator_shipping)
      }
    })
  }

  submitShipping(item_ids, vendor_shipping, decorator_shipping) {
    if (this.state.temp_split_ship_file) {
      this.props.onUploadFile(this.props.items[0].order_id, 'ORDER', this.state.temp_split_ship_file).then(
        action => this.props.onUpdateShipping(item_ids, !decorator_shipping ? { ...vendor_shipping, split_shipping_id: action.payload.id } : vendor_shipping, !!decorator_shipping ? { ...decorator_shipping, split_shipping_id: action.payload.id } : decorator_shipping)
      )
    } else {
      this.props.onUpdateShipping(item_ids, vendor_shipping, decorator_shipping)
    }
  }

}

const mapStateToProps = (state, ownProps) => {
  const { hasUserFlags, hasCapabilities } = getIdentityUtils(state.identity);

  //const psEligible = hasUserFlags('PS-PO') && ownProps.items.every(i => 'ps-products' === i.copied_from);
  const psEligible = ownProps.items.every(i => 'ps-products' === i.copied_from);
  let vendor_ship_methods;
  if (psEligible && (ownProps.items[0] || {}).division_id) {
    vendor_ship_methods = getShipMethodOptions(state, { parent_id: ownProps.items[0].division_id });
  }
  if (!vendor_ship_methods || !vendor_ship_methods.length) {
    vendor_ship_methods = getShipMethodOptions(state, { parent_id: state.identity.company_id });
  }
  const  decorator_ship_methods = getShipMethodOptions(state, { parent_id: state.identity.company_id });

  const items = ownProps.items.map(i => { 
    if (i.shipping && i.shipping[0]) {
      i.vendor_details = Object.assign({ship_method_id: psEligible ? null : vendor_ship_methods[0].key}, state.entities.shipping[i.shipping[0]]);
    } else {
      i.vendor_details = { ship_method_id: psEligible ? null : vendor_ship_methods[0].key, source_parent_id: i.division_id, origin: i.origin };
    }
    if (i.shipping && i.shipping[1]) {
      i.decorator_details = Object.assign({ship_method_id: decorator_ship_methods[0].key}, state.entities.shipping[i.shipping[1]]);
    }
    return i;
  });
  const getAddresses = parent_id => getAddressOptions(state, {parent_id});
  const client = state.entities.clients[ownProps.account_id];
  const company = { company_id: state.identity.company_id, company_name: state.identity.company_name };

  const division_addresses = getAddresses(state.temp[TEMP_SUPPLIER_REGISTER] || (items[0].vendor_details ? (items[0].vendor_details.destination_parent_type === 'SUPPLIER' ? items[0].vendor_details.destination_parent_id : '') : ''));
  const in_hands_date = (state.entities.orders[items[0].order_id].date_inhandsdate).replace(/[0-9]+(:)[0-9]+(:)[0-9]+/, '');

  const getAddress = address_id => state.entities.addresses[address_id]

  const job_id = Object.keys(state.entities.projects)[0]

  return {
    canAddThirdPartyAccount: hasCapabilities('MODIFY-DOWNSTREAM-ACCOUNT'),
    psEligible,
    vendor_ship_methods,
    decorator_ship_methods,
    supplier_accounts: getSupplierAccountOptions(state),
    quirks: state.entities.promostandards_quirks,
    divisions: state.entities.supplier_accounts,
    client,
    company,
    items,
    addresses: state.entities.addresses,
    client_addresses: getAddressOptions(state, { parent_id: ownProps.account_id }),
    company_addresses: getAddressOptions(state, { parent_id: state.identity.company_id }),
    getAddresses,
    division_addresses,
    in_hands_date,
    company_data: getCompanyData(state),
    tenant_default_address_id: state.identity.tenant_default_address_id,
    getShipMethodDetails: ship_method_id => state.entities.ship_methods[ship_method_id] || {},
    third_party_accounts: getThirdPartyShippingAccountDropdown(state).sort(sortThirdPartyAccounts),
    default_third_party_shipping_accounts: state.display.defaults.third_party_shipping_accounts || {},
    company_avatar: state.identity.company_avatar.small,
    getAddress,
    split_shipping_files: state.temp[TEMP_FILE_REGISTER] ? [state.entities.files[state.temp[TEMP_FILE_REGISTER]]] : null,
    job_id
  };
}

const mapDispatchToProps = dispatch => {
  return {
    loadSupplierAddresses: division_id => {
      dispatch(createAddTemp(TEMP_SUPPLIER_REGISTER, division_id));
      dispatch(createLoadDivisionAddresses(division_id));
    },
    loadSupplierShipMethods: division_id => {
      dispatch(createLoadShipMethods(division_id));
    },
    onUpdateShipping: (item_ids, vendor_shipping, decorator_shipping) => {
      dispatch(createUpdateShipping(item_ids, vendor_shipping, decorator_shipping));
      dispatch(createDeleteTemp(TEMP_SUPPLIER_REGISTER));
      dispatch(closePopup());
    },
    onAddThirdPartyShippingAccount: ship_method_id => dispatch(createAddThirdPartyShippingAccountPopup(ship_method_id)),
    onClosePopup: () => {
      dispatch(createDeleteTemp(TEMP_SUPPLIER_REGISTER));
      dispatch(closePopup());
    },
    onCreateEditAddressPopup: (address_id, psEligible, suggestion) => {
      dispatch(createEditAddressPopup(address_id, psEligible, suggestion));
    },
    onUploadFile: (parent_id, parent_type, file) => dispatch(createUploadFile(parent_id, parent_type, file)),
    onCreateSelectFilePopup: (register, parent_id, parent_type, client_id) => {
      dispatch(createSelectFilePopup(register, parent_id, parent_type, client_id))
    }
  }
}

const ConnectedEditShippingPopup = connect(mapStateToProps, mapDispatchToProps)(EditShippingPopup);
export default ConnectedEditShippingPopup;
