import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import Form from './Form'
import LabelledContact from './LabelledContact'

import { updateProject } from '../actions/project'
import { updateOrder } from '../actions/order'
import { createEditMessagePopup, closePopup } from '../actions/popup'
import { createUpdateNote, createFetchReminders } from '../actions/message'
import { createFetchContactList, createFetchPhoneList } from '../actions'

import { getContact } from '../selectors'
import { getUserOptions, getContactOptions, getEventTypeOptions, getStatusOptions } from '../selectors/dropdowns'
import { parseMysqlDate, toTimestamp, formatMoney, toTitleCase } from '../utils'

import { BASE_ZINDEX } from '../popup-factory'

class EditProjectPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            status_id: props.job.status_id,
            date_inhandsdate: props.job.date_inhandsdate,
            sales_rep_id: props.job.sales_rep_id,
            client_rep_id: props.job.client_rep_id,
            total_budget: formatMoney(props.job.total_budget, 0),
            total_subtotal: formatMoney(props.job.total_subtotal, 0),
            event_type_id: props.job.event_type_id,
            reminder_list: props.reminder_list,
            billing_contact_id: props.job.billing_contact_id,
            contacts: [],
            billing_contact: {},
            locked: props.locked
        }

        _.bindAll(this, ['handleFieldChange', 'handleEditProject', 'onFormRef', 'handleToggleReminder'])

        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleEditProject = this.handleEditProject.bind(this);
        this.onFormRef = this.onFormRef.bind(this);
    }

    componentDidMount() {
        this.props.onCreateFetchReminders(this.props.job.job_id)
        //this.props.onCreateFetchContactList(this.props.job.job_id, this.props.job.account_id)
        //this.props.onCreateFetchPhoneList(this.props.job.job_id)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ 
            reminder_list: Object.values(nextProps.reminder_list).sort((a, b) => toTimestamp(a.date_reminder) - toTimestamp(b.date_reminder)),
            locked: nextProps.locked
        })

        if (nextProps.redux_state !== this.props.redux_state) {
            this.setState({
                contacts: getContactOptions(nextProps.redux_state, { parent_id: this.props.job.account_id }),
                billing_contact: getContact(nextProps.redux_state, { contact_id: this.state.billing_contact_id })
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener('onchange', this.handleFieldChange, false);
    }

    onFormRef(form) {
        this._form = form;
    }

    handleFieldChange(value, field) {
        //changing in-hands date of the latest order within a project
        if (field === 'date_inhandsdate' || field === 'status_id') {
            this.props.onUpdateOrder(this.props.job.order_id, field, this.state[field])(value)
            this.setState({ [field]: value })
            return
        }
        this.props.onUpdateProject(this.props.job.job_id, field, this.state[field])(value)
        if (field === 'billing_contact_id') {
            this.setState({ [field]: value, billing_contact: getContact(this.props.redux_state, { contact_id: value }) })
        }else{
            this.setState({ [field]: value })
        }
    }

    handleEditProject(values, errors) {
        if (!_.every(errors, (error) => error === null)) {
            return 
        }
    }

    handleToggleReminder(reminder) {
        return e => {
            e.preventDefault();
            const new_value = reminder.reminder_complete == 1 ? 0 : 1;
            this.props.onUpdateNote(reminder.message_id, reminder.note_id, 'reminder_complete', reminder.reminder_complete)(new_value);
        };
    }

    renderTask(t) {
        return (
            <div key={t.message_id} className="row small-12 columns" style={{ padding: 0 }}>
                <div className="column small-1" style={{ padding: 0 }}>
                    <input
                        type="checkbox"
                        style={{ marginTop: '5px' }}
                        checked={t.reminder_complete == 1}
                        onChange={this.handleToggleReminder(t)}
                    />
                </div>
                <div className="column small-9" style={{ padding: 0 }}>
                    <span style={t.reminder_complete == 1 ? { textDecoration: 'line-through' } : null} dangerouslySetInnerHTML={{ __html: t.message_text }} />
                    <br />
                    <small><b>for {t.reminder_user_first_name} {t.reminder_user_last_name} on {parseMysqlDate(t.date_reminder)}</b></small>
                </div>
                <div className="column small-2" style={{ padding: 0 }}>
                    <a style={{ float: 'right', background: "url(/images/actions-sprite-items.png) no-repeat top left", marginTop: '8px' }} 
                       onClick={e => { e.preventDefault(); this.props.onCreateEditMessagePopup(t, 'task', this.props.job_id) }}>
                       &nbsp;&nbsp;&nbsp;&nbsp;
                    </a>
                </div>
            </div>
        )
    }

    render() {
        const {
            job,
            users,
            event_types,
            statuses,
            hasCapability,
            onClosePopup,
            index } = this.props;
        
        let has_upcoming = this.state.reminder_list.filter(r => r.reminder_complete != 1).length > 0 
        let has_completed = this.state.reminder_list.filter(r => r.reminder_complete == 1).length > 0
        const locked = this.state.locked == 1

        return (
            <div id='edit-project-modal' className='reveal large' style={{ display: 'block', zIndex: BASE_ZINDEX + index }} aria-labelledby='modalTitle' aria-hidden='true' role='dialog'>
                <div className='row'>
                    <div className='small-12 columns'>
                        <h3 id='modalTitle' style={{ width: '90%' }}>{job.client_name} - Project #{job.job_number} {job.job_name}</h3>
			<div style={{ position: 'fixed', right: '1rem', top: '1rem' }}>
                        <a className='button' href={"/project/" + job.job_number} target="_blank" style={{ marginRight: '5px' }}>View Project</a>
                        <a className='button' onClick={e => onClosePopup()}>Close</a>
			</div>
                    </div>
                </div>
                <div className='row popup-content'>
                    <Form className='popup-content form columns large-6'
                        ref={this.onFormRef}
                        onFieldChange={this.handleFieldChange}
                    >   
                        <Form.TextInput label='Stage' field='order_type' value={job.order_type} disabled />
                        <Form.Select label='Status' field='status_id' options={statuses} value={this.state.status_id} disabled={locked} />
                        <Form.DateInput label='In-Hands Date' field='date_inhandsdate' value={this.state.date_inhandsdate} />
                        <Form.Select label='Order Rep' field='sales_rep_id' options={users} value={this.state.sales_rep_id} />
                        <Form.Select disabled={!hasCapability('MODIFY-CLIENT-REP')} label='Client Rep' field='client_rep_id' options={users} value={this.state.client_rep_id} />
                        <Form.TextInput label='Budget' field='total_budget' value={this.state.total_budget} />
                        <Form.TextInput label='Value' field='total_subtotal' value={job.order_type === 'SALES ORDER' || job.order_type === 'INVOICE' ? this.state.total_subtotal : 0} disabled />
                        {/* <LabelledContact
                            className="field row"
                            label="Initial Client Contact"
                            contact={this.state.billing_contact}
                            contacts={this.state.contacts}
                            side={true}
                            onChangeContact={e => this.handleFieldChange(e, 'billing_contact_id')}
                        />
                        <Form.Select label='Event Type' field='event_type_id' options={event_types} value={this.state.event_type_id} /> */}
                    </Form>
                    <div className="columns large-6" style={{ backgroundColor: '#7498AE2B', paddingBottom: '5px' }}>
                        <a style={{ float: 'right', color: '#FA297C', fontSize: 'initial', marginTop: '2px', fontWeight: 'bold' }} onClick={e => { e.preventDefault(); this.props.onCreateEditMessagePopup(null, 'task', job.job_id) }}>+ Add Task</a>
                        {has_upcoming ? <p style={{ marginTop: '2px', marginBottom: 0, lineHeight: 1.8 }}><b>Tasks</b></p> : null}
                        {has_upcoming ? this.state.reminder_list.filter(r => r.reminder_complete != 1).map(t => 
                            this.renderTask(t)
                        ) : null}
                        {has_completed ? <p style={{ marginTop: '10px', marginBottom: 0, lineHeight: 1.8 }}><b>Completed</b></p> : null}
                        {has_completed ? this.state.reminder_list.filter(r => r.reminder_complete == 1).map(t => 
                            this.renderTask(t)
                        ) : null}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        redux_state: state,
        reminder_list: Object.values(state.entities.jobs[ownProps.job.job_id]['reminder_list']).sort((a, b) => toTimestamp(a.date_reminder) - toTimestamp(b.date_reminder)),
        users: getUserOptions(state),
        event_types: getEventTypeOptions(state),
        statuses: getStatusOptions(state, ownProps.job),
        locked: state.entities.jobs[ownProps.job.job_id].locked,
        hasCapability: capability => state.identity.capabilities.includes(capability)
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onClosePopup: () => {
            dispatch(closePopup())
        },
        onUpdateNote: (message_id, note_id, field, previous_value) => value => {
            dispatch(createUpdateNote(message_id, note_id, field, previous_value, value));
        },
        onCreateFetchReminders: (job_id) => {
            dispatch(createFetchReminders(job_id));
        },
        onCreateEditMessagePopup: (message, message_type, job_id) => {
            dispatch(createEditMessagePopup(message, message_type, job_id));
        },
        onUpdateProject: (project_id, field, previous_value) => value => dispatch(updateProject(project_id, field, previous_value, value)),
        onUpdateOrder: (order_id, field, previous_value) => value => {
            dispatch(updateOrder(order_id, field, previous_value, value));
        },
        onCreateFetchContactList: (job_id, account_id) => {
            dispatch(createFetchContactList(job_id, account_id));
        },
        onCreateFetchPhoneList: (job_id) => {
            dispatch(createFetchPhoneList(job_id));
        }
    }
}


const ConnectedEditProjectPopup = connect(mapStateToProps, mapDispatchToProps)(EditProjectPopup);
export default ConnectedEditProjectPopup;
